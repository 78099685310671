import React, { Component } from 'react';
import {
  Form,
  FormRow,
  TextField,
  Button,
  Snackbar,
} from '@beetrack/hp-components';
import { Link } from 'react-router-dom';
import { resetPasswordUrl } from '../../utils/const';
import { loginMessages } from '../../utils/const';
import { login } from '../../utils/api.js';
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      message: null,
      snackbarState: false,
      snackbarStatus: 'error',
    };
  }

  componentDidMount() {
    if (this.props.location.state === 200) {
      this.setState({
        message: 'Password updated',
        snackbarStatus: 'success',
        snackbarState: true,
      });
    }
  }

  setValue = (e, key) => {
    this.setState({ [key]: e.target.value });
  };

  handleClickLogin = async () => {
    let result = await login(this.state.email, this.state.password);
    if (result.status === 200) {
      this.props.history.push('/');
    } else if (result.status === 401) {
      this.setState(
        { message: loginMessages['invalid'] },
        this.setState({ snackbarStatus: 'error', snackbarState: true }),
      );
    }
  };

  setOpen = (open) => {
    this.setState({ snackbarState: open });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={'form-container-login'}>
        <Snackbar
          key="snackbar"
          isOpen={this.state.snackbarState}
          message={this.state.message}
          onDismiss={() => this.setOpen(false)}
          status={this.state.snackbarStatus}
        />
        <div className={'row-login'}>
          <div className={'column-login'}>
            <div className={'logo-position-login'}>
              <img
                src={
                  'https://beetrack-hive.s3-us-west-2.amazonaws.com/hive/hive_logo_big.png'
                }
              />
            </div>
          </div>
          <div className={'column-login'}>
            <div className={'form-position-login'}>
              <Form
                title="Beetrack Hive"
                buttons={[
                  <Button type="primary" onClick={this.handleClickLogin}>
                    Iniciar Sesión
                  </Button>,
                ]}
              >
                <FormRow>
                  <TextField
                    value={this.state.email}
                    onChange={(e) => this.setValue(e, 'email')}
                    label="Email"
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        this.handleClickLogin();
                      }
                    }}
                  />
                </FormRow>
                <FormRow>
                  <TextField
                    value={this.state.password}
                    onChange={(e) => this.setValue(e, 'password')}
                    label="Contraseña"
                    type={'password'}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        this.handleClickLogin();
                      }
                    }}
                  />
                </FormRow>
                <div className={'forgot-password'}>
                  <Link to={resetPasswordUrl}>
                    <span>Olvidé mi contraseña</span>
                  </Link>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
