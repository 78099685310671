import React from 'react';
import { FormRow, FormGroup, TextField } from '@beetrack/hp-components';
import '../../../styles.scss';

export function generateBase(plan, idx, setter, readOnly = false) {
  if (plan.condition.base === undefined) {
    return <></>;
  }

  return (
    <FormGroup title="Base" collapsible={false}>
      <div>
        <FormRow additionalClassName="grid">
          <TextField
            additionalClassName="grid__item-6"
            placeholder="Cantidad"
            label="Cantidad incluida"
            value={plan.condition.base.qty}
            type="number"
            disabled
            readOnly={readOnly}
            fullWidth
          />
          <TextField
            additionalClassName="grid__item-6"
            placeholder="Precio"
            label={`Precio en ${plan.currency}`}
            value={plan.condition.base.price}
            onChange={(e) => setter(e.target.value, idx)}
            type="number"
            readOnly={readOnly}
            fullWidth
          />
        </FormRow>
      </div>
    </FormGroup>
  );
}
