import React, { useState } from 'react';
import { Table } from '@beetrack/hp-components';
import { Edit, View, Delete } from '@beetrack/hp-icons-react';

const PrepaidsTable = (props) => {
  const history = props.history;
  const columns = [
    {
      title: 'Cliente',
      name: 'deal_name',
      valueByAttribute: 'deal_name',
    },
    {
      title: 'Producto',
      name: 'product',
      valueByAttribute: 'product',
    },
    {
      title: 'Cantidad prepagada',
      name: 'quantity',
      valueByAttribute: 'quantity',
    },
    {
      title: 'Desde',
      name: 'from',
      valueByAttribute: 'from',
    },
    {
      title: 'Hasta',
      name: 'to',
      valueByAttribute: 'to',
    },
  ];
  const actions = [
    {
      title: 'Editar',
      icon: <Edit />,
      onClick: (e) => props.handleEdit(e[0].item),
      isGlobal: false,
      key: 'edit',
    },
    {
      title: 'Ver cliente',
      icon: <View />,
      onClick: (e) => history.push(`/client/${e[0].item.hubspot_id}`),
      isGlobal: false,
      key: 'view',
    },
    {
      title: 'Eliminar prepago',
      icon: <Delete />,
      onClick: (e) => props.handleDelete(e[0].item),
      isGlobal: false,
      key: 'delete',
    },
  ];
  const data = props.data;
  const loading = props.loading;

  return (
    <Table
      columns={columns}
      data={data}
      fetchData={() => {}}
      loading={loading}
      actions={actions}
    ></Table>
  );
};

export default PrepaidsTable;
