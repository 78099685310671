import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { havePermissions } from '../../../../utils/utils';
import { CheckCircle, Feedback, Visibility } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { NoDataState, SuccessState, Snackbar, InfoBox } from '@beetrack/hp-components';
import { post } from '../../../../utils/api';

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
  },
});

const DraftTable = (props) => {

  const {
    dataTable,
    billingPeriod,
    tableType,
    setModalComment,
    setModalDraft
  } = props

  const [selectedRow, setSelectedRow] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [snackBar, setSnackBar] = useState({
    open: false,
    status: 'close',
    message: '',
  });

  const setColumns = () => {
    return [
      { title: 'ID', field: 'id', searchable: false, hidden: true },
      {
        title: 'Nombre',
        field: 'name',
        field: 'company_name',
        filtering: false,
      },
      {
        title: 'Período Facturación',
        field: 'facturation_date',
        filtering: false,
      },
      { title: 'LastMile', field: 'last_mile', filtering: false },
      { title: 'Tarifa LM - $ local', field: 'last_mile_rate', filtering: false },
      { title: 'PlannerPro', field: 'planner_pro', filtering: false },
      { title: 'Tarifa PP - $ local', field: 'planner_pro_rate', filtering: false },
      { title: 'OnDemand', field: 'on_demand', filtering: false },
      { title: 'Tarifa OD - $ local', field: 'on_demand_rate', filtering: false },
      { title: 'Email', field: 'mail', filtering: false },
      { title: 'SMS', field: 'sms', filtering: false },
      { title: 'WhatsApp', field: 'wsp', filtering: false },
      { title: 'Otros', field: 'other', filtering: false },
      {
        title: 'Monto Total',
        field: 'total_amount',
        filtering: false,
        render: (rowData) => '$ ' + rowData.total_amount.toLocaleString(),
        cellStyle: {
          textAlign: 'left',
        },
      },
      {
        title: 'Tipo de Documento',
        field: 'type',
        lookup: { estimate: 'Estimación', invoice: 'Factura' },
      },
    ];
  };

  const approveDraft = (invoiceId) => {
    let payload = {
      status: 2,
      comment: 'Aprobado por vendedor',
      invoice_draft_id: invoiceId,
    };
    post('/draft_comments', payload).then((postResponse) => {
      if (postResponse.status === 201) {
        props.reloadAction();
        setSnackBar({
          open: true,
          status: 'success',
          message: 'Borrador Aprobado',
        });
      } else {
        setSnackBar({
          open: true,
          status: 'error',
          message: 'Ha ocurrido un problema',
        });
      }
    });
  };

  const handleSelectionChange = (rows) => {
    setSelectedRows(rows);
  };

  const handleApproveMultipleDrafts = () => {
    const selectedRowIds = selectedRows.map(row => row.id);
    post('/draft_comments/approve_multiple', {
      ids: selectedRowIds,
    }).then((response) => {
      if (response.status === 200) {
        setSnackBar({
          open: true,
          status: 'success',
          message: 'Borradores aprobados',
        });
      } else {
        setSnackBar({
          open: true,
          status: 'error',
          message: 'Ocurrió un error',
        });
      }
      props.reloadAction();
    });
  };

  const setActions = (selectedRows) => {
    if (havePermissions(['sales_man']) && tableType === 'pending') {
      if (selectedRows && selectedRows.length > 1) {
        return [
          {
            icon: () => <CheckCircle style={{ color: 'green' }} />,
            tooltip: 'Aprobar Borradores',
            onClick: handleApproveMultipleDrafts,
          }
        ];
      } else if (selectedRows && selectedRows.length == 1) {
        return [
          {
            icon: () => <CheckCircle style={{ color: 'green' }} />,
            tooltip: 'Aprobar Borrador',
            onClick: (_, selectedRows) => approveDraft(selectedRows[0].id),
          },
          {
            icon: () => <Feedback />,
            tooltip: 'Agregar Comentarios',
            onClick: (_, selectedRows) =>
              setModalComment({ open: true, data: selectedRows[0] }),
          },
          {
            icon: () => <Visibility />,
            tooltip: 'Visualizar Borrador',
            onClick: (_, selectedRows) => setModalDraft({ open: true, data: selectedRows[0] }),
          },
        ];
      };
    } else if (tableType === 'other') {
      return [
        {
          icon: () => <Visibility />,
          tooltip: 'Visualizar Borrador',
          onClick: (_, rowData) => setModalDraft({ open: true, data: rowData }),
        },
      ];
    } else {
      return [];
    }
  };

  return (
    <div>
      {dataTable.length !== 0 && (
        <MaterialTable
          title={'Borradores asignados a mi'}
          pageSize={20}
          pageSizeOptions={[20, 30, 50]}
          columns={setColumns()}
          data={dataTable}
          detailPanel={[
            {
              tooltip: "Último comentario",
              render: rowData => {
                return (
                  <div>
                    {rowData.last_comment ? (
                      rowData.last_comment.status == 'approved' ? (
                        <InfoBox status='success'>
                          {"Comentario última factura ✍️➡️ "}
                          {rowData.last_comment.comment.toLocaleString()}
                        </InfoBox>
                      ) : (
                        <InfoBox>
                          {"Comentario última factura ✍️➡️ "}
                          {rowData.last_comment.comment.toLocaleString()}
                        </InfoBox>
                      )
                    ) : (
                      <InfoBox status='error'>
                        La última factura no tiene ningún comentario
                      </InfoBox>)
                }
                </div>
                )}
              }
            ]}
          actions={setActions(selectedRows || [])}
          options={{
            actionsColumnIndex: -1,
            filtering: true,
            rowStyle: (rowData) => ({
              backgroundColor: selectedRow === rowData.id ? '#EEE' : '#FFF',
            }),
            pageSize: 20,
            pageSizeOptions: [20, 30, 50],
            selection: tableType === 'pending',
          }}
          onSelectionChange={handleSelectionChange}
          localization={{
            body: {
              emptyDataSourceMessage: 'No hay documentos por resolver',
            },
            header: {
              actions: 'Acciones',
            },
          }}
          onRowClick={(evt, selectedRow) => setSelectedRow(selectedRow.id)}
        />
      )}
      {dataTable.length === 0 && tableType === 'pending' && (
        <SuccessState text="Todos tus borradores asignados han sido resueltos" />
      )}
      {dataTable.length === 0 && tableType === 'other' && (
        <NoDataState text="No hay facturas para mostrar" />
      )}
      {snackBar.open && (
        <Snackbar
          key="snackbar-table"
          isOpen={snackBar.open}
          message={snackBar.message}
          onDismiss={() =>
            setSnackBar({
              open: !snackBar.status,
              status: 'close',
              message: '',
            })
          }
          status={snackBar.status}
        />
      )}
    </div>
  );
};

export default DraftTable;
