import React, { useState } from 'react';
import { Table } from '@beetrack/hp-components';
import { View, Check, Close } from '@beetrack/hp-icons-react';
import { patch } from '../../../../../utils/api';
import ConfirmationModal from '../../../../../shared/ConfirmationModal';

const BillsTable = (props) => {
  const { bills, permissionType, setSnack, reload, setReload } = props;
  const [modal, setModal] = useState({
    open: false,
    func: () => {},
    text: '',
    secondaryText: '',
    id: '',
  });

  const approveBill = (bill) => {
    if (bill.item.status === 'Aprobado') {
      setSnack({
        open: true,
        status: 'error',
        message: 'Factura ya ha sido aprobada',
      });
    } else if (bill.item.status === 'Rechazado') {
      setSnack({
        open: true,
        status: 'error',
        message: 'Factura ya ha sido rechazada',
      });
    } else {
      patch(`/bills/${bill.item.id}`, { status: 'approved' }).then(
        (response) => {
          if (response.status === 200) {
            setReload(reload + 1);
            setSnack({
              open: true,
              status: 'success',
              message: 'Factura aprobada correctamente',
            });
          } else {
            setSnack({
              open: true,
              status: 'error',
              message: 'Problemas aprobando factura, intente nuevamente',
            });
          }
        },
      );
    }
  };

  const rejectBill = (bill) => {
    if (bill.item.status === 'Aprobado') {
      setSnack({
        open: true,
        status: 'error',
        message: 'Factura ya ha sido aprobada',
      });
    } else if (bill.item.status === 'Rechazado') {
      setSnack({
        open: true,
        status: 'error',
        message: 'Factura ya ha sido rechazada',
      });
    } else {
      patch(`/bills/${bill.item.id}`, { status: 'rejected' }).then(
        (response) => {
          if (response.status === 200) {
            setReload(reload + 1);
            setSnack({
              open: true,
              status: 'success',
              message: 'Factura rechazada correctamente',
            });
          } else {
            setSnack({
              open: true,
              status: 'error',
              message: 'Problemas rechazando factura, intente nuevamente',
            });
          }
        },
      );
    }
  };
  const columns = [
    {
      name: 'due_date',
      title: <strong> Fecha de vencimiento </strong>,
      valueByAttribute: 'due_date',
    },
    {
      name: 'amount',
      title: <strong> Monto </strong>,
      valueByAttribute: 'amount',
    },
    {
      name: 'stauts',
      title: <strong> Estado </strong>,
      valueByAttribute: 'status',
    },
  ];
  const actionsTeamLeader = [
    {
      title: 'Ver factura',
      icon: <View />,
      onClick: (e) => {
        window.open(e[0].item.url, '__blank');
      },
      isGlobal: false,
    },
    {
      title: 'Aprobar',
      icon: <Check />,
      onClick: (e) =>
        setModal({
          open: true,
          func: () => approveBill(e[0]),
          text: '¿Aprobar factura?',
          secondaryText:
            'Se aprobará la factura del proveedor y se notificará a Payments.',
        }),
      isGlobal: false,
      hidden: true,
    },
    {
      title: 'Rechazar',
      icon: <Close />,
      onClick: (e) =>
        setModal({
          open: true,
          func: () => rejectBill(e[0]),
          text: '¿Rechazar factura?',
          secondaryText:
            'Se rechazará la factura del proveedor y se notificará a Payments.',
        }),
      isGlobal: false,
      hidden: true,
    },
  ];

  const actionsAccounting = [
    {
      title: 'Ver factura',
      icon: <View />,
      onClick: (e) => {
        window.open(e[0].item.url, '__blank');
      },
      isGlobal: false,
    },
  ];

  return (
    <>
      <ConfirmationModal
        text={modal.text}
        secondaryText={modal.secondaryText}
        open={modal.open}
        close={() =>
          setModal({
            open: false,
            func: () => {},
            text: '',
            secondaryText: '',
            id: '',
          })
        }
        onConfirm={() => modal.func()}
      />
      <Table
        columns={columns}
        data={bills}
        fetchData={() => {}}
        actions={
          permissionType == '?accounting'
            ? actionsAccounting
            : actionsTeamLeader
        }
      />
    </>
  );
};

export default BillsTable;
