export const useStyles = (theme) => ({
  honeypotCardFullWidth: {
    width: 'auto',
    backgroundColor: '#fff',
    color: '#000',
    borderRadius: '2px',
    borderTop: '3px solid #f5b63e',
  },
  datepicker: {
    width: '200px',
  },
});
