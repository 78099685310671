import React, { useEffect, useState } from 'react';
import './styles.scss';
import {
  Accordion,
  AccordionItem,
  Button,
  Form,
  FormRow,
  TextField,
  Select,
  ConfirmationModal,
  Snackbar,
} from '@beetrack/hp-components';
import { Save, Delete } from '@beetrack/hp-icons-react';

import { get, post, put, destroy } from '../../../utils/api';

export default (props) => {
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(0);
  const [editDisabled, setEditDisabled] = useState(true);
  const [snackBar, setSnackBar] = useState({
    open: false,
    status: 'success',
    message: '',
  });

  useEffect(() => {
    retrieveData();
  }, []);

  const retrieveData = async () => {
    let response = await get(`/clients/${props.hubspot_id}/custom_fields`);
    if (response.status === 200) {
      if (response.data.columns !== undefined)
        setColumns(response.data.columns);
      if (response.data.data !== undefined) setData(response.data.data);
    }
  };

  const addCustomField = () => {
    const newData = [...data];
    newData.push({
      custom_field_value: '',
      custom_field_type: '',
    });
    setData(newData);
    setEditDisabled(false);
  };

  return (
    <>
      <Accordion>
        <AccordionItem
          launcher={
            <div className="hp-page__header-title">Campos Personalizados</div>
          }
          id={1}
          selected={selected === 1}
          setSelected={setSelected}
          buttons={[
            <Button
              onClick={() => setSelected(0)}
              key="close-custom-fields"
              type="tertiary"
              flat
            >
              Cerrar
            </Button>,
            <Button
              onClick={(e) => setEditDisabled(!editDisabled)}
              key="edit/cancel-custom-fields"
              type="secondary"
              flat
            >
              {editDisabled ? 'Editar' : 'Cancelar'}
            </Button>,
          ]}
        >
          <Form title={<>Campos Personalizado</>}>
            {data.map((customField) => (
              <CustomFieldField
                options={columns}
                disabled={editDisabled}
                dealId={props.hubspot_id}
                customField={customField}
                setSnackBar={setSnackBar}
              />
            ))}
          </Form>
          <br />
          <Button
            key="submit"
            form="my-form"
            type="tertiary"
            onClick={addCustomField}
          >
            + Añadir campo personalizado
          </Button>
        </AccordionItem>
      </Accordion>
      <Snackbar
        key="snackbar-table"
        isOpen={snackBar.open}
        message={snackBar.message}
        onDismiss={() =>
          setSnackBar({
            open: !snackBar.status,
            status: 'success',
            message: '',
          })
        }
        status={snackBar.status}
      />
    </>
  );
};

const CustomFieldField = (props) => {
  const { options, dealId, setSnackBar } = props;
  const [customField, setCustomField] = useState(props.customField);
  const [hideAfterDelete, setHideAfterDelete] = useState(false);
  const [newCustomField, setNewCustomField] = useState(
    customField.id === undefined || customField.id === null,
  );
  const disabled = props?.disabled || false;
  const [text, setText] = useState(customField.custom_field_value);
  const [selected, setSelected] = useState(customField.custom_field_type);
  const [open, setOpen] = useState(false);

  const saveRow = () => {
    const payload = {
      custom_field_type: selected,
      custom_field_value: text,
    };
    newCustomField ? saveNew(payload) : saveInstance(payload);
  };

  const saveNew = (payload) => {
    post(`/clients/${dealId}/custom_fields`, payload).then((response) => {
      if (response.status === 201) {
        setSnackBar({
          status: 'success',
          message: `Se creó el campo ${text}`,
          open: true,
        });
        setCustomField({ ...customField, id: response.data.custom_field_id });
        setNewCustomField(false);
      } else {
        setSnackBar({
          status: 'error',
          message: `No se pudo crear el campo ${text}`,
          open: true,
        });
      }
    });
  };

  const saveInstance = (payload) => {
    put(`/clients/${dealId}/custom_fields/${customField.id}`, payload).then(
      (response) => {
        if (response.status === 200) {
          setSnackBar({
            status: 'success',
            message: `Se actualizó el campo ${text}`,
            open: true,
          });
        } else {
          setSnackBar({
            status: 'error',
            message: `No se pudo actualizar el campo ${text}`,
            open: true,
          });
        }
      },
    );
  };

  const deleteRow = () => {
    setOpen(false);
    newCustomField ? null : deleteInstance();
    setHideAfterDelete(true);
  };

  const deleteInstance = () => {
    destroy(`/clients/${dealId}/custom_fields/${customField.id}`).then(
      (response) => {
        if (response.status === 204) {
          setSnackBar({
            status: 'success',
            message: `Se eliminó el campo ${text}`,
            open: true,
          });
        } else {
          setSnackBar({
            status: 'error',
            message: `No se pudo eliminar el campo ${text}`,
            open: true,
          });
        }
      },
    );
  };

  return (
    <>
      {hideAfterDelete ? (
        <></>
      ) : (
        <>
          <FormRow additionalClassName="grid">
            <Select
              key="simple-select-1"
              value={selected}
              options={options}
              label="Tipo de campo personalizado"
              onChange={(e) => setSelected(e.value)}
              disabled={disabled}
            />
            <TextField
              value={text}
              label="Valor del campo personalizado"
              additionalClassName="grid__item-6"
              onChange={(e) => setText(e.target.value)}
              disabled={disabled}
            />
            <Button
              type="tertiary"
              icon={<Save />}
              disabled={disabled}
              onClick={saveRow}
            />
            <p style={{ color: 'white' }}>a</p>
            <Button
              type="danger"
              icon={<Delete />}
              disabled={disabled}
              onClick={() => setOpen(!open)}
            />
            <ConfirmationModal
              key="custom-field-destroy"
              title="Eliminar campo personalizado"
              open={open}
              type="destroy"
              onClose={() => setOpen(!open)}
              confirmButton={
                <Button type="danger" onClick={deleteRow}>
                  Eliminar
                </Button>
              }
            >
              La acción no será reversible
            </ConfirmationModal>
          </FormRow>
        </>
      )}
    </>
  );
};
