import React, { useState, useEffect } from 'react';
import { Page, Snackbar, Button } from '@beetrack/hp-components';
import { get, put } from '../../../utils/api';
import { useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';
import { havePermissions } from '../../../utils/utils';
// left out commented for when HP supports multi line rows
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { Loading } from '@beetrack/hp-components';
// custom modals
import PlanModal from './planModal.js';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  loadingContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
  containerIsloading: {
    background: 'white',
    paddingTop: `${window.innerHeight - 100}px`,
    borderRadius: '12px',
  },
  containerIsNotloading: {},
}));

const CommercialConditionsApprovalTable = (props) => {
  const history = useHistory();

  const [planModal, setPlanModal] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  // snackbar
  const [snackState, setSnackState] = useState({
    open: false,
    text: '',
    type: 'error',
  });

  const classes = useStyles();

  const columns = [
    {
      field: 'dealname',
      title: 'Nombre del negocio',
      valueByAttribute: 'dealname',
    },
    {
      field: 'created_by',
      title: 'Vendedor',
      valueByAttribute: 'created_by',
    },
    {
      field: 'beetrackId',
      title: 'Beetrack ID',
      valueByAttribute: 'beetrackId',
    },
    {
      field: 'plans',
      title: 'Productos/Servicios',
      filtering: false,
    },
    {
      field: 'currency',
      title: 'Moneda',
      valueByAttribute: 'currency',
    },
    {
      field: 'others',
      title: 'Otros cobros',
      filtering: false,
    },
    {
      field: 'price',
      title: 'Precio',
      filtering: false,
    },
    {
      field: 'comment',
      title: 'Comentario',
      filtering: false,
    },
  ];

  const [proposals, setProposals] = useState([]);

  const _setPlansOnModal = (plan) => {
    setOpen(true);
    setPlanModal(plan);
  };

  const _planLinkGenerator = (plans) => {
    return (
      <ul>
        {plans.map((plan) =>
          plan.product !== 'other' ? (
            <>
              <li>
                <a
                  onClick={(e) => {
                    _setPlansOnModal(plan);
                  }}
                  style={{ cursor: 'pointer', color: '#0792E0' }}
                >
                  {plan.name}
                </a>
              </li>
            </>
          ) : (
            <></>
          ),
        )}
      </ul>
    );
  };

  const _otherNamesparser = (plans) => {
    return (
      <ul>
        {plans.map((plan) =>
          plan.product === 'other' ? <li>{plan.name}</li> : <></>,
        )}
      </ul>
    );
  };

  const _otherPricesParser = (plans) => {
    return (
      <>
        {plans.map((plan) =>
          plan.product === 'other' ? (
            <>
              {plan.condition.base.price}
              <br />{' '}
            </>
          ) : (
            <></>
          ),
        )}
      </>
    );
  };

  const paseIntoTableData = (proposals) => {
    var data = [];
    proposals.forEach((proposal) => {
      let currencies = proposal.plan_relations.map(
        (plan_relation) => plan_relation.plan.currency,
      );
      currencies = [...new Set(currencies)].join(', ');

      let plans = proposal.plan_relations.map((pr) => pr.plan);

      data.push({
        proposalId: proposal.id,
        dealname: proposal.dealname,
        beetrackId: proposal.beetrack_id,
        dealId: proposal.deal_id,
        created_by: proposal.created_by,
        currency: currencies,
        plans: _planLinkGenerator(plans),
        others: _otherNamesparser(plans),
        price: _otherPricesParser(plans),
        comment: proposal.comment?.body,
      });
    });
    return data;
  };

  const getConditionData = () => {
    get(`/commercial_conditions/proposals`).then((response) => {
      if (response.status === 200) {
        setProposals(response.data.data.plan_relation_proposals.nodes);
      }
      setLoading(false);
    });
  };

  const updatePlanRelation = (data, approved) => {
    put(`/commercial_conditions/proposals/${data.proposalId}`, {
      approved: approved,
    }).then((response) => {
      if (response.status === 200) {
        if (approved) {
          setSnackState({
            open: true,
            text: (
              <>
                La condición comercial para el cliente "<b>{data.dealname}</b>"
                (ID: {data.dealId}) fue <b>aceptada</b> con éxito.
              </>
            ),
            type: 'success',
          });
        } else {
          setSnackState({
            open: true,
            text: (
              <>
                La condición comercial para el cliente "<b>{data.dealname}</b>"
                (ID: {data.dealId}) fue <b>rechazada</b> con éxito.
              </>
            ),
          });
        }
        setProposals(
          proposals.filter((proposal) => data.proposalId !== proposal.id),
        );
      } else if (response.status === 400) {
        setSnackState({
          open: true,
          text: response.data.errors[0].message,
          type: 'error',
        });
      } else {
        setSnackState({
          open: true,
          text: 'No se pudo realizar la acción. Vuelve a intentarlo.',
          type: 'error',
        });
      }
    });
  };

  const handleOnClickNewCondition = () => {
    history.push(`/sales/new-custom-commercial-condition`);
  };

  useEffect(() => {
    getConditionData();
  }, []);

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#f5b73c',
      },
      secondary: {
        main: '#f5b73c',
      },
    },
  });

  return (
    <Page
      title={<>Solicitudes condiciones comerciales</>}
      // buttons={[
      //   havePermissions(['admin_sales_man']) && (
      //     <Button key="redirector" onClick={(e) => handleOnClickNewCondition()}>
      //       + Nueva condición
      //     </Button>
      //   ),
      // ]}
    >
      <div
        className={
          loading ? classes.containerIsloading : classes.containerIsNotLoading
        }
      >
        {proposals.length !== 0 ? (
          <PlanModal plan={planModal} open={open} setOpen={setOpen} />
        ) : (
          ''
        )}
        {/* <p>
          Aquí podrás aprobar o rechazar las “Condiciones comerciales” creadas por
          el equipo de ventas.{' '}
        </p> */}
        {loading && (
          <div className={classes.loadingContainer}>
            <Loading />
          </div>
        )}
        {!loading && (
          <MuiThemeProvider theme={theme}>
            <MaterialTable
              title="Aquí podrás aprobar o rechazar las “Condiciones comerciales” creadas por el equipo de ventas."
              columns={columns}
              data={paseIntoTableData(proposals)}
              options={{
                search: false,
                filtering: true,
                actionsColumnIndex: -1,
              }}
              actions={[
                {
                  icon: 'check',
                  tooltip: 'Aprobar',
                  onClick: (event, rowData) =>
                    updatePlanRelation(rowData, true),
                },
                {
                  icon: 'close',
                  tooltip: 'Rechazar',
                  onClick: (event, rowData) =>
                    updatePlanRelation(rowData, false),
                },
              ]}
            />
          </MuiThemeProvider>
        )}
      </div>
      <Snackbar
        key="snackbar"
        isOpen={snackState.open}
        message={snackState.text}
        onDismiss={() => setSnackState({ ...!open })}
        status={snackState.type === undefined ? '' : snackState.type}
      />
    </Page>
  );
};

export default CommercialConditionsApprovalTable;
