import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ConditionsTable from '../ConditionsTable';
import HistoryIcon from '@material-ui/icons/History';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import moment from 'moment';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import { Accordion, AccordionItem, Button } from '@beetrack/hp-components';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const DraftCollapsable = (props) => {
  const classes = useStyles();
  const products = props.products;
  const comments = props.comments;
  const invoiceId = props.invoiceId;
  const [selected, setSelected] = useState(null);

  return (
    <div className={classes.root}>
      <Accordion >
        <AccordionItem
          launcher={<h3 style={{ color: '#546884' }}>Condiciones Comerciales</h3>}
          selected={selected === '1'}
          id='1'
          setSelected={setSelected}
          dense={true}
          buttons={
            <Button key='key-2' flat onClick={() => setSelected(null)}>Cerrar</Button>
          }
        >
          { products?.map((product) => (
            <ConditionsTable plan={product.plan} />
          )) || <></> }
        </AccordionItem>
        <AccordionItem
          selected={selected === '2'}
          id='2'
          setSelected={setSelected}
          launcher={<h3 style={{ color: '#546884' }}>Comentarios Históricos</h3>}
          dense={true}
          buttons={
            <Button key='key-2' flat onClick={() => setSelected(null)}>Cerrar</Button>
          }
        >
          <List>
          <p className='hp-typography__subtitle--medium' style={{ color: '#546884' }}> Comentarios de las últimas 3 facturas emitidas</p>
            {comments.map((comment, key) => (
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar>
                    {invoiceId === comment.draft_id ? (
                      <Tooltip title="Comentario borrador actual">
                        <NewReleasesIcon style={{ color: 'green' }} />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Comentario factura antigua">
                        <HistoryIcon />
                      </Tooltip>
                    )}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    comment.creator +
                    ' - ' +
                    moment(comment.date).format('DD/MM/YYYY')
                  }
                  secondary={comment.comment}
                />
                <Divider variant="inset" component="li" />
              </ListItem>
            ))}
          </List>
        </AccordionItem>
    </Accordion>
    </div>
  );
};
export default DraftCollapsable;
