import React, { useState, useEffect } from 'react';
import { Select } from '@beetrack/hp-components';
import NewCommissions from '../NewCommissions';
import { get } from '../../utils/api';
import { userData } from '../../utils/utils';

const NewCommissionManagement = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({ label: '', value: '' });

  const fetchUserData = () => {
    get('/users').then((response) => {
      // map to label value pair
      if (response.status === 200) {
        const userMap = response.data.users.data.map((user) => {
          return { label: user.attributes.name, value: user.id };
        });
        const loggedUser = userData();
        const currentUser = userMap.find(
          (user) => user.label === loggedUser.user.name,
        );
        setSelectedUser(currentUser);
        setUsers(userMap);
      }
    });
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <>
      <NewCommissions
        userSelector={
          <Select
            key="simple-select-1"
            value={selectedUser.value}
            label="Vendedor"
            options={users}
            onChange={(e) => setSelectedUser(e)}
          />
        }
        userId={selectedUser.value}
        actionsAllowed={true}
      />
    </>
  );
};

export default NewCommissionManagement;
