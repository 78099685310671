import React, { useState } from 'react';
import { InfoBox, Radio, IconButton } from '@beetrack/hp-components';
import { CircleEmpty, CheckCircle } from '@beetrack/hp-icons-react';
import { post } from '../../../../utils/api';
import DOMPurify from 'dompurify';
import { Grid } from '@material-ui/core';

const Task = (props) => {
  const body = DOMPurify.sanitize(props.body);
  const { id, setCompletedTasks, completedTasks } = props;
  const [completed, setCompleted] = useState(props.completed);

  const check = () => {
    setCompleted(!completed);
    let status = completed ? 'unmark' : 'mark';
    if (status === 'mark') {
      setCompletedTasks(completedTasks + 1);
    } else {
      setCompletedTasks(completedTasks - 1);
    }
    post(`/check_list/${status}/${id}`, { team: 'sales' });
  };
  return (
    <div
      className="checklist-task"
      style={{
        margin: '2%',
        padding: '1%',
        border: `1px solid ${completed ? '#28a745' : '#bfbfbf'}`,
        borderRadius: '5px',
      }}
    >
      <Grid container spacing={0} alignItems="center">
        <Grid item xs={11}>
          {completed ? (
            <p
              className="hp-typography__subtitle--medium"
              style={{ color: 'grey', paddingLeft: '24px' }}
            >
              {' '}
              <s>
                {' '}
                <div dangerouslySetInnerHTML={{ __html: body }} />
              </s>{' '}
            </p>
          ) : (
            <p
              className="hp-typography__subtitle--medium"
              style={{ paddingLeft: '24px' }}
            >
              {' '}
              <div dangerouslySetInnerHTML={{ __html: body }} />{' '}
            </p>
          )}
        </Grid>
        <Grid item xs={1}>
          {!completed && (
            <IconButton
              size="small"
              onClick={() => check()}
              icon={<CircleEmpty additionalClassName="unchecked-circle-icon" />}
            />
          )}
          {completed && (
            <IconButton
              size="small"
              onClick={() => check()}
              icon={<CheckCircle additionalClassName="checked-circle-icon" />}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Task;
