import React, { useState, useEffect } from 'react';
import { Table, SearchBar, Snackbar } from '@beetrack/hp-components';
import { Edit, Delete } from '@beetrack/hp-icons-react';
import { get, post } from '../../../../utils/api';
import ConfirmationModal from '../../../../shared/ConfirmationModal';

const InventoryTable = (props) => {
  const { history } = props;
  const [inventory, setInventory] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState({});
  const [modalAccept, setModalAccept] = useState({ open: false, id: null });
  const [snack, setSnack] = useState({
    open: false,
    status: 'success',
    message: '',
  });
  const [reload, setReload] = useState(0);

  const handleViewItem = (item) => {
    history.push(`/hr/resources/inventory/${item[0].item.id}`);
  };

  const handleDeleteItem = (item) => {
    setModalAccept({ open: true, id: item[0].item.id });
  };

  const deleteInventory = (id) => {
    post('/resources/discard', { list: [id] }).then((response) => {
      if (response.status === 200) {
        setReload(reload + 1);
        setSnack({
          open: true,
          status: 'success',
          message: 'Inventario eliminado exitosamente',
        });
      } else {
        setSnack({
          open: true,
          status: 'error',
          message: 'Problema eliminando inventario, intente nuevamente',
        });
      }
    });
  };

  const handleSearch = (value) => {
    setSearch(value);
    var new_data = data;
    if (value.name) {
      new_data = new_data.filter((element) =>
        element.name.toLowerCase().includes(value.name.toLowerCase()),
      );
    } else if (value.object_type) {
      new_data = new_data.filter((element) =>
        element.object_type
          .toLowerCase()
          .includes(value.object_type.toLowerCase()),
      );
    } else if (value.identifier) {
      new_data = new_data.filter((element) =>
        element.identifier
          .toLowerCase()
          .includes(value.identifier.toLowerCase()),
      );
    } else if (value.user_name) {
      new_data = new_data.filter((element) =>
        element.user_name.toLowerCase().includes(value.user_name.toLowerCase()),
      );
    }
    setData(new_data);
  };

  const columns = [
    { name: 'name', title: 'Nombre', valueByAttribute: 'name' },
    {
      name: 'identifier',
      title: 'Identificador',
      valueByAttribute: 'identifier',
    },
    {
      name: 'user_name',
      title: 'Tracker',
      valueByAttribute: 'user_name',
    },
  ];

  const actions = [
    {
      title: 'Más información',
      icon: <Edit />,
      onClick: handleViewItem.bind(this),
      isGlobal: false,
    },
    {
      title: 'Eliminar',
      icon: <Delete />,
      onClick: handleDeleteItem.bind(this),
      isGlobal: false,
    },
  ];

  useEffect(() => {
    get('/resources/show/inventory').then((response) => {
      if (response.status == 200) {
        let result = [];
        for (const key in response.data) {
          result = result.concat(response.data[key]);
        }
        setInventory(result);
        setData(result);
        setLoading(false);
      }
    });
  }, [reload]);

  return (
    <div className="inventory-table">
      <SearchBar
        callback={(value) => handleSearch(value)}
        emptyCallback={() => {
          setSearch({});
          setData(inventory);
        }}
        value={search}
        options={[
          { label: 'Nombre', name: 'name' },
          { label: 'Tipo', name: 'object_type' },
          { label: 'Identificador', name: 'identifier' },
          { label: 'Tracker', name: 'user_name' },
        ]}
      />
      <br />
      <Table
        columns={columns}
        data={data}
        loading={loading}
        fetchData={() => {}}
        actions={actions}
        isItemLoaded={() => {}}
      />
      <ConfirmationModal
        text="¿Borrar inventario?"
        secondaryText="Se eliminará el inventario"
        open={modalAccept.open}
        close={() => setModalAccept({ open: false, id: null })}
        onConfirm={() => deleteInventory(modalAccept.id)}
      />
      <Snackbar
        key="snackbar-table"
        isOpen={snack.open}
        message={snack.message}
        onDismiss={() =>
          setSnack({
            open: !snack.open,
            status: snack.status,
            message: '',
          })
        }
        status={snack.status}
      />
    </div>
  );
};

export default InventoryTable;
