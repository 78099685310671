import React, { useEffect, useState } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({});

const Graph = (props) => {
  const { data, reactions, reactionsData } = props;

  const classes = useStyles();

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  );

  const options = {
    responsive: true,
    tooltips: {
      mode: 'index',
      intersect: false,
      callbacks: {
        label: (tooltipItem, data) => {
          // get the corresponding label from the tooltipLabels array
          const tooltipLabels = data.datasets[0].tooltipLabels;
          const label = tooltipLabels[tooltipItem.index];
          return label;
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Total Views',
      },
    },
  };

  const optionsReactions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Total Reactions',
      },
    },
  };

  return (
    <div>
      {data ? (
        <div>
          <Line data={data} options={options} />
        </div>
      ) : (
        <div>No hay datos de este articulo</div>
      )}
      {reactions && (
        <div style={{ width: '90%' }}>
          <Line data={reactionsData} options={optionsReactions} />
        </div>
      )}
    </div>
  );
};

export default Graph;
