import moment from 'moment';

function rangeGenerator(from, to, price) {
  return {
    from: from,
    to: to,
    price: price,
  };
}

function rangesGenerator(conditions) {
  const ranges = [];
  if (conditions.base) {
    ranges.push(rangeGenerator(0, conditions.base.qty, conditions.base.price));
  }
  if (conditions.variable) {
    ranges.push(...conditions.variable);
  }
  return ranges;
}

function priceRanges(conditions, money = true) {
  return rangesGenerator(conditions).map((range) => ({
    from: money ? range.from * range.price : range.from,
    to: money ? range.to * range.price : range.to,
    price: range.price,
  }));
}

function priceRange(conditions, money) {
  const ranges = priceRanges(conditions);
  return ranges
    .filter((range) => range.from <= money && money <= range.to)
    .at(-1);
}

function priceRangeFromUnits(conditions, units) {
  const ranges = priceRanges(conditions, false);
  return ranges
    .filter((range) => range.from <= units && units <= range.to)
    .at(-1);
}

export function calculateMoneyEquivalent(conditions, units) {
  const range = priceRangeFromUnits(conditions, units);
  return {
    units: range ? units : 0,
    price: range ? range.price : 0,
  };
}

export function calculateUserEquivalent(conditions, money) {
  const range = priceRange(conditions, money);
  return {
    units: range ? money / range.price : 0,
    price: range ? range.price : 0,
  };
}

export function newDateFromDelta(date, delta) {
  return moment(date, 'DD-MM-YYYY').add(delta, 'months').format('DD-MM-YYYY');
}

export function calculateDiscountFromDeltaMonths(deltaMonths) {
  if (deltaMonths < 6) {
    return 0;
  }
  if (deltaMonths < 12) {
    return 10;
  } else {
    return 20;
  }
}
