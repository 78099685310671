import React, { useState, useEffect } from 'react';
import {
  Page,
  Button,
  Card,
  Tabs,
  TabItem,
  Loading,
  CardDivider,
  CardContent,
  Snackbar,
} from '@beetrack/hp-components';
import { Add } from '@beetrack/hp-icons-react';
import { Grid } from '@material-ui/core';
import AccessesTable from './Components/AccessesTable';
import InventoryTable from './Components/InventoryTable';
import { get, post } from '../../../utils/api';
import ConfirmationModal from '../../../shared/ConfirmationModal';

const ShowResources = (props) => {
  const { history } = props;
  const id = props.match.params.id;
  const [user, setUser] = useState({});
  const [accesses, setAccesses] = useState([]);
  const [inventory, setInventory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    status: 'alert',
  });
  const [offboarding, setOffboarding] = useState(false);
  const [solicitedOff, setSolicitedOff] = useState(false);
  const [reload, setReload] = useState(0);

  const handleOffboarding = () => {
    setSolicitedOff(true);
    setOffboarding(false);
    let payload = {
      id: id,
    };
    post('/resources/offboarding', payload).then((response) => {
      if (response.status === 200) {
        setSnackbar({
          open: true,
          message: 'Offboarding solicitado con éxito',
          status: 'success',
        });
      } else {
        setSnackbar({
          open: true,
          message: 'Error solicitando offboarding, intente nuevamente',
          status: 'error',
        });
        setSolicitedOff(false);
      }
    });
  };

  useEffect(() => {
    get(`/resources/${id}`).then((response) => {
      if (response.status == 200) {
        setAccesses(
          response.data.accesses.data.map((access) => access.attributes),
        );
        setInventory(
          response.data.inventory.data.map((item) => item.attributes),
        );
        setUser(response.data.user.data.attributes);
        setLoading(false);
      }
    });
  }, [reload]);
  return (
    <div>
      {!loading && (
        <Page
          onGoBack={() => history.push('/hr/resources/all')}
          title={'Volver'}
          buttons={[
            <Button
              onClick={() => history.push(`/hr/resources/${id}/add`)}
              icon={<Add />}
            >
              Agregar Recurso
            </Button>,
          ]}
        >
          <br />
          <Grid container spacing={3}>
            <Grid style={{ minWidth: '250px' }} item xs={3}>
              <Card fullWidth bordered={false}>
                <CardContent>
                  <h3>{user.name}</h3>
                  <CardDivider />
                  <p>Email: {user.email} </p>
                  <p>Area: {user.area} </p>
                  <p>Responsable: {user.boss} </p>
                  <Button
                    type="secondary"
                    onClick={() => setOffboarding(true)}
                    disabled={solicitedOff}
                  >
                    Solicitar Offboarding
                  </Button>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={9}>
              <Tabs>
                <TabItem label="Accesos">
                  <AccessesTable
                    data={accesses}
                    reload={reload}
                    setReload={setReload}
                    setSnackbar={setSnackbar}
                    history={history}
                  />
                </TabItem>
                <TabItem label="Inventarios">
                  <InventoryTable
                    data={inventory}
                    reload={reload}
                    setReload={setReload}
                    setSnackbar={setSnackbar}
                    history={history}
                  />
                </TabItem>
              </Tabs>
            </Grid>
          </Grid>
          <Snackbar
            key="destroy-snackbar"
            isOpen={snackbar.open}
            message={snackbar.message}
            onDismiss={() =>
              setSnackbar({ open: false, message: '', status: '' })
            }
            status={snackbar.status}
          />
          <ConfirmationModal
            text="¿Solicitar Offboarding?"
            secondaryText="Se notificará a los responsables de eliminar los accesos correspondientes"
            open={offboarding}
            close={() => setOffboarding(false)}
            onConfirm={() => handleOffboarding()}
          />
        </Page>
      )}
      {loading && (
        <center>
          <Loading hasText />
        </center>
      )}
    </div>
  );
};

export default ShowResources;
