import React, { useState, useEffect } from 'react';
// import CommentsTable from './Components/Table';
import {
  Form,
  FormRow,
  FormGroup,
  Snackbar,
  Button,
  Select,
  TextField,
  InfoBox,
  Checkbox,
} from '@beetrack/hp-components';
import { get, post, put } from '../../../utils/api';
import { sleep } from '../../../utils/utils';
import { MAX_VARIABLE_NUMBER } from '../../../utils/const';
import {
  commercialConditionsCurrencies,
  commercialConditionsProducts,
} from '../../../utils/const';
import { useHistory } from 'react-router-dom';
import { TrendingUpRounded } from '@material-ui/icons';

const defaultBaseCondition = { price: '', qty: '' };
const defaultVariableCondition = [
  {
    from: parseFloat(0),
    to: parseFloat(MAX_VARIABLE_NUMBER),
    price: parseFloat(0),
  },
];

const CommercialCondition = (props) => {
  const history = useHistory();
  const planId = props.match.params.condition_id;

  // snackbar
  const [open, setOpen] = useState(false);
  const [snackText, setSnackText] = useState('');
  const [snackType, setSnackType] = useState('error');

  // basic info of plan
  const [planName, setPlanName] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [description, setDescription] = useState('');

  // type of plan
  const [selectedProduct, setSelectedProduct] = useState({});
  const [visible, setVisible] = useState(false);
  const [addBaseToVariable, setAddBaseToVariable] = useState(false);
  // general minimum
  const [generalMinimum, setGeneralMinimum] = useState(0);

  // base conditions
  const [baseCondition, setBaseCondition] = useState(defaultBaseCondition);
  const [basePerUser, setBasePerUser] = useState('');
  // variable conditions
  const [variableCondition, setVariableCondition] = useState(
    defaultVariableCondition,
  );

  const [dealName, setDealName] = useState('');
  const [hubspotId, setHubspotId] = useState(
    props.match.params.hubspot_id ? props.match.params.hubspot_id : '',
  );
  const [validHubspotData, setValidHubspotData] = useState(false);

  const setObjState = (setter, key, value) => {
    setter((state) => ({ ...state, [key]: value }));
  };

  const _variableConditionGenerator = (previousVariable) => {
    return {
      from: previousVariable === undefined ? 0 : previousVariable.to + 1,
      to: previousVariable === undefined ? 1 : previousVariable.to + 2,
      price: 0,
    };
  };

  const handleAddNewVariable = (event) => {
    // use last condition to create the new one
    let newCondition = _variableConditionGenerator(variableCondition.at(-1));
    setVariableCondition((state) => [...state, newCondition]);
  };

  const handleChangeVariableCondition = (event, index, key) => {
    const newData = [...variableCondition];
    newData[index][key] =
      event.target.value === ''
        ? key !== 'price'
          ? parseFloat(MAX_VARIABLE_NUMBER)
          : 0.0
        : parseFloat(Math.max(event.target.value, 0));
    setVariableCondition(newData);
  };

  const previousPage = (url, delay = 0) => {
    sleep(delay * 1000).then(() => {
      history.push(url);
    });
  };

  const handlePostPlan = () => {
    const planPayload = {
      name: planName,
      hubspot_id: hubspotId,
      currency: selectedCurrency.value,
      product: selectedProduct.value,
      description: description,
      visible: visible,
      condition: {
        general_minimum: generalMinimum,
        add_base_to_variable: addBaseToVariable,
        base:
          baseCondition === defaultBaseCondition ? undefined : baseCondition,
        variable:
          variableCondition === defaultVariableCondition
            ? undefined
            : variableCondition,
      },
    };
    if (planId) {
      put(`/commercial_conditions/plans/${planId}`, planPayload).then(
        (response) => {
          if (response.status === 200) {
            setSnackText(`Plan ${planName} actualizado`);
            setOpen(true);
            setSnackType('success');
            previousPage(`/client/${hubspotId}`, 5);
          } else {
            setSnackText(response.data.errors[0].message);
            setOpen(true);
            setSnackType('error');
          }
        },
      );
    } else {
      const payload = {
        plans: [planPayload],
        // as this is a custom made condition we want to force the process
        // of approval for this condition in specific
        force_approved_value: false,
        comment: '',
        hubspot_id: hubspotId,
        dealname: dealName,
      };
      post('/commercial_conditions/plans/create_plan_relations', payload).then(
        (response) => {
          if (response.status === 200) {
            var names = payload.plans
              .map((cond) => `"${cond.name}"`)
              .join(', ');
            setSnackText(
              `Condiciones creadas ${names}, falta aprobacion por management`,
            );
            setOpen(true);
            setSnackType('alert');
            previousPage('/sales/commercial-conditions', 5);
          } else {
            setSnackText(response.data.errors[0].message);
            setOpen(true);
            setSnackType('error');
          }
        },
      );
    }
  };

  const validateHubspotData = (hs_object_id, dealname) => {
    post('/commercial_conditions/plans/validate_hubspot', {
      hs_object_id: hs_object_id,
      dealname: dealname,
    }).then((response) => {
      setValidHubspotData(response.data.exists);
    });
  };

  useEffect(() => {
    if (planId) {
      get(`/commercial_conditions/plans?id=${planId}`).then((response) => {
        const plan = response.data.data.plans.nodes[0];
        setPlanName(plan.name);
        setDescription(plan.description);
        setVisible(plan.visible);
        setAddBaseToVariable(plan.condition.add_base_to_variable);
        setGeneralMinimum(plan.condition.general_minimum);
        setSelectedCurrency(
          commercialConditionsCurrencies.find((curr) => {
            return curr.value === plan.currency;
          }),
        );
        setSelectedProduct(
          commercialConditionsProducts.find((prod) => {
            return prod.value === plan.product;
          }),
        );
        if (plan.condition.variable)
          setVariableCondition(plan.condition.variable);
        if (plan.condition.base) {
          setBaseCondition(plan.condition.base);
        }
      });
    }
  }, []);

  return (
    <Form
      title={`${planId ? 'Editar' : 'Nueva'} Condición Comercial`}
      backTo={planId ? `/client/${hubspotId}` : '/sales/commercial-conditions'}
      buttons={[
        <Button
          key="new-cc-button"
          disabled={
            // we have to have a base plan (valid product and curency combination) and a name
            planName.length === 0 ||
            selectedCurrency.value === undefined ||
            selectedProduct.value === undefined ||
            !(
              dealName !== '' &&
              // test if there is a valid number
              hubspotId !== '' &&
              validHubspotData
            )
          }
          onClick={handlePostPlan}
        >
          {planId ? 'Actualizar' : 'Guardar'}
        </Button>,
      ]}
    >
      <p>
        Ingresa la siguiente información para crear una nueva condición
        comercial. Al crearla, en caso de hacerla visible, esta se reflejará en
        la lista de opciones de condiciones comerciales.
      </p>
      <FormGroup
        title="Información básica"
        collapsible={false}
        additionalClassName="grid flex-start"
      >
        <div>
          {/* name and curency */}
          <FormRow additionalClassName="grid">
            <TextField
              additionalClassName="grid__item-6"
              key="name"
              placeholder="Nombre de la condición comercial"
              label="Nombre de la condición comercial"
              value={planName}
              onChange={(e) => setPlanName(e.target.value)}
              fullWidth
            />
          </FormRow>
          <FormRow additionalClassName="grid">
            <Select
              additionalClassName="grid__item-6"
              key="currency"
              options={commercialConditionsCurrencies}
              label="Moneda"
              value={selectedCurrency.value}
              onChange={(e) => setSelectedCurrency(e)}
            />
          </FormRow>
          <FormRow additionalClassName="grid">
            <Select
              additionalClassName="grid__item-6"
              options={commercialConditionsProducts}
              key="cc-types"
              label="Selecciona producto"
              value={selectedProduct.value}
              onChange={(e) => setSelectedProduct(e)}
            />
          </FormRow>
          {/* product and info */}
          <FormRow additionalClassName="grid">
            <InfoBox status="alert">
              <p>
                Este campo determinará los valores por defecto de los campos a
                continuación.
              </p>
            </InfoBox>
          </FormRow>
        </div>
      </FormGroup>
      {/* general minimum */}
      <FormGroup
        title="Mínimo general y cálculo base variable"
        collapsible={false}
      >
        <div>
          <FormRow additionalClassName="grid">
            <TextField
              additionalClassName="grid__item-6"
              key="general-minimum"
              onChange={(e) =>
                setGeneralMinimum(
                  e.target.value === '' ? 0 : parseFloat(e.target.value),
                )
              }
              placeholder={`Mínimo general a facturar ${
                selectedCurrency.value === undefined
                  ? ''
                  : '(' + selectedCurrency.value + ')'
              }`}
              label={`Mínimo general a facturar ${
                selectedCurrency.value === undefined
                  ? ''
                  : '(' + selectedCurrency.value + ')'
              }`}
              type="number"
              value={generalMinimum}
              fullWidth
            />
            <Checkbox
              additionalClassName="grid__item-6"
              label="¿Añadir costo base al pasar a un tramo variable? (Al añdir el tramo base al variable el costo de los tramos variables será el costo sumado al costo del tramo base)."
              checked={addBaseToVariable}
              onChange={(e) => setAddBaseToVariable(e.target.checked)}
              name="checkbox"
            />
          </FormRow>
        </div>
      </FormGroup>
      {/* base condition */}
      <FormGroup title="Base" collapsible={false}>
        <div>
          <FormRow additionalClassName="grid">
            <TextField
              additionalClassName="grid__item-6"
              key="base-qty"
              onChange={(e) => {
                setObjState(
                  setBaseCondition,
                  'qty',
                  e.target.value === '' ? 0 : parseInt(e.target.value),
                );
                setObjState(
                  setBaseCondition,
                  'price',
                  basePerUser === '' || e.target.value === ''
                    ? 0
                    : parseFloat(basePerUser * e.target.value),
                );
              }}
              placeholder="Cantidad"
              label="Cantidad"
              type="number"
              value={baseCondition.qty}
              fullWidth
            />
            <TextField
              additionalClassName="grid__item-121"
              key="base-price-per-user"
              onChange={(e) => {
                setBasePerUser(
                  e.target.value === '' ? 0 : parseFloat(e.target.value),
                );
                setObjState(
                  setBaseCondition,
                  'price',
                  e.target.value === '' || baseCondition.qty === ''
                    ? 0
                    : parseFloat(e.target.value * baseCondition.qty),
                );
              }}
              placeholder="Precio por usuario"
              label="Precio por usuario"
              type="number"
              value={basePerUser}
              fullWidth
            />
            <TextField
              additionalClassName="grid__item-6"
              key="base-price"
              disabled={true}
              placeholder="Precio Total Base"
              label="Precio Total Base"
              type="number"
              value={baseCondition.price}
              fullWidth
            />
          </FormRow>
        </div>
      </FormGroup>
      {/* varible conditions */}
      <FormGroup title="Tramos" collapsible={false}>
        <div>
          {' '}
          {variableCondition.map((cond, index) => {
            return (
              <FormRow key={`fr-variable-${index}`}>
                <TextField
                  additionalClassName="grid__item-6"
                  key={`fr-variable-from-${index}`}
                  placeholder="Desde"
                  label="Desde"
                  type="number"
                  value={cond.from}
                  onChange={(e) =>
                    handleChangeVariableCondition(e, index, 'from')
                  }
                  fullWidth
                />
                <TextField
                  additionalClassName="grid__item-6"
                  key={`fr-variable-to-${index}`}
                  placeholder="Hasta"
                  label="Hasta"
                  type="number"
                  value={
                    cond.to === parseFloat(MAX_VARIABLE_NUMBER) ? '' : cond.to
                  }
                  onChange={(e) =>
                    handleChangeVariableCondition(e, index, 'to')
                  }
                  fullWidth
                />
                <TextField
                  additionalClassName="grid__item-6"
                  key={`fr-variable-price-${index}`}
                  placeholder="Precio"
                  label="Precio"
                  type="number"
                  value={cond.price}
                  onChange={(e) =>
                    handleChangeVariableCondition(e, index, 'price')
                  }
                  fullWidth
                />
              </FormRow>
            );
          })}
        </div>
        <Button type="tertiary" onClick={(e) => handleAddNewVariable(e)}>
          + Añadir tramo
        </Button>
        <div />
        <br />
        <br />
        {/* description */}
        <FormRow additionalClassName="grid">
          <TextField
            additionalClassName="grid__item-12"
            key="description"
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Comentarios"
            label="Comentarios"
            helperText="Comentario breve de en qué casos se debería utilizar este plan"
            value={description}
            fullWidth
          />
          {/* <Checkbox
            label="¿Visible para ser reutilizado?"
            checked={visible}
            onChange={(e) => setVisible(e.target.checked)}
            name="checkbox"
          /> */}
        </FormRow>
      </FormGroup>
      <FormGroup title="Datos del deal" collapsible={false}>
        <div>Ingresa datos del cliente</div>
        <FormRow>
          <TextField
            onChange={(e) => {
              setDealName(e.target.value);
              validateHubspotData(hubspotId, e.target.value);
            }}
            placeholder="Transportes Abejita"
            label="Nombre del deal"
            value={dealName}
            helperText={
              hubspotId === '' || dealName === ''
                ? 'Ingresa el nombre de la misma forma que está ingresado en Husbpot'
                : validHubspotData
                ? 'Ingresa el nombre de la misma forma que está ingresado en Husbpot'
                : 'El nombre del deal no calza con el ID de hubspot'
            }
            error={
              hubspotId === '' || dealName === '' ? false : !validHubspotData
            }
            fullWidth
          />
          <TextField
            onChange={(e) => {
              setHubspotId(e.target.value);
              validateHubspotData(e.target.value, dealName);
            }}
            placeholder="ID Deal en Hubspot"
            label="ID Deal en Hubspot"
            type="number"
            value={hubspotId}
            helperText={
              hubspotId === '' || dealName === ''
                ? 'Solo se permiten números '
                : validHubspotData
                ? 'Solo se permiten números '
                : 'El ID de hubspot no existe o no calza con el nombre del deal'
            }
            error={
              hubspotId === '' || dealName === '' ? false : !validHubspotData
            }
            fullWidth
          />
        </FormRow>
      </FormGroup>
      <Snackbar
        key="test-snackbar"
        isOpen={open}
        message={snackText}
        onDismiss={() => setOpen(!open)}
        status={snackType}
      />
    </Form>
  );
};

export default CommercialCondition;
