import React, {useState, useEffect} from 'react'
import CommentsTable from './Components/Table'
import { Tabs, TabItem, LoadingPage, Snackbar, TextField, Button } from "@beetrack/hp-components"
import { ConfirmationModal as RejectionModal } from "@beetrack/hp-components"
import { get, post } from '../../utils/api'
import ConfirmationModal from '../../shared/ConfirmationModal';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import DraftModal from '../DraftsComments/Components/DraftModal';

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltip: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 11,
  },
});

const ApproveDraft = () => {
  const classes = useStyles();
  const [reload, setReload] = useState(0)
  const [dataTablePending, setDataTablePending] = useState([])
  const [dataTableApproved, setDataTableApproved] = useState([])
  const [dataTableRejected, setDataTableRejected] = useState([])
  const [modalDraft, setModalDraft] = useState({ open: false, data: {} });
  const [modalAccept, setModalAccept] = useState({ open: false, id: null });
  const [modalReject, setModalReject] = useState({ open: false, id: null });
  const [rejectionComment, setRejectionComment] = useState('')
  const [characters, setCharacters] = useState(rejectionComment.length);
  const [loading, setLoading] = useState(true)
  const [snackBar, setSnackBar] = useState({
    open: false,
    status: 'close',
    message: '',
  });


  const getDrafts = () => {
    get('/management/drafts').then((response) => {
      if (response.status === 200) {
        setDataTableApproved(response.data.approved)
        setDataTableRejected(response.data.rejected)
        setDataTablePending(response.data.pending)
        setLoading(false)
      }
    })
  }

  const approveComment = (draftId) => {
    let payload = {
      status: 'approved',
      draft_id: draftId,
    };
    post('/management/drafts/review', payload).then((postResponse) => {
      if (postResponse.status === 200) {
        setModalAccept({ open: false, id: null })
        setReload(reload+1);
        setSnackBar({
          open: true,
          status: 'success',
          message: 'Comentario Aprobado',
        });
      } else {
        setSnackBar({
          open: true,
          status: 'error',
          message: 'Ha ocurrido un problema',
        });
      }
    });
  };

  const rejectComment = (draftId, rejectionComment) => {
    let payload = {
      status: 'rejected',
      draft_id: draftId,
      reject_comment: rejectionComment,
    };
    post('/management/drafts/review', payload).then((postResponse) => {
      if (postResponse.status === 200) {
        setModalReject({ open: false, id: null })
        setReload(reload+1);
        setSnackBar({
          open: true,
          status: 'success',
          message: 'Comentario Rechazado',
        });
      } else {
        setSnackBar({
          open: true,
          status: 'error',
          message: 'Ha ocurrido un problema',
        });
      }
    });
  };

  const closeModals = () => {
    setModalDraft({ open: false, data: {} });
  };

  useEffect(() => {
    getDrafts()
  }, [reload])

  return (
    <div>
      <h2> Management </h2>
      <p> Aquí encontrarás todos los <strong> comentarios a borradores </strong> creados desde el área de ventas.</p>
      {!loading && (
        <div>
        <Tabs >
          <TabItem label={'Pendientes de Revisión\n' + dataTablePending.length + '/' + (dataTablePending.length + dataTableApproved.length + dataTableRejected.length).toString()} >
            <CommentsTable 
              tableType='pending' 
              dataTable={dataTablePending}
              setModalAccept={setModalAccept}
              setModalReject={setModalReject}
              setModalDraft={setModalDraft}
            />
          </TabItem>
          <TabItem label={'Aprobados por Management\n' + dataTableApproved.length + '/' + (dataTablePending.length + dataTableApproved.length + dataTableRejected.length).toString()} >
            <CommentsTable 
              tableType='other' 
              dataTable={dataTableApproved}
              setModalAccept={setModalAccept}
              setModalReject={setModalReject}
              setModalDraft={setModalDraft}
            />
          </TabItem>
          <TabItem label={'Rechazados por Management\n' + dataTableRejected.length + '/' + (dataTablePending.length + dataTableApproved.length + dataTableRejected.length).toString()} >
            <CommentsTable
              tableType='other' 
              dataTable={dataTableRejected}
              setModalAccept={setModalAccept}
              setModalReject={setModalReject}
              setModalDraft={setModalDraft} 
            />
          </TabItem>
        </Tabs>
        <Modal
        open={modalDraft.open}
        onClose={() => setModalDraft({ open: false, data: {} })}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
        className={classes.modal}
        >
          <Fade in={modalDraft.open}>
            <DraftModal
              setSnackBar={setSnackBar}
              amount={modalDraft.data.total_amount}
              close={() => closeModals()}
              id={modalDraft.data.id}
              client={modalDraft.data.company_name}
            />
          </Fade>
      </Modal>
      <ConfirmationModal
        text="¿Aceptar comentario?"
        secondaryText="Se notificará a payments de los cambios requeridos por solicitante"
        open={modalAccept.open}
        close={() => setModalAccept({ open: false, id: null })}
        onConfirm={() => approveComment(modalAccept.id)}
      />
      <RejectionModal
        title="¿Rechazar comentario?"
        open={modalReject.open}
        onClose={() => setModalReject({ open: false, id: null })}
        placeholder="Agrega tu comentario"
        helperText={'(' + characters.toString() + '/300 caracteres)'}
        confirmButton={
          <Button
            disabled={!rejectionComment}
            onClick={() => (rejectComment(modalReject.id, rejectionComment))}
          >
            Rechazar y enviar comentario
          </Button>
        }
      >
        <TextField
          label='Motivo rechazo'
          textArea
          onChange={(e) => {
            setRejectionComment(e.target.value);
            setCharacters(e.target.value.length);
          }}
          type='text'
          helperText={'(' + characters.toString() + '/300 caracteres)'}
          value={rejectionComment}
        />
        <div> Este comentario será notificado a Payments y al solicitante</div>
      </RejectionModal>
      {snackBar.open && (
        <Snackbar
          key="snackbar-table"
          isOpen={snackBar.open}
          message={snackBar.message}
          onDismiss={() =>
            setSnackBar({
              open: !snackBar.status,
              status: 'close',
              message: '',
            })
          }
          status={snackBar.status}
        />
      )}
      </div>
        )}
        {loading && (
          <LoadingPage text='Cargando...' />
        )}
    </div>
  )
}

export default ApproveDraft