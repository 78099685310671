import React, { Component } from 'react';
import { Table, CardContent, Card } from '@beetrack/hp-components';
import { CheckCircle, Alert, Close } from '@beetrack/hp-icons-react';

import { get, post } from '../../../../utils/api';

class MainManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { name: 'product', title: 'Producto', valueByAttribute: 'product' },
        { name: 'client', title: 'Cliente', valueByAttribute: 'client' },
        {
          name: 'ownerName',
          title: 'Hubspot Owner',
          valueByAttribute: 'ownerName',
        },
        { name: 'invoice', title: 'Folio', valueByAttribute: 'invoice' },
        { name: 'comment', title: 'Comentario', valueByAttribute: 'comment' },
        {
          name: 'invoiceAmount',
          title: 'Monto Factura',
          valueByAttribute: 'invoiceAmount',
        },
        {
          name: 'commission',
          title: 'Comisión',
          valueByAttribute: 'commission',
        },
      ],
      data: [],
      loading: true,
      products: { PlannerPro: 0, LastMile: 1 },
    };
  }

  componentDidMount() {
    this.fetchInvoicesData(this.props.selectedSalesmanObject);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      this.fetchInvoicesData(this.props.selectedSalesmanObject);
    }
  }

  approveAction = (event) => {
    let commission_status = 0;
    this.createCommission(commission_status, event);
    this.updateTable(event);
  };

  notConsiderAction = (event) => {
    let commission_status = 1;
    this.createCommission(commission_status, event);
    this.updateTable(event);
  };

  rejectAction = (event) => {
    let commission_status = 2;
    this.createCommission(commission_status, event);
    this.updateTable(event);
  };

  updateTable = (event) => {
    let tableData = this.state.data;
    event.map((commission) => {
      tableData = tableData.filter(
        (item) => item.instanceId !== commission.item.instanceId,
      );
    });
    this.setState({ data: tableData });
  };

  createCommission = (commission_status, event) => {
    event.map((commission) => {
      let userId = this.props.selectedSalesmanObject.id;
      if (commission.item.ownerId != null) {
        userId = commission.item.ownerId;
      }
      post('/commissions', {
        invoice_id: commission.item.id,
        status: commission_status,
        product: this.state.products[commission.item.product],
        original_amount: commission.item.invoiceAmount,
        amount_paid: commission.item.commission,
        user_id: userId,
        doc_number: commission.item.invoice,
        client: commission.item.client,
        line_id: commission.item.product,
        currency: commission.item.currency,
        client_id: commission.item.clientId,
        realm_id: commission.item.realmId,
        comment: commission.item.comment,
      });
    });
  };

  fetchInvoicesData = (salesmanObject) => {
    get(
      `/salesman?deals=all&status=paid&sales_man_id=${salesmanObject.id}`,
    ).then((response) => {
      if (response.status === 200) {
        this.unpackResponseData(response.data);
      } else {
        return "Couldn't get a response";
      }
    });
  };

  unpackResponseData = (responseData) => {
    var data = [];
    responseData.map((customers) => {
      customers.map((customer) => {
        customer.Line.map((line) => {
          data.push({
            product: line.SalesItemLineDetail.ItemRef.name,
            client: customer.CustomerRef.name,
            clientId: customer.CustomerRef.value,
            invoice: customer.DocNumber,
            comment: line.Description,
            invoiceAmount: `${line.Amount} ${customer.CurrencyRef.value}`,
            commission: line.Commission,
            checkboxSelected: false,
            id: customer.Id,
            realmId: customer.RealmId,
            lineId: line.Id,
            currency: customer.CurrencyRef.value,
            ownerName: line.OwnerName,
            ownerId: line.OwnerId,
            instanceId: Math.random().toString(36).substr(2, 9),
          });
        });
      });
    });
    this.filterData(data);
    this.setState({ loading: false });
  };

  filterData = (data) => {
    get(
      `/commissions?sales_man_id=${this.props.selectedSalesmanObject.id}`,
    ).then((response) => {
      if (response.status === 200) {
        let availableData = [];
        let commissionsData = response.data;
        data.map((invoice) => {
          let foundCommission = commissionsData.find(
            (commission) =>
              invoice.invoice === commission.doc_number &&
              invoice.product === this.mapProductName(commission.product) &&
              parseInt(invoice.clientId) === commission.client_id &&
              invoice.comment === commission.comment,
          );
          if (!foundCommission) {
            availableData.push(invoice);
          }
        });
        this.selectDataByOwner(availableData);
      }
    });
  };

  selectDataByOwner = (data) => {
    let listData = [];
    data.map((commission) => {
      if (commission.ownerId === this.props.selectedSalesmanObject.id) {
        listData.push(commission);
      }
    });
    this.setState({ data: listData });
  };

  mapProductName = (name) => {
    if (name === 'planner_pro') {
      return 'PlannerPro';
    } else if (name === 'last_mile') {
      return 'LastMile';
    } else {
      return 'No registered product';
    }
  };

  render() {
    return (
      <div>
        <Card fullWidth>
          <CardContent>
            <div>
              <Table
                columns={this.state.columns}
                data={this.state.data}
                loading={this.state.loading}
                actions={[
                  {
                    title: 'Aprobar',
                    onClick: this.approveAction.bind(this),
                    icon: <CheckCircle />,
                    isGlobal: true,
                  },
                  {
                    title: 'No Considerar',
                    onClick: this.notConsiderAction.bind(this),
                    icon: <Alert />,
                    isGlobal: true,
                  },
                  {
                    title: 'Rechazar',
                    onClick: this.rejectAction.bind(this),
                    icon: <Close />,
                    isGlobal: true,
                  },
                ]}
              />
            </div>
            <div className={'glossary-container'}>
              <div className={'glossary-item'}>
                <CheckCircle />
                <div className={'glossary-text'}>= Aprobar</div>
              </div>
              <div className={'glossary-item'}>
                <Alert />
                <div className={'glossary-text'}>= No considerar</div>
              </div>
              <div className={'glossary-item'}>
                <Close /> <div className={'glossary-text'}>= Rechazar</div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default MainManager;
