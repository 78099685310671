import React from 'react';
import {
  Form,
  FormRow,
  Button,
  Select,
  TextField,
} from '@beetrack/hp-components';
import { Delete } from '@material-ui/icons';

import { commercialConditionsCurrencies } from '../../../utils/const';

const OtherProductForm = (props) => {
  const setOthers = props.setOthers;
  const others = props.others;
  const other = props.other;
  const idx = props.idx;

  const setOtherState = (newValue, index, key) => {
    const newData = [...others];
    newData[index][key] = newValue;
    setOthers(newData);
  };

  const _removeOther = (idx) => {
    others.splice(idx, 1);
    const newData = [...others];
    setOthers(newData);
  };

  return (
    <Form
      buttons={[
        <Button
          type="tertiary"
          onClick={(e) => _removeOther(idx)}
          icon={<Delete />}
        >
          Eliminar Cobro
        </Button>,
      ]}
    >
      <FormRow additionalClassName="grid">
        <TextField
          additionalClassName="grid__item-4"
          label="Nombre del cobro"
          onChange={(e) => setOtherState(e.target.value, idx, 'name')}
          value={other.name}
          helperText="Ej: Mantención integración ABC"
          required
        />
        <Select
          additionalClassName="grid__item-4"
          label="Moneda"
          options={commercialConditionsCurrencies}
          onChange={(e) => setOtherState(e.value, idx, 'currency')}
          value={other.currency}
          helperText="Moneda en la cual se hará el cargo fijo"
          required
        />
        <TextField
          additionalClassName="grid__item-4"
          label="Precio"
          onChange={(e) =>
            setOtherState(
              { base: { price: parseFloat(e.target.value), qty: 0 } },
              idx,
              'condition',
            )
          }
          type="number"
          value={other.condition.base.price}
          helperText={`Cobro mensual fijo en ${other.currency}`}
          required
        />
      </FormRow>
    </Form>
  );
};

export default OtherProductForm;
