import React, { useEffect, useState } from 'react';
import { Select } from '@beetrack/hp-components';
import { Grid } from '@mui/material';

import { getNestedValue } from '../utils';

const SimpleElementTranslator = (props) => {
  const { classes, form, formValues, path, simpleChangeHandler, dependant } =
    props;

  const [options, setOptions] = useState([]);

  const element = getNestedValue(form.content, path);

  const setOptionsHandler = () => {
    setOptions(Object.values(element.options));
    if (element.emptyOption) {
      setOptions([options, { label: '', value: '' }]);
    }
  };

  useEffect(() => {
    setOptionsHandler();
  }, []);

  return (
    <Grid item sm={12} md={dependant ? 12 : 10} className={classes.element}>
      <div className={classes.question}>{element.question}</div>
      {element.imgUrl && (
        <div className={classes.imageContainer}>
          <img
            style={{ width: '100%', height: '100%' }}
            src={element.imgUrl}
            alt="new"
          />
        </div>
      )}
      <div className={classes.selectField}>
        <Select
          helperText={element.helperText}
          value={formValues[`${element.propertyName}`]}
          label={element.title}
          options={options}
          required={element.required}
          onChange={(e) => simpleChangeHandler(e, element.propertyName)}
        />
      </div>
    </Grid>
  );
};

export default SimpleElementTranslator;
