import React, { Component, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { CssBaseline, LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import TopBarHive from '../../shared/layout/Topbar';
import SideBarHive from '../../shared/layout/Sidebar';
import Menu from '../Menu';
import Settings from '../Settings';
import Client from '../Client';
import AssociateClient from '../Client/Associate';
import ShowClient from '../Client/Show';
import UserManagement from '../UserManagement';
import Collection from '../Collection';
import Invoices from '../Invoices';
import InvoiceDetails from '../Invoices/InvoiceDetails';
import Drafts from '../Drafts';
import Conciliations from '../Conciliations';
import Commissions from '../Commissions';
import NewCommissions from '../NewCommissions';
import CommissionManagement from '../CommissionManagement';
import NewCommissionManagement from '../NewCommissionManagement';
import CreateAccount from '../Beetrack/Create';
import ToggleAccount from '../Beetrack/Toggle';
import DraftsComments from '../DraftsComments';
import { test } from '../../utils/api.js';
import SalesCollection from '../Sales/SalesCollection';
import HRNewOnboarding from '../NewHR/Onboarding';
import Candidates from '../NewHR/Candidates';
import ApproveDraft from '../ApproveDraft';
import AccountPartnerDrafts from '../AccountPartnerDrafts';
import ClientForm from '../Client/Create/ClientForm';
import Prospector from '../Prospectator';
import EditCandidate from '../NewHR/EditCandidate';
import CandidateView from '../NewHR/Candidates/CandidateView';
import NewSupplier from '../Suppliers/NewSupplier';
import TableSuppliers from '../Suppliers/TableSuppliers';
import Supplier from '../Suppliers/Supplier';
import Resources from '../Resources';
import ShowResources from '../Resources/Show';
import AddResources from '../Resources/Add';
import CommercialConditionsTable from '../CommercialConditions/Table';
import CommercialConditionsApprovalTable from '../CommercialConditions/ApprovalTable';
import PlanRelation from '../CommercialConditions/PlanRelation';
import CommercialCondition from '../CommercialConditions/CommercialCondition';
import SalesManForm from '../CommercialConditions/CommercialCondition/salesManForm';
import CheckList from '../CheckList';
import { havePermissions } from '../../utils/utils';
import InventoryEdit from '../Resources/Components/InventoryTable/Components/InventoryEdit';
import InventoryAdd from '../Resources/Components/InventoryTable/Components/InventoryAdd';
import ClientRelations from '../Client/ClientRelations';
import NewRelation from '../Client/ClientRelations/New';
import EditRelation from '../Client/ClientRelations/Edit';
import AssociatedDeals from '../Client/AssociatedDeals';
import NewAssociated from '../Client/AssociatedDeals/New';
import EditAssociated from '../Client/AssociatedDeals/Edit';
import Content from '@beetrack/hp-content-react';
import { makeStyles } from '@material-ui/core/styles';
import Prepaid from '../Prepaid';
import Datawarehouse from '../Datawarehouse';
import Forms from '../Form/Forms';
import CreateForm from '../Form/CreateForm';
import FormView from '../Form/FormView';
import Dashboard from '../Dashboard';
import DashboardHelpCenter from '../Dashboard/HelpCenter';
import CustomerPortal from '../CustomerPortal';
import CollectionV2 from '../CollectionV2/index.js';

const useStyles = makeStyles((theme) => ({
  app: {
    height: '100vh !important',
    overflowY: 'scroll',
  },
  contentDiv: {
    height: '100% !important',
    padding: '23px',
    marginTop: '30px',
  },
}));

const Home = (props) => {
  const [loading, setLoading] = useState(false);
  const [smallselected, setsmallselected] = useState('');
  const [bigselected, setbigselected] = useState('');
  const classes = useStyles();

  const loadState = (isLoading) => {
    setLoading(isLoading);
  };

  useEffect(() => {
    test(localStorage.getItem('jwt_token')).then((result) => {
      if (result.status === 401) {
        localStorage.removeItem('jwt_token');
        props.history.push('/login?expired');
      }
    });
  }, []);

  return (
    <Content
      topBar={<TopBarHive history={props.history} />}
      sideBar={
        <SideBarHive
          smallselected={smallselected}
          setsmallselected={setsmallselected}
          bigselected={bigselected}
          setbigselected={setbigselected}
        />
      }
      dark={false}
    >
      <div className={classes.contentDiv}>
        <Switch>
          <Route exact path="/settings" component={Settings} />
          <Route
            exact
            path="/client"
            render={() =>
              havePermissions('client') && (
                <Client history={props.history} loadState={loadState} />
              )
            }
          />
          <Route
            exact
            path="/client/create"
            render={() =>
              havePermissions('client_admin') && (
                <ClientForm history={props.history} loadState={loadState} />
              )
            }
          />
          <Route
            exact
            path="/client/associate"
            render={() =>
              havePermissions('client_admin') && (
                <AssociateClient loadState={loadState} />
              )
            }
          />
          <Route
            exact
            path="/client/associated"
            render={(props) =>
              havePermissions('client_admin') && (
                <AssociatedDeals loadState={loadState} {...props} />
              )
            }
          />
          <Route
            exact
            path="/client/associated/new"
            render={(props) =>
              havePermissions('client_admin') && (
                <NewAssociated loadState={loadState} {...props} />
              )
            }
          />
          <Route
            exact
            path="/client/associated/edit/:id"
            render={(props) =>
              havePermissions('client_admin') && (
                <EditAssociated loadState={loadState} {...props} />
              )
            }
          />
          <Route
            exact
            path="/client/relations"
            render={(props) =>
              havePermissions('client_admin') && (
                <ClientRelations loadState={loadState} {...props} />
              )
            }
          />
          <Route
            exact
            path="/client/relations/new"
            render={(props) =>
              havePermissions('client_admin') && (
                <NewRelation loadState={loadState} {...props} />
              )
            }
          />
          <Route
            exact
            path="/client/relations/edit/:id"
            render={(props) =>
              havePermissions('client_admin') && (
                <EditRelation loadState={loadState} {...props} />
              )
            }
          />
          <Route
            exact
            path="/client/:deal_id"
            render={(props) =>
              havePermissions('client') && (
                <ShowClient loadState={loadState} {...props} />
              )
            }
          />
          <Route
            exact
            path="/client/edit-condition/:hubspot_id/:condition_id"
            component={
              havePermissions(['admin_sales_man', 'approver']) &&
              CommercialCondition
            }
          />
          <Route
            exact
            path="/user-management"
            render={() =>
              havePermissions('admin') && (
                <UserManagement loadState={loadState} />
              )
            }
          />
          {/* <Route
            exact
            path="/collection"
            render={(props) =>
              havePermissions('accounting') && (
                <Collection loadState={loadState} {...props} />
              )
            }
          /> */}
          <Route
            exact
            path="/collection"
            render={(props) =>
              havePermissions('accounting') && (
                <CollectionV2 loadState={loadState} {...props} />
              )
            }
          />
          <Route
            exact
            path="/invoices"
            render={() =>
              havePermissions('accounting') && (
                <Invoices loadState={loadState} />
              )
            }
          />
          <Route
            path="/invoices/client/:id/:type"
            render={(props) =>
              havePermissions('accounting') && (
                <InvoiceDetails loadState={loadState} {...props} />
              )
            }
          />
          <Route
            exact
            path="/drafts"
            render={() =>
              havePermissions('accounting') && <Drafts loadState={loadState} />
            }
          />
          <Route
            exact
            path="/datawarehouse"
            render={() =>
              havePermissions('data_admin') && (
                <Datawarehouse loadState={loadState} />
              )
            }
          />
          <Route
            exact
            path="/accounting/prepaids"
            render={() =>
              havePermissions('accounting') && (
                <Prepaid loadState={loadState} {...props} />
              )
            }
          />
          <Route
            exact
            path="/sales/drafts"
            render={() =>
              havePermissions('sales_man') && (
                <DraftsComments loadState={loadState} />
              )
            }
          />
          <Route
            exact
            path="/sales/checklist"
            render={() =>
              havePermissions('sales_man') && (
                <CheckList loadState={loadState} />
              )
            }
          />
          <Route
            exact
            path="/accountpartner/drafts"
            render={() =>
              havePermissions('account_partner') && (
                <AccountPartnerDrafts loadState={loadState} />
              )
            }
          />
          <Route
            exact
            path="/conciliations"
            render={() =>
              havePermissions('accounting') && (
                <Conciliations loadState={loadState} />
              )
            }
          />
          <Route
            exact
            path="/commission-management"
            component={havePermissions('accounting') && CommissionManagement}
          />
          <Route
            exact
            path="/new-commission-management"
            component={havePermissions('accounting') && NewCommissionManagement}
          />
          <Route
            exact
            path="/user/commissions"
            component={havePermissions('sales_man') && Commissions}
          />
          <Route
            exact
            path="/user/new_commissions"
            component={
              havePermissions([
                'sales_man',
                'account_partner',
                'prospector',
                'admin_sales_man',
              ]) && NewCommissions
            }
          />
          <Route
            exact
            path="/management/drafts"
            component={havePermissions('approver') && ApproveDraft}
          />
          <Route
            exact
            path="/new-onboarding"
            component={havePermissions('hr') && HRNewOnboarding}
          />
          <Route
            exact
            path="/candidates"
            component={havePermissions('hr') && Candidates}
          />
          <Route
            exact
            path="/hr/candidate/edit/:id"
            component={havePermissions('hr') && EditCandidate}
          />
          <Route
            exact
            path="/hr/candidate/:id"
            component={havePermissions('hr') && CandidateView}
          />
          <Route
            exact
            path="/hr/resources/:type"
            component={havePermissions('hr') && Resources}
          />
          <Route
            exact
            path="/hr/resources/:id/add"
            component={havePermissions('hr') && AddResources}
          />
          <Route
            exact
            path="/hr/resources/user/:id"
            component={havePermissions('hr') && ShowResources}
          />
          <Route
            exact
            path="/hr/resources/inventory/:id"
            component={havePermissions('hr') && InventoryEdit}
          />
          <Route
            exact
            path="/hr/resources/add/inventory"
            component={havePermissions('hr') && InventoryAdd}
          />
          <Route
            exact
            path="/sales/collection"
            component={havePermissions('sales_man') && SalesCollection}
          />
          <Route
            exact
            path="/sales/prospectors"
            component={havePermissions('prospector') && Prospector}
          />
          <Route
            exact
            path="/sales/commercial-conditions"
            component={
              havePermissions(['sales_man']) && CommercialConditionsTable
            }
          />
          <Route
            exact
            path="/sales/commercial-conditions-approval"
            component={
              havePermissions(['admin_sales_man']) &&
              CommercialConditionsApprovalTable
            }
          />
          <Route
            exact
            path="/sales/new-commercial-condition"
            component={havePermissions(['sales_man']) && SalesManForm}
          />
          <Route
            exact
            path="/sales/new-custom-commercial-condition"
            component={
              havePermissions(['admin_sales_man']) && CommercialCondition
            }
          />
          <Route
            exact
            path="/beetrack/accounts/create"
            component={
              havePermissions([
                'support_agent',
                'support_supervisor',
                'support_admin',
              ]) && CreateAccount
            }
          />
          <Route
            exact
            path="/beetrack/accounts/toggle"
            component={
              havePermissions([
                'support_agent',
                'support_supervisor',
                'support_admin',
              ]) && ToggleAccount
            }
          />
          <Route
            exact
            path="/create-form"
            component={havePermissions(['form_admin']) && CreateForm}
          />
          <Route
            exact
            path="/edit-form/:fid"
            component={havePermissions(['form_admin']) && CreateForm}
          />
          <Route
            exact
            path="/forms/:fid"
            component={havePermissions(['form_admin']) && FormView}
          />
          <Route
            exact
            path="/dashboards/helpcenter"
            component={havePermissions(['dashboard']) && DashboardHelpCenter}
          />
          <Route
            exact
            path="/customer-auth"
            component={havePermissions(['super']) && CustomerPortal}
          />
          <Route exact path="/forms" component={Forms} />
          <Route exact path="/suppliers/new" component={NewSupplier} />
          <Route exact path="/suppliers" component={TableSuppliers} />
          <Route exact path="/suppliers/:id" component={Supplier} />
          <Route path="/" component={Menu} />
        </Switch>
      </div>
    </Content>
  );
};

export default withStyles(styles)(Home);
