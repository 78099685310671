import React, { Component, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { get, del, put } from '../../utils/api';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import Grid from '@material-ui/core/Grid';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Divider from '@material-ui/core/Divider';
import NumberFormat from 'react-number-format';

class ConciliationsListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: [],
      loading: true,
      selected: null,
      unpaid_invoices: [],
      buttons_enabled: true,
    };
  }

  componentDidMount() {
    let client_list = [];
    this.props.clients.map((client) => {
      let elem = {};
      elem.label = client['company_name'];
      elem.value = client['realm_id'] + '-' + client['id'];
      client_list.push(elem);
    });
    this.setState({
      loading: false,
      clients: client_list,
    });
  }

  capitalize = (word) => {
    return word ? word.charAt(0).toUpperCase() + word.slice(1) : '';
  };

  handleChange = async (value) => {
    this.setState({ selected: value });
    let response = await get('/invoices/client/' + value.value);
    this.getUnpaidInvoices(response.data.invoices);
  };

  getUnpaidInvoices = (invoices = []) => {
    if (!invoices) {
      return;
    }
    let returned_array = [];
    invoices.map((invoice) => {
      if (invoice['Balance'] != 0) {
        returned_array.push(invoice);
      }
    });
    this.setState({
      unpaid_invoices: returned_array,
    });
  };

  handleClick = (accept) => {
    if (this.state.selected === null && accept) {
      alert(
        'Debe seleccionar un cliente de Quickbooks, para validar la homologación',
      );
      return;
    }
    this.setState({ buttons_enabled: false });
    if (accept) {
      let data = {
        client: this.state.selected,
      };
      put('/conciliations/' + this.props.item.id, data).then((response) => {
        if (response.status === 200) {
          window.location.reload();
        } else {
          alert(response.data.message);
        }
      });
    } else {
      del('/conciliations/' + this.props.item.id).then((response) => {
        if (response.status === 200) {
          window.location.reload();
        } else {
          alert('Ocurrió un error al rechazar la conciliación');
        }
      });
    }
  };

  renderUnpaidInvoices = () => {
    if (this.state.unpaid_invoices.length === 0) {
      return;
    }
    return (
      <React.Fragment>
        <Typography
          className={this.props.classes.title}
          color="textSecondary"
          variant="outlined"
        >
          Facturas Pendientes de Pago
        </Typography>
        {this.state.unpaid_invoices.map((invoice) => (
          <Typography className={this.props.classes.normalText} gutterBottom>
            <b>Folio {invoice['DocNumber']}</b>:{' '}
            <NumberFormat
              value={invoice['Balance']}
              displayType={'text'}
              prefix={'$'}
              thousandSeparator={true}
            />
          </Typography>
        ))}
      </React.Fragment>
    );
  };

  render() {
    const { classes } = this.props;
    let { clients, selected, loading } = this.state;
    return (
      <div className={classes.cardContainer}>
        <Card className={classes.card} variant="outlined">
          <Grid container spacing={10} className={classes.infoContainer}>
            <Grid item xs={6}>
              <div className={classes.details}>
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {this.capitalize(this.props.item.unique_json.txn_type)}
                  </Typography>
                  <Typography className={classes.normalText} gutterBottom>
                    <b>Cliente:</b> {this.props.item.unique_json.data.client}
                  </Typography>
                  <Typography className={classes.normalText} gutterBottom>
                    <b>Fecha:</b> {this.props.item.unique_json.data.date}
                  </Typography>
                  <Typography className={classes.normalText} gutterBottom>
                    <b>Monto:</b>{' '}
                    <NumberFormat
                      value={this.props.item.unique_json.data.amount}
                      displayType={'text'}
                      prefix={'$'}
                      thousandSeparator={true}
                    />
                  </Typography>
                  <Typography className={classes.normalText} gutterBottom>
                    <b>Identificador:</b>{' '}
                    {this.props.item.unique_json.data.tax_identifier}
                  </Typography>
                  <Typography className={classes.normalText} gutterBottom>
                    <b>Banco:</b> {this.capitalize(this.props.item.bank)}
                  </Typography>
                  <Divider className={classes.divider} />
                  <Select
                    components={makeAnimated()}
                    value={selected}
                    options={clients}
                    className={classes.selectClients}
                    name="quickbooksClients"
                    loading={this.state.loading}
                    searchable={true}
                    clearable={true}
                    onChange={(value) => this.handleChange(value)}
                  />
                  {this.renderUnpaidInvoices()}
                </CardContent>
              </div>
            </Grid>
            <Grid item xs={6} className={classes.buttons}>
              <div className={classes.controls}>
                <Fab
                  color="primary"
                  aria-label="accept"
                  disabled={!this.state.buttons_enabled}
                  onClick={() => this.handleClick(true)}
                >
                  <CheckIcon />
                </Fab>
                <div className={classes.spacer} />
                <Fab
                  color="secondary"
                  aria-label="reject"
                  disabled={!this.state.buttons_enabled}
                  onClick={() => this.handleClick(false)}
                >
                  <ClearIcon />
                </Fab>
              </div>
            </Grid>
          </Grid>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(ConciliationsListItem);
