import React, { useState } from 'react';
import { hr_post } from '../../utils/api';
import {
  Form,
  TextField,
  Button,
  Select,
  Snackbar,
} from '@beetrack/hp-components';

import { Grid } from '@material-ui/core';

const CustomerPortal = () => {
  const [clientId, setClientId] = useState('');
  const [permit, setPermit] = useState('');

  const [openAlert, setAlertOpen] = useState(false);
  const [status, setStatus] = useState('success');
  const [errorMessage, setErrorMessage] = useState('');

  const permitOptions = [
    { value: 'superAdmin', label: 'superAdmin' },
    { value: 'admin', label: 'admin' },
  ];

  const permitHandler = (event) => {
    setPermit(event.value);
  };

  const handleForm = (event) => {
    event.preventDefault();

    hr_post(
      '/billing_portal/permissions',
      { client_id: clientId, permit: permit },
      true,
    ).then((response) => {
      if (response.status === 200) {
        setStatus('success');
        setErrorMessage(response.data.message);
        setAlertOpen(true);
      } else {
        setStatus('error');
        setErrorMessage(response.data.message);
        setAlertOpen(true);
      }
    });
  };

  return (
    <div>
      <Form
        buttons={[
          <Button key="submit" form="my-form">
            Agregar Permisos
          </Button>,
        ]}
        id="my-form"
        onSubmit={handleForm}
        title="Agregar Permisos Customer"
      >
        <Grid item xs={12}>
          <TextField
            label="ID Cliente"
            name="id_client"
            key="text-field-22"
            value={clientId}
            onChange={(event) => setClientId(event.target.value)}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            label="Tipo de permiso"
            name="permit"
            key="simple-select-6"
            value={permit}
            options={permitOptions}
            onChange={permitHandler}
            required
          />
        </Grid>
      </Form>
      <Snackbar
        isOpen={openAlert}
        message={errorMessage}
        onDismiss={() => setAlertOpen(false)}
        status={status}
      />
    </div>
  );
};

export default CustomerPortal;
