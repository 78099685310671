import React, { Component, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import ConciliationsListItem from './conciliations_list_item'

class ConciliationsList extends Component {

  constructor(props) {
    super(props)
  }

  render() {
   return(
     <div>
       { this.props.conciliations.map((item) => { return(<ConciliationsListItem item={ item } clients={ this.props.clients } />) }) }
     </div>
   )
  }
}

export default ConciliationsList
