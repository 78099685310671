import React, { useEffect, useState } from 'react';
import { Table, SearchBar } from '@beetrack/hp-components';
import { View } from '@beetrack/hp-icons-react';
import { get } from '../../../../utils/api';

const ResourcesTable = (props) => {
  const { history } = props;
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState({});

  const columns = [
    { name: 'name', title: 'Nombre', valueByAttribute: 'name' },
    { name: 'email', title: 'Correo', valueByAttribute: 'email' },
  ];

  const handleViewUser = (user) => {
    history.push(`/hr/resources/user/${user[0].item.id}`);
  };

  const handleSearch = (value) => {
    setSearch(value);
    let new_data = data.filter((element) =>
      element.name.toLowerCase().includes(value.name.toLowerCase()),
    );
    setData(new_data);
  };

  const actions = [
    {
      title: 'Ver Tracker',
      icon: <View />,
      onClick: handleViewUser.bind(this),
      isGlobal: false,
    },
  ];

  useEffect(() => {
    get('/resources').then((response) => {
      if (response.status == 200) {
        setAllData(
          response.data.data.map((item) => {
            return item.attributes;
          }),
        );
        setData(
          response.data.data.map((item) => {
            return item.attributes;
          }),
        );
        setLoading(false);
      }
    });
  }, []);

  return (
    <>
      <SearchBar
        callback={(value) => handleSearch(value)}
        emptyCallback={() => {
          setSearch({});
          setData(allData);
        }}
        value={search}
        options={[{ label: 'Nombre', name: 'name' }]}
      />
      <br />
      <Table
        columns={columns}
        data={data}
        loading={loading}
        fetchData={() => {}}
        actions={actions}
        isItemLoaded={() => {}}
      />
    </>
  );
};

export default ResourcesTable;
