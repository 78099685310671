import React, { Component } from 'react';
import decode from 'jwt-decode';
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { register } from '../../utils/api.js';
import { styles } from './styles';
import { withStyles } from '@material-ui/core/styles';
import { Delete, Edit } from '@material-ui/icons';
import { get, del } from '../../utils/api';

class Oauth extends Component {
  constructor(props) {
    super(props);
    let scopes = decode(localStorage.getItem('jwt_token')).scopes;
    let superUser = scopes.indexOf('super') >= 0 ? true : false;
    this.state = {
      quickbooksMenu: null,
      quickbooksAccounts: null,
      hubspotAccounts: null,
      googleAccount: null,
      superUser: superUser,
    };
    this.loadQuickbooksAccounts = this.loadQuickbooksAccounts.bind();
    this.haveHubspotAccounts = this.haveHubspotAccounts.bind();
  }

  async componentDidMount() {
    this.loadQuickbooksAccounts();
    if (this.state.superUser) {
      this.haveHubspotAccounts();
      this.haveGoogleAccount();
    }
  }

  loadQuickbooksAccounts = async () => {
    let response = await get('/quickbooks');
    if (response.status === 200) {
      this.setState({ quickbooksAccounts: response.data });
    }
  };

  haveHubspotAccounts = async () => {
    let response = await get('/hubspot');
    if (response.status === 200) {
      this.setState({
        hubspotAccounts: response.data.length > 0 ? response.data : null,
      });
    }
  };

  haveGoogleAccount = () => {
    get('/google').then((response) => {
      if (response.status === 200) {
        this.setState({
          googleAccount: response.data > 0 ? response.data : null,
        });
      }
    });
  };

  handleClick = async (provider) => {
    let response = await register(provider, localStorage.getItem('jwt_token'));
  };

  openMenu = (event) => {
    this.setState({ quickbooksMenu: event.currentTarget });
  };

  closeMenu = () => {
    this.setState({ quickbooksMenu: null });
  };

  deleteAccount = async (identity, id) => {
    let response = await del(`/${identity}/${id}`);
    if (response.status === 200) {
      this.loadQuickbooksAccounts();
      this.haveHubspotAccounts();
      this.haveGoogleAccount();
    }
  };

  render() {
    const { classes } = this.props;
    const { quickbooksMenu, hubspotAccounts, googleAccount, superUser } =
      this.state;
    const hubspot = hubspotAccounts ? (
      <Button
        color="secondary"
        variant="contained"
        className={classes.socialButton}
        onClick={(e) => this.deleteAccount('hubspot', hubspotAccounts[0])}
      >
        Desvincular
      </Button>
    ) : (
      <Button
        color="primary"
        variant="contained"
        className={classes.socialButton}
        onClick={(e) => this.handleClick('hubspot')}
      >
        Vincular
      </Button>
    );
    const quickbooksAccounts =
      this.state.quickbooksAccounts &&
      this.state.quickbooksAccounts.map((account) => {
        return (
          <MenuItem key={'quickbook-' + account[0]}>
            {account[1]}
            <IconButton
              onClick={(e) => this.deleteAccount('quickbooks', account[0])}
            >
              <Delete />
            </IconButton>
          </MenuItem>
        );
      });
    const google = googleAccount ? (
      <Button
        color="secondary"
        variant="contained"
        className={classes.socialButton}
        onClick={(e) => this.deleteAccount('google', googleAccount[0])}
      >
        Desvincular
      </Button>
    ) : (
      <Button
        color="primary"
        variant="contained"
        className={classes.socialButton}
        onClick={(e) => this.handleClick('google')}
      >
        Vincular
      </Button>
    );

    return (
      <section id="connectSection">
        <Typography component="h2" className={classes.titleSection}>
          Conectar con otras plataformas
        </Typography>
        {superUser && (
          <div className={classes.socialNetwork}>
            <div className={classes.socialName}>
              <Typography
                fontSize={'1.2em'}
                variant="body1"
                display="inline"
                className={classes.socialText}
              >
                Hubspot:
              </Typography>
            </div>
            {hubspot}
          </div>
        )}
        {superUser && (
          <div className={classes.socialNetwork}>
            <div className={classes.socialName}>
              <Typography
                fontSize={'1.2em'}
                variant="body1"
                display="inline"
                className={classes.socialText}
              >
                Google:
              </Typography>
            </div>
            {google}
          </div>
        )}
        <div className={classes.socialNetwork}>
          <div className={classes.socialName}>
            <Typography
              fontSize={'1.2em'}
              variant="body1"
              display="inline"
              className={classes.socialText}
            >
              Quickbooks:
            </Typography>
          </div>
          <Button
            aria-owns={quickbooksMenu ? 'quickbooks-menu' : undefined}
            aria-haspopup="true"
            color="primary"
            variant="contained"
            className={classes.showSocial}
            onClick={this.openMenu}
          >
            <Edit />
          </Button>
          <Button
            color="primary"
            variant="contained"
            className={classes.newSocial}
            onClick={(e) => this.handleClick('quickbooks')}
          >
            Vincular
          </Button>
          <Menu
            id="quickbooks-menu"
            anchorEl={quickbooksMenu}
            open={Boolean(quickbooksMenu)}
            onClose={this.closeMenu}
          >
            {quickbooksAccounts}
          </Menu>
        </div>
      </section>
    );
  }
}

export default withStyles(styles)(Oauth);
