export const styles = theme => ({
  form: {
    width: '500px',
    height: '400px',
    position: 'fixed',
    transform: 'translate(50%, -25%)',
    right: '50%',
    padding: '5%',
    bottom: '0%'
  },
  title: {
    paddingBottom: theme.spacing(10)
  },
  fieldsForm: {
    textAlign: 'center'
  },
  fields: {
    margin: theme.spacing(2)
  },
  field: {
    width: theme.spacing(40)
  }
})