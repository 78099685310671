import React from 'react';
import { DatePicker } from '@beetrack/hp-components';
import { Grid } from '@mui/material';

import { getNestedValue } from '../utils';

const BigTextElementTranslator = (props) => {
  const { classes, form, formValues, setFormValues, path, dependant } = props;

  const element = getNestedValue(form.content, path);

  return (
    <Grid item sm={12} md={dependant ? 12 : 10} className={classes.element}>
      <div className={classes.question}>{element.question}</div>
      {element.imgUrl && (
        <div className={classes.imageContainer}>
          <img
            style={{ width: '100%', height: '100%' }}
            src={element.imgUrl}
            alt="new"
          />
        </div>
      )}
      <div className={classes.dateField}>
        <DatePicker
          format="YYYY-MM-DD"
          date={formValues[`${element.propertyName}`]}
          onChange={(value) => {
            setFormValues({
              ...formValues,
              [`${element.propertyName}`]: value,
            });
          }}
          required={element.required}
        />
      </div>
    </Grid>
  );
};

export default BigTextElementTranslator;
