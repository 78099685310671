import React, { useEffect, useState } from 'react';
import { Table, Modal, Button } from '@beetrack/hp-components';
import { Delete, View, Edit } from '@beetrack/hp-icons-react';
import { get, post } from '../../../../../utils/api';
import ConfirmationModal from '../../../../../shared/ConfirmationModal';

const InventoryTable = (props) => {
  const { history, data, setSnackbar, setReload, reload } = props;
  const [loading, setLoading] = useState(true);
  const [modalInfo, setModalInfo] = useState({
    open: false,
    data: { additional_data: {} },
  });
  const [modalDiscard, setModalDiscard] = useState({ open: false, list: [] });

  const columns = [
    {
      name: 'name',
      title: 'Nombre',
      valueByAttribute: 'name',
    },
    {
      name: 'object_id',
      title: 'Identificador',
      valueByAttribute: 'object_id',
    },
    {
      name: 'date',
      title: 'Fecha de entrega',
      valueByAttribute: 'created_at',
    },
  ];

  const handleDeleteResource = (resource) => {
    setModalDiscard({ open: true, list: resource });
  };

  const handleViewResource = (resource) => {
    setModalInfo({ open: true, data: resource[0].item });
  };

  const handleEdit = (resource) => {
    history.push(`/hr/resources/inventory/${resource[0].item.id}`);
  };

  const discardResources = (resources) => {
    let payload = {
      list: resources.map((resource) => resource.item.id),
    };
    post('/resources/discard', payload).then((response) => {
      if (response.status === 200) {
        setSnackbar({
          open: true,
          message: 'Recursos eliminados con exito',
          status: 'success',
        });
        setReload(reload + 1);
      } else {
        setSnackbar({
          open: true,
          message: 'Problema eliminando recursos, intente nuevamente',
          status: 'error',
        });
      }
    });
  };

  const actions = [
    {
      title: 'Más información',
      icon: <View />,
      onClick: handleViewResource.bind(this),
      isGlobal: false,
    },
    {
      title: 'Eliminar Inventario',
      icon: <Delete />,
      onClick: handleDeleteResource.bind(this),
      isGlobal: true,
    },
    {
      title: 'Editar',
      icon: <Edit />,
      onClick: handleEdit.bind(this),
      isGlobal: true,
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        data={data}
        fetchData={() => {}}
        actions={actions}
      />
      <Modal
        key="info-modal"
        open={modalInfo.open}
        title={modalInfo.data.name}
        onClose={() =>
          setModalInfo({ open: false, data: { additional_data: {} } })
        }
      >
        <p>
          Marca:{' '}
          {modalInfo.data.additional_data.brand
            ? modalInfo.data.additional_data.brand
            : '-'}
        </p>
        <p>
          Modelo:{' '}
          {modalInfo.data.additional_data.model
            ? modalInfo.data.additional_data.model
            : '-'}
        </p>
        <p>
          Descripción:{' '}
          {modalInfo.data.additional_data.description
            ? modalInfo.data.additional_data.description
            : '-'}
        </p>
        <p>
          Identificador:{' '}
          {modalInfo.data.additional_data.identifier
            ? modalInfo.data.additional_data.identifier
            : '-'}
        </p>
      </Modal>
      <ConfirmationModal
        text="¿Eliminar Accesos?"
        secondaryText="Se notificará a los responsables de eliminar los accesos correspondientes"
        open={modalDiscard.open}
        close={() => setModalDiscard({ open: false, list: [] })}
        onConfirm={() => discardResources(modalDiscard.list)}
      />
    </>
  );
};

export default InventoryTable;
