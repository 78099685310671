import React, { useEffect, useState } from 'react';
import {
  Page,
  Button,
  ConfirmationModal,
  Snackbar,
} from '@beetrack/hp-components';
import PrepaidsTable from './Components/PrepaidsTable';
import { get, del } from '../../utils/api';
import EditPrepaid from './Components/EditPrepaid';
import moment from 'moment';

const Prepaid = (props) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openEditPrepaid, setOpenEditPrepaid] = useState({
    open: false,
    edit: false,
  });
  const [hubspotId, setHubspotId] = useState('');
  const [startDate, setStartDate] = useState(
    moment().startOf('month').format('DD/MM/YYYY'),
  );
  const [prepaidMonths, setPrepaidMonths] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [product, setProduct] = useState('');
  const [dealName, setDealName] = useState('');
  const [blockEdit, setBlockEdit] = useState(true);
  const [reload, setReload] = useState(0);
  const [planId, setPlanId] = useState('');
  const [prepaidId, setPrepaidId] = useState('');
  const [confirmBlocked, setConfirmBlocked] = useState(false);
  const [snackBar, setSnackBar] = useState({
    open: false,
    status: 'alert',
    message: '',
  });

  const history = props.history;

  const handleClickEdit = (data) => {
    setHubspotId(data.hubspot_id);
    setStartDate(data.from);
    setPrepaidMonths(prepaidMonthsFromDates(data.from, data.to));
    setProduct(data.product);
    setQuantity(data.quantity);
    setDealName(data.deal_name);
    setPlanId(data.plan_id);
    setPrepaidId(data.id);
    setBlockEdit(false);
    setOpenEditPrepaid({ open: true, edit: true });
  };

  const handleClickDelete = (data) => {
    setOpenConfirm(true);
    setPrepaidId(data.id);
  };

  const deletePrepaid = () => {
    setConfirmBlocked(true);
    del(`/prepaids/${prepaidId}`).then((response) => {
      if (response.status === 200) {
        setReload(reload + 1);
        setConfirmBlocked(false);
        setOpenConfirm(false);
        setSnackBar({
          open: true,
          message: 'Prepago eliminado correctamente',
          status: 'success',
        });
      } else {
        setConfirmBlocked(false);
        setOpenConfirm(false);
        setSnackBar({
          open: true,
          message: 'Problemas eliminando prepago, intente nuevamente',
          status: 'error',
        });
      }
    });
  };

  const clearForm = () => {
    setHubspotId('');
    setStartDate(moment().startOf('month').format('DD/MM/YYYY'));
    setPrepaidMonths(0);
    setProduct('');
    setQuantity(0);
    setDealName('');
    setBlockEdit(false);
    setPlanId('');
    setOpenEditPrepaid({ open: false, edit: false });
  };

  const prepaidMonthsFromDates = (from, to) => {
    // Change format of from and to date so moment() can use it to obtain its difference in months
    const [fromDay, fromMonth, fromYear] = from.split('/');
    const formatFromDate = [fromMonth, fromDay, fromYear].join('-');
    const [toDay, toMonth, toYear] = to.split('/');
    const formatToDate = [toMonth, toDay, toYear].join('-');
    return moment(formatToDate).diff(moment(formatFromDate), 'months');
  };

  useEffect(() => {
    get('/prepaids').then((response) => {
      if (response.status === 200) {
        setData(response.data.prepaids);
        setLoading(false);
      } else {
        setSnackBar({
          open: true,
          status: 'error',
          message: 'Problemas cargando información, intente nuevamente',
        });
      }
    });
  }, [reload]);

  return (
    <Page
      title="Clientes prepagos"
      buttons={[
        <Button
          key="new-prepaid"
          onClick={() => {
            setOpenEditPrepaid({ open: true, edit: false });
            setBlockEdit(true);
          }}
        >
          Nuevo prepago
        </Button>,
      ]}
    >
      <PrepaidsTable
        data={data}
        loading={loading}
        handleEdit={handleClickEdit}
        history={history}
        handleDelete={handleClickDelete}
      />
      <EditPrepaid
        open={openEditPrepaid.open}
        setOpen={setOpenEditPrepaid}
        dealId={hubspotId}
        setDealId={setHubspotId}
        startDate={startDate}
        setStartDate={setStartDate}
        prepaidMonths={prepaidMonths}
        setPrepaidMonths={setPrepaidMonths}
        quantity={quantity}
        setQuantity={setQuantity}
        product={product}
        setProduct={setProduct}
        edit={openEditPrepaid.edit}
        closeModal={clearForm}
        dealName={dealName}
        setDealName={setDealName}
        blockEdit={blockEdit}
        setBlockEdit={setBlockEdit}
        planId={planId}
        reload={reload}
        setReload={setReload}
        prepaidId={prepaidId}
        setPrepaidId={setPrepaidId}
        setSnackBar={setSnackBar}
      />
      <ConfirmationModal
        key="confirm-delete-prepaid-modal"
        open={openConfirm}
        title="¿Estás seguro de eliminar este prepago?"
        onClose={() => setOpenConfirm(false)}
        confirmButton={
          <Button onClick={() => deletePrepaid()} disabled={confirmBlocked}>
            Confirmar y eliminar
          </Button>
        }
      ></ConfirmationModal>
      <Snackbar
        key="snackbar-edit-prepaid"
        isOpen={snackBar.open}
        message={snackBar.message}
        onDismiss={() =>
          setSnackBar({
            open: false,
            status: 'alert',
            message: '',
          })
        }
        status={snackBar.status}
      />
    </Page>
  );
};

export default Prepaid;
