import React, { useEffect, useState } from 'react';
import { Table, Page, Button, SearchBar } from '@beetrack/hp-components';
import { View, Delete, Add } from '@beetrack/hp-icons-react';
import { get } from '../../../utils/api';
import { Grid, Paper } from '@material-ui/core';
import { simpleCountry } from '../../../utils/utils';

const TableSuppliers = (props) => {
  const permissionType = props.location.search;
  const [suppliers, setSuppliers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [countryInfo, setCountryInfo] = useState({
    chile: 0,
    mexico: 0,
    peru: 0,
    other: 0,
  });
  const [snack, setSnack] = useState({
    open: false,
    message: '',
    status: 'success',
  });
  const { history } = props;

  const handleViewSupplier = (event) => {
    event.forEach((element) => {
      history.push(`/suppliers/${element.item.id.toString()}${permissionType}`);
    });
  };

  const handleSearch = (value) => {
    setSearch(value);
    var new_data = filteredData;
    if (value.name) {
      new_data = new_data.filter((element) =>
        element.name.toLowerCase().includes(value.name.toLowerCase()),
      );
    } else if (value.country) {
      new_data = new_data.filter((element) =>
        element.country.toLowerCase().includes(value.country.toLowerCase()),
      );
    }
    setFilteredData(new_data);
  };

  useEffect(() => {
    get(`/suppliers?permission=${permissionType.replace('?', '')}`).then(
      (response) => {
        if (response.status === 200) {
          setSuppliers(response.data);
          setFilteredData(response.data);
          setLoading(false);
          let chile = response.data.filter(
            (element) => simpleCountry(element.country) === 'chile',
          ).length;
          let peru = response.data.filter(
            (element) => simpleCountry(element.country) === 'peru',
          ).length;
          let mexico = response.data.filter(
            (element) => simpleCountry(element.country) === 'mexico',
          ).length;
          let other = response.data.filter(
            (element) => simpleCountry(element.country) === 'other',
          ).length;
          setCountryInfo({ chile, peru, mexico, other });
        }
      },
    );
  }, []);

  const columns = [
    {
      name: 'name',
      title: <strong> Nombre Proveedor </strong>,
      valueByAttribute: 'name',
    },
    {
      name: 'country',
      title: <strong> País </strong>,
      valueByAttribute: 'country',
    },
    {
      name: 'currency',
      title: <strong> Moneda </strong>,
      valueByAttribute: 'currency',
    },
    { name: 'area', title: <strong> Área </strong>, valueByAttribute: 'area' },
    {
      name: 'owner',
      title: <strong> Responsable </strong>,
      valueByAttribute: 'owner',
    },
  ];
  return (
    <Page
      title="Dashboard Proveedores"
      buttons={[
        <Button
          key="new-supplier"
          icon={<Add />}
          onClick={() => history.push(`/suppliers/new${permissionType}`)}
        >
          {' '}
          Crear Proveedor{' '}
        </Button>,
      ]}
    >
      <Grid container spacing={3}>
        <Grid item xs>
          <Paper>
            <center>
              <h3 className="hp-page__header-title">{countryInfo.chile}</h3>
              <p className="hp-typography__body--regular"> Chile </p>
            </center>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper>
            <center>
              <h3 className="hp-page__header-title">{countryInfo.mexico}</h3>
              <p className="hp-typography__body--regular"> México </p>
            </center>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper>
            <center>
              <h3 className="hp-page__header-title">{countryInfo.peru}</h3>
              <p className="hp-typography__body--regular"> Perú </p>
            </center>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper>
            <center>
              <h3 className="hp-page__header-title">{countryInfo.other}</h3>
              <p className="hp-typography__body--regular"> Otro </p>
            </center>
          </Paper>
        </Grid>
      </Grid>
      <div style={{ paddingBottom: '15px' }}>
        <SearchBar
          callback={(value) => handleSearch(value)}
          emptyCallback={() => {
            setSearch({});
            setFilteredData(suppliers);
          }}
          value={search}
          options={[
            { label: 'Nombre', name: 'name' },
            { label: 'País', name: 'country' },
          ]}
        />
      </div>
      <Table
        columns={columns}
        data={filteredData}
        actions={[
          {
            title: 'Ver Proveedor',
            icon: <View />,
            onClick: handleViewSupplier.bind(this),
            isGlobal: false,
          },
        ]}
        loading={loading}
        isItemLoaded={(index) => suppliers[index]}
      />
    </Page>
  );
};

export default TableSuppliers;
