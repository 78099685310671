import React, { Component, useState } from 'react';
import MaterialTable, { MTableToolbar, MTablePagination } from 'material-table';
import { get, post } from '../../utils/api';
import { withStyles } from '@material-ui/core/styles';
import {
  Modal,
  Button,
  Select,
  TextField,
  DatePicker,
  FormRow,
  Table,
} from '@beetrack/hp-components';
import { Delete } from '@beetrack/hp-icons-react';
import './styles.scss';
import { styles } from './styles';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Paper } from '@material-ui/core';
import makeAnimated from 'react-select/animated';

const insideTable = {
  boxShadow: 'none !important',
  padding: '25px',
};

const toolbarDiv = {
  display: 'flex',
};
const innerTableToolbar = {
  flexGrow: '1 !important',
};
const innerTableButton = {
  flexGrow: '0',
  paddingTop: '1em',
  paddingRight: '1em',
};

class Invoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      selected: null,
      clients: [],
      loading: false,
      invoices: [],
      toSaveEvent: '',
      toSaveRowData: '',
      referencesModal: { open: false, data: {} },
      referenceOptions: [
        { value: 'HES', label: 'HES' },
        { value: 'OC', label: 'Orden de compra' },
        { value: 'CONTRACT', label: 'Contrato' },
      ],
      selectedReference: { value: '', label: '' },
      referenceDate: this.formatDate(new Date()),
      referenceFolio: '',
      referenceColumns: [
        {
          title: 'Referencia',
          name: 'doc_type',
          valueByAttribute: 'doc_type',
          sortable: true,
        },
        {
          title: 'Folio',
          name: 'folio',
          valueByAttribute: 'folio',
          sortable: true,
        },
        {
          title: 'Fecha',
          name: 'date',
          valueByAttribute: 'date',
          sortable: true,
        },
      ],
      referenceData: [],
    };
  }

  formatDate = (date) => {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    if (month < 10) {
      month = `0${month}`;
    }
    if (day < 10) {
      day = `0${day}`;
    }
    return `${day}-${month}-${year}`;
  };

  componentDidMount() {
    this.setState({ loading: true });
    this.getClients();
    this.setState({ loading: false });
  }

  getClients = async () => {
    let clients = [];
    this.props.loadState(true);
    await get('/invoices').then((response) => {
      Object.keys(response.data.clients).map((realm_id) => {
        Object.keys(response.data.clients[realm_id]).map((client_id) => {
          let client = {};
          client.value = realm_id + '-' + client_id;
          client.label = response.data.clients[realm_id][client_id].DisplayName;
          clients.push(client);
        });
      });
    });
    this.setState({ clients });
    this.props.loadState(false);
  };

  async getInvoices(client) {
    let invoices = [];
    let estimates = [];
    this.props.loadState(true);
    await get('/invoices/client/' + client).then((response) => {
      if (response.data.invoices != null) {
        response.data.invoices.forEach((invoiceData) => {
          let invoice = {};
          invoice.docType = 'Factura';
          invoice.folio = invoiceData.DocNumber;

          if (invoiceData.Balance == 0) {
            if (invoiceData.PrivateNote != undefined) {
              /* NOTE: Se valida con undefined y no con null dado que en caso
               de no existir comentario este campo no exite en el objeto */
              invoice.status = 'pagada';
            } else {
              invoice.status = 'anulada';
            }
          } else {
            invoice.status = 'pendiente';
          }

          invoice.created = moment(invoiceData.MetaData.CreateTime).format(
            'DD-MM-YYYY',
          );
          invoice.duedate = moment(invoiceData.DueDate).format('DD-MM-YYYY');
          invoice.amount = invoiceData.Balance;
          invoices.push(invoice);
        });
      }

      if (response.data.estimates != null) {
        response.data.estimates.forEach((estimateData) => {
          let estimate = {};
          estimate.docType = 'Estimacion';
          estimate.folio = estimateData.DocNumber;
          estimate.created = moment(estimateData.TxnDate).format('DD-MM-YYYY');
          estimate.duedate = moment(estimateData.ExpirationData).format(
            'DD-MM-YYYY',
          );
          estimate.amount = estimateData.TotalAmt;
          estimate.docId = estimateData.Id;
          if (estimateData.TxnStatus === 'Closed') {
            estimate.status = 'cerrada';
          } else {
            estimate.status = 'pendiente';
          }
          estimates.push(estimate);
        });
      }
    });
    let data = [...invoices, ...estimates];
    this.props.loadState(false);
    return data;
  }

  handleChange = async (client) => {
    this.setState({ loading: true });
    let invoices = await this.getInvoices(client.value);
    this.setState({ loading: false, selected: client, invoices: invoices });
  };

  createInvoice = (event, data, references) => {
    let payload = {
      realm_id: this.state.selected.value.split('-')[0],
      id: data.docId,
      references: references,
    };
    post('/estimates/create_invoice', payload).then((response) => {
      if (response.status === 200) {
        this.getInvoices(this.state.selected.value);
        this.onCloseModal();
      }
    });
  };

  saveInvoice = () => {
    this.createInvoice(
      this.state.toSaveEvent,
      this.state.toSaveRowData,
      this.state.referenceData,
    );
  };

  onChangeReferenceSeletor = (e) => {
    this.setState({ selectedReference: e });
  };

  setReferenceFolio = (value) => {
    this.setState({ referenceFolio: value });
  };

  onSelectDate = (date) => {
    this.setState({ referenceDate: date });
  };

  addReference = () => {
    var old_references = this.state.referenceData;
    var new_references = [];
    old_references.map((reference) => {
      new_references.push(reference);
    });
    new_references.push({
      doc_type: this.state.selectedReference.value,
      folio: this.state.referenceFolio,
      date: this.state.referenceDate,
    });
    this.setState({
      referenceData: new_references,
      referenceFolio: '',
      referenceDate: this.formatDate(new Date()),
      selectedReference: {
        value: '',
        label: '',
      },
    });
  };

  deleteReference = (e) => {
    var old_references = this.state.referenceData;
    var new_references = [];
    old_references.map((reference) => {
      if (reference.folio !== e[0].item.folio) {
        new_references.push(reference);
      }
    });
    this.setState({ referenceData: new_references });
  };

  onCloseModal = () => {
    this.setState({
      referencesModal: { open: false, data: {} },
      referenceData: [],
      selectedReference: { value: '', label: '' },
      referenceFolio: '',
    });
  };

  render() {
    let { classes } = this.props;
    let {
      clients,
      selected,
      loading,
      referencesModal,
      referenceOptions,
      selectedReference,
    } = this.state;
    return (
      <div className={classes.wrapper}>
        <div>
          <Modal
            key="simple-modal"
            open={referencesModal.open}
            title="Añadir referencias"
            onClose={() =>
              this.setState({
                referencesModal: { open: false, data: {} },
                selectedReference: { value: '', label: '' },
                referenceDate: '',
                referenceFolio: '',
                referenceData: [],
              })
            }
            confirmButton={
              <Button type="secondary" onClick={this.saveInvoice}>
                Guardar
              </Button>
            }
          >
            <FormRow>
              <Select
                key="simple-select-1"
                value={selectedReference.value}
                options={referenceOptions}
                onChange={(e) => this.onChangeReferenceSeletor(e)}
                label="Añadir referencia"
              />
              <TextField
                key="simple"
                onChange={(e) => this.setReferenceFolio(e.target.value)}
                placeholder="Folio"
                label="Folio"
                value={this.state.referenceFolio}
              />
              <DatePicker
                date={this.state.referenceDate}
                onChange={(value) => {
                  this.onSelectDate(value);
                }}
              />
              <div className={'add-reference-button'}>
                <Button onClick={this.addReference}>Agregar</Button>
              </div>
            </FormRow>
            <br />
            <Table
              columns={this.state.referenceColumns}
              data={this.state.referenceData}
              actions={[
                {
                  title: 'Delete',
                  onClick: (e) => this.deleteReference(e),
                  icon: <Delete />,
                  isGlobal: false,
                },
              ]}
            />
          </Modal>
        </div>
        <Paper className={classes.paperSelector}>
          <div className={classes.selectContainer}>
            <Select
              value={selected}
              options={clients}
              name="quickbooksClients"
              onChange={(value) => this.handleChange(value)}
            />
          </div>
        </Paper>
        <Paper>
          <MaterialTable
            title="Facturas"
            columns={[
              { title: 'Documento', field: 'docType' },
              { title: 'Folio', field: 'folio' },
              { title: 'Estado', field: 'status' },
              { title: 'Ingreso', field: 'created' },
              { title: 'Vencimiento', field: 'duedate' },
              { title: 'Monto', field: 'amount', searchable: false },
            ]}
            data={this.state.invoices}
            options={{
              filtering: false,
              paging: true,
              sorting: true,
              pageSize: 5,
              pageSizeOptions: [],
              actionsColumnIndex: -1,
              showFirstLastPageButtons: false,
            }}
            localization={{
              body: {
                emptyDataSourceMessage: 'No hay facturas de este cliente.',
              },
            }}
            actions={[
              (rowData) => ({
                icon: 'receipt',
                tooltip: 'Crear factura',
                disabled:
                  rowData.docType == 'Factura' || rowData.status == 'cerrada',
                onClick: (event, rowdata) => {
                  rowData.status = 'cerrada';
                  this.setState({
                    referencesModal: { open: true },
                    toSaveEvent: event,
                    toSaveRowData: rowData,
                  });
                },
              }),
              (rowData) => ({
                icon: 'cancel',
                tooltip: 'Anular factura',
                disabled:
                  (rowData.docType == 'Factura' &&
                    rowData.status != 'pendiente') ||
                  rowData.docType == 'Estimacion',
                onClick: (event, rowdata) =>
                  alert('Factura anulada para folio: '),
              }),
            ]}
            components={{
              Toolbar: (props) => (
                <div style={toolbarDiv}>
                  <MTableToolbar {...props} style={innerTableToolbar} />
                  <div style={innerTableButton}>
                    {selected && (
                      <Link
                        to={{
                          pathname: `/invoices/client/${selected.value}/invoice`,
                        }}
                      >
                        <Button variant="contained" size="small">
                          Nueva Factura
                        </Button>
                      </Link>
                    )}
                  </div>
                  <div style={innerTableButton}>
                    {selected && (
                      <Link
                        to={{
                          pathname: `/invoices/client/${selected.value}/estimate`,
                        }}
                      >
                        <Button variant="contained" size="small">
                          Nueva Estimación
                        </Button>
                      </Link>
                    )}
                  </div>
                  <div style={innerTableButton}>
                    {selected && (
                      <Link
                        to={{
                          pathname: `/invoices/client/${selected.value}/prepaid`,
                        }}
                      >
                        <Button variant="contained" size="small">
                          Nuevo Prepago
                        </Button>
                      </Link>
                    )}
                  </div>
                </div>
              ),
            }}
          />
        </Paper>
      </div>
    );
  }
}
export default withStyles(styles)(Invoices);
