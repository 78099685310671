import React, { Component } from 'react';
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import Line from './Line';
import './style.scss';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { Button, Snackbar, Select, DatePicker } from '@beetrack/hp-components';
import { get, put } from '../../../../utils/api';
import NumberFormat from 'react-number-format';

const QBCL = process.env.QB_REALM_CL;
const QBMX = process.env.QB_REALM_MX;
const QBCO = process.env.QB_REALM_CO;
const TAX = JSON.parse(process.env.QB_TAX);

class DraftLines extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: false,
      edit: false,
      lines: this.props.lines,
      neto: this.props.lines.reduce(
        (sum, { Amount }) => sum + parseFloat(Amount),
        0,
      ),
      snackbarState: false,
      snackbarStatus: '',
      snackbarMessage: '',
      productItems: null,
    };
  }

  getProductItems = () => {
    if (this.state.productItems === null) {
      let quickbooksId = this.props.draft.table.CustomerRef.value;
      let clientId = `${this.props.realmId}-${quickbooksId}`;
      let items = [];
      get('/invoices/services/' + clientId).then((response) => {
        if (response.status == 200) {
          response.data.services.map((item) => {
            items.push({
              value: item.Id,
              label: item.Name,
            });
          });
          this.setState({ productItems: items });
        } else {
          return 'Bad Request';
        }
      });
    }
  };

  renderLines = () => {
    const lines = this.state.lines;
    let domLines = lines.map((line, index) => {
      return <Line key={index} line={line} />;
    });
    return domLines;
  };

  handleEditClick = () => {
    this.setState({ edit: !this.state.edit });
  };

  renderEdit = () => {
    let lines = this.state.lines;
    let domLines = lines.map((line, index) => {
      return this.renderEditLine(line, index);
    });
    return domLines;
  };

  verifyDescription = (event) => {
    const result = event.target.value.match(/\(([^)]+)\)/);
    if (result) {
      if (
        result[1].split(' ').length == 2 &&
        Number.isInteger(parseInt(result[1].split(' ')[0])) &&
        result[1].split(' ')[1] === 'usuarios'
      ) {
        this.setState({ errorMessage: false });
      } else {
        this.setState({ errorMessage: true });
      }
    } else {
      this.setState({ errorMessage: true });
    }
  };

  handleAddLine = () => {
    let lines = this.state.lines;
    let newLine = {
      Amount: '',
      DetailType: 'SalesItemLineDetail',
      Description: '',
      SalesItemLineDetail: {
        Qty: '',
        ItemRef: {
          name: '',
          value: '1',
        },
        UnitPrice: '',
      },
    };
    lines = [...lines, newLine];
    this.setState({ lines: lines });
  };

  handleRemoveLine = (e) => {
    let lines = this.state.lines;
    let lineObject = lines[e.currentTarget.id];
    let filteredLines = lines.filter((item) => item !== lineObject);
    this.setState({ lines: filteredLines });
    this.setNeto(filteredLines);
  };

  getAmount = (line) => {
    let qty = line.SalesItemLineDetail.Qty;
    let unitPrice = line.SalesItemLineDetail.UnitPrice;
    if (qty === '' || unitPrice === '') {
      return '';
    } else {
      return (
        Math.round(
          line.SalesItemLineDetail.Qty *
            line.SalesItemLineDetail.UnitPrice *
            1000,
        ) / 1000
      );
    }
  };

  changeField = (e, field, index) => {
    var lines = this.state.lines;
    if (field === 'Description') {
      lines[index][field] = e.target.value;
    } else if (field === 'ServiceDate') {
      lines[index].SalesItemLineDetail[field] = e;
      lines[index].Amount = this.getAmount(lines[index]);
    } else if (field === 'UnitPrice') {
      lines[index].SalesItemLineDetail[field] = e.floatValue;
      lines[index].Amount = this.getAmount(lines[index]);
    } else {
      lines[index].SalesItemLineDetail[field] = e.target.value;
      lines[index].Amount = this.getAmount(lines[index]);
    }
    this.setState({ lines: lines });
    this.setNeto(lines);
  };

  checkDescription = (description) => {
    const result = description.match(/\(([^)]+)\)/);
    if (result) {
      if (
        result[1].split(' ').length == 2 &&
        Number.isInteger(parseInt(result[1].split(' ')[0])) &&
        result[1].split(' ')[1] === 'usuarios'
      ) {
        return true;
      }
    }
    return false;
  };
  setNeto = (lines) => {
    let calcNeto = 0;
    lines.map((line) => {
      calcNeto += this.getAmount(line);
    });
    this.setState({ neto: calcNeto });
  };

  handleSave = () => {
    let editedDraft = this.props.draft.table;
    editedDraft.Line = this.state.lines;
    let data = {
      id: this.props.draftId,
      resolution: 'pending',
      invoice_draft: { table: editedDraft },
    };
    put('/drafts/' + this.props.draftId, data).then((response) => {
      if (response.status === 204) {
        this.setState({
          snackbarState: true,
          snackbarStatus: 'success',
          snackbarMessage: 'Borrador editado con éxito',
          edit: false,
        });
      } else {
        this.setState({
          snackbarState: true,
          snackbarStatus: 'error',
          snackbarMessage: 'No se pudo editar el borrador',
          edit: false,
        });
      }
    });
  };

  setDismissSnackbar = () => {
    this.setState({ snackbarState: false });
  };

  getLineItem = (line) => {
    let lineValue = line.SalesItemLineDetail.ItemRef.value;
    if (this.state.productItems !== null && lineValue !== null) {
      const found = this.state.productItems.find(
        (item) => item.value == lineValue,
      );
      return found.value;
    } else {
      return null;
    }
  };

  taxCode(realm_id, currency) {
    if (realm_id == QBCL) {
      if (currency == 'CLP') {
        return TAX['CL'];
      } else {
        return {
          rate: {
            TotalTax: 0,
          },
        };
      }
    } else if (realm_id == QBMX) {
      return TAX['MX'];
    } else if (realm_id == QBCO) {
      return TAX['CO'];
    }
  }

  isTaxFree(currency) {
    return !['MXN', 'CLP', 'COP'].includes(currency);
  }

  setSelectedItem = (selected, index) => {
    let lines = this.state.lines;
    let currencyRef = this.props.draft.table.CurrencyRef.value;
    let item = this.state.productItems.find((item) => item.value == selected);
    lines[index].SalesItemLineDetail.ItemRef.value = selected;
    lines[index].SalesItemLineDetail.ItemRef.name = item.label;
    if (!this.isTaxFree(currencyRef)) {
      lines[index]['SalesItemLineDetail']['TaxCodeRef'] = this.taxCode(
        this.props.realmId,
        currencyRef,
      )['code'];
    }
    this.setState({ lines: lines });
  };

  renderEditLine = (line, index) => {
    this.getProductItems();
    return (
      <TableRow>
        <TableCell>
          <div className={'item-select'}>
            <Select
              key="simple-select-1"
              options={this.state.productItems}
              label="Producto"
              value={this.getLineItem(line)}
              onChange={(option) => {
                this.setSelectedItem(option.value, index);
              }}
            />
          </div>
        </TableCell>
        <TableCell>
          <div className="description-box">
            <TextField
              id={index}
              error={this.state.errorMessage}
              value={this.state.lines[index].Description}
              onChange={(e) => this.changeField(e, 'Description', index)}
              onBlur={(e) => this.verifyDescription(e)}
            />
          </div>
        </TableCell>
        <TableCell>
          <div className="service-date-box">
            <DatePicker
              id={index}
              date={this.state.lines[index].SalesItemLineDetail.ServiceDate}
              onChange={(e) => this.changeField(e, 'ServiceDate', index)}
              format="YYYY/MM/DD"
            />
          </div>
        </TableCell>
        <TableCell>
          <div className="quantity-box">
            <TextField
              id={index}
              value={this.state.lines[index].SalesItemLineDetail.Qty}
              onChange={(e) => this.changeField(e, 'Qty', index)}
              type="number"
            />
          </div>
        </TableCell>
        <TableCell>
          <div className="unit-price-box">
            <NumberFormat
              value={this.state.lines[index].SalesItemLineDetail.UnitPrice}
              customInput={TextField}
              prefix={'$ '}
              type="text"
              thousandSeparator={true}
              onValueChange={(e) => this.changeField(e, 'UnitPrice', index)}
            />
          </div>
        </TableCell>
        <TableCell>
          <div className="total">
            <NumberFormat
              disabled
              value={this.getAmount(line)}
              customInput={TextField}
              prefix={'$ '}
              type="text"
              thousandSeparator={true}
            />
          </div>
        </TableCell>
        <IconButton
          className={'remove-icon'}
          id={index}
          onClick={(e) => this.handleRemoveLine(e)}
        >
          <DeleteIcon />
        </IconButton>
      </TableRow>
    );
  };

  renderInvoiceInformation = () => {
    let txnDate = this.props.txnDate;
    return (
      <div className={'row'}>
        <div className={'column'}>
          <h4>
            <b className='hp-typography__subtitle--bold' style={{ color: '#132045' }}> Fecha de Factura: {txnDate} </b>
          </h4>
        </div>
        <div className={'column'}>
        {  this.props.tableType === 'approved' ?
          (<></>
          ) : (
          <div className={'actions-container'}>
            {this.state.edit ? (
                <IconButton onClick={this.handleEditClick}>
                  <CloseIcon />
                </IconButton>
              ) : (
                <IconButton onClick={this.handleEditClick}>
                  <EditIcon />
                </IconButton>
              )}
          </div>
        )}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="table-container">
        <Snackbar
          key="snackbar"
          isOpen={this.state.snackbarState}
          message={this.state.snackbarMessage}
          onDismiss={() => this.setDismissSnackbar(false)}
          status={this.state.snackbarStatus}
        />
        <div>{this.renderInvoiceInformation()}</div>
        {this.state.edit ? (
          <div>
            <TableHead>
              <TableRow>
                <TableCell>Servicio</TableCell>
                <TableCell>Descripción</TableCell>
                <TableCell>Service Date</TableCell>
                <TableCell align="right">Cantidad</TableCell>
                <TableCell align="right">Precio</TableCell>
                <TableCell align="right">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.renderEdit()}
              <TableRow>
                <Button type="tertiary" onClick={this.handleAddLine}>
                  Añadir linea
                </Button>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4} />
                <TableCell align="right">
                  <b>Neto:</b>
                </TableCell>
                <TableCell align="right">{this.state.neto}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={5} />
                <TableCell align="right">
                  <Button onClick={this.handleSave}>Guardar</Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </div>
        ) : (
          <div>
            <TableHead>
              <TableRow>
                <TableCell><strong>Servicio</strong></TableCell>
                <TableCell><strong>Descripción</strong></TableCell>
                <TableCell><strong>Service Date</strong></TableCell>
                <TableCell align="right"><strong>Cantidad</strong></TableCell>
                <TableCell align="right"><strong>Precio</strong></TableCell>
                <TableCell align="right"><strong>Total</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.renderLines()}
              <TableRow>
                <TableCell colSpan={4} />
                <TableCell align="right">
                  <b>Neto:</b>
                </TableCell>
                <TableCell align="right">{this.state.neto}</TableCell>
              </TableRow>
            </TableBody>
          </div>
        )}
      </div>
    );
  }
}

export default DraftLines;
