import React, { useState } from 'react';
import {
  FormRow,
  FormGroup,
  TextField,
  DatePicker,
  Select,
  Button,
  Snackbar,
} from '@beetrack/hp-components';
import { calculateMoneyEquivalent } from '../../../Calculator';
import { deltaMonths } from '../../../../../utils/commercialConditions/activePrepaid';
import '../../../styles.scss';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import {
  newDateFromDelta,
  calculateDiscountFromDeltaMonths,
} from '../../../../CommercialConditions/Calculator/index';
import { post } from '../../../../../utils/api';
import { GenerateDiscount } from './discount';

const MONTHS_PREPAID = [6, 12];

const manageStartDate = (startDate) => {
  // if startDate is after the 15th of the month, it will be fixed
  // as the 1st of the next month, else it will be fixed
  // as the 1st of the current month
  const [day, month, year] = startDate.split('-');
  const formatStartDate = [year, month, day].join('/');
  const startOfMonth = moment(formatStartDate).startOf('month');
  if (
    moment(formatStartDate).diff(startOfMonth.format('YYYY/MM/DD'), 'days') >=
    15
  ) {
    return moment(moment(startOfMonth).add(1, 'M').calendar()).format(
      'DD-MM-YYYY',
    );
  }
  return startOfMonth.format('DD-MM-YYYY');
};

const metaForm = (
  plan,
  idx,
  setter,
  discountSetter,
  prepaidIndex = 0,
  disabled = false,
  discountIdx = 0,
) => {
  const priceRange = calculateMoneyEquivalent(
    plan.condition,
    plan.prepaids[prepaidIndex].qty,
  );

  return (
    <div>
      <FormRow additionalClassName="grid">
        <DatePicker
          additionalClassName={`grid__item-5 ${
            disabled ? 'custom-disabled' : ''
          }`}
          date={plan.prepaids[prepaidIndex].from}
          onChange={(value) => {
            setter(manageStartDate(value), idx, 'from');
          }}
          helperText="Fecha de inicio"
          disabled={disabled}
        />
        <DatePicker
          additionalClassName="grid__item-5 custom-disabled"
          date={plan.prepaids[prepaidIndex].to}
          helperText="Fecha de término"
          disabled={true}
        />
        <p class="advice">
          <small>*El mes de prepago es siempre de 01 a 30/31.</small>
        </p>
      </FormRow>
      <FormRow additionalClassName="grid">
        <Select
          additionalClassName={`${disabled ? 'custom-disabled' : ''}`}
          key={`prepaid-${idx}-months`}
          value={plan.prepaids[prepaidIndex].delta}
          options={MONTHS_PREPAID.map((delta) => ({
            label: `${delta} meses`,
            value: delta,
          }))}
          onChange={(value) => {
            setter(value.value, idx, 'delta', discountIdx);
          }}
          disabled={disabled}
        />
      </FormRow>
      <FormRow additionalClassName="grid">
        <TextField
          additionalClassName="grid__item-3"
          placeholder="Cantidad"
          label="Cantidad de prepago (unidades)"
          helperText="Indica por cuántas unidades se realizará la factura."
          value={plan.prepaids[prepaidIndex].qty}
          onChange={(e) => setter(e.target.value, idx, 'qty')}
          type="number"
          readOnly={disabled}
          fullWidth
        />
        <NumberFormat
          customInput={TextField}
          prefix={`${plan.currency} `}
          additionalClassName="custom-disabled"
          placeholder="Cantidad equivalente en facturación"
          label={`Monto total a pagar (${plan.prepaids[prepaidIndex].qty} un. x ${plan.prepaids[prepaidIndex].delta} meses x ${priceRange.price} ${plan.currency} / un.)`}
          value={(
            priceRange.price *
            plan.prepaids[prepaidIndex].qty *
            plan.prepaids[prepaidIndex].delta
          ).toFixed(2)}
          type="text"
          allowNegative={false}
          isNumericString={true}
          decimalSeparator={','}
          thousandSeparator={'.'}
          readOnly={true}
          helperText={'Monto total sin descuento'}
        />
      </FormRow>
    </div>
  );
};

export function generatePrepaid(
  plan,
  idx,
  setter,
  discountSetter,
  prepaidIndex = 0,
) {
  if (!plan.prepaid) {
    return <></>;
  }
  // from the plan construction we know that the plan has
  // a single pre paid in a list
  return (
    <FormGroup title={<div>Prepago </div>} collapsible={false}>
      {metaForm(plan, idx, setter, discountSetter, prepaidIndex)}
    </FormGroup>
  );
}

export const GeneratePrepaids = (props) => {
  const hubspotId = props.hubspotId;
  const [renewAvailable, setRenewAvailable] = useState(props.renewal);
  const [renewButtonState, setRenewButtonState] = useState(false);
  const [plan, setPlan] = useState(props.plan);
  const [added, setAdded] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [renwedState, setRenwedState] = useState(false);
  const [snackBar, setSnackBar] = useState({
    open: false,
    status: 'alert',
    message: '',
  });

  if (plan.prepaids.length === 0) {
    return <></>;
  }

  const [lastPrepaidIdx, setLastPrepaidIdx] = useState(
    plan.prepaids.length - 1,
  );
  const [lastDiscountIdx, setLastDiscountIdx] = useState(
    plan.discounts.length - 1,
  );

  if (!added) {
    const newPlan = { ...plan };
    newPlan.prepaids.push({
      from: moment(plan.prepaids[plan.prepaids.length - 1].to, 'YYYY-MM-DD')
        .add(1, 'day')
        .format('DD-MM-YYYY'),
      to: moment(plan.prepaids[plan.prepaids.length - 1].to, 'YYYY-MM-DD')
        .add(1, 'day')
        .add(6, 'months')
        .format('DD-MM-YYYY'),
      delta: 6,
      qty: plan.prepaids[plan.prepaids.length - 1].qty,
    });

    newPlan.discounts.push({
      percentage: 10,
    });

    setAdded(true);
    setPlan({ ...newPlan });
  }

  const _setPlanPrepaidNew = (buttonState) => {
    const newPlan = { ...plan };
    newPlan.prepaid = buttonState;
    setRenewButtonState(buttonState);
    setPlan({ ...newPlan });
  };

  const _parsePlanToConst = (plan, indexLimit) => {
    const parsedPlan = JSON.parse(JSON.stringify(plan));

    for (let index = 0; index <= indexLimit; index++) {
      parsedPlan.prepaids[index] = {
        from: moment(parsedPlan.prepaids[index].from, 'YYYY-MM-DD').format(
          'DD-MM-YYYY',
        ),
        to: moment(parsedPlan.prepaids[index].to, 'YYYY-MM-DD').format(
          'DD-MM-YYYY',
        ),
        qty: parsedPlan.prepaids[index].qty,
        delta: deltaMonths(
          parsedPlan.prepaids[index].from,
          parsedPlan.prepaids[index].to,
        ),
      };
    }
    return parsedPlan;
  };

  const setNewPrePaid = (newValue, index, param, discountIdx) => {
    const newData = { ...plan };

    if (param === 'from') {
      newData.prepaids[index]['from'] = newValue;
      newData.prepaids[index]['to'] = newDateFromDelta(
        newValue,
        newData.prepaids[index]['delta'],
      );
    } else if (param === 'delta') {
      newData.prepaids[index]['delta'] = newValue;
      newData.prepaids[index]['to'] = newDateFromDelta(
        newData.prepaids[index]['from'],
        newValue,
      );
      newData.discounts[discountIdx].percentage =
        calculateDiscountFromDeltaMonths(newData.prepaids[index]['delta']);
    } else if (param === 'qty') {
      newData.prepaids[index]['qty'] = Math.max(parseInt(newValue), 1);
    }

    setPlan({ ...newData });
  };

  const _setFinishedState = (plan) => {
    setSnackBar({
      open: true,
      status: 'success',
      message: `Prepago renovado por ${
        plan.prepaids[lastPrepaidIdx + 1].qty
      } unidades hasta el ${plan.prepaids[lastPrepaidIdx + 1].to}`,
    });
    setRenwedState(true);
  };

  const createPrepaid = () => {
    setWaiting(true);
    const payloadPrepaid = {
      plan_id: plan.id,
      from: plan.prepaids[lastPrepaidIdx + 1].from,
      to: plan.prepaids[lastPrepaidIdx + 1].to,
      qty: plan.prepaids[lastPrepaidIdx + 1].qty,
    };

    post(`/commercial_conditions/prepaids`, payloadPrepaid).then((response) => {
      if (response.status === 200) {
        if (plan.discount) {
          const discountPayload = {
            plan_id: plan.id,
            percentage: plan.discounts[lastDiscountIdx + 1].percentage,
          };
          post(`/commercial_conditions/discounts`, discountPayload).then(
            (response) => {
              if (response.status === 200) {
                triggerInvoice(plan.prepaids[lastPrepaidIdx + 1].delta);
                _setFinishedState(plan);
              } else {
                setSnackBar({
                  open: true,
                  status: 'error',
                  message: response.data.errors[0].message,
                });
              }
            },
          );
        } else {
          triggerInvoice(plan.prepaids[lastPrepaidIdx + 1].delta);
          _setFinishedState(plan);
        }
      } else {
        setSnackBar({
          open: true,
          status: 'error',
          message: response.data.errors[0].message,
        });
      }
    });

    setWaiting(false);
  };

  const triggerInvoice = (months) => {
    post('/invoices', {
      invoicing: {
        hubspot_id: hubspotId,
        prepaid: true,
        prepaid_months: months,
      },
    });
  };

  const _generateRenewButtonHeader = () => {
    return (
      <div>
        Prepago{' '}
        {renewButtonState || !renewAvailable ? (
          <></>
        ) : (
          <Button
            type="text"
            onClick={(e) => _setPlanPrepaidNew(!renewButtonState)}
            flat
          >
            Renovar prepago
          </Button>
        )}
      </div>
    );
  };

  const _generateRenewButtonCreate = () => {
    if (!renewAvailable || renwedState) {
      return <></>;
    }
    return (
      <>
        <br />
        <div style={{ textAlign: 'right' }}>
          <Button
            type="tertiary"
            flat
            onClick={(e) => _setPlanPrepaidNew(!renewButtonState)}
          >
            Cancelar
          </Button>{' '}
          <Button
            disabled={renwedState}
            isLoading={waiting}
            flat
            onClick={(e) => createPrepaid()}
          >
            Renovar prepago
          </Button>
        </div>
      </>
    );
  };

  return (
    <FormGroup title={_generateRenewButtonHeader()} collapsible={false}>
      {renewButtonState && renewAvailable ? (
        <>
          {metaForm(
            plan,
            lastPrepaidIdx + 1,
            setNewPrePaid,
            null,
            lastPrepaidIdx + 1,
            renwedState,
            lastDiscountIdx + 1,
          )}
          {!renwedState ? (
            <GenerateDiscount
              plan={plan}
              setPlan={setPlan}
              prepaidIndex={lastPrepaidIdx + 1}
              discountIdx={lastDiscountIdx + 1}
            />
          ) : (
            <></>
          )}
          {_generateRenewButtonCreate()}
        </>
      ) : (
        metaForm(
          _parsePlanToConst(plan, lastPrepaidIdx),
          lastPrepaidIdx,
          null,
          null,
          lastPrepaidIdx,
          true,
          lastDiscountIdx,
        )
      )}
      <Snackbar
        key="snackbar"
        isOpen={snackBar.open}
        message={snackBar.message}
        onDismiss={() =>
          setSnackBar({
            open: !snackBar.status,
            status: 'alert',
            message: '',
          })
        }
        status={snackBar.status}
      />
    </FormGroup>
  );
};
