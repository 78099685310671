import React from 'react';

import { Grid } from '@material-ui/core';
import {
  TextField,
  ActionsList,
  Switch,
  IconButton,
} from '@beetrack/hp-components';
import { Delete, FileCopy } from '@beetrack/hp-icons-react';
import Collapse from '@mui/material/Collapse';
import { getNestedValue } from '../utils';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { useStyles } from '../Styles/styles';

const DateElement = (props) => {
  const {
    components,
    path,
    elementID,
    indexElement,
    type,
    textChangeHandler,
    switchHandler,
    deleteElement,
    collapsed,
    setCollapsed,
    draggableProvided,
    handleClone,
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.elementContainer}>
      <div
        className={classes.introElement}
        {...(draggableProvided && draggableProvided.dragHandleProps)}
      >
        <p className={classes.subTitle}>
          {getNestedValue(components, [...path, 'question'])
            ? getNestedValue(components, [...path, 'question'])
            : `Elemento: ${indexElement}`}
          {` (${type})`}
          <a className={classes.red}>
            {getNestedValue(components, [...path, 'required']) ? '*' : ''}
          </a>
        </p>
        <ActionsList
          actions={[
            {
              title: 'Eliminar Elemento',
              onClick: () => deleteElement(path),
              icon: <Delete />,
            },
            {
              title: 'Clonar pregunta',
              onClick: () => handleClone(path),
              icon: <FileCopy />,
            },
          ]}
          launcherType="tertiary"
        />
        <p className={classes.collapsable}>
          <a
            onClick={() =>
              setCollapsed({
                ...collapsed,
                [elementID]: !collapsed[elementID],
              })
            }
          >
            {!collapsed[elementID] ? 'Ver más +' : 'Ver menos'}
          </a>
        </p>
      </div>
      <div className={classes.container}>
        <Collapse in={collapsed[elementID]} timeout="auto" unmountOnExit>
          <div className={classes.horizontal}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Pregunta"
                name={`question`}
                key={`text-field-${elementID}-1`}
                placeholder="¿Te gustan nuestros productos?"
                value={getNestedValue(components, [...path, 'question'])}
                onChange={(e) => textChangeHandler(e, path)}
                helperText="Título o pregunta que quieres que aparezca SOBRE la casilla"
                required
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.horizontal}>
              <TextField
                label="Valor interno property en HubSpot"
                name={`propertyName`}
                key={`text-field-${elementID}-3`}
                placeholder="HS-property-name"
                value={getNestedValue(components, [...path, 'propertyName'])}
                onChange={(e) => textChangeHandler(e, path)}
                helperText="Ingresa el valor interno de la propiedad que se va a rellenar"
                required
              />
              <IconButton
                icon={<ContentCopyIcon />}
                size="large"
                onClick={() =>
                  navigator.clipboard.writeText(
                    `${getNestedValue(components, [...path, 'propertyName'])}`,
                  )
                }
              />
            </Grid>
          </div>
          <div className={classes.horizontal}>
            <Grid item xs={12} md={6}>
              <TextField
                label="URL de imagen de apoyo"
                name={`imgUrl`}
                key={`text-field-${elementID}-5`}
                value={getNestedValue(components, [...path, 'imgUrl'])}
                onChange={(e) => textChangeHandler(e, path)}
                helperText="Si tu pregunta usa una imagen de apoyo ingresa la url"
              />
            </Grid>
            <Grid className={classes.vertical} item xs={12} md={2}>
              <a className={classes.requiredSwitch}>
                {' '}
                ¿Es un campo obligatorio?
              </a>
              <Switch
                rightLabel="Sí"
                leftLabel="No"
                onChange={(e) => switchHandler(e, [...path, 'required'])}
                checked={getNestedValue(components, [...path, 'required'])}
              />
            </Grid>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default DateElement;
