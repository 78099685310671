import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { styles } from './styles';
import { destroy, get, post, put } from '../../utils/api';
import { IsAdmin, serializerUnpacker } from '../../utils/utils';
import MaterialTable from 'material-table';
import AddIcon from '@material-ui/icons/Add';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  FormControl,
  InputLabel,
  Paper,
  TextField,
} from '@material-ui/core';
import { Select } from '@beetrack/hp-components';

class UserManagement extends Component {
  constructor(props) {
    super(props);

    this.loadUsers = this.loadUsers.bind();
    this.setAction = this.setAction.bind();
    this.deleteUser = this.deleteUser.bind();

    this.state = {
      actions: [],
      columns: [
        {
          title: 'Nombre',
          field: 'name',
        },
        {
          title: 'Correo',
          field: 'email',
        },
      ],
      contentDialog: '',
      dialog: false,
      email: '',
      name: '',
      scopesByUser: {},
      titleDialog: '',
      users: [],
      userScopes: [],
      selectedScopes: [],
    };
  }

  componentDidMount() {
    this.props.loadState(true);
    this.loadUsers();
    this.setAction();
  }

  deleteUser = (userId) => {
    if (userId !== undefined) {
      destroy(`/users/${userId}`).then((response) => {
        if (response.status === 202) {
          this.removeUserFromList(userId);
        }
      });
    } else {
      alert('Ha ocurrido un error:\nRecarga la página y vuelve a intentar.');
    }
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  reverseMapRoleToValue = (names, roles) => {
    return names.map(
      (roleName) => roles.find((o) => o.label === roleName).value,
    );
  };

  handleChangeScopes = (event) => {
    this.setState({ selectedScopes: (event || []).map((x) => x.value) });
  };

  findAndReplaceUser = (newUser) => {
    const arrIdx = this.state.users.findIndex((o) => o.id === newUser.id);
    this.state.users[arrIdx] = newUser;
    this.setState({
      users: this.state.users,
    });
  };

  removeUserFromList = (userId) => {
    this.setState({
      users: this.state.users.filter((o) => o.id !== userId),
    });
  };

  handleDialogUser = (action) => {
    let { name, email, selectedScopes } = this.state;
    let user = { name, email, scopes: selectedScopes };
    if (action === 'Nuevo') {
      post('/users', user).then((response) => {
        this.setState({
          name: '',
          email: '',
          selectedScopes: [],
          dialog: false,
        });
        this.loadUsers();
      });
    } else {
      put('/users', user).then((response) => {
        const data = response.data.users.data;
        this.findAndReplaceUser(serializerUnpacker(data));
        this.setState({
          name: '',
          email: '',
          selectedScopes: [],
          dialog: false,
        });
        this.loadUsers();
      });
    }
  };

  handleDialog = () => {
    let { dialog } = this.state;
    dialog = !dialog;
    this.setState({
      dialog: dialog,
      titleDialog: 'Nuevo',
      contentDialog: 'Agrega el nombre, correo y permisos del nuevo usuario.',
    });
    if (!dialog) {
      this.setState({ name: '', email: '', selectedScopes: [] });
    }
  };

  loadUsers = () => {
    get('/users').then((response) => {
      if (response.status === 200) {
        let data = response.data;
        const userScopes = serializerUnpacker(data.scopes.data).map(
          (scope) => ({
            value: scope.id,
            label: scope.name,
          }),
        );
        this.setState({
          users: serializerUnpacker(data.users.data),
          userScopes: userScopes,
          scopesByUser: data.scopes_by_user,
        });
      }
      this.props.loadState(false);
    });
  };

  setAction = () => {
    if (IsAdmin()) {
      let actions = [
        {
          icon: 'edit',
          tooltip: 'Editar Usuario',
          onClick: (event, rowData) => {
            let { dialog } = this.state;
            this.setState({
              dialog: !dialog,
              name: rowData.name,
              email: rowData.email,
              selectedScopes: this.reverseMapRoleToValue(
                rowData.roles_names,
                this.state.userScopes,
              ),
              titleDialog: 'Editar',
              contentDialog: 'Edita el nombre y permisos del usuario.',
            });
          },
        },
        (rowData) => ({
          icon: 'delete',
          tooltip: 'Eliminar Usuario',
          onClick: (event, rowData) => {
            if (confirm(`¿Desea eliminar a ${rowData.name}?`)) {
              this.deleteUser(rowData.id);
            }
          },
        }),
      ];
      this.setState({ actions: actions });
    }
  };

  render() {
    let {
      actions,
      columns,
      contentDialog,
      email,
      name,
      dialog,
      selectedScopes,
      titleDialog,
      users,
      userScopes,
    } = this.state;
    let { classes } = this.props;

    return (
      <Paper>
        <div>
          <MaterialTable
            actions={actions}
            columns={columns}
            data={users}
            title="Administración de Usuarios"
            options={{
              actionsColumnIndex: -1,
              maxBodyHeigth: '100%',
              paging: true,
              search: true,
            }}
          />
        </div>
        <Fab
          color="primary"
          aria-label="Nuevo"
          className={classes.buttonNewUser}
          onClick={this.handleDialog}
        >
          <AddIcon />
        </Fab>
        <Dialog
          open={dialog}
          onClose={this.handleDialog}
          aria-labelledby="form-dialog-new-user"
        >
          <DialogTitle id="form-dialog-title">
            {titleDialog} Usuario
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{contentDialog}</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Nombre de Usuario"
              type="text"
              fullWidth
              value={name}
              onChange={this.handleChange('name')}
            />
            <TextField
              margin="dense"
              id="email"
              label="Correo electrónico"
              type="email"
              fullWidth
              value={email}
              onChange={this.handleChange('email')}
              disabled={titleDialog === 'Editar'}
            />
            <FormControl fullWidth>
              <InputLabel htmlFor="select-scopes">Permisos</InputLabel>
              <Select
                isMulti
                options={userScopes}
                key="key"
                label="Roles"
                value={selectedScopes}
                onChange={(values) => {
                  this.handleChangeScopes(values);
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialog} color="primary">
              Cerrar
            </Button>
            <Button
              onClick={() => this.handleDialogUser(titleDialog)}
              color="primary"
            >
              Guardar
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    );
  }
}

export default withStyles(styles)(UserManagement);
