import React from 'react';

import { Grid } from '@material-ui/core';
import {
  TextField,
  ActionsList,
  IconButton,
  Switch,
} from '@beetrack/hp-components';
import { Delete, Add, FileCopy } from '@beetrack/hp-icons-react';
import Collapse from '@mui/material/Collapse';
import { getNestedValue, sortKeys } from '../utils';

import TextElement from './TextElement.js';
import SimpleElement from './SimpleElement';
import DateElement from './DateElement';
import FileElement from './FileElement';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { useStyles } from '../Styles/styles';

const DependantElement = (props) => {
  const {
    components,
    path,
    elementID,
    indexElement,
    type,
    textChangeHandler,
    switchHandler,
    simpleChangeHandler,
    addSimpleOption,
    addSpecialOption,
    deleteOption,
    deleteElement,
    collapsed,
    setCollapsed,
    newElement,
    draggableProvided,
    handleClone,
    fileTypeChangeHandler,
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.elementContainer}>
      <div
        className={classes.introElement}
        {...(draggableProvided && draggableProvided.dragHandleProps)}
      >
        <p className={classes.subTitle}>
          {getNestedValue(components, [...path, 'question'])
            ? getNestedValue(components, [...path, 'question'])
            : `Elemento: ${indexElement}`}
          {` (seleccion ${type})`}
          <a className={classes.red}>
            {getNestedValue(components, [...path, 'required']) ? '*' : ''}
          </a>
        </p>
        <ActionsList
          actions={[
            {
              title: 'Eliminar Elemento',
              onClick: () => deleteElement(path),
              icon: <Delete />,
            },
            {
              title: 'Clonar pregunta',
              onClick: () => handleClone(path),
              icon: <FileCopy />,
            },
          ]}
          launcherType="tertiary"
        />
        <p className={classes.collapsable}>
          <a
            onClick={() =>
              setCollapsed({
                ...collapsed,
                [elementID]: !collapsed[elementID],
              })
            }
          >
            {!collapsed[elementID] ? 'Ver más +' : 'Ver menos'}
          </a>
        </p>
      </div>
      <div className={classes.container}>
        <Collapse in={collapsed[elementID]} timeout="auto" unmountOnExit>
          <div className={classes.horizontal}>
            <Grid item xs={12} md={4}>
              <TextField
                label="Pregunta"
                name={`question`}
                key={`text-field-${elementID}-1`}
                placeholder="Pregunta"
                value={getNestedValue(components, [...path, 'question'])}
                onChange={(e) => textChangeHandler(e, path)}
                helperText="Título o pregunta que quieres que aparezca SOBRE la casilla"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Título"
                name={`title`}
                key={`text-field-${elementID}-2`}
                placeholder="Título"
                value={getNestedValue(components, [...path, 'title'])}
                onChange={(e) => textChangeHandler(e, path)}
                helperText="Título o pregunta que quieres que aparezca EN la casilla"
              />
            </Grid>
            <Grid className={classes.vertical} item xs={12} md={2}>
              <a className={classes.requiredSwitch}>
                {' '}
                ¿Es un campo obligatorio?
              </a>
              <Switch
                rightLabel="Sí"
                leftLabel="No"
                onChange={(e) => switchHandler(e, [...path, 'required'])}
                checked={getNestedValue(components, [...path, 'required'])}
              />
            </Grid>
          </div>
          <div className={classes.horizontal}>
            <Grid item xs={12} md={4} className={classes.horizontal}>
              <TextField
                label="Valor interno property en HubSpot"
                name={`propertyName`}
                key={`text-field-${elementID}-3`}
                placeholder="HS-property-name"
                value={getNestedValue(components, [...path, 'propertyName'])}
                onChange={(e) => textChangeHandler(e, path)}
                helperText="Ingresa el valor interno de la propiedad que se va a rellenar"
                required
              />
              <IconButton
                icon={<ContentCopyIcon />}
                size="large"
                onClick={() =>
                  navigator.clipboard.writeText(
                    `${getNestedValue(components, [...path, 'propertyName'])}`,
                  )
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Texto que quieres que quede de ayuda"
                name={`helperText`}
                key={`text-field-${elementID}-4`}
                value={getNestedValue(components, [...path, 'helperText'])}
                onChange={(e) => textChangeHandler(e, path)}
                helperText="Texto que iría acá"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="URL de imagen de apoyo"
                name={`imgUrl`}
                key={`text-field-${elementID}-5`}
                value={getNestedValue(components, [...path, 'imgUrl'])}
                onChange={(e) => textChangeHandler(e, path)}
                helperText="Si tu pregunta usa una imagen de apoyo ingresa la url"
              />
            </Grid>
          </div>
          {sortKeys(getNestedValue(components, path).options).map(
            (option, indexOption) => {
              return (
                <div className={classes.optionContainer}>
                  <div className={classes.optionTitle}>
                    {`Option: ${indexOption + 1}`}
                    <IconButton
                      onClick={() => deleteOption([...path, 'options'], option)}
                      icon={<Delete />}
                    />
                    <ActionsList
                      actions={[
                        {
                          title: 'Agregar elemento texto (corto)',
                          onClick: () =>
                            newElement(
                              [...path, 'options', option, 'set'],
                              'text',
                            ),
                        },
                        {
                          title: 'Agregar elemento texto (parrafo)',
                          onClick: () =>
                            newElement(
                              [...path, 'options', option, 'set'],
                              'bigText',
                            ),
                        },
                        {
                          title: 'Agregar elemento numerico',
                          onClick: () =>
                            newElement(
                              [...path, 'options', option, 'set'],
                              'number',
                            ),
                        },
                        {
                          title: 'Agregar elemento fecha',
                          onClick: () =>
                            newElement(
                              [...path, 'options', option, 'set'],
                              'date',
                            ),
                        },
                        {
                          title: 'Agregar elemento seleccion simple',
                          onClick: () =>
                            newElement(
                              [...path, 'options', option, 'set'],
                              'simple',
                            ),
                        },
                        {
                          title: 'Agregar elemento seleccion multiple',
                          onClick: () =>
                            newElement(
                              [...path, 'options', option, 'set'],
                              'multiple',
                            ),
                        },
                        {
                          title: 'Agregar elemento dependiente',
                          onClick: () =>
                            newElement(
                              [...path, 'options', option, 'set'],
                              'dependant',
                            ),
                        },
                      ]}
                    />
                  </div>
                  <div className={classes.horizontal}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Opcion que se mostrara para el cliente"
                        name={`label`}
                        key={`text-field-${elementID}-5`}
                        value={getNestedValue(components, [
                          ...path,
                          'options',
                          option,
                          'label',
                        ])}
                        onChange={(e) =>
                          simpleChangeHandler(e, [...path, 'options', option])
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Valor interno de hubspot"
                        name={`value`}
                        key={`text-field-${elementID}-6`}
                        value={getNestedValue(components, [
                          ...path,
                          'options',
                          option,
                          'value',
                        ])}
                        onChange={(e) =>
                          simpleChangeHandler(e, [...path, 'options', option])
                        }
                      />
                    </Grid>
                  </div>
                  <div>
                    {sortKeys(
                      getNestedValue(components, [
                        ...path,
                        'options',
                        option,
                        'set',
                      ]),
                    ).map((element, indexElement) => {
                      const auxElement = getNestedValue(components, [
                        ...path,
                        'options',
                        option,
                        'set',
                        element,
                      ]);
                      return (
                        <div key={indexElement} className={classes.component}>
                          {(auxElement.type === 'text' ||
                            auxElement.type === 'number' ||
                            auxElement.type === 'bigText') && (
                            <TextElement
                              components={components}
                              path={[
                                ...path,
                                'options',
                                option,
                                'set',
                                element,
                              ]}
                              elementID={element}
                              indexElement={indexElement + 1}
                              type={auxElement.type}
                              textChangeHandler={textChangeHandler}
                              switchHandler={switchHandler}
                              deleteElement={deleteElement}
                              collapsed={collapsed}
                              setCollapsed={setCollapsed}
                              draggableProvided={draggableProvided}
                              handleClone={handleClone}
                            />
                          )}
                          {(auxElement.type === 'simple' ||
                            auxElement.type === 'multiple') && (
                            <SimpleElement
                              components={components}
                              path={[
                                ...path,
                                'options',
                                option,
                                'set',
                                element,
                              ]}
                              elementID={element}
                              indexElement={indexElement + 1}
                              type={auxElement.type}
                              textChangeHandler={textChangeHandler}
                              simpleChangeHandler={simpleChangeHandler}
                              addSimpleOption={addSimpleOption}
                              switchHandler={switchHandler}
                              deleteOption={deleteOption}
                              deleteElement={deleteElement}
                              collapsed={collapsed}
                              setCollapsed={setCollapsed}
                              draggableProvided={draggableProvided}
                              handleClone={handleClone}
                            />
                          )}
                          {auxElement.type === 'dependant' && (
                            <DependantElement
                              components={components}
                              path={[
                                ...path,
                                'options',
                                option,
                                'set',
                                element,
                              ]}
                              elementID={element}
                              indexElement={indexElement + 1}
                              type={auxElement.type}
                              textChangeHandler={textChangeHandler}
                              simpleChangeHandler={simpleChangeHandler}
                              addSimpleOption={addSimpleOption}
                              addSpecialOption={addSpecialOption}
                              switchHandler={switchHandler}
                              deleteOption={deleteOption}
                              deleteElement={deleteElement}
                              collapsed={collapsed}
                              setCollapsed={setCollapsed}
                              newElement={newElement}
                              draggableProvided={draggableProvided}
                              handleClone={handleClone}
                            />
                          )}
                          {auxElement.type === 'date' && (
                            <DateElement
                              components={components}
                              path={[
                                ...path,
                                'options',
                                option,
                                'set',
                                element,
                              ]}
                              elementID={element}
                              indexElement={indexElement + 1}
                              type={auxElement.type}
                              textChangeHandler={textChangeHandler}
                              switchHandler={switchHandler}
                              deleteElement={deleteElement}
                              collapsed={collapsed}
                              setCollapsed={setCollapsed}
                              draggableProvided={draggableProvided}
                              handleClone={handleClone}
                            />
                          )}
                          {auxElement.type === 'file' && (
                            <FileElement
                              components={components}
                              path={[
                                ...path,
                                'options',
                                option,
                                'set',
                                element,
                              ]}
                              elementID={element}
                              indexElement={indexElement + 1}
                              type={auxElement.type}
                              textChangeHandler={textChangeHandler}
                              switchHandler={switchHandler}
                              deleteElement={deleteElement}
                              collapsed={collapsed}
                              setCollapsed={setCollapsed}
                              draggableProvided={draggableProvided}
                              handleClone={handleClone}
                              fileTypeChangeHandler={fileTypeChangeHandler}
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            },
          )}
          <IconButton
            onClick={() => addSpecialOption([...path, 'options'])}
            icon={<Add />}
          />
        </Collapse>
      </div>
    </div>
  );
};

export default DependantElement;
