import React, { useState, useEffect } from 'react';
import {
  Form,
  FormRow,
  Button,
  InfoBox,
  FormGroup,
  Checkbox,
  TextField,
  TimePicker,
  Modal,
  DatePicker,
} from '@beetrack/hp-components';
import { ImportantCircle } from '@beetrack/hp-icons-react';
import { get } from '../../../../../utils/api';
import moment from 'moment';

const CandidateMail = (props) => {
  const { candidates, handleSendMail, refresh, setRefresh, entryDate } = props;
  const [fixture, setFixture] = useState('');
  const [time, setTime] = useState('9:00');
  const [meeting, setMeeting] = useState('');
  const [open, setOpen] = useState(false);
  const [delayed, setDelayed] = useState(false);
  const [delayedOnboarding, setDelayedOnboarding] = useState(
    moment().format('DD-MM-YYYY'),
  );

  const formSubmit = () => {
    handleSendMail(
      candidates,
      time,
      fixture,
      meeting,
      delayed,
      delayedOnboarding,
    );
  };
  const close = () => {
    setRefresh(refresh + 1);
  };

  const getSchedule = () => {
    get(`/candidate/schedule/${candidates[0].item.id.toString()}`).then(
      (response) => {
        if (response.status === 200) {
          setFixture(response.data.schedule);
        }
      },
    );
  };
  useEffect(() => {
    getSchedule();
  }, []);
  return (
    <div>
      <Modal
        key="confirmation-modal"
        open={open}
        title="¿Enviar Correo?"
        onClose={() => setOpen(!open)}
        confirmButton={
          <Button type="secondary" onClick={() => formSubmit()}>
            Enviar
          </Button>
        }
      >
        {!delayed && (
          <div class="sub-message-spacing">
            <p>
              El próximo {entryDate} es tu primer día en DispatchTrack. Los
              primeros 2 días de Onboarding son 100% remotos. ¡Tenemos todo
              organizado para que puedas conocer todo sobre DispatchTrack y
              nuestro equipo!
            </p>
            <p>
              {' '}
              Tendrás una inducción general DispatchTrack para lo cual te
              mandaremos algunas invitaciones con un link donde podrás
              conectarte. Una vez terminada la inducción general, tu Team Leader
              se pondrá en contacto contigo para transmitirte los objetivos
              generales de tu rol.{' '}
            </p>
            <p>
              {' '}
              No tienes que tener nada especial, solo las ganas de conocer todo
              sobre nuestra cultura DispatchTrack!
            </p>
            <p>
              Nos vemos el {entryDate} a las {time} (hora de Chile) en el
              siguiente link: <a href={meeting}> {meeting} </a>{' '}
            </p>
          </div>
        )}
        {delayed && (
          <div class="sub-message-spacing">
            <p>
              El próximo {entryDate} es tu primer día en DispatchTrack y estamos
              muy contentos de recibirte.
            </p>
            <p>
              {' '}
              Durante los primeros días tendrás un Onboarding del área con tu
              Team Leader, quien te presentará a tu equipo y te dará a conocer
              los objetivos generales de tu rol.{' '}
            </p>
            <p>
              {' '}
              Luego el {delayedOnboarding} a las {time} (hora Chile) tendrás el
              Onboarding general a DispatchTrack en el siguiente link:{' '}
              <a href={meeting} target="_blank">
                {' '}
                {meeting}{' '}
              </a>
            </p>
            <p>
              No tienes que tener nada especial, solo las ganas de conocer todo
              sobre nuestra cultura DispatchTrack!{' '}
            </p>
          </div>
        )}
      </Modal>
      <Form
        title="Correo Bienvenida"
        id="send-mail-form"
        tooltip="Crea el correo de bienvenida para candidatos"
        buttons={
          <>
            <Button type="close" onClick={() => close()}>
              Cancelar
            </Button>{' '}
            <Button onClick={() => setOpen(true)}>Enviar</Button>
          </>
        }
      >
        <FormRow>
          <InfoBox alignment="left" icon={<ImportantCircle />}>
            <p>
              Cuando envíes todos los correos asociados a una misma fecha de
              onboarding, <strong> automáticamente </strong>
              se enviará el correo con los newbees que ingresan y la
              programación a BTL.
            </p>
          </InfoBox>
        </FormRow>
        <FormGroup
          title="Trackers"
          collapsible={false}
          tooltip="A estas personas se le enviara el correo de bienvenida y se programarán en el mismo onboarding"
        >
          {candidates.map((candidate) => {
            return (
              <FormRow>
                <Checkbox
                  label={candidate.item.name}
                  checked
                  disabled
                  name="checkbox-candidate"
                />
              </FormRow>
            );
          })}
        </FormGroup>
        <FormGroup
          title="¿Onboarding diferido?"
          collapsible={false}
          tooltip="Esto genera un correo avisando que su onboarding no es el mismo día de ingreso"
        >
          <FormRow>
            <Checkbox
              label="¿Diferido?"
              checked={delayed}
              onChange={(e) => setDelayed(!delayed)}
              name="checkbox-delayed"
            />
          </FormRow>
          <FormRow>
            <>
              {delayed && (
                <DatePicker
                  label="Fecha de Onboarding"
                  date={delayedOnboarding}
                  onChange={(value) => setDelayedOnboarding(value)}
                />
              )}
            </>
          </FormRow>
        </FormGroup>
        <FormGroup
          title="Hora Onboarding"
          collapsible={false}
          tooltip="Esta es la hora en la que ingresa el grupo onboarding"
        >
          <TimePicker
            time={time}
            onChange={(value) => {
              setTime(value);
            }}
          />
        </FormGroup>
        <FormGroup
          title="Link reunión"
          collapsible={false}
          tooltip="Link de la reunión en Meet o Zoom"
        >
          <TextField
            label="Link"
            onChange={(e) => setMeeting(e.target.value)}
            value={meeting}
          />
        </FormGroup>
        <FormGroup
          title="Programación"
          collapsible={false}
          tooltip="Programación del onboarding para el grupo"
        >
          <FormRow>
            <TextField
              label="Ingresa la programación del onboarding para BTL"
              textArea
              onChange={(e) => setFixture(e.target.value)}
              type="text"
              value={fixture}
            />
          </FormRow>
        </FormGroup>
      </Form>
    </div>
  );
};

export default CandidateMail;
