import React from 'react';
import { Table } from '@beetrack/hp-components';
import { CheckCircle, CloseCircle } from '@beetrack/hp-icons-react';
import { havePermissions } from '../../utils/utils';
import { patch } from '../../utils/api';

const BDRPointsTable = (props) => {
  // [
  //   {
  //     id: '104',
  //     type: 'prospecting_point',
  //     attributes: {
  //       hubspot_id: 9488018457,
  //       bdr_id: 142388084,
  //       prospected_amount: 780,
  //       score: 0.5,
  //       registered_at: '2022-07-14T16:16:30.104Z',
  //       closed_at: '2022-07-25T19:49:14.694Z',
  //       pays: true,
  //       status: 'approved',
  //       hubspot_payload: {
  //         dealname: 'Transporte Ossa',
  //         dealtype: 'Inbound',
  //         prospectador: '142388084',
  //         flujo_cliente: 'B',
  //         potential_amount:
  //           '1769.64462000000015251544027705676853656768798828125',
  //         deal_currency_code: 'CLF',
  //         numero_de_conductores: '45',
  //         yields_bdr_commission: false,
  //         products_interest_hive: 'LastMile',
  //         amount_in_home_currency:
  //           '1647.89999999999988489207680686376988887786865234375',
  //       },
  //     },
  //   },
  // ];
  const data = props.data;

  const { reload, setReload } =
    props.reloader === undefined ? {} : props.reloader;
  // [
  //   {
  //     "id": "24802665",
  //     "properties": {
  //       "createdate": "2016-03-29T17:19:55.271Z",
  //       "dealname": "El taxista sa",
  //       "hs_lastmodifieddate": "2022-07-22T17:56:01.807Z",
  //       "hs_object_id": "24802665"
  //     },
  //     "createdAt": "2016-03-29T17:19:55.271Z",
  //     "updatedAt": "2022-07-22T17:56:01.807Z",
  //     "archived": false
  //   },
  // ]
  const hubspotData = props.hubspotData;
  const actionsAllowed =
    props.actionsAllowed === undefined
      ? false
      : props.actionsAllowed && havePermissions(['admin_sales_man']);

  const listActionsAllowed =
    props.listActionsAllowed === undefined ? [] : props.listActionsAllowed;

  const actions = (listActionsAllowed) => {
    const actionsList = [];
    if (listActionsAllowed.includes('approve') && actionsAllowed) {
      actionsList.push({
        title: 'Entrega puntaje + aprobar comisión',
        onClick: handleApprove.bind(this),
        icon: <CheckCircle />,
        isGlobal: false,
      });
    }
    if (listActionsAllowed.includes('reject') && actionsAllowed) {
      actionsList.push({
        title: 'No entrega puntaje + rechazar comisión',
        onClick: handleReject.bind(this),
        icon: <CloseCircle />,
        isGlobal: false,
      });
    }
    return actionsList;
  };

  const columns = [
    {
      name: 'hubspot_client',
      title: 'Nombre del cliente',
      valueByFunc: (attr) => {
        return attr?.attributes?.hubspot_payload?.dealname;
      },
      width: 250,
    },
    {
      name: 'hubspot_client_id',
      title: 'Hubspot ID',
      valueByFunc: (attr) => {
        return attr?.attributes?.hubspot_id;
      },
      width: 250,
    },
    {
      name: 'prospected_amount',
      title: 'USD prospectados',
      valueByFunc: (attr) => {
        return attr?.attributes?.prospected_amount;
      },
      // valueByAttribute: 'prospected_amount',
    },
    {
      name: 'score',
      title: 'Puntaje',
      valueByFunc: (attr) => {
        return attr?.attributes?.score;
      },
    },
    {
      name: 'pays',
      title: '¿Entrega puntaje?',
      valueByFunc: (attr) => {
        if (attr?.attributes?.pays) {
          return 'Si';
        }
        return 'No';
      },
    },
    {
      name: 'client_segment',
      title: 'Segmento',
      valueByFunc: (attr) => {
        return attr?.attributes?.hubspot_payload?.flujo_cliente;
      },
    },
    {
      name: 'dealtype',
      title: 'Tipo de cliente',
      valueByFunc: (attr) => {
        return attr?.attributes?.hubspot_payload?.dealtype;
      },
    },
    {
      name: 'registered_at',
      title: 'Fecha de registro',
      valueByFunc: (attr) => {
        return attr?.attributes?.registered_at;
      },
      width: 250,
    },
    {
      name: 'closed_at',
      title: 'Fecha de cierre',
      valueByFunc: (attr) => {
        return attr?.attributes?.closed_at;
      },
      width: 250,
    },
    {
      name: 'status',
      title: 'Estado',
      valueByFunc: (attr) => {
        return attr?.attributes?.status;
      },
      width: 250,
    },
  ];

  // given a husbpot id, it returns the maatched data
  const _matchHubspotId = (hubspotId) => {
    return hubspotData.find((entry) => entry.id === hubspotId);
  };

  const _addTotalScore = (scores) => {
    return scores
      .reduce(
        (partialSum, entry) =>
          partialSum + (entry.attributes.pays ? entry.attributes.score : 0),
        0,
      )
      .toFixed(2);
  };

  const handleApprove = (item) => {
    const payload = { status: 'approved' };
    patch(`/sales/new_commissions/points/${item[0].item.id}`, payload).then(
      (response) => {
        if (response.status === 200) {
          setReload(reload + 1);
        }
      },
    );
  };

  const handleReject = (item) => {
    const payload = { status: 'rejected' };
    patch(`/sales/new_commissions/points/${item[0].item.id}`, payload).then(
      (response) => {
        if (response.status === 200) {
          setReload(reload + 1);
        }
      },
    );
  };

  return (
    <>
      <Table
        columns={columns}
        actions={actions(listActionsAllowed)}
        data={props.data}
        defaultHeight={200}
        pageSize={2}
        loading={props.loading}
        topHeader={
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: 20,
              paddingBottom: 20,
            }}
          >
            {`Puntaje total: ${_addTotalScore(props.data)}`}
          </p>
        }
      />
    </>
  );
};

export default BDRPointsTable;
