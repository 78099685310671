import React, { useEffect, useState } from 'react';
import { Table } from '@beetrack/hp-components';
import { Delete } from '@beetrack/hp-icons-react';
import { get, post } from '../../../../../utils/api';
import ConfirmationModal from '../../../../../shared/ConfirmationModal';

const AccessesTable = (props) => {
  const { history, data, reload, setSnackbar, setReload } = props;
  const [modalDiscard, setModalDiscard] = useState({ open: false, list: [] });

  const columns = [
    { name: 'name', title: 'Nombre', valueByAttribute: 'name' },
    {
      name: 'description',
      title: 'Descripción',
      valueByAttribute: 'description',
    },
    { name: 'date', title: 'Fecha de entrega', valueByAttribute: 'created_at' },
  ];

  const handleDeleteResource = (resource) => {
    setModalDiscard({ open: true, list: resource });
  };

  const discardResources = (resources) => {
    let payload = {
      list: resources.map((resource) => resource.item.id),
    };
    post('/resources/discard', payload).then((response) => {
      if (response.status === 200) {
        setSnackbar({
          open: true,
          message: 'Recursos eliminados con exito',
          status: 'success',
        });
        setReload(reload + 1);
      } else {
        setSnackbar({
          open: true,
          message: 'Problema eliminando recursos, intente nuevamente',
          status: 'error',
        });
      }
    });
  };

  const actions = [
    {
      title: 'Eliminar Acceso',
      icon: <Delete />,
      onClick: handleDeleteResource.bind(this),
      isGlobal: true,
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        data={data}
        fetchData={() => {}}
        actions={actions}
      />
      <ConfirmationModal
        text="¿Eliminar Accesos?"
        secondaryText="Se notificará a los responsables de eliminar los accesos correspondientes"
        open={modalDiscard.open}
        close={() => setModalDiscard({ open: false, list: [] })}
        onConfirm={() => discardResources(modalDiscard.list)}
      />
    </>
  );
};

export default AccessesTable;
