import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  app: {
    padding: 140,
    width: '50%',
    margin: 'auto',
    '& a': {
      textDecoration: 'none',
    },
  },
  font: {
    fontSize: '3rem',
  },
  title: {
    fontSize: '36px',
    fontWeight: '600',
    margin: 0,
  },
  subtitle: {
    fontSize: '25px',
    fontWeight: '500',
    margin: '8px 0px',
  },
  iconGrey: {
    color: 'grey',
  },
  iconDiv: {
    color: '#546884',
  },
  action: {
    padding: 20,
    textAlign: 'center',
  },
}));

const Error = (props) => {
  const { status, message } = props;
  const classes = useStyles();
  return (
    <div className={classes.app}>
      {status === undefined && (
        <div>
          <div className={classes.iconDiv}>
            <p className={classes.title}>Error</p>
          </div>
          <p> {message}</p>
        </div>
      )}
      {status === 500 && (
        <div>
          <div className={classes.iconDiv}>
            <p className={classes.title}>Oops, ocurrio un problema</p>
            <p className={classes.subtitle}>Intenta recargar la pagina</p>
          </div>
          <p>
            {' '}
            Si este error persiste, contacta a{' '}
            <a href="mailto: soporte@dispatchtrack.com">soporte</a> para que te
            ayude
          </p>
        </div>
      )}
    </div>
  );
};

export default Error;
