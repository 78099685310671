import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SideBarItem from './Components/SideBarItem';
import HomeIcon from '@material-ui/icons/Home';
import { mainRoutes } from './items';
import { havePermissions } from '../../../utils/utils';

const SIDEBARCOLOR = '#2b456a';

const useStyles = makeStyles((theme) => ({
  sideBar: {
    position: 'fixed',
    zIndex: 4,
    overflow: 'auto',
    backgroundColor: SIDEBARCOLOR,
    height: '97%',
    borderBottomRightRadius: '30px',
    marginTop: '23px',
  },
  openSideBar: {
    boxShadow:
      '0 6px 6px -3px rgb(0 0 0 / 20%), 0 10px 14px 1px rgb(0 0 0 / 14%), 0 4px 18px 3px rgb(0 0 0 / 12%)',
  },
  links: {
    marginTop: '28px',
    width: '52px',
    overflow: 'hidden',
    transition: 'width 0.25s',
    '&:hover': {
      width: '220px',
    },
    [theme.breakpoints.down('sm')]: {
      width: 0,
    },
  },
  linksOpen: {
    width: '220px',
    transition: 'width 0.25s',
    [theme.breakpoints.up('sm')]: {
      width: '52px',
      transition: 'width 0.25s',
      '&:hover': {
        width: '220px',
      },
    },
  },
}));

const SideBarHive = (props) => {
  const classes = useStyles();
  const [over, setOver] = useState(false);
  return (
    <div className={`${classes.sideBar} ${open && classes.openSideBar}`}>
      <div
        className={`${open ? classes.linksOpen : ''} ${classes.links} `}
        onMouseEnter={() => setOver(true)}
        onMouseLeave={() => setOver(false)}
      >
        {mainRoutes.map((item, index) => {
          return (
            (havePermissions(item.permission) || item.mainRoute === 'home') && (
              <SideBarItem
                id={item.id}
                bigselected={props.bigselected}
                smallselected={props.smallselected}
                setbigselected={props.setbigselected}
                setsmallselected={props.setsmallselected}
                key={index}
                over={over}
                icon={item.icon}
                mainRoute={item.mainRoute}
                subRoutes={item.subRoutes}
                content={item.content}
                to={item.to}
              />
            )
          );
        })}
      </div>
    </div>
  );
};

export default SideBarHive;
