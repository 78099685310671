import React from 'react';
import { render } from 'react-dom';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import Login from '../components/Login';
import AsyncComponent from '../utils/asyncComponent';
import ConfirmationUser from '../components/ConfirmationUser';
import loader from 'images/loader.gif';
import ForgotPassword from '../components/ForgotPassword';
import ResetPassword from '../components/ResetPassword';
import PublicForm from '../components/NewHR/PublicForm';
import '../utils/i18n';
import Home from '../components/Home';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem('jwt_token') !== null ? (
        <Component {...props} />
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

document.addEventListener('DOMContentLoaded', () => {
  render(
    <Router>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/confirm_account" component={ConfirmationUser} />
        <Route exact path="/password/recover" component={ForgotPassword} />
        <Route exact path="/password/reset/:token" component={ResetPassword} />
        <Route exact path="/onboarding-form/:uuid" component={PublicForm} />
        <PrivateRoute path="/" component={Home} />
      </Switch>
    </Router>,
    document.body.appendChild(document.createElement('div')),
  );
});
