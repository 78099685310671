import React, { Component } from 'react'
import { Tabs, TabItem, DatePicker } from '@beetrack/hp-components'
import MainManager from "./MainManager";
import ViewTable from "./ViewTable";
import {get} from "../../../utils/api";
import PaidManager from "./PaidManager";
import '../styles.scss'


class DetailCommission extends Component {

  constructor(props) {
    super(props);
    this.state = {
      date: this.formatDate(new Date()),
      commissionStatus: 0,
      notConsideredData: [],
      rejectedData: [],
      reversedData: [],
      currentTab: '',
      modalStatus: false
    }
  }

  formatDate = (date) =>{
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let day = date.getDate()

    if(month < 10){
      month = `0${month}`
    }
    return(`${day}-${month}-${year}`)
  }

  openedTab(object) {
    this.setState({currentTab: object.toIndex}, ()=> {
      if ((object.toIndex !== 0) && (object.toIndex !== 1)){
        this.fetchCommissionsData(this.props.selectedSalesmanObject, this.resolveStatus(object.toIndex))
      }
    })
  }

  fetchCommissionsData = (salesmanObject, commissionsStatus) =>{
    get(`/commissions?sales_man_id=${salesmanObject.id}&date=${this.state.date}&status=${commissionsStatus}`)
      .then((response)=> {
        if (response.status===200){
          var data = []
          response.data.map((commission)=>{
            data.push(
              {
                product: commission.product,
                client: commission.client,
                invoice: commission.invoice_id,
                comment: commission.comment,
                invoiceAmount: commission.original_amount,
                commission: commission.amount_paid,
                paidDate: commission.paid_on,
              }
            )
          })
          this.saveData(data, commissionsStatus)
        }
        else {
          return "Couldn't get a response"
        }
      })
  }

  resolveStatus = (activeTab) =>{
    if(activeTab === 2){
      return 1
    }
    else if(activeTab === 3){
      return 2
    }
    else if(activeTab === 4){
      return 3
    }
  }

  saveData = (data, commissionsStatus) =>{
    if(commissionsStatus === 1){
      this.setState({notConsideredData: data})
    }
    else if(commissionsStatus === 2){
      this.setState({rejectedData: data})
    }
    else if(commissionsStatus === 3){
      this.setState({reversedData: data})
    }
  }

  onDateChange = (date) =>{
    this.setState(
      {date: date},
      ()=>this.fetchCommissionsData(
        this.props.selectedSalesmanObject,
        this.resolveStatus(this.state.currentTab))
    )
  }

  setModalState = (state) =>{
    this.setState({modalOpen: state})
  }

  render() {
    let { classes } = this.props;
    return(
      <div>
        <Tabs defaultActiveTab={0} onChange={(object)=> this.openedTab(object)}>
          <TabItem label='Pendientes' >
            <MainManager
              selectedSalesmanObject = {this.props.selectedSalesmanObject}
            />
          </TabItem>
          <TabItem label='Pagadas'>
            <div className={'datepicker-format'}>
              <DatePicker
                inputLabel='Fecha'
                date={this.state.date}
                onChange={(value) => {
                  this.onDateChange(value)
                }}
                locale='es'
              />
            </div>
            <PaidManager
              selectedSalesmanObject = {this.props.selectedSalesmanObject}
              date = {this.state.date}
            />
          </TabItem>
          <TabItem label='No consideradas'>
            <div className={'datepicker-format'}>
              <DatePicker
                inputLabel='Fecha'
                date={this.state.date}
                onChange={(value) => {
                  this.onDateChange(value)
                }}
                locale='es'
              />
            </div>
            <ViewTable
              selectedSalesmanObject = {this.props.selectedSalesmanObject}
              status = {'notConsidered'}
              data = {this.state.notConsideredData}
            />
          </TabItem>
          <TabItem label='Rechazadas' >
            <div className={'datepicker-format'}>
              <DatePicker
                inputLabel='Fecha'
                date={this.state.date}
                onChange={(value) => {
                  this.onDateChange(value)
                }}
                locale='es'
              />
            </div>
            <ViewTable
              selectedSalesmanObject = {this.props.selectedSalesmanObject}
              status = {'rejected'}
              data = {this.state.rejectedData}
            />
          </TabItem>
          <TabItem label='Reversadas' >
            <div className={'datepicker-format'}>
              <DatePicker
                inputLabel='Fecha'
                date={this.state.date}
                onChange={(value) => {
                  this.onDateChange(value)
                }}
                locale='es'
              />
            </div>
            <ViewTable
              selectedSalesmanObject = {this.props.selectedSalesmanObject}
              status = {'reversed'}
              data = {this.state.reversedData}
            />
          </TabItem>
        </Tabs>
      </div>
    )
  }
}

export default DetailCommission
