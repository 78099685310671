import React, { useState, useEffect } from 'react';
import {
  Page,
  Form,
  FormRow,
  TextField,
  Button,
  Snackbar,
} from '@beetrack/hp-components';
import Table from './Components/Table';
import { get } from '../../utils/api';

const Collection = (props) => {
  const [reload, setReload] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [accountTypes, setAccountTypes] = useState('debtors');

  useEffect(() => {
    get(`/collections/`).then((response) => {
      if (response.status === 200) {
        setLoading(false);
        setData(response.data.data);
      }
    });
  }, [reload]);

  return (
    <Page
      title="Cobranza"
      buttons={[
        <Button
          type={accountTypes === 'debtors' ? 'secondary' : 'tertiary'}
          onClick={() =>
            accountTypes === 'debtors'
              ? setAccountTypes('all')
              : setAccountTypes('debtors')
          }
          disabled={loading}
        >
          {accountTypes === 'debtors' ? 'Mostrar todos' : 'Mostrar Deudores'}
        </Button>,
      ]}
    >
      <p>
        Aquí encontrarás información de <strong> clientes </strong> deudores
      </p>
      <Table data={data} loading={loading} accountTypes={accountTypes} />
    </Page>
  );
};

export default Collection;
