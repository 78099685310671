import React from 'react';
import { BeetrackText, DispatchTrackText } from '@beetrack/hp-graphic-react';
import { useStyles } from './Styles/styles';

const TopBanner = () => {
  const classes = useStyles();

  return (
    <div className={classes.fixedBanner}>
      <DispatchTrackText dark height={20} />
    </div>
  );
};

export default TopBanner;
