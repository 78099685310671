import React, { useState } from 'react';
import {
  Radio,
  Select,
  TextField,
  Button,
  Snackbar,
  DatePicker,
  Form,
  FormRow,
} from '@beetrack/hp-components';
import { post } from '../../../utils/api';
import moment from 'moment';
import './styles.scss';
import { Grid } from '@material-ui/core';

const HRNewOnboarding = (props) => {
  const { history } = props;
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [name, setName] = useState('');
  const [personalEmail, setPersonalEmail] = useState('');
  const [onboardingDate, setOnboardingDate] = useState(
    moment().format('DD-MM-YYYY'),
  );
  const [rut, setRut] = useState('');
  const [position, setPosition] = useState('');
  const [emailInCharge, setEmailInCharge] = useState('');
  const [area, setArea] = useState();
  const [contractType, setContractType] = useState();
  const [country, setCountry] = useState('Chile');
  const [workEmail, setWorkEmail] = useState('correo@beetrack.com');
  const [radioButton, setRadioButton] = useState(true);
  const [disabledButton, setDisabledButton] = useState(false);
  const [fixedMonth, setFixedMonth] = useState(1);
  const [offboardingDate1, setOffboardingDate1] = useState(
    moment().add(2, 'months').format('DD-MM-YYYY'),
  );
  const [offboardingDate2, setOffboardingDate2] = useState(
    moment().add(4, 'months').format('DD-MM-YYYY'),
  );

  const areaOptions = [
    { label: 'Customer Experience', value: 'Customer Experience' },
    { label: 'Finance', value: 'Finance' },
    { label: 'Marketing', value: 'Marketing' },
    { label: 'Producto', value: 'Producto' },
    { label: 'Sales', value: 'Sales' },
    { label: 'HR', value: 'HR' },
  ];
  const contractOptions = [
    { label: 'Contrato indefinido', value: 'indefinido' },
    { label: 'Plazo fijo', value: 'fijo' },
  ];
  const fixedMonthOptions = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
  ];

  const handleFormSend = () => {
    setDisabledButton(true);
    if (
      name === '' ||
      personalEmail === '' ||
      workEmail === 'correo@beetrack.com' ||
      position === '' ||
      workEmail === '' ||
      emailInCharge === ''
    ) {
      setOpenSnack(true);
      setSnackMessage('Falta completar datos necesarios');
      setDisabledButton(false);
    } else {
      let payload = {
        name,
        personalEmail,
        workEmail,
        onboardingDate,
        rut,
        position,
        area,
        contractType,
        country,
        emailInCharge,
        fixedMonth,
        offboardingDate1,
        offboardingDate2,
      };
      post('/candidates', payload).then((response) => {
        if (response.status === 201) {
          history.push('/candidates');
        } else {
          setOpenSnack(true);
          setSnackMessage('Error ingresando los datos');
          setDisabledButton(false);
        }
      });
    }
  };
  return (
    <div>
      <Grid container justify="center">
        <Form
          backTo="/candidates"
          title="Nuevo Ingreso"
          tooltip="Aquí puedes ingresar un nuevo Beetracker para generar notificaciones automáticas"
          buttons={<Button onClick={() => handleFormSend()}>Ingresar</Button>}
        >
          <FormRow>
            <TextField
              key="name"
              label="Nombre Completo"
              value={name}
              fullWidth
              onChange={(e) => setName(e.target.value)}
            />
          </FormRow>
          <FormRow>
            <TextField
              key="personal_email"
              label="Correo personal"
              value={personalEmail}
              onChange={(e) => setPersonalEmail(e.target.value)}
              fullWidth
            />
            <TextField
              key="work_email"
              label="Correo Beetrack"
              value={workEmail}
              onChange={(e) => setWorkEmail(e.target.value)}
              fullWidth
            />
          </FormRow>
          <FormRow>
            <DatePicker
              label="Fecha de ingreso"
              date={onboardingDate}
              onChange={(value) => {
                setOnboardingDate(value);
              }}
            />
            <TextField
              key="position"
              label="Cargo"
              value={position}
              fullWidth
              onChange={(e) => setPosition(e.target.value)}
            />
          </FormRow>
          <FormRow>
            <Select
              key="area"
              options={areaOptions}
              label="Elegir area"
              value={area}
              onChange={(e) => setArea(e.value)}
            />
            <TextField
              key="supervisor_email"
              label="Correo del supervisor"
              value={emailInCharge}
              fullWidth
              onChange={(e) => setEmailInCharge(e.target.value)}
            />
          </FormRow>
          <FormRow>
            <Select
              key="contract"
              options={contractOptions}
              label="Tipo de contrato"
              value={contractType}
              onChange={(e) => setContractType(e.value)}
            />
            {contractType === 'fijo' && (
              <div>
                <Select
                  key="number-of-months"
                  options={fixedMonthOptions}
                  label={'Cantidad de contratos fijos'}
                  value={fixedMonth}
                  onChange={(e) => setFixedMonth(e.value)}
                />
                <FormRow>
                  {fixedMonth >= 1 && (
                    <DatePicker
                      label="Fecha de termino 1"
                      date={offboardingDate1}
                      onChange={(value) => {
                        setOffboardingDate1(value);
                      }}
                    />
                  )}
                  {fixedMonth == 2 && (
                    <DatePicker
                      label="Fecha de termino 2"
                      date={offboardingDate2}
                      onChange={(value) => {
                        setOffboardingDate2(value);
                      }}
                    />
                  )}
                </FormRow>
              </div>
            )}
          </FormRow>
          <FormRow>
            <Radio
              label="Chile"
              name="radio"
              value="chile"
              checked={radioButton}
              onChange={(e) => {
                setRadioButton(true);
                setCountry('Chile');
              }}
            />
            <Radio
              label="Otro"
              name="radio"
              value="otro"
              checked={!radioButton}
              onChange={(e) => {
                setRadioButton(false);
                setCountry('');
              }}
            />
          </FormRow>
          {!radioButton && (
            <FormRow>
              <TextField
                key="country"
                label="Pais"
                value={country}
                fullWidth
                onChange={(e) => {
                  setCountry(e.target.value);
                }}
              />
            </FormRow>
          )}
          {radioButton && (
            <FormRow>
              <TextField
                key="rut"
                label="Rut"
                value={rut}
                fullWidth
                onChange={(e) => setRut(e.target.value)}
              />
            </FormRow>
          )}
        </Form>
      </Grid>
      <Snackbar
        isOpen={openSnack}
        message={snackMessage}
        onDismiss={() => setOpenSnack(!openSnack)}
        status="error"
      />
    </div>
  );
};

export default HRNewOnboarding;
