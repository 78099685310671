import React, { useState, useEffect } from 'react';
import {
  Snackbar,
  LoadingPage,
  Page,
  Card,
  CardContent,
  CardDivider,
  Chip,
  Button,
} from '@beetrack/hp-components';
import { get, post } from '../../../../utils/api';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {
  AlternateEmail,
  Phone,
  LocationOn,
  Event,
  QueryBuilder,
  Assignment,
  LocationCity,
  Home,
  Group,
  LocalHospital,
  Healing,
  MonetizationOn,
  AccountBalance,
  Fingerprint,
  CheckCircle,
  Cancel,
} from '@material-ui/icons';
import { Face, Edit } from '@beetrack/hp-icons-react';
import ConfirmationModal from '../../../../shared/ConfirmationModal';

const CandidateView = (props) => {
  const { history } = props;
  const id = props.match.params.id;
  const [candidate, setCandidate] = useState({});
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(0);
  const [snack, setSnack] = useState({
    open: false,
    message: '',
    status: 'success',
  });
  const [confirmMail, setConfirmMail] = useState({ open: false, id: null });
  const [confirmForm, setConfirmForm] = useState({ open: false, id: null });

  const onGoBack = () => {
    history.push('/candidates');
  };

  const createEmail = (id) => {
    setLoading(true);
    post('/candidates/create_account/' + id).then((response) => {
      if (response.status === 200) {
        setSnack({
          open: true,
          message: 'Correo creado exitosamente',
          status: 'success',
        });
        setRefresh(refresh + 1);
        setLoading(false);
      } else {
        setSnack({
          open: true,
          message: 'Correo no pudo ser creado',
          status: 'error',
        });
        setLoading(false);
      }
    });
  };

  const sendForm = (id) => {
    setLoading(true);
    post(`/candidates/send_form/${id}`).then((response) => {
      if (response.status === 200) {
        setRefresh(refresh + 1);
        setSnack({
          open: true,
          message: 'Formulario enviado exitosamente',
          status: 'success',
        });
        setLoading(false);
      } else {
        setSnack({
          open: true,
          message: 'Error enviando formulario',
          status: 'error',
        });
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    get('/candidate/' + id).then((response) => {
      if (response.status === 200) {
        setCandidate(response.data);
        setLoading(false);
      }
    });
  }, [refresh]);
  return (
    <div>
      <Snackbar
        key="snackbar"
        isOpen={snack.open}
        message={snack.message}
        onDismiss={() =>
          setSnack({ open: false, message: '', status: snack.status })
        }
        status={snack.status}
      />
      <ConfirmationModal
        text={`¿Estás seguro de crear el correo ${candidate.work_email}?`}
        secondaryText="Esta acción creará el correo y notificará al beetracker de su correo."
        open={confirmMail.open}
        close={() => setConfirmMail({ open: false, id: null })}
        onConfirm={() => createEmail(confirmMail.id)}
        confirmButton="Crear"
      />
      <ConfirmationModal
        text={`¿Estás seguro de enviar el formulario a ${candidate.personal_email}?`}
        secondaryText="Esta acción notificará al candidato con el formulario."
        open={confirmForm.open}
        close={() => setConfirmForm({ open: false, id: null })}
        onConfirm={() => sendForm(confirmForm.id)}
        confirmButton="Enviar"
      />
      <Page
        onGoBack={onGoBack}
        title="Ingresos"
        buttons={[
          <Button
            icon={<Edit />}
            onClick={() => history.push(`/hr/candidate/edit/${id}`)}
          >
            {' '}
            Editar{' '}
          </Button>,
        ]}
      >
        {!loading && (
          <Grid container justify="center">
            <Card bordered={false} fullWidth>
              <CardContent>
                <Grid container justify="flex-start">
                  <Chip text={candidate.area} closeButton={false} />
                </Grid>
                <center>
                  <Face height="60" width="60" />
                  <h3 className="hp-page__header-title">{candidate.name}</h3>
                  <p> {candidate.position} </p>
                </center>
                <Grid container style={{ marginLeft: '90px' }}>
                  <Grid item xs>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Phone style={{ paddingRight: '5px' }} />
                      <span>
                        {candidate.form_data.phone
                          ? candidate.form_data.phone
                          : 'no informado'}
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <AlternateEmail style={{ paddingRight: '5px' }} />
                      <span>{candidate.personal_email}</span>
                    </div>
                  </Grid>
                </Grid>
                <CardDivider />
                <h3 className="hp-page__header-title">Información</h3>
                <br />
                <Grid container spacing={4}>
                  <Grid item xs={4}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <LocationOn style={{ paddingRight: '5px' }} />
                      <span>{candidate.country}</span>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Event style={{ paddingRight: '5px' }} />
                      <span>
                        {moment(candidate.onboarding_date)
                          .utc()
                          .format('DD/MM/YYYY')}
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <QueryBuilder style={{ paddingRight: '5px' }} />
                      <span>
                        {candidate.entry_time
                          ? candidate.entry_time
                          : 'no definida'}
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Assignment style={{ paddingRight: '5px' }} />
                      <span>{candidate.contract_type}</span>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <LocationCity style={{ paddingRight: '5px' }} />
                      <span>
                        {candidate.form_data.city
                          ? candidate.form_data.city
                          : 'no informado'}
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Home style={{ paddingRight: '5px' }} />
                      <span>
                        {candidate.form_data.address
                          ? `${candidate.form_data.address}, ${candidate.form_data.commune}`
                          : 'no informado'}
                      </span>
                    </div>
                  </Grid>
                  {(candidate.country === 'Chile' ||
                    candidate.country === 'chile') && (
                    <>
                      <Grid item xs={4}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                          }}
                        >
                          {candidate.form_data.covid_insurance === 'true' ? (
                            <CheckCircle style={{ paddingRight: '5px' }} />
                          ) : (
                            <Cancel style={{ paddingRight: '5px' }} />
                          )}
                          <span>
                            {candidate.form_data.covid_insurance === 'true' ? (
                              <span>
                                {' '}
                                Seguro Covid (
                                <a href={candidate.form_data.url_covid}>
                                  pdf seguro
                                </a>
                                )
                              </span>
                            ) : (
                              'NO Posee Seguro Covid'
                            )}
                          </span>
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                          }}
                        >
                          <Healing style={{ paddingRight: '5px' }} />
                          <span>
                            {candidate.form_data.prevision_type ? (
                              candidate.form_data.prevision_type !==
                              'isapre' ? (
                                candidate.form_data.prevision_type
                              ) : (
                                <span>
                                  {' '}
                                  {candidate.form_data.isapre + ' ('}{' '}
                                  <a
                                    target="_blank"
                                    href={candidate.form_data.url_fun}
                                  >
                                    Ver Fun{' '}
                                  </a>
                                  )
                                </span>
                              )
                            ) : (
                              'no informado'
                            )}
                          </span>
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                          }}
                        >
                          <MonetizationOn style={{ paddingRight: '5px' }} />
                          <span>
                            {candidate.form_data.afp &&
                            candidate.form_data.afp !== 'Sin cotizar' ? (
                              <span>
                                {' '}
                                {candidate.form_data.afp + ' ('}{' '}
                                <a
                                  href={candidate.form_data.afp_url}
                                  target="_blank"
                                >
                                  {' '}
                                  Ver Certificado{' '}
                                </a>
                                )
                              </span>
                            ) : (
                              'no informado'
                            )}{' '}
                          </span>
                        </div>
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid spacing={4} container style={{ marginLeft: '50px' }}>
                  <Grid item xs={6}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Group style={{ paddingRight: '5px' }} />
                      <span>
                        {candidate.form_data.marital_status
                          ? candidate.form_data.marital_status
                          : 'no informado'}
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <LocalHospital style={{ paddingRight: '5px' }} />
                      <span>
                        {candidate.form_data.emergency_name
                          ? candidate.form_data.emergency_name +
                            ' (' +
                            candidate.form_data.emergency_phone +
                            ')'
                          : 'no informado'}
                      </span>
                    </div>
                  </Grid>
                  {(candidate.country === 'Chile' ||
                    candidate.country === 'chile') && (
                    <>
                      <Grid item xs={6}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                          }}
                        >
                          <AccountBalance style={{ paddingRight: '5px' }} />
                          <span>
                            {candidate.form_data.bank
                              ? candidate.form_data.bank +
                                '-' +
                                candidate.form_data.account_number +
                                '-' +
                                candidate.form_data.account_type
                              : 'no informado'}{' '}
                          </span>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                          }}
                        >
                          <Fingerprint style={{ paddingRight: '5px' }} />
                          <span>
                            {candidate.rut ? (
                              <a
                                target="__blank"
                                href={candidate.form_data.url_identity}
                              >
                                {' '}
                                {candidate.rut}{' '}
                              </a>
                            ) : (
                              'no informado'
                            )}{' '}
                          </span>
                        </div>
                      </Grid>
                    </>
                  )}
                </Grid>
                <CardDivider />
                <h3 className="hp-page__header-title">Estado</h3>

                <List>
                  <ListItem>
                    <ListItemIcon>
                      {candidate.welcome_mail ? (
                        <CheckCircle style={{ color: 'green' }} />
                      ) : (
                        <Cancel style={{ color: 'red' }} />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      id="welcome-mail"
                      primary={
                        candidate.welcome_mail
                          ? 'Correo bienvenida Enviado'
                          : 'Correo bienvenida NO enviado'
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      {candidate.form_sended ? (
                        <CheckCircle style={{ color: 'green' }} />
                      ) : (
                        <Cancel style={{ color: 'red' }} />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      id="form-sended"
                      primary={
                        candidate.form_sended
                          ? 'Formulario enviado'
                          : 'Formulario NO enviado'
                      }
                    />
                    <Button
                      disabled={candidate.form_sended}
                      onClick={() =>
                        setConfirmForm({ open: true, id: candidate.id })
                      }
                    >
                      Enviar formulario
                    </Button>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      {candidate.form_completed ? (
                        <CheckCircle style={{ color: 'green' }} />
                      ) : (
                        <Cancel style={{ color: 'red' }} />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      id="form-completed"
                      primary={
                        candidate.form_completed
                          ? 'Formulario completado'
                          : 'Formulario NO completado'
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      {candidate.mail_created ? (
                        <CheckCircle style={{ color: 'green' }} />
                      ) : (
                        <Cancel style={{ color: 'red' }} />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      id="mail-created"
                      primary={
                        candidate.mail_created
                          ? `Cuenta HIVE creada (${candidate.work_email})`
                          : `Cuenta HIVE NO creada (${candidate.work_email})`
                      }
                    />
                    <Button
                      disabled={candidate.mail_created}
                      onClick={() =>
                        setConfirmMail({ open: true, id: candidate.id })
                      }
                    >
                      Crear Correo
                    </Button>
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Grid>
        )}
        {loading && <LoadingPage text="Cargando..." />}
      </Page>
    </div>
  );
};

export default CandidateView;
