import React, { forwardRef, useEffect, useState } from 'react';
import { Paper, Grid } from '@material-ui/core';
import { get, post } from '../../../../utils/api';
import { Button } from '@beetrack/hp-components';
import { makeStyles } from '@material-ui/core/styles';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import DraftCollapsable from '../../../DraftsComments/Components/DraftModal/Components/Collapsable';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { Loading } from '@beetrack/hp-components';
import DraftLines from '../DraftLines';
import CommentsModal from '../CommentsModal';
import BusinessIcon from '@material-ui/icons/Business';
import ContactsIcon from '@material-ui/icons/Contacts';

const useStyles = makeStyles({
  root: {
    paddingBottom: '3%',
  },
  modal: {
    padding: '3%',
  },
  buttons: {
    paddingTop: '5%',
    paddingLeft: '10%',
  },
  rightBorder: {
    borderRight: '1px solid black',
    paddingLeft: '3%',
  },
  table: {
    minWidth: 650,
    border: '2px solid black',
    backgroundColor: '#ebb734',
  },
  loading: {
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
});

const DraftModal = forwardRef((props, ref) => {
  const id = props.id;
  const tableType = props.tableType;
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [accountPartner, setAccountPartner] = useState('');
  const [salesMan, setSalesMan] = useState('');
  const [companyType, setCompanyType] = useState('');
  const [dealName, setDealName] = useState('');
  const [dealSource, setDealSource] = useState('');
  const [beetrackId, setBeetrackId] = useState('');
  const [products, setProducts] = useState([]);
  const [comments, setComments] = useState([]);
  const [lastInvoice, setLastInvoice] = useState({ amount: 1532.34, id: 5 });
  const [companyName, setCompanyName] = useState(props.client);
  const [lines, setLines] = useState([]);
  const [clientInformation, setClientInformation] = useState([]);
  const [invoiceDate, setInvoiceDate] = useState([]);
  const [invoiceDraft, setInvoiceDraft] = useState([]);
  const [realmId, setrealmId] = useState([]);
  const [draftComment, setDraftComment] = useState({});

  const clickPdf = () => {
    props.setSnackBar({
      open: true,
      status: 'alert',
      message: 'Espera mientras se carga el pdf...',
    });
    const payload = {
      realm_id: lastInvoice.realm_id,
    };
    post(
      '/draft_comments/last_invoice_pdf/' + lastInvoice.id.toString(),
      payload,
    ).then((response) => {
      if (response.status === 200) {
        window.open(response.data.url, '_blank');
      }
    });
  };

  const renderData = () => (
    <div className={classes.modal}>
      {!loading && (
        <div>
          <Grid container spacing={3}>
            <Grid item xs>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  margin: 0,
                  padding: 0,
                }}
              >
                <BusinessIcon style={{ color: '#132045' }}/>
                <span>
                  {' '}
                  <h3 style={{ margin: 0, paddingLeft: '2px' }}>
                    {' '}
                    <b className='hp-typography__title--bold' style={{ color: '#132045' }}> {companyName} </b>
                  </h3>{' '}
                </span>
              </div>
              <h4 style={{ margin: 0, paddingLeft: '25px' }}>
                {' '}
                <b className='hp-typography__subtitle--bold' style={{ color: '#132045' }}> Deal: {dealName} </b>
              </h4>
              <h4 style={{ margin: 0, paddingLeft: '25px' }}>
                {' '}
                <b className='hp-typography__subtitle--bold' style={{ color: '#132045' }}> Beetrack ID: {beetrackId} </b>
              </h4>
              <div style={{ padding: '2%' }}>
                <DraftCollapsable
                  invoiceId={id}
                  products={products}
                  comments={comments}
                />
              </div>
              {lastInvoice.id && (
                <Button
                  type="secondary"
                  icon={<ReceiptIcon font="small" />}
                  onClick={() => clickPdf()}
                >
                  Última Factura Emitida ${lastInvoice.amount.toLocaleString()}
                </Button>
              )}
            </Grid>
            <Grid item xs>
              <div style={{ paddingLeft: '2%', paddingBottom: '10%' }}>
                <DraftLines
                  lines={lines}
                  clientInformation={clientInformation}
                  txnDate={invoiceDate}
                  draft={invoiceDraft}
                  draftId={id}
                  realmId={realmId}
                  tableType={tableType}
                />
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                {draftComment ? 
                  (<CommentsModal
                    author={salesMan}
                    draftComment = {draftComment}
                  />) :
                  <></>}
              </div>
            </Grid>
          </Grid>
          
        </div>
      )}
      {loading && (
        <div className={classes.loading}>
          <Loading hasText />
        </div>
      )}
    </div>
  );

  useEffect(() => {
    getDraftInfo();
  }, []);

  const getDraftInfo = () => {
    get('/draft_comments/' + id).then((response) => {
      if (response.status === 200) {
        setAccountPartner(response.data.account_partner);
        setDealName(response.data.deal_name);
        setDealSource(response.data.deal_source);
        setBeetrackId(response.data.beetrack_id);
        setCompanyType(response.data.company_type);
        setSalesMan(response.data.salesman_mail);
        setLastInvoice(response.data.last_invoice);
        setComments(response.data.last_comments);
        setrealmId(response.data.realm_id);
        setLines(response.data.invoice_draft.table.Line);
        setDraftComment(response.data.draft_comment);
        setClientInformation(
          response.data.invoice_draft.table.CustomerRef.name,
        );
        setInvoiceDate(response.data.invoice_draft.table.TxnDate);
        setInvoiceDraft(response.data.invoice_draft);
        setLoading(false);
        get(
          `/commercial_conditions/plan_relations?hubspot_id=${response.data.hubspot_id}`,
        ).then((response) => {
          if (response.status === 200) {
            setProducts(response.data.data.plan_relations.nodes);
          }
        });
      }
    });
  };

  return renderData();
});

export default DraftModal;
