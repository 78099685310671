export const styles = theme => ({
  cardContainer: {
    marginBottom: '20px'
  },
  title: {
    fontSize: 18
  },
  normalText: {
    fontSize: 14,
  },
  buttons: {
    flexBasis: 'auto'
  },
  infoContainer: {
    justifyContent: 'space-between'
  },
  middleSpacer: {
    minWidth: '1px'
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  divider: {
    marginBottom: '10px'
  },
  controls: {
    margin: '10px',
    textAlign: 'right',
    display: 'flex',
    minWidth: '120px'
  },
  card: {
    display: 'flex',
    overflow: 'visible'
  },
  selectClients: {
    margin: '0 auto',
    width: '500px',
    maxHeight: '200px',
    position: 'absolute'
  },
  spacer: {
    marginRight: '10px'
  }
})
