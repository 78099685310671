import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: 'center',
    position: 'absolute',
  },
  big: {
    left: '50%',
    [theme.breakpoints.down('md')]: {
      left: '45%',
    },
    top: '42%',
  },
  small: {
    top: '50%',
    width: '100%',
  },
  plan: {
    top: '50%',
    width: '87%',
  },
  colorCircular: {
    color: 'linear-gradient(180deg,#ffd200,#f4aa1f)',
  },
  changeCC: {
    left: '35%',
    top: '40%',
  },
  deleteCC: {
    left: '33%',
    top: '40%',
  },
  addCC: {
    left: '32%',
    top: '40%',
  },
  registerBS: {
    left: '41%',
    top: '40%',
  },
  registerST: {
    left: '43%',
    top: '40%',
  },
  download: {
    left: '45%',
    top: '42%',
  },
  payment: {
    left: '30.5%',
    top: '40%',
  },
}));

const LoadingCircle = (props) => {
  const { size, text } = props;
  const classes = useStyles();

  return (
    <div className={`${classes[size]} ${classes.center}`}>
      <CircularProgress className={classes.colorCircular} />
      <p>{text}</p>
    </div>
  );
};

export default LoadingCircle;
