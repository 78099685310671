import React, { useState } from 'react';
import {
  Form,
  FormRow,
  TextField,
  Select,
  Button,
  Snackbar,
} from '@beetrack/hp-components';
import { post } from '../../../../utils/api';
import {
  QUICKBOOKS_REALM_ID_CL,
  QUICKBOOKS_REALM_ID_CO,
  QUICKBOOKS_REALM_ID_MX,
} from '../../../../utils/const';

const NewRelation = (props) => {
  const { history } = props;
  const [hubspotId, setHubspotId] = useState('');
  const [quickbooksId, setQuickbooksId] = useState('');
  const [realmId, setRealmId] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [snack, setSnack] = useState({
    open: false,
    message: '',
    status: 'error',
  });

  const options = [
    { label: 'Chile', value: QUICKBOOKS_REALM_ID_CL },
    { label: 'Mexico', value: QUICKBOOKS_REALM_ID_MX },
    { label: 'Colombia', value: QUICKBOOKS_REALM_ID_CO },
  ];

  const handleNew = () => {
    setDisabled(true);
    let payload = {
      hubspot_id: hubspotId,
      quickbooks_id: quickbooksId,
      quickbooks_realm_id: realmId,
    };
    post('/hubspot_quickbooks_relation', payload).then((response) => {
      if (response.status === 201) {
        history.push('/client/relations');
      } else {
        setSnack({
          message: 'Problemas creando relación',
          status: 'error',
          open: true,
        });
        setDisabled(false);
      }
    });
  };

  return (
    <div>
      <Snackbar
        key="snackbar"
        isOpen={snack.open}
        message={snack.message}
        onDismiss={() =>
          setSnack({ open: false, message: '', status: 'error' })
        }
        status={snack.status}
      />
      <Form
        title="Nueva Relación en Hive"
        backTo="/client/relations"
        buttons={[
          <Button
            key="not-submit"
            type="tertiary"
            onClick={() => history.push('/client/relations')}
            disabled={disabled}
          >
            Cancelar
          </Button>,
          <Button key="submit" onClick={() => handleNew()} disabled={disabled}>
            Guardar
          </Button>,
        ]}
      >
        <FormRow>
          <TextField
            key="hubspot-id"
            onChange={(e) => setHubspotId(e.target.value)}
            placeholder="HubSpot ID"
            label="Ingresa HubSpot ID"
            value={hubspotId}
          />
          <TextField
            key="quickbooks-id"
            onChange={(e) => setQuickbooksId(e.target.value)}
            placeholder="Quickbooks ID"
            label="Ingresa Quickbooks ID"
            value={quickbooksId}
          />
        </FormRow>
        <FormRow>
          <Select
            key="realm-id"
            options={options}
            label="País de Quickbooks"
            onChange={(e) => setRealmId(e.value)}
            value={realmId}
          />
        </FormRow>
      </Form>
    </div>
  );
};

export default NewRelation;
