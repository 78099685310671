import React, { Component } from 'react'
import {Button, Card, CardContent, TextField, Snackbar} from "@beetrack/hp-components";
import {post} from "../../../utils/api";


class ManualCommission extends Component {

  constructor(props) {
    super(props);
    this.state = {
      amount: '',
      comment: '',
      status: 0,
      errorOpen: false,
      successOpen: false,
      noData: false
    }
  }

  createCustomCommission = () =>{
    if(this.state.amount === '' || this.state.comment ===''){
      this.setState({noData: true, errorOpen: true})
    }
    else{
      this.setState({noData: false})
      post('/commissions',{
        amount_paid: this.state.amount,
        comment: this.state.comment,
        user_id: this.props.selectedSalesmanObject.id,
        status: this.state.status
      })
        .then((response) =>{
          if(response.status === 201){
            this.setState({successOpen: true, amount:'', comment:''})
          }
        })
    }
  }

  render() {
    return(
      <div className={'sub-card'}>
        <Card>
          <CardContent>
            <div>
              <p>Nueva comisión</p>
            </div>
            <div>
              <TextField
                key='amount'
                placeholder='Monto'
                label='Monto'
                onChange={(e) => this.setState({amount: e.target.value})}
                value={this.state.amount}
                type='number'
              />
            </div>
            <div>
              <TextField
                key='simple'
                placeholder='placeholder'
                label='Comentario'
                onChange={(e) => this.setState({comment: e.target.value})}
                value={this.state.comment}
              />
            </div>
            <div className={'button-container'}>
              <Button type='primary' onClick={this.createCustomCommission}>Crear comisón</Button>
              {this.state.noData?
                <Snackbar
                  key='test-snackbar'
                  isOpen={this.state.errorOpen}
                  message='Se debe ingresar monto y comentario'
                  onDismiss={() => this.setState({errorOpen: false})}
                  status='error'
                />
              :
                <Snackbar
                  key='test-snackbar'
                  isOpen={this.state.successOpen}
                  message='Comisión creada con éxito'
                  onDismiss={() => this.setState({successOpen: false})}
                  status='success'
                />
              }
            </div>
          </CardContent>
        </Card>
      </div>
    )
  }
}

export default ManualCommission
