import React, { useState, useEffect } from 'react';
import {
  Page,
  Chip,
  InfoBox,
  ErrorState,
  Button,
  Modal,
} from '@beetrack/hp-components';
import Breadcrumbs, { BreadcrumbLink } from '@beetrack/hp-breadcrumbs-react';
import Loader from 'images/loader.gif';
import { Divider, Paper } from '@material-ui/core';
import { get, post } from '../../../utils/api';
import InvoiceTable from './invoice_table';
import CustomFieldsTable from './custom_fields_table';
import CommercialConditions from './commercial_conditions';
import moment from 'moment';
import { havePermissions } from '../../../utils/utils';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  spacing: {
    paddingTop: '15px',
    paddingBottom: '15px',
  },
});

export default (props) => {
  const [dealId, setDealId] = useState(null);
  const [client, setClient] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [associateRequired, setAssociateRequired] = useState(true);

  const classes = useStyles();

  useEffect(() => {
    setDealId(props.match.params.deal_id);
    props.loadState(true);
    retrieveData();
    props.loadState(false);
  }, []);

  const invoiceLineFormatter = (line) => {
    // last element is ommited as it is a sum of all the others
    return line
      .slice(0, -1)
      .map((inv) =>
        inv.table.description !== undefined
          ? `${inv.table.description} ($${inv.table.amount})`
          : '',
      )
      .join(', ');
  };

  const parseDocumentData = (documentData, paymentData) => {
    const parsedData = [];
    documentData.forEach((document) => {
      let paymentTxn = document.table?.linked_txn?.find(
        (item) => item?.table?.txn_type === 'Payment',
      );
      let lastPayment = undefined;
      if (paymentTxn) {
        lastPayment = paymentTxn.table.txn_id;
      }

      parsedData.push({
        DocType:
          document.table.balance === undefined ? 'Estimación' : 'Factura',
        Id: document.table.id,
        DocNumber: document.table.doc_number,
        TxnDate: document.table.txn_date,
        CostSep: invoiceLineFormatter(document.table.line),
        TotalTax: `$${document.table.txn_tax_detail.table.total_tax}`,
        TotalAmt: `$${document.table.total_amt}`,
        Balance:
          document.table.balance === undefined
            ? '-'
            : `$${document.table.balance}`,
        PaymentDate:
          lastPayment === undefined
            ? '-'
            : moment(
                paymentData.find((payment) => lastPayment === payment.table.id)
                  ?.table?.txn_date,
              ).format('YYYY-MM-DD'),
      });
    });
    parsedData.sort(
      (a, b) =>
        new Date(...a.TxnDate.split('-')) - new Date(...b.TxnDate.split('-')),
    );
    return parsedData;
  };

  const retrieveData = async () => {
    await get('/clients/' + props.match.params.deal_id).then((response) => {
      // data payload format
      //
      // data = {
      //   crm: {
      //     dealname: deal.crm.properties.dealname,
      //     dealstage: deal.crm.properties.dealstage,
      //     decision_makers_emails_crm: (deal.crm.contacts.decision_makers.map(&:email) + [deal.crm.properties.email_facturacion]).compact,
      //     last_contact_date: deal.crm.properties.notes_last_contacted,
      //     last_ticket_date: deal.crm.properties.last_ticket_date,
      //     potential_users: deal.crm.properties.numero_conductores,
      //     users_last_2_weeks: deal.crm.properties.users_lm_last_period,
      //     owner_id: deal.crm.properties.hubspot_owner_id,
      //     beetrack__webapp_id: deal.crm.properties.beetrack__webapp_id,
      //   }
      // }

      // deal.reverse_lookup(:crm, :accounting)
      // data[:accounting] = {
      //   company_name: deal.accounting.properties.company_name,
      //   decision_makers_emails_accounting: deal.accounting.properties.primary_email_addr.address,
      //   # rut: deal.accounting.properties.alternate_phone.free_form_number
      //   invoices: deal.accounting.invoices.all.sort_by { |invoice| invoice.doc_number.to_i }.reverse
      //   attachments: deal.accounting.invoices.attachments.sort_by { |invoice| invoice.doc_number.to_i }.reverse,
      //   balance: deal.accounting.properties.balance
      // }

      // if it is 200, then we have all the information
      if (response.status === 200) {
        if (response.data.errors.length !== 0) {
          setClient({ ...response.data.data.crm });
          setAssociateRequired(true);
        } else {
          setAttachments(response.data.data.accounting.attachments);
          setInvoices(
            parseDocumentData(
              [
                ...response.data.data.accounting.invoices,
                ...response.data.data.accounting.estimates,
              ],
              response.data.data.accounting.payments,
            ),
          );
          setClient({
            ...response.data.data.crm,
            ...response.data.data.accounting,
          });
          setAssociateRequired(false);
        }
      }
      // if it is a not found, then we just know about the hubspot data
      else {
        setClient({});
      }
    });
  };

  const renderView = () => {
    if (client === null) return loader();
    return renderData();
  };

  const loader = () => (
    <div className="loader">
      <img src={Loader} />
    </div>
  );

  const showMessage = (message) => {
    setMessage(message);
    setOpen(true);
  };

  const renderData = () => (
    <>
      {Object.keys(client).length === 0 ? (
        <ErrorState
          text={
            <div>
              <p>No se ha encontrado al cliente con hubspot ID: {dealId}</p>
            </div>
          }
        />
      ) : (
        <Paper
          style={{
            padding: 8,
            backgroundColor: 'rgba(54, 25, 25, 0)',
            border: '0px solid black',
            'box-shadow': 'none',
          }}
        >
          <Page
            title={
              <Breadcrumbs useRouter={false}>
                <BreadcrumbLink title="Clientes" to="/client" />
                <BreadcrumbLink title={client?.dealname} />
              </Breadcrumbs>
            }
            bodyAsCard
          >
            {associateRequired ? (
              <InfoBox status="error">
                Debes relacionar esta cuenta de Hubspot con la perteneciente en
                Quickbooks.
              </InfoBox>
            ) : (
              <></>
            )}
            <ClientHeaderData client={client} dealId={dealId} />
            <br></br>
            <br></br>
            <div className={classes.spacing}>
              <CommercialConditions
                message={props.message}
                hubspot_id={dealId}
              />
            </div>

            {/* <Message message={message} open={open} close={() => setOpen(false)} /> */}
            {Object.entries(client).length === 0 ? (
              <></>
            ) : (
              <>
                {/* <Header client={client} /> */}
                <Divider variant={'middle'} />
              </>
            )}
            <div className={classes.spacing}>
              <CustomFieldsTable message={props.message} hubspot_id={dealId} />
            </div>
            {/* <CommercialConditions message={props.message} hubspot_id={dealId} /> */}
            {/* <ClientData message={showMessage} client={client} hubspot_id={dealId} /> */}
            {Object.entries(client).length === 0 ? (
              <></>
            ) : (
              <div className={classes.spacing}>
                <InvoiceTable
                  message={showMessage}
                  invoices={invoices}
                  attachments={attachments}
                  dealId={dealId}
                />
              </div>
            )}
          </Page>
        </Paper>
      )}
    </>
  );

  return renderView();
};

const ClientHeaderData = (props) => {
  const { client, dealId } = props;

  const [openActivationModal, setOpenActivationModal] = useState(false);

  const handleManualActivationChange = () => {
    post(`/clients/manual_activation_toggle/${dealId}`, {});
    setOpenActivationModal(false);
  };

  const header = () => {
    return (
      <div className="hp-page__header-title">
        <p style={{ 'text-align': 'left' }}>
          {client?.dealname} / {client?.company_name}
          <span style={{ float: 'right' }}>
            <Chip text={client.dealstage} />
          </span>
          {havePermissions('accounting') && (
            <span style={{ float: 'right' }}>
              <Button
                type={client.active_account && 'danger'}
                onClick={() => setOpenActivationModal(true)}
              >
                {client.active_account ? 'Desactivar' : 'Activar'}
              </Button>
            </span>
          )}
        </p>
      </div>
    );
  };

  const invoicingEmails = () => {
    const emails = (client?.decision_makers_emails_crm || [])
      .concat([client?.decision_makers_emails_accounting])
      .join(', ');
    return (
      <>
        <b>Contactos de facturación:</b> {emails}
      </>
    );
  };

  const firstLine = () => {
    return (
      <>
        <b>Beetrack ID:</b> {client?.beetrack__webapp_id} | <b>RUT / RFC:</b>{' '}
        {client?.rut} | <b>Owner:</b> {client?.owner_id}
      </>
    );
  };

  const balance = () => {
    return (
      <>
        <b>Balance: </b> {client?.balance || '0'}
      </>
    );
  };

  const lastDates = () => {
    return (
      <>
        Último contacto: {client?.last_contact_date} | Último ticket:{' '}
        {client?.last_ticket_date}
      </>
    );
  };

  const usersStats = () => {
    return (
      <>
        <b>Usuarios reales vs usuarios potenciales:</b>{' '}
        {client?.users_last_2_weeks} / {client?.potential_users}
      </>
    );
  };

  const colombiaData = () => {
    return (
      <>
        <b>Tasa Fuente:</b> {client?.rete_fuente} | <b>Tasa Ica:</b>{' '}
        {client?.rete_ica}
      </>
    );
  };

  return (
    <>
      {header()}
      {firstLine()}
      <br />
      {balance()}
      <br />
      {lastDates()}
      <br />
      <br />
      {invoicingEmails()}
      <br />
      <br />
      {usersStats()}
      <br />
      <br />
      {client?.country === 'Colombia' ? colombiaData() : null}
      <Modal
        key="close-outside-modal"
        open={openActivationModal}
        title={`Desactivacion de cuenta ${client.dealname}`}
        onClose={() => setOpenActivationModal(!openActivationModal)}
        preventOnCloseOutside
        confirmButton={
          <Button type="primary" onClick={handleManualActivationChange}>
            Confirmar y {client.active_account ? 'Desactivar' : 'Activar'}
          </Button>
        }
      >
        <div>
          {client.active_account
            ? 'Estas seguro que deseas desactivar esta cuenta?'
            : 'Estas seguro que deseas activar esta cuenta?'}
        </div>
      </Modal>
    </>
  );
};
