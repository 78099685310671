import React, { useState } from 'react';
import {
  TextField,
  Button,
  Snackbar,
  DatePicker,
} from '@beetrack/hp-components';
import { Grid } from '@material-ui/core';
import { post } from '../../utils/api';
import moment from 'moment';

const TriggerBDRPoints = (props) => {
  const { reload, setReload } = props.reloader;
  const [blockButton, setBlockButton] = useState(false);
  const [hubspotId, setHubspotId] = useState('');
  const [score, setScore] = useState('');
  const [amount, setAmount] = useState('');
  const [snackState, setSnackState] = useState({
    message: '',
    status: 'success',
    open: false,
  });

  const [date, setDate] = useState(moment().format('DD-MM-YYYY'));

  const triggerPoints = () => {
    setBlockButton(true);
    post(`/sales/new_commissions/trigger/bdr/${hubspotId}`, {
      deal_id: hubspotId,
      score: score,
      registered_at: moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      prospected_amount: amount,
    }).then((response) => {
      if (response.status === 200) {
        setSnackState({
          message: `Puntaje gatillado correctamente para: "${hubspotId}"`,
          status: 'success',
          open: true,
        });
        setHubspotId('');
        setScore('');
        setAmount('');
        setReload(reload + 1);
      } else {
        setSnackState({
          message: `No se pudo gatillar el puntaje de "${value}". ${JSON.stringify(
            response.data.errors,
          )}`,
          status: 'error',
          open: true,
        });
      }
      setBlockButton(false);
    });
  };

  return (
    <>
      <Grid container spacing={1} alignItems="center" justify="center">
        <Grid item xs={9}>
          <Grid container spacing={0} alignItems="center">
            <Grid item xs={3}>
              <TextField
                label="Hubspot ID"
                onChange={(e) => setHubspotId(e.target.value)}
                type="number"
                value={hubspotId}
                placeholder="Hubspot ID del cliente al que se le gatillar el puntaje"
                helperText="ID de cliente en Hubspot que se gatillará."
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Puntaje que entrega"
                onChange={(e) => setScore(e.target.value)}
                type="number"
                value={score}
                helperText="Puntaje que entrega el deal que se prospectó"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Monto prospectado"
                onChange={(e) => setAmount(e.target.value)}
                type="number"
                value={amount}
                helperText="Monto original prospectado en USD."
              />
            </Grid>
            <Grid item xs={3}>
              <DatePicker
                helperText="Fecha en que se debería registrar el puntaje."
                date={date}
                onChange={(value) => {
                  setDate(value);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Button onClick={(e) => triggerPoints()} isLoading={blockButton}>
            Gatillar comisión de puntaje
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        key="test-snackbar"
        isOpen={snackState.open}
        message={snackState.message}
        onDismiss={() => setSnackState({ ...snackState, open: false })}
        status={snackState.status}
      />
    </>
  );
};

export default TriggerBDRPoints;
