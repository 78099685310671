import React, { useState } from 'react';
import MaterialTable from 'material-table';
import { havePermissions } from '../../../../utils/utils';
import { CheckCircle, Visibility, Cancel } from '@material-ui/icons';
import {
  InfoBox,
  NoDataState,
  SuccessState,
  Tooltip,
} from '@beetrack/hp-components';


const CommentsTable = (props) => {

  const {
    dataTable,
    tableType,
    setModalAccept,
    setModalReject,
    setModalDraft
  } = props
 
  const [selectedRow, setSelectedRow] = useState(0);
  const setColumns = () => {
    return [
      { title: 'ID', field: 'id', searchable: false, hidden: true },
      { title: 'Nombre', field: 'company_name', filtering: false },
      { title: 'País', field: 'country', filtering: false },
      {
        title: 'Período Facturación',
        field: 'facturation_date',
        filtering: false,
      },
      {
        title: 'Solicitante',
        field: 'creator',
        filtering: false,
        render: (rowData) =>
          comment(rowData.comment, 'Comentario', rowData.creator),
      },

      { title: 'Usuarios LM', field: 'last_mile', filtering: false },
      { title: 'Usuarios PP', field: 'planner_pro', filtering: false },
      {
        title: 'Monto Total',
        field: 'total_amount',
        filtering: false,
        render: (rowData) => '$ ' + rowData.total_amount.toLocaleString(),
      },
    ];
  };

  const comment = (comment, title, creator) => {
    return (
      <Tooltip size="large" position="bottom" title={title} content={comment}>
        <span> {creator} </span>
      </Tooltip>
    );
  };

  const setActions = () => {
    if (havePermissions(['approver']) && tableType === 'pending') {
      return [
        {
          icon: () => <CheckCircle style={{ color: 'green' }} />,
          tooltip: 'Aprobar Comentario',
          onClick: (_, rowData) =>
            setModalAccept({ open: true, id: rowData.draft_comment_id }),
        },
        {
          icon: () => <Cancel style={{ color: 'red' }} />,
          tooltip: 'Rechazar Comentario',
          onClick: (_, rowData) =>
            setModalReject({ open: true, id: rowData.draft_comment_id }),
        },
        {
          icon: () => <Visibility />,
          tooltip: 'Visualizar Borrador',
          onClick: (_, rowData) => setModalDraft({ open: true, data: rowData }),
        },
      ];
    } else if (tableType === 'other') {
      return [
        {
          icon: () => <Visibility />,
          tooltip: 'Visualizar Borrador',
          onClick: (_, rowData) => setModalDraft({ open: true, data: rowData }),
        },
      ];
    } else {
      return [];
    }
  };

  return (
    <div>
      {comment()}
      {dataTable.length !== 0 && (
        <MaterialTable
          title={'Comentarios a Borradores'}
          pageSize={20}
          pageSizeOptions={[20, 30, 50]}
          columns={setColumns()}
          onRowClick={(evt, selectedRow) =>
            setSelectedRow(selectedRow.tableData.id)
          }
          data={dataTable}
          detailPanel={[
            {
              tooltip: "Comentario",
              render: rowData => {
                if (rowData.comment_status !== 'commented') {
                  return null;
                }
          
                let status = '';
                if (rowData.comment_approval_status === 'pending') {
                  status = 'info';
                } else if (rowData.comment_approval_status === 'approved') {
                  status = 'success';
                } else if (rowData.comment_approval_status === 'rejected') {
                  status = 'error';
                }
          
                return (
                  <InfoBox status={status}>
                    <div>
                      Comentario hecho por <strong>{rowData.creator.toLocaleString()}</strong> ✍️➡️ {rowData.comment.toLocaleString()}
                    </div>
                    {status == 'error' && (
                      <div>
                        <strong>Motivo rechazo</strong> 📝🚫 {rowData.comment_reject_reason.toLocaleString()}
                      </div>
                    )}
                  </InfoBox>
                );
              }
            }
          ]}
          actions={setActions()}
          options={{
            actionsColumnIndex: -1,
            filtering: true,
            rowStyle: (rowData) => ({
              backgroundColor: selectedRow === rowData.id ? '#EEE' : '#FFF',
            }),
            pageSize: 20,
            pageSizeOptions: [20, 30, 50],
          }}
          localization={{
            body: {
              emptyDataSourceMessage: 'No hay documentos por resolver',
            },
            header: {
              actions: 'Acciones',
            },
          }}
        />
      )}
      {dataTable.length === 0 && tableType === 'pending' && (
        <SuccessState text="Todos los comentarios han sido resueltos" />
      )}
      {dataTable.length === 0 && tableType === 'other' && (
        <NoDataState text="No hay comentarios para mostrar" />
      )}
    </div>
  );
};

export default CommentsTable;
