import React, { useEffect, useState } from 'react';
import './styles.scss';
import { Paper } from '@material-ui/core';
import { get, destroy } from '../../../utils/api';
import {
  Accordion,
  AccordionItem,
  Button,
  Chip,
  ConfirmationModal,
} from '@beetrack/hp-components';
import {
  activePrepaid,
  expirationRangeActive,
} from '../../../utils/commercialConditions/activePrepaid';
import ConditionShow from './Conditions/ConditionShow';
import { useHistory } from 'react-router-dom';

import { havePermissions } from '../../../utils/utils';

export default (props) => {
  const history = useHistory();
  const [plans, setPlans] = useState([]);
  const [selected, setSelected] = useState(0);
  const [deletedRelations, setDeletedRelations] = useState({});
  const [open, setOpen] = useState(null);

  useEffect(() => {
    retrieveData();
  }, []);

  const retrieveData = async () => {
    let response = await get(
      `/commercial_conditions/plan_relations?hubspot_id=${props.hubspot_id}&approved=true`,
    );
    if (response.status === 200) {
      setPlans(response.data.data.plan_relations.nodes);
      var deletedState = {};
      for (const planRelation of response.data.data.plan_relations.nodes) {
        deletedState[planRelation.id] = false;
      }
      setDeletedRelations(deletedState);
    }
  };

  const conditionEdit = (hubspotId, planId) => {
    history.push(`edit-condition/${hubspotId}/${planId}`);
  };

  const conditionDelete = (relationId) => {
    setOpen(false);
    destroy(`/commercial_conditions/plan_relations/${relationId}`).then(
      (response) => {
        if (response.status === 200) {
          deletedRelations[relationId] = true;
          setDeletedRelations({ ...deletedRelations });
        }
      },
    );
  };

  const conditionNameParser = (plan) => {
    return (
      <div className="hp-page__header-title">
        Condiciones Comerciales {plan.plan.name}{' '}
        {activePrepaid(plan.plan.prepaids) ? <Chip text="Prepago" /> : <></>}{' '}
        {expirationRangeActive(plan.plan.prepaids) ? (
          <Chip text="Prepago por vencer" type="danger" />
        ) : (
          <></>
        )}
      </div>
    );
  };

  const editParser = (plan) => {
    const permissions =
      plan.hubspot_id &&
      plan.plan.product !== 'email' &&
      plan.plan.product !== 'whatsapp' &&
      havePermissions(['admin_sales_man', 'approver']);
    return [
      <Button
        key={`close-${plan.id}`}
        type="tertiary"
        flat
        onClick={(e) => setSelected(0)}
      >
        Cerrar
      </Button>,
      permissions ? (
        <Button
          key={`edit-${plan.id}`}
          type="secondary"
          flat
          onClick={() => conditionEdit(plan.hubspot_id, plan.plan.id)}
        >
          Editar condición
        </Button>
      ) : (
        <></>
      ),
      <>
        <Button
          key={`delete-${plan.id}`}
          type="danger"
          flat
          onClick={() => setOpen(plan.id)}
        >
          Eliminar condición
        </Button>
      </>,
    ];
  };

  return (
    <div>
      {plans === [] ? (
        <Paper>Cargando...</Paper>
      ) : (
        <Accordion>
          {plans.map((plan) => (
            <div key={plan.id}>
              {deletedRelations[plan.id] ? (
                <></>
              ) : (
                <>
                  <AccordionItem
                    launcher={conditionNameParser(plan)}
                    selected={selected === plan.plan.id}
                    id={plan.plan.id}
                    setSelected={(e) => setSelected(e)}
                    buttons={editParser(plan)}
                  >
                    <ConditionShow
                      plan={plan.plan}
                      hubspotId={plan.hubspot_id}
                    />
                  </AccordionItem>
                  <br></br>

                  <ConfirmationModal
                    key={`custom-field-destroy-${plan.id}`}
                    title={`Eliminar ${plan.plan.name}`}
                    open={open === plan.id}
                    type="destroy"
                    onClose={() => setOpen(null)}
                    confirmButton={
                      <Button
                        type="danger"
                        onClick={() => conditionDelete(plan.id)}
                      >
                        Eliminar
                      </Button>
                    }
                  >
                    La acción no será reversible
                  </ConfirmationModal>
                </>
              )}
            </div>
          ))}
        </Accordion>
      )}
    </div>
  );
};
