import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { clientSpeedDial, styles } from './styles';
import { get } from '../../utils/api';
import MaterialTable from 'material-table';
import {
  havePermissions,
  hubspotTimestampToDate,
  capitalize,
  capitalizeFirst,
  lowerizeValues,
  userData,
} from '../../utils/utils';
import { defaultDealStages, pilotTypes } from '../../utils/const';
import { CheckCircle, Cancel } from '@material-ui/icons';
import WarningMessage from '../../shared/WarningMessage';
import stringSimilarity from 'string-similarity';
import { InfoBox } from '@beetrack/hp-components';
import moment from 'moment';

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

function mapperSimilarity(stringToMatch, availableMatchersDict) {
  var matches = stringSimilarity.findBestMatch(
    stringToMatch,
    Object.values(availableMatchersDict),
  );
  var key = getKeyByValue(availableMatchersDict, matches.bestMatch.target);
  return key;
}

class Prospector extends Component {
  constructor(props) {
    super(props);
    const userInfo = userData();

    this.state = {
      totalComissionsProspector: '$0 USD',
      totalComissionsClientsProspector: 0,
      clients: null,
      error: {},
      filter: [],
      owners: null,
      stages: defaultDealStages,
      columns: [
        {
          title: 'Name',
          field: 'name',
          render: (rowData) => (
            <a href={'/client/' + rowData.deal_id}>{rowData.name}</a>
          ),
        },
        { title: 'Monto original', field: 'original_amount', filtering: false },
        {
          title: 'Comisión vendedor',
          field: 'salesman_commission',
          filtering: false,
        },
        {
          title: 'Comisión prospectador',
          field: 'prospector_commission',
          filtering: false,
        },
        {
          title: 'LM últimos 7 días',
          field: 'has_lm',
          filtering: false,
        },
        {
          title: 'PP últimos 7 días',
          field: 'has_pp',
          filtering: false,
        },
        {
          title: 'Fecha cierre deal',
          field: 'closedate',
          filtering: false,
        },
        {
          title: 'Prospectador',
          field: 'prospectador',
          defaultFilter: userInfo.user.email,
        },
        {
          title: 'Vendedor',
          field: 'hubspot_owner_id',
        },
        { title: 'Lugar del stage pipeline', field: 'dealstage' },
      ],
      routes: {},
      truck_drivers: {},
      speedDialOpen: false,
    };
  }

  speedDialStatus = (open) => {
    this.setState({ speedDialOpen: !open });
  };

  handleSpeedDialClick = (identifier) => {
    this.props.history.push(identifier);
  };

  filterActions = () => {
    return havePermissions(['client_admin']) ? clientSpeedDial : null;
  };

  rowParserMoney = (commissions) => {
    // this will return key, value pairs
    const text = Object.entries(commissions)
      .map(([key, value]) => `$${value} ${key}`)
      .join(`, `);
    return text === '' ? '-' : text;
  };

  rowParserProducts = (product, comparison, usage) => {
    if (product === null || product === '') {
      return <Cancel style={{ color: 'red' }} />;
    }

    return product.includes(comparison) ? (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <CheckCircle style={{ color: 'green' }} />
        <span>{usage}</span>
      </div>
    ) : (
      <Cancel style={{ color: 'red' }} />
    );
  };

  rowParser = (deal) => {
    return {
      deal_id: deal.id,
      name: deal.properties.dealname,
      dealstage: capitalize(this.state.stages[deal.properties.dealstage]),
      original_amount: this.rowParserMoney(deal.properties.original_amount),
      salesman_commission: this.rowParserMoney(
        deal.properties.salesman_commission,
      ),
      prospector_commission: this.rowParserMoney(
        deal.properties.prospector_commission,
      ),
      tipo_de_piloto:
        deal.properties.tipo_de_piloto === null
          ? '-'
          : deal.properties.tipo_de_piloto,
      prospectador: this.state.owners[deal.properties.prospectador],
      hubspot_owner_id:
        deal.properties.hubspot_owner_id === ''
          ? '-'
          : this.state.owners[deal.properties.hubspot_owner_id],
      has_lm: this.rowParserProducts(
        deal.properties.que_contrata_,
        'LastMile',
        deal.properties.users_lm_last_period,
      ),
      has_pp: this.rowParserProducts(
        deal.properties.que_contrata_,
        'PlannerPro',
        deal.properties.planner_usage_last_period,
      ),
      closedate:
        moment(deal.properties.closedate).format('DD/MM/YYYY') ===
        'Invalid date'
          ? 'No cerrado'
          : moment(deal.properties.closedate).format('DD/MM/YYYY'),
    };
  };
  processDealsRows = (deals) => {
    const dealRows = [];
    deals.forEach((deal) => {
      dealRows.push(this.rowParser(deal));
    });
    return dealRows;
  };
  processFilterToUrl = (query) => {
    var paired = query.filters.map(
      (filter) => `${filter.column.field}=${filter.value}`,
    );
    return paired.join('&');
  };

  processFilters = (query) => {
    const alteredQuery = JSON.parse(JSON.stringify(query));
    alteredQuery.filters.forEach((filter) => {
      if (filter.column.field === 'dealstage') {
        filter.value = mapperSimilarity(
          filter.value.toLowerCase(),
          this.state.stages,
        );
      }
      if (filter.column.field === 'prospectador') {
        filter.value = mapperSimilarity(
          filter.value.toLowerCase(),
          this.state.owners,
        );
      }
      if (filter.column.field === 'hubspot_owner_id') {
        filter.value = mapperSimilarity(
          filter.value.toLowerCase(),
          this.state.owners,
        );
      }
      if (filter.column.field === 'tipo_de_piloto') {
        let key = mapperSimilarity(capitalizeFirst(filter.value), pilotTypes);
        filter.value = pilotTypes[key];
      }
    });
    return alteredQuery;
  };

  queryRemoteData = (query) => {
    return new Promise((resolve, reject) => {
      const additionalQueryParams = this.processFilterToUrl(
        this.processFilters(query),
      );
      var prospectorGetUrl = `/prospectors/deals?page=${query.page}&per=${query.pageSize}`;
      prospectorGetUrl =
        additionalQueryParams.length === 0
          ? prospectorGetUrl
          : `${prospectorGetUrl}&${additionalQueryParams}`;
      get(prospectorGetUrl).then((response) => {
        resolve({
          data: this.processDealsRows(response.data.deals.results),
          page: query.page,
          totalCount: response.data.deals.total,
        });
      });
    });
  };

  componentWillMount() {
    get('/prospectors').then((response) => {
      if (response.status === 200) {
        // set the data of owners and stages which is fixed
        this.setState({
          owners: lowerizeValues(response.data.owners),
          stages: lowerizeValues(this.state.stages),
        });
        const userInfo = userData();
        const prospectorId = mapperSimilarity(
          userInfo.user.email,
          lowerizeValues(response.data.owners),
        );
        get(`/prospectors/own_commissions?prospectador=${prospectorId}`).then(
          (response) => {
            if (response.status === 200) {
              this.setState({
                totalComissionsProspector: this.rowParserMoney(
                  response.data.total_prospector_amount,
                ),
                totalComissionsClientsProspector:
                  response.data.total_prospector_deals,
              });
            }
          },
        );
      }
    });
  }

  render() {
    const { classes } = this.props;
    const { error } = this.state;

    return (
      <div className={classes.wrapper}>
        <p>
          La comisión total a recibir es aproximadamente de{' '}
          <b>{this.state.totalComissionsProspector}</b>, con{' '}
          <b>{this.state.totalComissionsClientsProspector}</b> clientes
          comisionables.
        </p>

        {error.hubspot === true ? (
          <WarningMessage
            message={
              'Para poder ingresar debes de vincular tu cuenta de Hubspot.'
            }
          />
        ) : (
          <div>
            <div>
              {this.state.owners !== null && (
                <MaterialTable
                  title="Comisiones de prospectadores"
                  columns={this.state.columns}
                  data={(query) => this.queryRemoteData(query)}
                  options={{
                    filtering: true,
                    search: false,
                    sorting: false,
                    paging: true,
                    pageSize: 15, // make initial page size
                    emptyRowsWhenPaging: true, //to make page size fix in case of less data rows
                    pageSizeOptions: [5, 15, 25], // rows selection options
                  }}
                />
              )}
            </div>
            <InfoBox status="info">
              Los valores entregados son meramente referenciales y pueden variar
              en la realidad.
            </InfoBox>
          </div>
        )}
      </div>
    );
  }
}
export default withStyles(styles)(Prospector);
