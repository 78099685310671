import React, { Component } from 'react';
import {Form, FormRow, TextField, Button, Snackbar} from '@beetrack/hp-components'
import { Link } from 'react-router-dom';
import { loginUrl } from '../../utils/const';
import { forgot_password } from '../../utils/api.js';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      message: null,
      snackbarState: false,
      status: 'error'
    }
  }

  setValue = (e, key) => {
    this.setState({[key]: e.target.value})
  }

  handleClickSendEmail = async () => {
    let result = await forgot_password(this.state.email);
    if (result.status === 200) {
      this.setState({
        status: 'success',
        message: 'Se ha enviado un correo con las instrucciones para resetear la constraseña',
        snackbarState: true,
        email: ''
      });
    } else {
      this.setState({
        status: 'error',
        message: result.data.errors,
        snackbarState: true
      });
    }
  }

  setOpen = (open) =>{
    this.setState({snackbarState: open})
  }

  render () {
    let { classes } = this.props;
    return (
      <div className={'form-container'}>
        <Snackbar
          key='snackbar'
          isOpen={this.state.snackbarState}
          message={this.state.message}
          onDismiss={() => this.setOpen(false)}
          status={this.state.status}
        />
        <div className={'row'}>
          <div className={'column'}>
            <div className={'logo-position'}>
              <img src={'https://beetrack-hive.s3-us-west-2.amazonaws.com/hive/hive_logo_big.png'} width={'390px'} />
            </div>
          </div>
          <div className={'column'}>
            <div className={'form-position'}>
              <Form
                title='Beetrack Hive - Recuperar contraseña'
              >
                <FormRow>
                  <TextField
                    value={this.state.email}
                    onChange={(e) => this.setValue(e, 'email')}
                    label='Email'
                    onKeyPress={ event => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                        this.handleClickSendEmail();
                      }
                    }}
                  />
                </FormRow>
                <FormRow>
                  <Button
                    type='primary'
                    className={'centered'}
                    onClick={this.handleClickSendEmail}>
                    Enviar Correo
                  </Button>
                </FormRow>
                <div className={'forgot-password'}>
                  <Link to={ loginUrl }>
                    <span>Iniciar sesión</span>
                  </Link>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ForgotPassword;
