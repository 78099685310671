import React, { useState, useEffect } from 'react';
import {
  Button,
  Select,
  Form,
  FormRow,
  TextField,
  Loading,
  Snackbar,
} from '@beetrack/hp-components';
import { get, put } from '../../../../../../utils/api';

const InventoryEdit = (props) => {
  const { history } = props;
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState('');
  const [objectType, setObjectType] = useState('');
  const [brand, setBrand] = useState('');
  const [model, setModel] = useState('');
  const [description, setDescription] = useState('');
  const [identifier, setIdentifier] = useState('');
  const [beetracker, setBeetracker] = useState('');
  const [snack, setSnack] = useState({
    open: false,
    status: 'error',
    message: '',
  });
  const [request, setRequest] = useState(false);

  const id = props.match.params.id;

  const updateResource = () => {
    let payload = {
      userId: beetracker,
      resourceType: 'item',
      name,
      brand,
      model,
      description,
      identifier,
      objectType,
    };
    put(`/resources/${id}`, payload).then((response) => {
      if (response.status === 200) {
        history.push('/hr/resources/inventory');
      } else {
        setSnack({
          open: true,
          status: 'error',
          message: 'Problemas actualizando inventario',
        });
      }
    });
  };

  useEffect(() => {
    get(`/resources/inventory/${id}`).then((response) => {
      if (response.status == 200) {
        let result = [];
        response.data.users.data.forEach((element) => {
          result.push({
            value: element.attributes.id,
            label: element.attributes.name,
          });
        });
        setUsers(result);
        setName(response.data.item.name);
        setObjectType(response.data.item.object_type);
        setBrand(response.data.item.brand);
        setModel(response.data.item.model);
        setIdentifier(response.data.item.identifier);
        setBeetracker(response.data.item.user_id);
        setDescription(response.data.item.description);
        setLoading(false);
      }
    });
  }, []);
  3;
  return (
    <div>
      {loading && (
        <center>
          <Loading />
        </center>
      )}
      {!loading && (
        <Form
          title={name}
          backTo="/hr/resources/inventory"
          buttons={[
            <Button
              key="not-submit"
              type="tertiary"
              disabled={request}
              onClick={() => history.push('/hr/resources/inventory')}
            >
              Cancelar
            </Button>,
            <Button
              key="submit"
              form="my-form"
              disabled={request}
              onClick={() => updateResource()}
            >
              Actualizar
            </Button>,
          ]}
        >
          <FormRow additionalClassName="grid">
            <TextField
              value={name}
              label="Nombre"
              onChange={(e) => setName(e.target.value)}
              additionalClassName="grid__item-6"
            />
          </FormRow>
          <FormRow additionalClassName="grid">
            <TextField
              value={brand}
              label="Marca"
              onChange={(e) => setBrand(e.target.value)}
              additionalClassName="grid__item-6"
            />
            <TextField
              value={model}
              label="Modelo"
              onChange={(e) => setModel(e.target.value)}
              additionalClassName="grid__item-6"
            />
          </FormRow>
          <FormRow additionalClassName="grid">
            <TextField
              value={identifier}
              label="Identificador"
              onChange={(e) => setIdentifier(e.target.value)}
              additionalClassName="grid__item-3"
            />
            <Select
              label="Beetracker asignado"
              value={beetracker}
              options={users}
              additionalClassName="grid__item-9"
              onChange={(option) => setBeetracker(option.value)}
            />
          </FormRow>
          <FormRow additionalClassName="grid">
            <TextField
              label="Descripción"
              textArea
              onChange={(e) => setDescription(e.target.value)}
              type="text"
              helperText="Descripción del inventario"
              value={description}
            />
          </FormRow>
        </Form>
      )}
    </div>
  );
};

export default InventoryEdit;
