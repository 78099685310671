import React, { useEffect, useState } from 'react';
import { Button, Form, FormGroup, TextField } from '@beetrack/hp-components';
import { Grid } from '@mui/material';
import TopBanner from '../../shared/TopBanner/TopBanner';
import { sortKeys } from './utils';
import { useFilePicker } from '@beetrack/hp-file-picker-react';

import TextElementTranslator from './TranslatorComponents/TextElement';
import BigTextElementTranslator from './TranslatorComponents/BigTextElement';
import NumberElementTranslator from './TranslatorComponents/NumberElement';
import DateElementTranslator from './TranslatorComponents/DateElement';
import SimpleElementTranslator from './TranslatorComponents/SimpleElement';
import MultipleElementTranslator from './TranslatorComponents/MultipleElement';
import DependantElementTranslator from './TranslatorComponents/DependantElement';
import FileElementTranslator from './TranslatorComponents/FileElement';
import { getNestedValue } from './utils';

const FormTranslator = (props) => {
  const {
    disableSubmit,
    form,
    formValues,
    setFormValues,
    classes,
    handleForm,
    setAlertOpen,
    setStatus,
    setErrorMessage,
  } = props;

  const [isDisabled, setIsDisabled] = useState(true);

  const { validateFileExtension } = useFilePicker();

  const textChangeHandler = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const numberChangeHandler = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value.replace(/[^0-9]/, ''),
    });
  };

  const simpleChangeHandler = (e, propertyName) => {
    setFormValues({
      ...formValues,
      [propertyName]: e.value,
    });
  };

  const checkDisabled = (form, formValues) => {
    if (
      form.content.email?.required &&
      (formValues[`${form.content.email.propertyName}`] === '' ||
        formValues[`${form.content.email.propertyName}`] === undefined)
    ) {
      return true;
    }

    const GKEYS = sortKeys(form.content);
    for (let i = 0; i < GKEYS.length; i++) {
      const GKEY = GKEYS[i];
      const EKEYS = sortKeys(form.content?.[GKEY]);
      for (let j = 0; j < EKEYS.length; j++) {
        const EKEY = EKEYS[j];
        if (
          form.content[GKEY][EKEY].required &&
          (formValues[`${form.content[GKEY][EKEY].propertyName}`] === '' ||
            formValues[`${form.content[GKEY][EKEY].propertyName}`] ===
              undefined)
        ) {
          return true;
        }

        if (
          form.content[GKEY][EKEY]['type'] === 'dependant' &&
          formValues[`${form.content[GKEY][EKEY].propertyName}`] !== '' &&
          formValues[`${form.content[GKEY][EKEY].propertyName}`] !== undefined
        ) {
          if (checkDisabledDependant(form, [GKEY, EKEY], formValues)) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const checkDisabledDependant = (form, path, formValues) => {
    let globalOption = '';

    for (const option in getNestedValue(form.content, path).options) {
      if (
        getNestedValue(form.content, path).options[option].value ===
        formValues[`${getNestedValue(form.content, path).propertyName}`]
      ) {
        globalOption = option;
        break;
      }
    }

    getNestedValue(form.content, [...path, 'options', globalOption, 'set']);
    const EKEYS = sortKeys(
      getNestedValue(form.content, [...path, 'options', globalOption, 'set']),
    );
    for (let k = 0; k < EKEYS.length; k++) {
      const EKEY = EKEYS[k];
      const helperElement = getNestedValue(form.content, [
        ...path,
        'options',
        globalOption,
        'set',
        EKEY,
      ]);
      if (helperElement.type === 'dependant') {
        return checkDisabledDependant(
          form.content,
          [...path, 'options', globalOption, 'set', EKEY],
          formValues,
        );
      } else {
        if (
          helperElement.required &&
          (formValues[`${helperElement.propertyName}`] === '' ||
            formValues[`${helperElement.propertyName}`] === undefined)
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const fileChangeHandler = (event, fileName, extentions) => {
    const { name, size } = event[0];

    if (!validateFileExtension(name, extentions)) {
      setAlertOpen(true);
      setStatus('alert');
      setErrorMessage('Formato de archivo no aceptado');
      return;
    }

    setFormValues({
      ...formValues,
      [`FILE-${fileName}`]: event[0],
    });
  };

  useEffect(() => {
    setIsDisabled(checkDisabled(form, formValues));
  }, [formValues]);

  return (
    <div>
      {!disableSubmit && (
        <div className={classes.bannerContainer}>
          <TopBanner />
          <div className={classes.title}>
            <div className={classes.beetrack}>
              <h1>Bienvenid@ a DispatchTrack!</h1>
            </div>
            <div className={classes.description}>
              <p>{form.description}</p>
            </div>
          </div>
        </div>
      )}
      <Form
        title={form.title}
        buttons={
          !disableSubmit && [
            <Button key="submit" form="my-form" disabled={isDisabled}>
              Enviar Respuesta
            </Button>,
          ]
        }
        id="my-form"
        onSubmit={handleForm}
      >
        <div className={classes.content}>
          {form.content.email?.required && (
            <div className={classes.fillerEmail}>
              <TextField
                label={`Email`}
                name={form.content.email.propertyName}
                helperText={`Ingrese el correo de quien rellena el formulario`}
                placeholder={`ejemplo@dispatchtrack.com`}
                value={formValues[`${form.content.email.propertyName}`]}
                onChange={textChangeHandler}
                required={form.content.email?.required}
              />
            </div>
          )}
          {sortKeys(form.content).map((Gkey) => {
            return (
              <div key={Gkey} className={classes.formContainer}>
                <FormGroup title={form.content[Gkey].title} collapsible={false}>
                  <Grid container className={classes.groupContainer}>
                    {sortKeys(form.content[Gkey]).map((Ekey) => {
                      if (form.content[Gkey][Ekey]['type'] === 'text') {
                        return (
                          <TextElementTranslator
                            classes={classes}
                            form={form}
                            formValues={formValues}
                            path={[Gkey, Ekey]}
                            textChangeHandler={textChangeHandler}
                          />
                        );
                      } else if (
                        form.content[Gkey][Ekey]['type'] === 'bigText'
                      ) {
                        return (
                          <BigTextElementTranslator
                            classes={classes}
                            form={form}
                            formValues={formValues}
                            path={[Gkey, Ekey]}
                            textChangeHandler={textChangeHandler}
                          />
                        );
                      } else if (
                        form.content[Gkey][Ekey]['type'] === 'number'
                      ) {
                        return (
                          <NumberElementTranslator
                            classes={classes}
                            form={form}
                            formValues={formValues}
                            path={[Gkey, Ekey]}
                            numberChangeHandler={numberChangeHandler}
                          />
                        );
                      } else if (form.content[Gkey][Ekey]['type'] === 'date') {
                        return (
                          <DateElementTranslator
                            classes={classes}
                            form={form}
                            formValues={formValues}
                            setFormValues={setFormValues}
                            path={[Gkey, Ekey]}
                          />
                        );
                      } else if (
                        form.content[Gkey][Ekey]['type'] === 'simple'
                      ) {
                        return (
                          <SimpleElementTranslator
                            classes={classes}
                            form={form}
                            formValues={formValues}
                            path={[Gkey, Ekey]}
                            simpleChangeHandler={simpleChangeHandler}
                          />
                        );
                      } else if (
                        form.content[Gkey][Ekey]['type'] === 'multiple'
                      ) {
                        return (
                          <MultipleElementTranslator
                            classes={classes}
                            form={form}
                            formValues={formValues}
                            setFormValues={setFormValues}
                            path={[Gkey, Ekey]}
                            simpleChangeHandler={simpleChangeHandler}
                          />
                        );
                      } else if (
                        form.content[Gkey][Ekey]['type'] === 'dependant'
                      ) {
                        return (
                          <DependantElementTranslator
                            classes={classes}
                            form={form}
                            formValues={formValues}
                            setFormValues={setFormValues}
                            path={[Gkey, Ekey]}
                            simpleChangeHandler={simpleChangeHandler}
                            textChangeHandler={textChangeHandler}
                            numberChangeHandler={numberChangeHandler}
                            fileChangeHandler={fileChangeHandler}
                          />
                        );
                      } else if (form.content[Gkey][Ekey]['type'] === 'file') {
                        return (
                          <FileElementTranslator
                            classes={classes}
                            form={form}
                            formValues={formValues}
                            setFormValues={setFormValues}
                            path={[Gkey, Ekey]}
                            fileChangeHandler={fileChangeHandler}
                          />
                        );
                      }
                    })}
                  </Grid>
                </FormGroup>
              </div>
            );
          })}
        </div>
      </Form>
    </div>
  );
};

export default FormTranslator;
