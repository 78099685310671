import React from 'react';
import { FormRow, FormGroup, TextField } from '@beetrack/hp-components';
import { MAX_VARIABLE_NUMBER } from '../../../../../utils/const';
import '../../../styles.scss';
import NumberFormat from 'react-number-format';

const _generateVariable = (
  index,
  idx,
  variableCondition,
  setter,
  currency,
  readOnly = false,
) => {
  return (
    <FormRow key={`fr-variable-${index}-${idx}`} additionalClassName="grid">
      <TextField
        additionalClassName="grid__item-4 custom-disabled"
        placeholder="Desde"
        label="Desde"
        value={variableCondition.from}
        disabled
      />
      <TextField
        additionalClassName="grid__item-4 custom-disabled"
        placeholder="Hasta"
        label="Hasta"
        value={
          variableCondition.to === parseFloat(MAX_VARIABLE_NUMBER)
            ? ''
            : variableCondition.to
        }
        disabled
      />
      <NumberFormat
        customInput={TextField}
        value={variableCondition.price}
        onValueChange={(e) => setter(e.floatValue, idx, index)}
        prefix={`${currency} `}
        suffix={` / un.`}
        type="text"
        allowNegative={false}
        isNumericString={true}
        decimalSeparator={','}
        thousandSeparator={'.'}
        readOnly={readOnly}
      />
    </FormRow>
  );
};

export function generateVariables(plan, idx, setter, readOnly = false) {
  if (plan.condition.variable === undefined) {
    return <></>;
  }

  return (
    <FormGroup title="Tramos" collapsible={false}>
      <div>
        {plan.condition.variable.map((variable, index) => {
          return _generateVariable(
            index,
            idx,
            variable,
            setter,
            plan.currency,
            readOnly,
          );
        })}
      </div>
    </FormGroup>
  );
}
