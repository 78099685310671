import React from 'react';
import { FormRow, FormGroup, TextField } from '@beetrack/hp-components';
import '../../../styles.scss';

export function generateStep(plan, idx, setter, readOnly = false) {
  if (plan.condition.step === undefined) {
    return <></>;
  }

  return (
    <FormGroup title="Paquete" collapsible={false}>
      <div>
        <FormRow additionalClassName="grid">
          <TextField
            additionalClassName="grid__item-5 custom-disabled"
            placeholder="Tamaño del paquete"
            label="Tamaño del paquete"
            type="number"
            value={plan.condition.step.step}
            readOnly={readOnly}
            fullWidth
          />
          <TextField
            additionalClassName="grid__item-5 custom-disabled"
            placeholder="Cantidad incluida en el paquete (gratis)"
            label="Cantidad incluida en el paquete (gratis)"
            type="number"
            value={plan.condition.step.free_amount}
            readOnly={readOnly}
            fullWidth
          />
          <TextField
            additionalClassName="grid__item-6"
            placeholder={`Precio de paquete en "${plan.currency}"`}
            label={`Precio de paquete en "${plan.currency}"`}
            type="number"
            value={plan.condition.step.price}
            readOnly={readOnly}
            fullWidth
          />
        </FormRow>
      </div>
    </FormGroup>
  );
}
