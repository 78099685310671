import React, { Component } from 'react'
import { Card, CardContent, Tabs, TabItem, DatePicker, Snackbar, Checkbox, Tooltip} from '@beetrack/hp-components'
import './styles.scss'
import DebtorsTable from './DebtorsTable'
import { get, put } from '../../../utils/api.js'

class SalesCollection extends Component {

  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {name: 'client', title:'Cliente', valueByAttribute:'client_name'},
        {name: 'unpaid_invoices', title:'F. Impagas', valueByAttribute:'unpaid_invoices'},
        {name: 'due_invoices', title:'F. Vencidas',valueByAttribute:'due_invoices'},
        {name: 'hubspot_owner', title:'Hubspot Owner',valueByAttribute:'hubspot_owner_email'},
        {name: 'contacted', title:'Contactado',valueByFunc:this.contactedCheckbox}
      ],
      data: [],
      hasData:true,
      loading: true,
      date: this.formatDate(new Date()),
      country: 'CL',
      snackbarOpen: false
    }
  }

  componentDidMount() {
    this.getDebtors(this.state.date, this.state.country)
  }

  formatDate = (date) =>{
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let day = date.getDate()

    if(month < 10){
      month = `0${month}`
    }
    return(`${year}-${month}-${day}`)
  }

  handleCheckboxChange = (e) => {
    e.client_contacted = true
    put(`/sales/debtors/${e.id}`, e)
        .then((response) => {
          if (response.status === 200){
            this.getDebtors(this.state.date, this.state.country)
          }
        })
  }

  contactedCheckbox = (e) => {
    if(e.client_contacted === true){
      return (
          <Tooltip
            position='top'
            title={e.contacted_by_email}
            delay='100'
          >
            <Checkbox
                checked disabled
            />
          </Tooltip>
      )
    }
      return (
          <Checkbox
              onChange={this.handleCheckboxChange.bind(this, e)}
          />)
  }

  onDateChange = (date) => {
    this.setState({date: date})
    this.getDebtors(date, this.state.country)
  }

  handleChange = (value)=>{
    let country;
    if (value.toIndex === 0) {
      this.setState({country: 'CL'})
      country = 'CL'
    } else if (value.toIndex === 1) {
      this.setState({country: 'MX'})
      country = 'MX'
    }
    this.getDebtors(this.state.date, country)
  }

  getDebtors = (date, country) => {
    get(`/sales/debtors?date=${date}&country=${country}`)
        .then((response) => {
          if(response.status === 200){
            if (response.data.message != null) {
              var data = [];
              response.data.message.map((item, i) => {
                data.push(
                  {
                    client_name: item.client_name,
                    unpaid_invoices: item.unpaid_invoices,
                    due_invoices: item.due_invoices,
                    hubspot_owner_email: item.hubspot_owner_email,
                    client_contacted: item.client_contacted,
                    contacted_by_email: item.contacted_by_email,
                    id: item.id
                  }
                )
              })
              this.setState({data: data, loading: false, hasData:true})
            }
            else{
              this.setState({hasData:false})
              // Here should set NoDataState Honeypot component
            }
          } else {
            this.setState({
              snackbarOpen: true,
              hasData: false,
              loading: false
            })
          }
    })
  }


  render() {
    let { classes } = this.props;
    return(
      <div className='main-card'>
        <Card>
          <CardContent>
            <h3>Resumen de Cobranza Automática</h3>
            <div className='date-picker'>
              <DatePicker
                inputLabel='Fecha'
                date={this.state.date}
                onChange={(value) => {
                  this.onDateChange(value)
                }}
                locale='es'
              />
            </div>
            <div className='tabs-container'>
              <Tabs defaultActiveTab={0} onChange={this.handleChange.bind(this)}>
                <TabItem label='Chile'>
                  <DebtorsTable
                      columns={this.state.columns}
                      data={this.state.data}
                      loading={this.state.loading}
                  />
                </TabItem>
                <TabItem label='Mexico'>
                  <DebtorsTable
                      columns={this.state.columns}
                      data={this.state.data}
                      loading={this.state.loading}
                  />
                </TabItem>
              </Tabs>
            </div>
          </CardContent>
        </Card>
        <Snackbar
          message='Error al obtener los datos'
          status='error'
          isOpen={this.state.snackbarOpen}
          onDismiss={() => this.setState({snackbarOpen: false})}
          />
      </div>
    )
  }
}

export default SalesCollection
