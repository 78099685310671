import decode from 'jwt-decode';
import moment from 'moment';

export function lowerizeValues(object) {
  Object.keys(object).forEach((key) => {
    if (object[key] !== null) {
      object[key] = object[key].toLowerCase();
    }
  });
  return object;
}

export function capitalize(str) {
  return str === undefined
    ? ''
    : str.replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
}

export function capitalizeFirst(str) {
  return str && str[0].toUpperCase() + str.slice(1).toLowerCase();
}

export function IsAdmin() {
  let scopes = userData().scopes;
  return scopes.indexOf('super') >= 0 ? true : false;
}

export function userData() {
  return decode(localStorage.getItem('jwt_token'));
}

export function havePermissions(permissions) {
  let scopes = userData().scopes;
  if (IsAdmin()) {
    return true;
  } else {
    return scopes.some((scope) => {
      return permissions.includes(scope);
    });
  }
}

export function hubspotTimestampToDate(stringDate) {
  if (stringDate === '' || stringDate === null) {
    return '-';
  }
  const timestamp = Date.parse(stringDate);
  return timestamp !== NaN
    ? new Date(timestamp).toLocaleDateString('es-ES', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    : '-';
}

export function arraysToHash(arrays) {
  if (Array.isArray(arrays)) {
    return arrays.reduce(function (hash, array) {
      hash[array[0]] = array[1];
      return hash;
    }, {});
  } else if (typeof arrays === 'object') {
    return arrays;
  } else {
    return {};
  }
}

export function formatCurrency(num) {
  num = num.toString().replace('.', ',');
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}

export function filterJsonByElement(json, filter, value, nestedLevel = 0) {
  nestedLevel = checkValue(nestedLevel);
  if (checkJson(json)) {
    if (nestedLevel > 0) {
      Object.keys(json).forEach(
        (key) =>
          (json[key] = filterJsonByElement(
            json[key],
            filter,
            value,
            nestedLevel - 1,
          )),
      );
    } else {
      Object.keys(json).forEach((key) => {
        if (!json[key][filter] === value) delete json[key];
      });
    }
    return json;
  } else {
    return {};
  }
}

export function verifyChileanRut(rut) {
  let value = rut.replace(/[\.\-]+/g, '');
  let serial = value.slice(0, -1);
  let dv = value.slice(-1).toUpperCase();

  if (serial.length < 7) return { error: true, reason: 'Rut Incompleto' };

  let dvCalculated = dvForChileanRut(value, serial);

  switch (dv.toString()) {
    case 'K':
      dv = 10;
      break;

    case '0':
      dv = 11;
      break;

    default:
      dv = dv;
      break;
  }

  if (dvCalculated != dv) {
    return { error: true, reason: 'Rut Invalido' };
  } else {
    return { error: false };
  }
}

export function dateSort(dateA, dateB) {
  if (dateA === '-') dateA = '1/1/1900';

  if (dateB === '-') dateB = '1/1/1900';

  return moment(dateA, 'D/M/YYYY') - moment(dateB, 'D/M/YYYY');
}

function checkValue(value) {
  if (value !== null && value >= 0) {
    return value;
  } else {
    return 0;
  }
}

function checkJson(json) {
  if (isNaN(json)) {
    if (JSON.parse(JSON.stringify(json)).length == undefined) {
      return true;
    }
  }
  return false;
}

function dvForChileanRut(rutClean, serial) {
  let sum = 0;
  let factor = 2;

  for (let i = 1; i <= serial.length; i++) {
    let index = factor * rutClean.charAt(serial.length - i);
    sum = sum + index;
    factor = factor < 7 ? factor + 1 : 2;
  }
  return 11 - (sum % 11);
}

export function serializerUnpacker(data) {
  return Array.isArray(data)
    ? data.map((item) => item.attributes)
    : data.attributes;
}

export function isNumeric(str) {
  return /^\d+$/.test(str);
}

export function simpleCountry(country) {
  const countrySolved = country.toLowerCase();
  const chile = ['chile', 'cl', 'clp'];
  const mexico = ['mexico', 'méxico', 'mxn', 'MXN', 'mx'];
  const peru = ['peru', 'perú', 'pe', 'per'];
  if (chile.includes(countrySolved)) {
    return 'chile';
  } else if (mexico.includes(countrySolved)) {
    return 'mexico';
  } else if (peru.includes(countrySolved)) {
    return 'peru';
  } else {
    return 'other';
  }
}

export function countryToCurrency(country) {
  switch (country) {
    case 'Chile':
      return 'UF';
    case 'Mexico':
      return 'MXN';
    default:
      return 'USD';
  }
}

export function returnCardBrand(number) {
  var re = {
    VISA: /^4[0-9]{12}(?:[0-9]{3})?$/,
    MASTERCARD: /^5[1-5][0-9]{14}$/,
    AMEX: /^3[47][0-9]{13}$/,
    DINERS: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
  };
  for (var key in re) {
    if (re[key].test(number)) {
      return key;
    }
  }
}

export const generateRGB = () => {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  return `rgb(${r}, ${g}, ${b})`;
};

// sleep time expects milliseconds
export function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}
