import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  fixedBanner: {
    overflow: 'hidden',
    position: 'fixed',
    backgroundColor: '#FbFbFb',
    textAlign: 'center',
    left: '0',
    top: '0',
    background: '#132045 !important',
    height: '50px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'flex-start',
    zIndex: '2',
    paddingLeft: '15px',
    borderBottom: '5px solid #F27B42',
  },
  verticalLine: {
    borderLeft: '2px solid white',
    height: '26.31px',
    marginLeft: '5px',
    marginRight: '5px',
    borderRadius: '1px',
  },
}));
