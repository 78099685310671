import React, { useState, useEffect } from 'react';
import { Table } from '@beetrack/hp-components';
import MaterialTable from 'material-table';
import { CheckCircle, Cancel } from '@material-ui/icons';
import { havePermissions } from '../../utils/utils';
import { patch } from '../../utils/api';

const BDPointsTable = (props) => {
  // [
  //   {
  //     "id": "91",
  //     "type": "paid_lines_registry",
  //     "attributes": {
  //       "hubspot_quickbooks_relation": {
  //         "id": 1,
  //         "hubspot_id": 31279790,
  //         "quickbooks_id": 126,
  //         "quickbooks_realm_id": 123145779072667,
  //         "created_at": "2022-03-14T15:30:57.651Z",
  //         "updated_at": "2022-03-14T15:30:57.651Z",
  //         "automatic_invoice": true,
  //         "in_advance": true,
  //         "document_type": "invoice",
  //         "discarded_at": null
  //       },
  //       "line_detail": "Servicio LastMile (10 usuarios) (08-2022)",
  //       "amount": 7900.0,
  //       "currency": "MXN",
  //       "invoice_id": 9918,
  //       "paid_at": "2022-07-14T18:06:39.000Z",
  //       "closed_at": "2022-07-25T19:48:18.402Z",
  //       "product": "lastmile",
  //       "service_date": "2022-07-01T00:00:00.000Z",
  //       "status": "approved"
  //     }
  //   }
  // ]
  const data = props.data;

  const { reload, setReload } =
    props.reloader === undefined ? {} : props.reloader;
  // [
  //   {
  //     "id": "24802665",
  //     "properties": {
  //       "createdate": "2016-03-29T17:19:55.271Z",
  //       "dealname": "El taxista sa",
  //       "hs_lastmodifieddate": "2022-07-22T17:56:01.807Z",
  //       "hs_object_id": "24802665"
  //     },
  //     "createdAt": "2016-03-29T17:19:55.271Z",
  //     "updatedAt": "2022-07-22T17:56:01.807Z",
  //     "archived": false
  //   },
  // ]
  const [selectedRow, setSelectedRow] = useState(0);
  const hubspotData = props.hubspotData;
  const currencyRates = props.currencyRates;
  const actionsAllowed =
    props.actionsAllowed === undefined
      ? false
      : props.actionsAllowed && havePermissions(['admin_sales_man']);
  
  const generateClientName = (item) => {
    const match = _matchHubspotId(item.attributes?.hubspot_quickbooks_relation?.hubspot_id?.toString());
    const dealname = match?.properties?.dealname;
    return dealname === undefined ? 'Nombre desconocido' : dealname;
  };

  const [sortedData, setSortedData] = useState([]);

  const setColumns = () => {
    return [
      {
        field: 'hubspot_client',
        title: 'Nombre del cliente',
        render: (rowData) => {
          const match = _matchHubspotId(
            rowData?.attributes?.hubspot_quickbooks_relation?.hubspot_id?.toString(),
          );
          const dealname = match?.properties?.dealname;
          return dealname === undefined ? 'Nombre desconocido' : dealname;
        },
        customSort: (a, b) => {
          const dealnameA = a.hubspot_client;
          const dealnameB = b.hubspot_client;
          if (typeof dealnameA === 'string' && typeof dealnameB === 'string') {
            return dealnameA.localeCompare(dealnameB);
          }
          return dealnameA < dealnameB ? -1 : dealnameA > dealnameB ? 1 : 0;
        },
        sortable: false,
        sorting: true,
        width: 300,
      },
      {
        field: 'attributes.amount',
        title: 'Monto facturado',
        render: (rowData) => {
          return `${rowData.attributes.currency} ${rowData.attributes.amount}`
        },
        sorting: false,
      },
      {
        name: 'amount_usd',
        title: 'Comisión en USD',
        render: (rowData) => {
          let upper =
            rowData.attributes?.amount *
            currencyRates[rowData.attributes?.currency.toLowerCase()];
          let lower = currencyRates['usd'];
          return ((upper / lower) * 0.1).toFixed(2);
        },
        sorting: false,
      },
      {
        name: 'amount_clp',
        title: 'Comisión en CLP',
        render: (rowData) => {
          let upper =
            rowData.attributes?.amount *
            currencyRates[rowData.attributes?.currency.toLowerCase()];
          return (upper * 0.1).toFixed(0);
        },
        sorting: false,
      },
      {
        name: 'attributes.product',
        title: 'Producto',
        render: (rowData) => {
          return rowData?.attributes?.product;
        },
        sorting: false,
      },
      {
        name: 'attributes.line_detail',
        title: 'Glosa',
        render: (rowData) => {
          return rowData?.attributes?.line_detail;
        },
        width: 450,
        sorting: false,
      },
      {
        name: 'attributes.invoice_id',
        title: 'ID de factura',
        render: (rowData) => {
          return rowData?.attributes?.invoice_id;
        },
        sorting: false,
      },
      {
        name: 'attributes.service_date',
        title: 'Fecha de facturación',
        render: (rowData) => {
          return rowData?.attributes?.service_date;
        },
        width: 250,
        sorting: false,
      },
      {
        name: 'attributes.paid_at',
        title: 'Fecha de registro',
        render: (rowData) => {
          return rowData?.attributes?.paid_at;
        },
        width: 250,
        sorting: false,
      },
      {
        name: 'attributes.closed_at',
        title: 'Fecha de cierre',
        render: (rowData) => {
          return rowData?.attributes?.closed_at;
        },
        width: 250,
        sorting: false,
      },
    ];
  };
  // given a husbpot id, it returns the maatched data
  const _matchHubspotId = (hubspotId) => {
    return hubspotData.find((entry) => entry.id === hubspotId);
  };

  const _totalPerCurrency = (data) => {
    let total = 0;

    data.forEach((entry) => {
      // (amt * rate_moneda) / CurrencyRate.usd = total en USD
      let upper =
        entry.attributes?.amount *
        currencyRates[entry.attributes?.currency.toLowerCase()];
      let lower = currencyRates['usd'];
      total += upper / lower;
    });
    return (total * 0.1).toFixed(2);
  };

  const _conversionRates = () => {
    const conversions = {};
    Object.keys(currencyRates).forEach((currency) => {
      if (currency === 'uf') {
        conversions[currency] = currencyRates[currency] / currencyRates['usd'];
      } else if (currency !== 'usd'){
        conversions[currency] = currencyRates['usd'] / currencyRates[currency];
      }
    });

    return Object.keys(conversions).map((currency) => {
      if (currency === 'uf') {
        return `1 ${currency.toUpperCase()} = $${conversions[currency].toFixed(5)} USD`
      } else if (currency !== 'usd') {
        return `1 USD = $${conversions[currency].toFixed(5)} ${currency.toUpperCase()}`
      }})
    };

  const handleApprove = (item) => {
    const payload = { status: 'approved' };
    patch(`/sales/new_commissions/registries/${item.id}`, payload).then(
      (response) => {
        if (response.status === 200) {
          setReload(reload + 1);
        }
      },
    );
  };

  const handleReject = (item) => {
    const payload = { status: 'rejected' };
    patch(`/sales/new_commissions/registries/${item.id}`, payload).then(
      (response) => {
        if (response.status === 200) {
          setReload(reload + 1);
        }
      },
    );
  };

  const setActions = () => {
    if (actionsAllowed) {
      return [
        {
          tooltip: 'Aprobar',
          onClick: (event, rowData) => handleApprove(rowData),
          icon:() => <CheckCircle style={{ color: 'green' }}/>,
          isGlobal: false,
        },
        {
          tooltip: 'Rechazar',
          onClick: (event, rowData) => handleReject(rowData),
          icon:() => <Cancel style={{ color: 'red' }} />,
          isGlobal: false,
        },
      ]
    } else {
      []};  
  }

  const tableOptions = {
    actionsColumnIndex: -1,
    rowStyle: (rowData) => ({
      backgroundColor: selectedRow === rowData.id ? '#EEE' : '#FFF',
    }),
    cellStyle: {
      color: '#707070',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif !important'
    },
    headerStyle: {
      color: '#707070',
      backgroundColor: '#E1E6ED',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif !important',
      fontWeight: 'bold'
    },
    titleStyle: {
      backgroundColor: '#E1E6ED',
    },
    pageSize: 20,
    pageSizeOptions: [20, 30, 50],
  }

  useEffect(() => {
    const sortedCopy = data.map((item) => ({
      ...item,
      hubspot_client: generateClientName(item),
    }));
    setSortedData(sortedCopy);
  }, [data]);


  return (
    <div>
      <MaterialTable
      title= {<p
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {`Comisión total: $${_totalPerCurrency(props.data)} USD`}
              </p>} 
        columns={setColumns()}
        data={sortedData}
        actions={setActions()}
        onRowClick={(evt, selectedRow) =>
          setSelectedRow(selectedRow.id)
        }
        options={tableOptions}
        pageSize={20}
        pageSizeOptions={[20, 30, 50]}
      />
      <div
        style={{
          padding: 10,
        }}
      >
        Tasas de conversión:
        {_conversionRates().map((item, i) => (
          <li
            key={i}
            style={{
              padding: 5,
            }}
          >
            {item}
          </li>
        ))}
      </div>
  </div>
  );
};

export default BDPointsTable;
