import React, { useEffect, useState } from 'react';
import {
  Form,
  FormRow,
  TextField,
  Button,
  FormGroup,
  Select,
  DatePicker,
  Loading,
  Snackbar,
} from '@beetrack/hp-components';
import { get, post } from '../../../utils/api';
import { baseConditions, variableConditions } from '../../../utils/const';
import moment from 'moment';
import { verifyChileanRut, isNumeric } from '../../../utils/utils';
import { MAX_TAX_IDENTIFIER_LENGTHS } from '../../../utils/const';

const ClientForm = (props) => {
  const [hubspotClients, setHubspotClients] = useState([]);
  const [taxIdentifier, setTaxIdentifier] = useState('');
  const [selectedClient, setSelectedClient] = useState({});
  const [loading, setLoading] = useState(true);
  const [razon, setRazon] = useState('');
  const [giro, setGiro] = useState('');
  const [country, setCountry] = useState({ label: '', value: '' });
  const [city, setCity] = useState('');
  const [office, setOffice] = useState('');
  const [comune, setComune] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [address, setAddress] = useState('');
  const [contacts, setContacts] = useState([{ name: '', email: '' }]);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [paymentTerm, setPaymentTerm] = useState('');
  const [dateStartService, setDateStartService] = useState(
    moment().format('DD-MM-YYYY'),
  );
  const [loadingPost, setLoadingPost] = useState(false);
  const [openSnack, setOpenSnack] = useState({
    open: false,
    message: '',
    status: 'success',
  });

  const paymentMethods = [
    { label: 'Tarjeta de crédito', value: '3' },
    { label: 'Transferencia', value: '5' },
  ];

  const paymentTerms = [
    { label: '15 días', value: '2' },
    { label: '30 días', value: '3' },
    { label: '60 días', value: '4' },
  ];

  const changeOption = (value) => {
    setSelectedClient(value);
    get(`/client_actions/qb-information/${value.value}`).then((response) => {
      if (response.status === 200) {
        setCountry(response.data.country);
      }
    });
  };

  const addContact = () => {
    setContacts([...contacts, { name: '', email: '' }]);
  };

  const handleContactEmail = (email, i) => {
    let contactsChange = [...contacts];
    contactsChange[i].email = email;
    setContacts(contactsChange);
  };

  const handleContactName = (name, i) => {
    let contactsChange = [...contacts];
    contactsChange[i].name = name;
    setContacts(contactsChange);
  };

  const formSubmit = () => {
    if (
      country.label === 'Chile' &&
      verifyChileanRut(taxIdentifier).error === true
    ) {
      setOpenSnack({ message: 'RUT invalido', status: 'error', open: true });
    } else {
      setLoadingPost(true);
      let payload = {
        razon,
        giro,
        taxIdentifier,
        country,
        city,
        comune,
        postalCode,
        address,
        office,
        contacts,
        paymentMethod,
        paymentTerm,
        hubspot: {
          id: selectedClient.value,
          name: selectedClient.label,
        },
        dateStartService,
      };
      post('/client_actions/create', {
        client: payload,
        bases: baseConditions,
        variables: variableConditions,
      }).then((response) => {
        if (response.status === 201) {
          setOpenSnack({
            open: true,
            message: 'Cliente QB Creado Exitosamente',
            status: 'success',
          });
          setSelectedClient('');
          setTaxIdentifier('');
          setRazon('');
          setGiro('');
          setCountry('');
          setCity('');
          setOffice('');
          setComune('');
          setPostalCode('');
          setAddress('');
          setContacts([{ name: '', email: '' }]);
          setPaymentMethod('');
          setPaymentTerm('');
          setDateStartService(moment().format('DD-MM-YYYY'));
          setLoadingPost(false);
        } else if (response.status === 500) {
          setLoadingPost(false);
          setOpenSnack({
            open: true,
            message: 'Faltan completar campos',
            status: 'error',
          });
        } else if (response.status === 409) {
          setLoadingPost(false);
          setOpenSnack({
            open: true,
            message: JSON.parse(response.data.errors).Fault.Error[0].Message,
            status: 'error',
          });
        }
      });
    }
  };

  const handleTaxIdentifierChange = (value) => {
    const regExp = /(?!-)+\W*/gm;
    // rfc starts with alpabetic chars while rut start with numbers
    let cleaned = value.replace(regExp, '').toUpperCase();
    return isNumeric(cleaned.charAt(0))
      ? handleRut(cleaned)
      : handleRfc(cleaned);
  };

  const handleRut = (value) => {
    const regExp = /[^0-9|K|-]/gm;
    return value
      .replace(regExp, '')
      .substring(0, MAX_TAX_IDENTIFIER_LENGTHS.CL);
  };

  const handleRfc = (value) => {
    const regExp = /\W*/gm;
    return value
      .replace(regExp, '')
      .substring(0, MAX_TAX_IDENTIFIER_LENGTHS.MX);
  };

  useEffect(() => {
    get('/client_actions/create').then((response) => {
      if (
        response['data'] !== undefined &&
        response['data'].status != 'error'
      ) {
        let hubspotClientsArray = response['data']['hubspot_deals'].map(
          (deal) => {
            return {
              label: deal['properties']['dealname']['value'],
              value: deal['dealId'],
            };
          },
        );
        setHubspotClients(hubspotClientsArray);
        setSelectedClient(hubspotClientsArray[0].value);
        setLoading(false);
      }
    });
  }, []);

  return (
    <>
      <Snackbar
        key="creation-snackbar"
        isOpen={openSnack.open}
        message={openSnack.message}
        onDismiss={() => setOpenSnack(!openSnack.open)}
        status={openSnack.status}
      />
      {loading && (
        <center>
          <Loading hasText />
        </center>
      )}
      {!loading && (
        <Form
          title="Crear Cliente Quickbooks"
          id="qb-form"
          backTo="/client"
          tooltip="Este formulario creará automáticamente el cliente Hubspot en Quickbooks y los asociará"
          buttons={
            <Button
              disabled={loadingPost}
              onClick={() => formSubmit()}
              form="my-form"
            >
              {' '}
              Crear{' '}
            </Button>
          }
        >
          <FormGroup title="Cliente" collapsible={false}>
            <Select
              label="Cliente Hubspot a crear en Quickbooks"
              onChange={(option) => changeOption(option)}
              options={hubspotClients}
              value={selectedClient.value}
            />
          </FormGroup>
          <FormGroup title="Información Contable" collapsible={false}>
            <FormRow>
              <TextField
                fullWidth
                value=""
                label="Razón social"
                name="text-one"
                value={razon}
                onChange={(e) => setRazon(e.target.value)}
              />
            </FormRow>
            <FormRow>
              <TextField
                fullWidth
                value=""
                label="RUT/RFC"
                name="text-one"
                value={taxIdentifier}
                onChange={(e) =>
                  setTaxIdentifier(handleTaxIdentifierChange(e.target.value))
                }
              />
              <TextField
                value=""
                label="Giro comercial"
                name="text-one"
                value={giro}
                onChange={(e) => setGiro(e.target.value)}
              />
            </FormRow>
            <FormRow>
              <TextField
                value={country.label}
                label="País"
                name="text-one"
                disabled
              />
              <TextField
                value=""
                label="Ciudad"
                name="text-one"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </FormRow>
            <FormRow>
              <TextField
                value=""
                label="Dirección"
                name="text-one"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
              <TextField
                value=""
                label="Oficina"
                name="text-one"
                value={office}
                onChange={(e) => setOffice(e.target.value)}
              />
            </FormRow>
            <FormRow>
              <TextField
                value=""
                label="Comuna"
                name="text-one"
                value={comune}
                onChange={(e) => setComune(e.target.value)}
              />
              <TextField
                fullWidth={true}
                value=""
                label="Código postal"
                name="text-one"
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
              />
            </FormRow>
          </FormGroup>
          <FormGroup title="Contactos" collapsible={false}>
            {contacts.map((obj, i) => (
              <FormRow key={i}>
                <TextField
                  value=""
                  label="Nombre"
                  name="text-one"
                  value={contacts[i].name}
                  onChange={(e) => handleContactName(e.target.value, i)}
                />
                <TextField
                  value={contacts[i].email}
                  label="Correo electrónico"
                  name="text-one"
                  onChange={(e) => handleContactEmail(e.target.value, i)}
                />
              </FormRow>
            ))}
            <Button type="tertiary" onClick={() => addContact()}>
              {' '}
              Añadir otro contacto{' '}
            </Button>
          </FormGroup>
          <FormGroup title="Servicio" collapsible={false}>
            <DatePicker
              label="Fecha de inicio de servicio"
              date={dateStartService}
              onChange={(e) => setDateStartService(e)}
            />
          </FormGroup>
          <FormGroup title="Pagos" collapsible={false}>
            <Select
              label="Medio de pago"
              options={paymentMethods}
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.value)}
            />
            <Select
              label="Plazo de pago"
              options={paymentTerms}
              value={paymentTerm}
              onChange={(e) => setPaymentTerm(e.value)}
            />
          </FormGroup>
        </Form>
      )}
    </>
  );
};

export default ClientForm;
