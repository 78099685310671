import React from 'react';
import { DatePicker } from '@beetrack/hp-components';
import { Grid } from '@mui/material';
import FilePicker, { useFilePicker } from '@beetrack/hp-file-picker-react';

import { getNestedValue } from '../utils';

const FileElementTranslator = (props) => {
  const {
    classes,
    form,
    formValues,
    setFormValues,
    path,
    fileChangeHandler,
    dependant,
  } = props;
  const element = getNestedValue(form.content, path);

  return (
    <Grid item sm={12} md={dependant ? 12 : 10} className={classes.element}>
      <div className={classes.question}>{element.question}</div>
      {element.imgUrl && (
        <div className={classes.imageContainer}>
          <img
            style={{ width: '100%', height: '100%' }}
            src={element.imgUrl}
            alt="new"
          />
        </div>
      )}
      <div className={classes.dateField}>
        <FilePicker
          helperText={
            element.helperText + ' - Extensiones: ' + element.extentions
          }
          onChange={(e) =>
            fileChangeHandler(e, element.fileName, element.extentions)
          }
          value={
            formValues[`FILE-${element.fileName}`] && [
              formValues[`FILE-${element.fileName}`],
            ]
          }
        />
      </div>
    </Grid>
  );
};

export default FileElementTranslator;
