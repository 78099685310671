import React, { useState, useEffect } from 'react';
import { get } from '../../utils/api';
import { makeStyles } from '@material-ui/core/styles';
import DraftTable from './Components/DraftTable';
import { Tabs, TabItem, LoadingPage } from '@beetrack/hp-components';
import CommentModal from './Components/ComentModal';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import DraftModal from './Components/DraftModal';

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
  },
});

const DraftsComments = (props) => {
  const classes = useStyles();
  const [dataTablePending, setDataTablePending] = useState([]);
  const [dataTableApproved, setDataTableApproved] = useState([]);
  const [dataTableCommented, setDataTableCommented] = useState([]);
  const [dataTablePreApproved, setDataTablePreApproved] = useState([]);
  const [reload, setReload] = useState(0);
  const [billingPeriod, setBillingPeriod] = useState('Enero 2021');
  const [loading, setLoading] = useState(true);
  const [modalDraft, setModalDraft] = useState({ open: false, data: {} });
  const [modalComment, setModalComment] = useState({ open: false, data: {} });
  const [snackBar, setSnackBar] = useState({ open: false, status: 'close', message: '' });

  const closeModals = () => {
    setModalComment({ open: false, data: {} });
    setModalDraft({ open: false, data: {} });
  };

  const getDrafts = async () => {
    get('/draft_comments').then((response) => {
      if (response.status === 200) {
        setDataTablePending(response.data['pending']);
        setDataTableApproved(response.data['approved']);
        setDataTableCommented(response.data['commented']);
        setDataTablePreApproved(response.data['pre_approved']);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    getDrafts();
  }, [reload]);

  return (
    <div>
      <h2> Solicitud de aprobación de borradores de factura </h2>
      <p>
        {' '}
        Aquí encontrarás todos los <strong>
          {' '}
          borradores asignados a ti{' '}
        </strong>{' '}
        para su confirmación de aprobación.
      </p>
      {!loading && (
        <div>
          <Tabs>
            <TabItem
              label={`Pendientes ${dataTablePending.length}/${
                dataTablePending.length +
                dataTableApproved.length +
                dataTableCommented.length +
                dataTablePreApproved.length
              }`}
            >
              <DraftTable
                reloadAction={() => setReload(reload + 1)}
                tableType="pending"
                billingPeriod={billingPeriod}
                dataTable={dataTablePending}
                setModalComment={setModalComment}
                setModalDraft={setModalDraft}
              />
            </TabItem>
            <TabItem
              label={`Comentarios ${dataTableCommented.length}/${
                dataTablePending.length +
                dataTableApproved.length +
                dataTableCommented.length +
                dataTablePreApproved.length
              }`}
            >
              <DraftTable
                tableType="other"
                billingPeriod={billingPeriod}
                dataTable={dataTableCommented}
                setModalComment={setModalComment}
                setModalDraft={setModalDraft}
              />
            </TabItem>
            <TabItem
              label={`Pre-Aprobadas ${dataTablePreApproved.length}/${
                dataTablePending.length +
                dataTableApproved.length +
                dataTableCommented.length + 
                dataTablePreApproved.length
              }`}
            >
              <DraftTable
                tableType="other"
                billingPeriod={billingPeriod}
                dataTable={dataTablePreApproved}
                setModalComment={setModalComment}
                setModalDraft={setModalDraft}
              />
            </TabItem>
            <TabItem
              label={`Aprobadas ${dataTableApproved.length}/${
                dataTablePending.length +
                dataTableApproved.length +
                dataTableCommented.length +
                dataTablePreApproved.length
              }`}
            >
              <DraftTable
                tableType="other"
                billingPeriod={billingPeriod}
                dataTable={dataTableApproved}
                setModalComment={setModalComment}
                setModalDraft={setModalDraft}
              />
            </TabItem>
          </Tabs>
          <Modal
          open={modalComment.open}
          onClose={() => setModalComment({ open: false, data: {} })}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
          className={classes.modal}
        >
          <CommentModal
            setSnackBar={setSnackBar}
            client={modalComment.data.company_name}
            data={modalComment.data}
            close={closeModals}
            reloadAction={() => setReload(reload + 1)}
          />
        </Modal>
        <Modal
          open={modalDraft.open}
          onClose={() => setModalDraft({ open: false, data: {} })}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
          className={classes.modal}
        >
          <Fade in={modalDraft.open}>
            <DraftModal
              setSnackBar={setSnackBar}
              amount={modalDraft.data.total_amount}
              close={closeModals}
              id={modalDraft.data.id}
              client={modalDraft.data.company_name}
            />
          </Fade>
        </Modal>
      </div>
      )}
      {loading && <LoadingPage text="Cargando..." />}
    </div>
  );
};

export default DraftsComments;
