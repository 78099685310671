import React, { Component } from 'react';
import { Table, CardContent, Card } from '@beetrack/hp-components';

class ViewTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { name: 'product', title: 'Producto', valueByAttribute: 'product' },
        { name: 'client', title: 'Cliente', valueByAttribute: 'client' },
        { name: 'invoice', title: 'Folio', valueByAttribute: 'invoice' },
        { name: 'comment', title: 'Comentario', valueByAttribute: 'comment' },
        {
          name: 'invoiceAmount',
          title: 'Monto Factura',
          valueByAttribute: 'invoiceAmount',
        },
        {
          name: 'commission',
          title: 'Comisión',
          valueByAttribute: 'commission',
        },
        { name: 'paidDate', title: 'Fecha', valueByAttribute: 'paidDate' },
      ],
      data: this.props.data,
      loading: false,
    };
  }

  render() {
    return (
      <div>
        <Card fullWidth>
          <CardContent>
            <Table
              columns={this.state.columns}
              data={this.props.data}
              loading={this.state.loading}
            />
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default ViewTable;
