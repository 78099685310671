const drawerWidth = 240;

export const styles = theme => ({
  content: {
    display: 'inline-block',
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingBottom: '0px'
  },
  root: {
    display: 'flex',
  },
  components: {
    width: '100%',
    height: 'calc(100vh - 128px)',
  },
  toolbar: theme.mixins.toolbar,
  progressbar: {
    width: '100%',
    height: '5px',
    position: 'fixed',
    top: '45px',
    zIndex: 10000,
    backgroundColor: '#fe7b17'
  }
});
