import React from 'react';

import { Grid } from '@material-ui/core';
import {
  TextField,
  ActionsList,
  IconButton,
  Switch,
} from '@beetrack/hp-components';
import { Delete, Add, FileCopy } from '@beetrack/hp-icons-react';
import Collapse from '@mui/material/Collapse';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { getNestedValue } from '../utils';

import { useStyles } from '../Styles/styles';
import { Droppable, Draggable } from 'react-beautiful-dnd';

const SimpleElement = (props) => {
  const {
    components,
    path,
    elementID,
    indexElement,
    type,
    textChangeHandler,
    switchHandler,
    simpleChangeHandler,
    addSimpleOption,
    deleteOption,
    deleteElement,
    collapsed,
    setCollapsed,
    draggableProvided,
    handleClone,
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.elementContainer}>
      <div
        className={classes.introElement}
        {...(draggableProvided && draggableProvided.dragHandleProps)}
      >
        <p className={classes.subTitle}>
          {getNestedValue(components, [...path, 'question'])
            ? getNestedValue(components, [...path, 'question'])
            : `Elemento: ${indexElement}`}
          {` (seleccion ${type})`}
          <a className={classes.red}>
            {getNestedValue(components, [...path, 'required']) ? '*' : ''}
          </a>
        </p>
        <ActionsList
          actions={[
            {
              title: 'Eliminar Elemento',
              onClick: () => deleteElement(path),
              icon: <Delete />,
            },
            {
              title: 'Clonar pregunta',
              onClick: () => handleClone(path),
              icon: <FileCopy />,
            },
          ]}
          launcherType="tertiary"
        />
        <p className={classes.collapsable}>
          <a
            onClick={() =>
              setCollapsed({
                ...collapsed,
                [elementID]: !collapsed[elementID],
              })
            }
          >
            {!collapsed[elementID] ? 'Ver más +' : 'Ver menos'}
          </a>
        </p>
      </div>
      <div className={classes.container}>
        <Collapse in={collapsed[elementID]} timeout="auto" unmountOnExit>
          <div className={classes.horizontal}>
            <Grid item xs={12} md={4}>
              <TextField
                label="Pregunta"
                name={`question`}
                key={`text-field-${elementID}-1`}
                placeholder="Pregunta"
                value={getNestedValue(components, [...path, 'question'])}
                onChange={(e) => textChangeHandler(e, path)}
                helperText="Título o pregunta que quieres que aparezca SOBRE la casilla"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Título"
                name={`title`}
                key={`text-field-${elementID}-2`}
                placeholder="Título"
                value={getNestedValue(components, [...path, 'title'])}
                onChange={(e) => textChangeHandler(e, path)}
                helperText="Título o pregunta que quieres que aparezca EN la casilla"
              />
            </Grid>
            <Grid className={classes.vertical} item xs={12} md={2}>
              <a className={classes.requiredSwitch}>
                {' '}
                ¿Es un campo obligatorio?
              </a>
              <Switch
                rightLabel="Sí"
                leftLabel="No"
                onChange={(e) => switchHandler(e, [...path, 'required'])}
                checked={getNestedValue(components, [...path, 'required'])}
              />
            </Grid>
            <Grid className={classes.vertical} item xs={12} md={2}>
              <a className={classes.requiredSwitch}> ¿Opcion vacia?</a>
              <Switch
                rightLabel="Sí"
                leftLabel="No"
                onChange={(e) => switchHandler(e, [...path, 'emptyOption'])}
                checked={getNestedValue(components, [...path, 'emptyOption'])}
              />
            </Grid>
          </div>
          <div className={classes.horizontal}>
            <Grid item xs={12} md={4} className={classes.horizontal}>
              <TextField
                label="Valor interno property en HubSpot"
                name={`propertyName`}
                key={`text-field-${elementID}-3`}
                placeholder="HS-property-name"
                value={getNestedValue(components, [...path, 'propertyName'])}
                onChange={(e) => textChangeHandler(e, path)}
                helperText="Ingresa el valor interno de la propiedad que se va a rellenar"
                required
              />
              <IconButton
                icon={<ContentCopyIcon />}
                size="large"
                onClick={() =>
                  navigator.clipboard.writeText(
                    `${getNestedValue(components, [...path, 'propertyName'])}`,
                  )
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Texto que quieres que quede de ayuda"
                name={`helperText`}
                key={`text-field-${elementID}-4`}
                value={getNestedValue(components, [...path, 'helperText'])}
                onChange={(e) => textChangeHandler(e, path)}
                helperText="Texto que iría acá"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="URL de imagen de apoyo"
                name={`imgUrl`}
                key={`text-field-${elementID}-5`}
                value={getNestedValue(components, [...path, 'imgUrl'])}
                onChange={(e) => textChangeHandler(e, path)}
                helperText="Si tu pregunta usa una imagen de apoyo ingresa la url"
              />
            </Grid>
          </div>
          <Droppable key={elementID} type="OPTION" droppableId={elementID}>
            {(droppableProvidedO, snapshotO) => (
              <div
                key={indexElement}
                ref={droppableProvidedO.innerRef}
                {...droppableProvidedO.droppableProps}
                isDraggingFrom={Boolean(snapshotO.draggingFromThisWith)}
                className={
                  snapshotO.isDraggingOver
                    ? classes.groupContainerCreatorOver
                    : classes.groupContainerCreator
                }
              >
                {Object.keys(getNestedValue(components, path).options).map(
                  (option, indexOption) => {
                    return (
                      <Draggable
                        key={option}
                        draggableId={option}
                        index={indexOption}
                        type={'OPTION'}
                      >
                        {(draggableProvidedO, snapshotO) => (
                          <div
                            isDragging={snapshotO.isDragging}
                            {...draggableProvidedO.draggableProps}
                            ref={draggableProvidedO.innerRef}
                            key={indexOption}
                            className={
                              snapshotO.isDragging
                                ? classes.draggingComponent
                                : classes.component
                            }
                          >
                            <div className={classes.optionContainer}>
                              <div
                                className={classes.optionTitle}
                                {...draggableProvidedO.dragHandleProps}
                              >
                                {`Option: ${indexOption + 1}`}
                                <IconButton
                                  onClick={() =>
                                    deleteOption([...path, 'options'], option)
                                  }
                                  icon={<Delete />}
                                />
                              </div>
                              <div className={classes.horizontal}>
                                <Grid item xs={12} md={5}>
                                  <TextField
                                    label="Opcion que se mostrara para el cliente"
                                    name={`label`}
                                    key={`text-field-${elementID}-5`}
                                    value={getNestedValue(components, [
                                      ...path,
                                      'options',
                                      option,
                                      'label',
                                    ])}
                                    onChange={(e) =>
                                      simpleChangeHandler(e, [
                                        ...path,
                                        'options',
                                        option,
                                      ])
                                    }
                                  />
                                </Grid>
                                <Grid item xs={10} md={5}>
                                  <TextField
                                    label="Valor interno de hubspot"
                                    name={`value`}
                                    key={`text-field-${elementID}-6`}
                                    value={getNestedValue(components, [
                                      ...path,
                                      'options',
                                      option,
                                      'value',
                                    ])}
                                    onChange={(e) =>
                                      simpleChangeHandler(e, [
                                        ...path,
                                        'options',
                                        option,
                                      ])
                                    }
                                  />
                                </Grid>
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    );
                  },
                )}
                {droppableProvidedO.placeholder}
              </div>
            )}
          </Droppable>
          <IconButton
            onClick={() => addSimpleOption([...path, 'options'])}
            icon={<Add />}
          />
        </Collapse>
      </div>
    </div>
  );
};

export default SimpleElement;
