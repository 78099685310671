import React, { forwardRef, useEffect, useState } from 'react';
import { Paper, Grid } from '@material-ui/core';
import { get, post, put } from '../../../../utils/api';
import { TextField, Button } from '@beetrack/hp-components';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    paddingBottom: '3%',
  },
  modal: {
    width: 'auto',
    paddingLeft: '3%',
    paddingRight: '3%',
    paddingBottom: '3%',
    margin: '0 auto',
    display: 'flex',
    maxHeight: '70%',
    overflow: 'auto',
  },
  buttons: {
    paddingTop: '5%',
    paddingLeft: '10%',
  },
});

const CommentModal = forwardRef((props, ref) => {
  const classes = useStyles();
  const [comments, setComments] = useState(props.data.comment);
  const client = props.client;
  const [characters, setCharacters] = useState(comments.length);

  const editComment = () => {
    let payload = {
      status: 'commented',
      comment: comments,
      invoice_draft_id: props.data.id,
    };
    post('/draft_comments', payload).then((response) => {
      if (response.status === 201) {
        props.reloadAction();
        props.close();
        props.setSnackBar({
          open: true,
          status: 'success',
          message: 'Borrador Comentado',
        });
      } else if (response.status === 400) {
        payload = {
          status: 'commented',
          comment: comments,
        };
        put('/draft_comments/' + props.data.id, payload).then((putResponse) => {
          if (putResponse.status === 200) {
            props.reloadAction();
            props.close();
          } else {
            props.reloadAction();
            props.close();
            props.setSnackBar({
              open: true,
              status: 'error',
              message: 'Ha ocurrido un problema',
            });
          }
        });
      }
    });
  };

  const renderData = () => (
    <div className={classes.root}>
      <h2> Agregar comentario al borrador </h2>
      <p> Cliente: {client}</p>
      <TextField
        textArea
        value={comments}
        onChange={(e) => {
          setComments(e.target.value);
          setCharacters(e.target.value.length);
        }}
        placeholder="Agrega tu comentario"
        helperText={'(' + characters.toString() + '/300 caracteres)'}
      />
      <Grid className={classes.buttons} container spacing={2}>
        <Grid item xs={5}>
          <Button type="tertiary" onClick={() => props.close()}>
            Cancelar
          </Button>
        </Grid>
        <Grid item xs={7}>
          <Button onClick={() => editComment()}>Enviar comentario</Button>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <Paper className={classes.modal} ref={ref} tabIndex={-1}>
      {renderData()}
    </Paper>
  );
});

export default CommentModal;
