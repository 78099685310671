import React, { useState, useEffect } from 'react';
import {
  IconButton,
  Button,
  TextField,
  Snackbar,
  ConfirmationModal,
} from '@beetrack/hp-components';
import { Delete, Edit, ArrowLeft } from '@beetrack/hp-icons-react';
import { get, delForm } from '../../utils/api';
import { useStyles } from './Styles/styles';
import FormTranslator from './FormTranslator';
import LoadingCircle from '../../shared/LoadingCircle/LoadingCircle';
import WarningMessage from '../../shared/WarningMessage';

const FormView = (props) => {
  const fid = props.match.params.fid;

  const [openModal, setOpenModal] = useState(false);

  const [openAlert, setAlertOpen] = useState(false);
  const [status, setStatus] = useState('success');
  const [errorMessage, setErrorMessage] = useState('');

  const [form, setForm] = useState(undefined);
  const [formValues, setFormValues] = useState({});

  const [link, setLink] = useState('');
  const [hubspotID, setHubspotID] = useState('');
  const [loading, setLoading] = useState(false);

  const [loadingError, setLoadingError] = useState(false);

  const classes = useStyles();

  const generateLink = async () => {
    setLoading(true);
    if (hubspotID) {
      setLink(`hive.beetrack.com/forms/${fid}/${hubspotID}`);
    }
    setLoading(false);
  };

  const onChangeHandler = (e) => {
    setHubspotID(e.target.value);
  };

  const deleteForm = async () => {
    const response = await delForm(`/forms/delete/${fid}`);
    if (response.status) {
      setErrorMessage('Eliminado Correctamente');
      setStatus('success');
      setAlertOpen(true);
      window.location = '/forms';
    }
  };

  const handleForm = () => {
    return;
  };

  const editForm = () => {
    window.location = `/edit-form/${fid}`;
  };

  const setUpForm = async () => {
    const response = await get(`/forms/fetch_form/${fid}`);
    if (response.status === 200) {
      setForm(response.data.data);
    } else {
      setForm(undefined);
      setLoadingError(true);
    }
  };

  useEffect(() => {
    setUpForm();
  }, []);

  return (
    <div>
      <div>
        {form !== undefined ? (
          <div>
            {
              <div>
                <div className={classes.buttons}>
                  <IconButton
                    icon={<ArrowLeft />}
                    onClick={() => (window.location = '/forms')}
                  />
                  <div className={classes.buttons}>
                    <IconButton
                      icon={<Delete />}
                      onClick={() => setOpenModal(!openModal)}
                    />
                    <IconButton icon={<Edit />} onClick={() => editForm()} />
                  </div>
                </div>
                <Button disabled={!hubspotID} onClick={generateLink}>
                  Generar Link
                </Button>
                <TextField
                  label="Ingrese hubspot ID para obtener link"
                  placeholder="123456"
                  name="hubspot_id"
                  key="bid"
                  value={hubspotID}
                  onChange={onChangeHandler}
                />
                <div className={classes.subTitle}>{form.name}</div>
              </div>
            }
            {loading ? <LoadingCircle size="small" /> : link}
            <FormTranslator
              disableSubmit
              form={form}
              formValues={formValues}
              setFormValues={setFormValues}
              classes={classes}
              handleForm={handleForm}
              setAlertOpen={setAlertOpen}
              setStatus={setStatus}
              setErrorMessage={setErrorMessage}
            />
            <ConfirmationModal
              key="simple-modal"
              open={openModal}
              title="Eliminar Formulario"
              onClose={() => setOpenModal(!openModal)}
              confirmButton={<Button onClick={deleteForm}>Eliminar</Button>}
            >
              <div>Estas a punto de eliminar un formulario, estas seguro?</div>
            </ConfirmationModal>
          </div>
        ) : loadingError ? (
          <div>
            <WarningMessage
              message={'Oops.. algo salio mal, recarga la pagina'}
            />
          </div>
        ) : (
          <div className={classes.loading}>
            <LoadingCircle size="big" />
          </div>
        )}
      </div>
      <Snackbar
        isOpen={openAlert}
        message={errorMessage}
        onDismiss={() => setAlertOpen(false)}
        status={status}
      />
    </div>
  );
};

export default FormView;
