import React, { Component } from 'react';
import { Paper } from '@material-ui/core';
import { styles } from './styles';
import Oauth from './Oauth';
import { withStyles } from '@material-ui/core/styles';

class Setting extends Component {
  
  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.settingsPaper}>
        <Oauth />
      </Paper>
    );
  }
}

export default withStyles(styles)(Setting);
