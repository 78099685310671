import React, { Component } from 'react'
import { Table } from '@beetrack/hp-components'

class DebtorsTable extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return(
      <Table
        columns={this.props.columns}
        data={this.props.data}
        loading={this.props.loading}
        /> 
    )
  }
}

export default DebtorsTable
