import React, { useEffect, useState } from 'react';
import { havePermissions } from '../../../utils/utils';
import {
  Select,
  TextField,
  Button,
  Snackbar,
  DatePicker,
  Form,
  FormRow,
  FormGroup,
} from '@beetrack/hp-components';
import { post } from '../../../utils/api';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import BankData from './Components/BankData';
import FilePicker, { useFilePicker } from '@beetrack/hp-file-picker-react';

const NewSupplier = (props) => {
  const { history } = props;
  const permissionType = props.location.search;
  const { handleChangeFile, filePickerProps } = useFilePicker({
    allowedExtensions: ['.pdf'],
  });
  const [openSnack, setOpenSnack] = useState({
    open: false,
    message: '',
    status: 'success',
  });
  const [name, setName] = useState('');
  const [legalName, setLegalName] = useState('');
  const [address, setAddress] = useState('');
  const [activity, setActivity] = useState('');
  const [area, setArea] = useState();
  const [representantName, setRepresentantName] = useState('');
  const [country, setCountry] = useState('');
  const [taxIdentifier, setTaxIdentifier] = useState('');
  const [representantEmail, setRepresentantEmail] = useState('');
  const [representantPhone, setRepresentantPhone] = useState('');
  const [legalRepresentant, setLegalRepresentant] = useState('');
  const [legalRepresentantId, setLegalRepresentantId] = useState('');
  const [mode, setMode] = useState('');
  const [currency, setCurrency] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [due, setDue] = useState('');
  const [paymentType, setPaymentType] = useState('');
  const [quantity, setQuantity] = useState('');
  const [service, setService] = useState('');
  const [serviceStart, setServiceStart] = useState(
    moment().format('DD-MM-YYYY'),
  );
  const [serviceEnd, setServiceEnd] = useState(moment().format('DD-MM-YYYY'));
  const [price, setPrice] = useState('');
  const [owner, setOwner] = useState('');
  const [city, setCity] = useState('');
  const [bankData, setBankData] = useState({});
  const [contractFile, setContractFile] = useState('');
  const areaOptions = [
    { label: 'Customer Success', value: 'Customer Success' },
    { label: 'Desarrollo', value: 'Desarrollo' },
    { label: 'Marketing', value: 'Marketing' },
    { label: 'Management', value: 'Management' },
    { label: 'Operaciones', value: 'Operaciones' },
    { label: 'Producto', value: 'Producto' },
    { label: 'Revenue', value: 'Revenue' },
    { label: 'Ventas', value: 'Sales' },
    { label: 'HR', value: 'HR' },
  ];
  const currencyOptions = [
    { label: 'CLP', value: 'CLP' },
    { label: 'USD', value: 'USD' },
    { label: 'MXN', value: 'MXN' },
  ];
  const paymentOptions = [
    { label: 'Tarjeta de Crédito', value: 'Credit' },
    { label: 'Transferencia local (Chile o México)', value: 'Transfer' },
    { label: 'Transferencia internacional (USD)', value: 'Transfer itl' },
  ];
  const dueOptions = [
    { label: 'Pago inmediato', value: 'instant' },
    { label: '15 días', value: '15' },
    { label: '30 días', value: '30' },
  ];
  const typeOptions = [
    { label: 'Recurrente', value: 'recurring' },
    { label: 'Pre-pago', value: 'prepay' },
    { label: 'One time', value: 'single' },
  ];
  const modeOptions = [
    { label: 'Definido', value: 'defined' },
    { label: 'Indefinido', value: 'indefined' },
  ];

  const handleUploadContract = (event) => {
    setContractFile(event.target.files[0]);
  };

  const sendData = () => {
    const payload = {
      name,
      legalName,
      address,
      activity,
      area,
      representantName,
      country,
      taxIdentifier,
      representantEmail,
      representantPhone,
      legalRepresentant,
      legalRepresentantId,
      currency,
      paymentMethod,
      due,
      paymentType,
      quantity,
      service,
      serviceStart,
      serviceEnd,
      price,
      city,
      owner,
      bankData,
      contract_file: filePickerProps.file[0],
    };
    var formData = new FormData();
    for (const [key, value] of Object.entries(payload)) {
      formData.append(key, value);
    }
    post('/suppliers', formData).then((response) => {
      if (response.status === 201) {
        setOpenSnack({
          status: 'success',
          open: true,
          message: 'Proveedor creado correctamente',
        });
      } else if (response.status === 400) {
        setOpenSnack({
          status: 'error',
          message: response.data.message[0].Message,
          open: true,
        });
      } else if (response.status === 404) {
        setOpenSnack({
          status: 'error',
          message: response.data.message,
          open: true,
        });
      } else {
        setOpenSnack({
          status: 'error',
          message: 'Error creando proveedor, revisa los campos',
          open: true,
        });
      }
    });
  };

  useEffect(() => {
    if ((permissionType === '?team_leader') & !havePermissions('team_leader')) {
      history.push('/');
    } else if (
      permissionType === '?accounting' &&
      !havePermissions('accounting')
    ) {
      history.push('/');
    } else if (
      permissionType !== '?team_leader' &&
      permissionType !== '?accounting'
    ) {
      history.push('/');
    }
  }, []);

  return (
    <div>
      <Grid container justify="center">
        <Form
          title="Crear Proveedor"
          id="my-form"
          backTo={`/suppliers${permissionType}`}
          buttons={<Button onClick={() => sendData()}>Crear</Button>}
          internalScroll={false}
          tooltip="Completa la información para crear un nuevo Proveedor en Hive"
        >
          <FormGroup title="SECCIÓN EMPRESA" collapsible={false}>
            <h5
              style={{ paddingLeft: '2%' }}
              className="hp-typography__subtitle--medium"
            >
              {' '}
              Datos comerciales{' '}
            </h5>
            <FormRow>
              <TextField
                key="name"
                label="Nombre Proveedor"
                value={name}
                fullWidth
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                key="country"
                label="País"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                fullWidth
              />
            </FormRow>
            <FormRow>
              <TextField
                key="tax_id"
                label="RUT/RFC"
                value={taxIdentifier}
                onChange={(e) => setTaxIdentifier(e.target.value)}
                fullWidth
              />
              <TextField
                key="legal_name"
                label="Razón Social"
                value={legalName}
                fullWidth
                onChange={(e) => setLegalName(e.target.value)}
              />
            </FormRow>
            <FormRow>
              <TextField
                key="address"
                label="Dirección Comercial"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                fullWidth
              />
              <TextField
                key="city"
                label="Ciudad"
                value={city}
                fullWidth
                onChange={(e) => setCity(e.target.value)}
              />
            </FormRow>
            <FormRow>
              <TextField
                key="activity"
                label="Giro Comercial"
                value={activity}
                fullWidth
                onChange={(e) => setActivity(e.target.value)}
              />
            </FormRow>
          </FormGroup>
          <br />
          <h5
            style={{ paddingLeft: '2%' }}
            className="hp-typography__subtitle--medium"
          >
            {' '}
            Datos administrativos{' '}
          </h5>
          <FormRow>
            <TextField
              key="invoice_representative"
              label="Representante facturación"
              value={representantName}
              onChange={(e) => setRepresentantName(e.target.value)}
              fullWidth
            />
            <TextField
              key="representant_email"
              label="Email"
              value={representantEmail}
              fullWidth
              onChange={(e) => setRepresentantEmail(e.target.value)}
            />
          </FormRow>
          <FormRow>
            <TextField
              key="representant_phone"
              label="Teléfono"
              value={representantPhone}
              fullWidth
              onChange={(e) => setRepresentantPhone(e.target.value)}
            />
          </FormRow>
          <h5
            style={{ paddingLeft: '2%' }}
            className="hp-typography__subtitle--medium"
          >
            {' '}
            Datos legales
          </h5>
          <FormRow>
            <TextField
              key="legal_representative"
              label="Representante legal"
              value={legalRepresentant}
              onChange={(e) => setLegalRepresentant(e.target.value)}
              fullWidth
            />
            <TextField
              key="legal_representative_id"
              label="RUT/RFC"
              value={legalRepresentantId}
              fullWidth
              onChange={(e) => setLegalRepresentantId(e.target.value)}
            />
          </FormRow>
          <br />
          <FormGroup title="SECCIÓN COMERCIAL" collapsible={false}>
            <h5
              style={{ paddingLeft: '2%' }}
              className="hp-typography__subtitle--medium"
            >
              {' '}
              Datos del contrato{' '}
            </h5>
            <FormRow>
              <Select
                key="currency"
                options={currencyOptions}
                label="Selecciona moneda"
                value={currency}
                onChange={(e) => setCurrency(e.value)}
              />
              <Select
                key="due"
                options={dueOptions}
                label="Plazo de pago"
                value={due}
                onChange={(e) => setDue(e.value)}
              />
            </FormRow>
            <FormRow>
              <Select
                key="payment_method"
                options={paymentOptions}
                label="Selecciona forma de pago"
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.value)}
              />
            </FormRow>
            <br />
            {(paymentMethod === 'Transfer' ||
              paymentMethod === 'Transfer itl') && (
              <BankData
                setBankData={setBankData}
                paymentMethod={paymentMethod}
                country={country}
              />
            )}
            <h5
              style={{ paddingLeft: '2%' }}
              className="hp-typography__subtitle--medium"
            >
              {' '}
              Datos de las condiciones comerciales{' '}
            </h5>
            <FormRow>
              <TextField
                key="service"
                label="Servicio contratado"
                value={service}
                fullWidth
                onChange={(e) => setService(e.target.value)}
              />
              <Select
                key="mode"
                options={modeOptions}
                label="Selecciona modalidad"
                value={mode}
                onChange={(e) => setMode(e.value)}
              />
            </FormRow>
            <FormRow>
              <TextField
                key="quantity"
                label="Ingresa n° de licencias"
                value={quantity}
                fullWidth
                onChange={(e) => setQuantity(e.target.value)}
              />
              <TextField
                key="price"
                label="Tarifa (unidad de medida)"
                value={price}
                fullWidth
                onChange={(e) => setPrice(e.target.value)}
                tooltip="holas"
              />
            </FormRow>
            <FormRow>
              <DatePicker
                label="Fecha de inicio de servicio"
                date={serviceStart}
                onChange={(value) => {
                  setServiceStart(value);
                }}
              />
              <DatePicker
                label="Fecha de corte de servicio"
                date={serviceEnd}
                onChange={(value) => {
                  setServiceEnd(value);
                }}
              />
            </FormRow>
            <br />
            <Select
              key="payment_type"
              options={typeOptions}
              label="Selecciona frecuencia de pago"
              value={paymentType}
              onChange={(e) => setPaymentType(e.value)}
            />
          </FormGroup>
          <br />
          <FormGroup title="SECCIÓN BEETRACK" collapsible={false}>
            <h5
              style={{ paddingLeft: '2%' }}
              className="hp-typography__subtitle--medium"
            >
              {' '}
              Datos responsable contratación{' '}
            </h5>
            <FormRow>
              <TextField
                key="owner"
                label="Owner Proveedor"
                value={owner}
                fullWidth
                onChange={(e) => setOwner(e.target.value)}
              />
              <Select
                key="area"
                options={areaOptions}
                label="Selecciona equipo"
                value={area}
                onChange={(e) => setArea(e.value)}
              />
            </FormRow>
          </FormGroup>
          <h5
            style={{ paddingLeft: '2%' }}
            className="hp-typography__subtitle--medium"
          >
            {' '}
            Adjunta el contrato{' '}
          </h5>
          <FilePicker
            onChange={handleChangeFile}
            value={filePickerProps.file}
            {...filePickerProps}
          />
        </Form>
      </Grid>
      <Snackbar
        isOpen={openSnack.open}
        message={openSnack.message}
        onDismiss={() =>
          setOpenSnack({ open: false, status: 'success', message: '' })
        }
        status={openSnack.status}
      />
    </div>
  );
};

export default NewSupplier;
