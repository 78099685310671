import React, { useState, useEffect } from 'react';
import '@beetrack/hp-styles';
import {
  TextField,
  Select,
  Button as HPButton,
  Form,
  FormRow,
  FormGroup,
} from '@beetrack/hp-components';
import DateFnsUtils from '@date-io/date-fns';
import { hr_new_post, hr_new_get } from '../../../../utils/api';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';

const FormOther = (props) => {
  const uuid = props.uuid;
  const [name, setName] = useState(props.name);
  const [phone, setPhone] = useState('');
  const [dob, setDob] = useState('1-1-2020');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState(props.email);
  const [city, setCity] = useState('');
  const [country, setCountry] = useState(props.country);
  const [nationality, setNationality] = useState('');
  const [emergencyName, setEmergencyName] = useState('');
  const [emergencyPhone, setEmergencyPhone] = useState('');
  const [maritalStatus, setMaritalStatus] = useState('');
  const [completed, setCompleted] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [identityFile, setIdentityFile] = useState('no identity');

  const handleUploadIdentity = (event) => {
    setIdentityFile(event.target.files[0]);
  };

  const handleSendForm = () => {
    setDisableButton(true);
    let send = true;
    let payload = {
      dob: moment(dob).format('DD/MM/YYYY'),
      name: name,
      email: email,
      marital_status: maritalStatus,
      address: address,
      country: country,
      city: city,
      phone: phone,
      nationality: nationality,
      emergency_name: emergencyName,
      emergency_phone: emergencyPhone,
      identity_file: identityFile,
    };
    var formData = new FormData();
    for (const [key, value] of Object.entries(payload)) {
      if (value === '' || value === {}) {
        send = false;
        setErrorMessage('Falta completar campos');
      } else {
        formData.append(key, value);
      }
    }
    if (send) {
      hr_new_post('/onboarding-form/' + uuid, formData).then((res) => {
        if (res.status === 200) {
          setCompleted(true);
          setDisableButton(false);
        }
      });
    }
  };
  const status = [
    { label: 'Soltero/a', value: 'Soltero' },
    { label: 'Casado/a', value: 'Casado' },
    { label: 'Divorciado/a', value: 'Divorciado' },
    { label: 'Viudo/a', value: 'Viudo' },
  ];
  useEffect(() => {
    hr_new_get('/onboarding-form/' + uuid).then((res) => {
      if (res.status === 200) {
        let candidate = res.data;
        setName(candidate.name);
        setCountry(candidate.country);
        setEmail(candidate.personal_email);
      }
    });
  }, []);

  return (
    <>
      {!completed && (
        <Form
          title="¡Bienvenido a Beetrack - DispatchTrack!"
          buttons={[
            <HPButton
              key="send-form"
              type="primary"
              onClick={() => handleSendForm()}
              disabled={disableButton}
            >
              Enviar formulario
            </HPButton>,
          ]}
        >
          <FormGroup title="Datos personales" collapsible={false}>
            <FormRow>
              <TextField
                fullWidth
                key="name"
                onChange={(e) => setName(e.target.value)}
                placeholder="Nombre Completo"
                label="Nombre Completo"
                value={name}
              />
              <TextField
                fullWidth
                key="nationality"
                onChange={(e) => setNationality(e.target.value)}
                placeholder="Nacionalidad"
                label="Nacionalidad"
                value={nationality}
              />
            </FormRow>
            <FormRow>
              <TextField
                fullWidth
                key="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Correo Personal"
                label="Correo Personal"
                value={email}
              />
              <Select
                key="maritalStatus"
                options={status}
                label="Estado Civil"
                value={maritalStatus}
                onChange={(e) => setMaritalStatus(e.value)}
              />
            </FormRow>
            <FormRow>
              <TextField
                fullWidth
                key="phone"
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Teléfono Móvil"
                label="Teléfono Móvil"
                value={phone}
              />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  style={{ width: '50%' }}
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Fecha de Nacimiento"
                  value={dob}
                  onChange={(date) => setDob(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  invalidDateMessage="Fecha inválida"
                />
              </MuiPickersUtilsProvider>
            </FormRow>
          </FormGroup>
          <FormGroup title="Dirección" collapsible={false}>
            <FormRow>
              <TextField
                fullWidth
                key="address"
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Dirección"
                label="Dirección"
                value={address}
              />
              <TextField
                fullWidth
                key="city"
                onChange={(e) => setCity(e.target.value)}
                placeholder="Ciudad"
                label="Ciudad"
                value={city}
              />
              <TextField
                fullWidth
                key="country"
                onChange={(e) => setCountry(e.target.value)}
                placeholder="País"
                label="País"
                value={country}
              />
            </FormRow>
          </FormGroup>
          <FormGroup title="Contacto de emergencia" collapsible={false}>
            <FormRow>
              <TextField
                fullWidth
                key="emergencyName"
                onChange={(e) => setEmergencyName(e.target.value)}
                placeholder="Nombre"
                label="Nombre contacto emergencia"
                value={emergencyName}
              />
              <TextField
                fullWidth
                key="emergencyPhone"
                onChange={(e) => setEmergencyPhone(e.target.value)}
                placeholder="Teléfono"
                label="Teléfono Emergencia"
                value={emergencyPhone}
              />
            </FormRow>
          </FormGroup>
          <FormGroup title="Documento de identidad" collapsible={false}>
            <FormRow>
              <div>
                <input
                  style={{ display: 'none' }}
                  id="contained-button-file-identity"
                  multiple
                  type="file"
                  onChange={(e) => handleUploadIdentity(e)}
                  accept="image/jpeg"
                />
                <label htmlFor="contained-button-file-identity">
                  <Button
                    style={{ marginLeft: '24px' }}
                    startIcon={<CloudUploadIcon />}
                    variant="contained"
                    color="primary"
                    component="span"
                  >
                    {identityFile !== 'no identity'
                      ? identityFile.name
                      : 'Subir imagen frontal de documento de identidad'}
                  </Button>
                </label>
              </div>
            </FormRow>
          </FormGroup>
          <p style={{ color: 'red' }}>
            {' '}
            <strong> {errorMessage} </strong>{' '}
          </p>
        </Form>
      )}
      {completed && (
        <center>
          <h3>
            {' '}
            ¡Gracias por completar el formulario, serás contactad@ en breve!
          </h3>
        </center>
      )}
    </>
  );
};

export default FormOther;
