import React, { Component } from 'react';
import './styles.scss';
import {
  FormRow,
  TextField,
  Radio,
  Form,
  Select,
  Button,
  DatePicker,
  Snackbar,
  Checkbox,
  Page,
} from '@beetrack/hp-components';
import { countries } from '../../../utils/countries.js';
import { timezones } from '../../../utils/timezones.js';
import { post } from '../../../utils/api.js';

class CreateAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      languages: [
        { label: 'Español', value: 'es' },
        { label: 'Inglés', value: 'en' },
      ],
      countries: countries,
      timezones: timezones,
      show_activation_type: false,
      account: {
        name: '',
        email: '',
        language: '',
        timezone: '',
        industry: 'b2b',
        country: '',
        activation_type: 'perm',
        production: true,
        valid_until: this.formatDate(new Date()),
      },
      radios: {
        b2b: true,
        b2c: false,
        tpl: false,
        other: false,
      },
      type_radios: {
        temp: false,
        perm: true,
      },
      first_load: true,
      validate_fields: ['name', 'email', 'language', 'country', 'timezone'],
      button_disabled: false,
      snackbarState: false,
      snackbar_message: '',
      snackbar_status: 'success',
    };
  }

  onDateChange = (date) => {
    let account = this.state.account;
    account['valid_until'] = date;
    this.setState({ account: account });
  };

  onProductionChange = (production) => {
    let account = this.state.account;
    account['production'] = production;
    this.setState({ account: account });
  };

  formatDate = (date) => {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
      month = `0${month}`;
    }
    return `${day}-${month}-${year}`;
  };

  setValue = (value) => {
    let tmp_value = value;
    let account = this.state.account;
    account[event.target.name] = tmp_value;
    this.setState({ account: account });
  };

  handleRadioChange = (event) => {
    let account = this.state.account;
    let radios = this.state.radios;
    Object.keys(radios).map((radio) => (radios[radio] = false));
    radios[event.target.name] = true;
    account['industry'] = event.target.name;
    this.setState({ radios: radios, account: account });
  };

  handleTypeRadioChange = (event) => {
    let account = this.state.account;
    let type_radios = this.state.type_radios;
    Object.keys(type_radios).map((radio) => (type_radios[radio] = false));
    type_radios[event.target.name] = true;
    account['activation_type'] = event.target.name;
    this.setState({ type_radios: type_radios, account: account });
    this.handleTempDatePicker(event.target.name);
  };

  handleTempDatePicker = (activation_type) => {
    if (activation_type === 'temp') {
      this.setState({ show_activation_type: true });
    } else {
      this.setState({ show_activation_type: false });
    }
  };

  cleanFields = () => {
    let account = this.state.account;
    this.state.validate_fields.forEach((field) => {
      account[field] = '';
    });
    this.setState({ account: account, first_load: true });
  };

  validateForm = (states) => {
    let valid = true;
    this.state.validate_fields.forEach((field) => {
      if (states[field].length === 0) {
        valid = false;
      }
    });
    return valid;
  };

  handleSubmit = () => {
    this.setState({ first_load: false });
    let valid_form = this.validateForm(this.state.account);

    if (valid_form === true) {
      this.setState({ button_disabled: true });
      post('/beetrack/accounts/actions', this.state.account).then(
        (response) => {
          if (response.status === 200) {
            this.cleanFields();
            this.setState({
              button_disabled: false,
              snackbar_message: response.data.message,
              snackbar_status: 'success',
            });
            this.setOpen(true);
          } else {
            this.setState({
              button_disabled: false,
              snackbar_message: response.data.message,
              snackbar_status: 'error',
            });
            this.setOpen(true);
          }
        },
      );
    } else {
      this.setState({
        snackbar_message: 'Faltan datos por completar en el formulario',
        snackbar_status: 'error',
      });
      this.setOpen(true);
    }
  };

  setOpen = (open) => {
    this.setState({ snackbar_state: open });
  };

  handleSelectChange = (event, selector) => {
    let account = this.state.account;
    account[selector] = event.value;
    this.setState({ account: account });
  };

  render() {
    let show_activation_type = this.state.show_activation_type;
    return (
      <div style={{ height: '875px' }}>
        <Form title="Nueva Cuenta Beetrack">
          <div className="row">
            <div className="column">
              <FormRow>
                <TextField
                  name="name"
                  value={this.state.account.name}
                  label="Nombre de la Cuenta"
                  onChange={(e) => this.setValue(e.target.value)}
                />
              </FormRow>
              {this.state.account.name.length === 0 &&
              !this.state.first_load ? (
                <span className={'error'}>Este campo es requerido</span>
              ) : (
                <div className={'span-space'}></div>
              )}
            </div>
            <div className="column">
              <div className="admin-email-box">
                <FormRow>
                  <TextField
                    name="email"
                    value={this.state.account.email}
                    label="Email del Administrador"
                    onChange={(e) => this.setValue(e.target.value)}
                  />
                </FormRow>
                {this.state.account.email.length === 0 &&
                !this.state.first_load ? (
                  <span className={'error'}>Este campo es requerido</span>
                ) : (
                  <div className={'span-space'}></div>
                )}
              </div>
            </div>
          </div>
          <div className="select-group row">
            <div className={'column'}>
              <Select
                name="language"
                value={this.state.account.language}
                options={this.state.languages}
                label="Idioma"
                onChange={(e) => this.handleSelectChange(e, 'language')}
              />
              {this.state.account.language.length === 0 &&
              !this.state.first_load ? (
                <span className={'error'}>Este campo es requerido</span>
              ) : (
                <div className={'span-space'}></div>
              )}
            </div>
            <div className={'column'}>
              <Select
                name="country"
                value={this.state.account.country}
                className="hp-select"
                options={this.state.countries}
                onChange={(e) => this.handleSelectChange(e, 'country')}
                label="Pais"
              />
              {this.state.account.country.length === 0 &&
              !this.state.first_load ? (
                <span className={'error'}>Este campo es requerido</span>
              ) : (
                <div className={'span-space'}></div>
              )}
            </div>
            <div className={'column'}>
              <Select
                name="timezone"
                value={this.state.account.timezone}
                options={this.state.timezones}
                label="Zona Horaria"
                onChange={(e) => this.handleSelectChange(e, 'timezone')}
              />
              {this.state.account.timezone.length === 0 &&
              !this.state.first_load ? (
                <span className={'error'}>Este campo es requerido</span>
              ) : (
                <div className={'span-space'}></div>
              )}
            </div>
          </div>
          <div className="select-group row">
            <Checkbox
              label="Production"
              checked={this.state.account.production}
              onChange={(e) => this.onProductionChange(e.target.checked)}
              name="checkbox"
            />
          </div>
          <h2 className="h2">Industria</h2>
          <FormRow>
            <Radio
              label="B2B"
              name="b2b"
              value="b2b"
              checked={this.state.radios.b2b}
              onChange={(e) => this.handleRadioChange(e)}
            />
            <Radio
              label="B2C"
              name="b2c"
              value="b2c"
              checked={this.state.radios.b2c}
              onChange={(e) => this.handleRadioChange(e)}
            />
            <Radio
              label="3PL"
              name="tpl"
              value="tpl"
              checked={this.state.radios.tpl}
              onChange={(e) => this.handleRadioChange(e)}
            />
            <Radio
              label="Other"
              name="other"
              value="other"
              checked={this.state.radios.other}
              onChange={(e) => this.handleRadioChange(e)}
            />
          </FormRow>
          <h2 className="h2">Tipo de Activación</h2>
          <FormRow>
            <Radio
              label="Temporal"
              name="temp"
              value="temp"
              checked={this.state.type_radios.temp}
              onChange={(e) => this.handleTypeRadioChange(e)}
            />
            <Radio
              label="Permanente"
              name="perm"
              value="perm"
              checked={this.state.type_radios.perm}
              onChange={(e) => this.handleTypeRadioChange(e)}
            />
          </FormRow>
          <FormRow>
            {show_activation_type ? (
              <DatePicker
                inputLabel="date"
                date={this.state.account.valid_until}
                onChange={(value) => {
                  this.onDateChange(value);
                }}
              />
            ) : (
              <div />
            )}
          </FormRow>
          <div className="submit-button">
            <Button
              disabled={this.state.button_disabled}
              onClick={() => this.handleSubmit()}
              primary
            >
              Crear Cuenta
            </Button>
          </div>
          <Snackbar
            key="snackbar"
            isOpen={this.state.snackbar_state}
            message={this.state.snackbar_message}
            onDismiss={() => this.setOpen(false)}
            status={this.state.snackbar_status}
          />
        </Form>
      </div>
    );
  }
}

export default CreateAccount;
