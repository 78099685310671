import React from 'react';
import { Select } from '@beetrack/hp-components';
import { Grid } from '@mui/material';

import { getNestedValue, sortKeys } from '../utils';

import TextElementTranslator from './TextElement';
import BigTextElementTranslator from './BigTextElement';
import NumberElementTranslator from './NumberElement';
import DateElementTranslator from './DateElement';
import SimpleElementTranslator from './SimpleElement';
import MultipleElementTranslator from './MultipleElement';
import FileElementTranslator from './FileElement';

const DependantElementTranslator = (props) => {
  const {
    classes,
    form,
    formValues,
    setFormValues,
    path,
    simpleChangeHandler,
    textChangeHandler,
    numberChangeHandler,
    fileChangeHandler,
    dependant,
  } = props;

  const element = getNestedValue(form.content, path);

  return (
    <Grid item sm={12} md={dependant ? 12 : 10} className={classes.element}>
      <div className={classes.question}>{element.question}</div>
      {element.imgUrl && (
        <div className={classes.imageContainer}>
          <img
            style={{ width: '100%', height: '100%' }}
            src={element.imgUrl}
            alt="new"
          />
        </div>
      )}
      <div className={classes.selectField}>
        <Select
          helperText={element.helperText}
          value={formValues[`${element.propertyName}`]}
          label={element.title}
          options={Object.values(element.options)}
          onChange={(e) => simpleChangeHandler(e, element.propertyName)}
          required={element.required}
        />
        {sortKeys(element.options).map((option) => {
          return (
            <div>
              {element.options[option].value ===
                formValues[`${element.propertyName}`] && (
                <div>
                  {sortKeys(element.options[option].set).map((Ekey) => {
                    if (element.options[option].set[Ekey].type === 'text') {
                      return (
                        <TextElementTranslator
                          classes={classes}
                          form={form}
                          formValues={formValues}
                          path={[...path, 'options', option, 'set', Ekey]}
                          textChangeHandler={textChangeHandler}
                          dependant={true}
                        />
                      );
                    } else if (
                      element.options[option].set[Ekey].type === 'bigText'
                    ) {
                      return (
                        <BigTextElementTranslator
                          classes={classes}
                          form={form}
                          formValues={formValues}
                          path={[...path, 'options', option, 'set', Ekey]}
                          textChangeHandler={textChangeHandler}
                          dependant={true}
                        />
                      );
                    } else if (
                      element.options[option].set[Ekey].type === 'number'
                    ) {
                      return (
                        <NumberElementTranslator
                          classes={classes}
                          form={form}
                          formValues={formValues}
                          path={[...path, 'options', option, 'set', Ekey]}
                          numberChangeHandler={numberChangeHandler}
                          dependant={true}
                        />
                      );
                    } else if (
                      element.options[option].set[Ekey].type === 'date'
                    ) {
                      return (
                        <DateElementTranslator
                          classes={classes}
                          form={form}
                          formValues={formValues}
                          setFormValues={setFormValues}
                          path={[...path, 'options', option, 'set', Ekey]}
                          dependant={true}
                        />
                      );
                    } else if (
                      element.options[option].set[Ekey].type === 'simple'
                    ) {
                      return (
                        <SimpleElementTranslator
                          classes={classes}
                          form={form}
                          formValues={formValues}
                          path={[...path, 'options', option, 'set', Ekey]}
                          simpleChangeHandler={simpleChangeHandler}
                          dependant={true}
                        />
                      );
                    } else if (
                      element.options[option].set[Ekey].type === 'multiple'
                    ) {
                      return (
                        <MultipleElementTranslator
                          classes={classes}
                          form={form}
                          formValues={formValues}
                          setFormValues={setFormValues}
                          path={[...path, 'options', option, 'set', Ekey]}
                          simpleChangeHandler={simpleChangeHandler}
                          dependant={true}
                        />
                      );
                    } else if (
                      element.options[option].set[Ekey].type === 'dependant'
                    ) {
                      return (
                        <DependantElementTranslator
                          classes={classes}
                          form={form}
                          formValues={formValues}
                          path={[...path, 'options', option, 'set', Ekey]}
                          simpleChangeHandler={simpleChangeHandler}
                          dependant={true}
                        />
                      );
                    } else if (
                      element.options[option].set[Ekey].type === 'file'
                    ) {
                      return (
                        <FileElementTranslator
                          classes={classes}
                          form={form}
                          formValues={formValues}
                          path={[...path, 'options', option, 'set', Ekey]}
                          simpleChangeHandler={simpleChangeHandler}
                          dependant={true}
                        />
                      );
                    }
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </Grid>
  );
};

export default DependantElementTranslator;
