import React, { useState } from 'react';
import {
  Modal,
  TextField,
  Button,
  DatePicker,
  Select,
  Snackbar,
} from '@beetrack/hp-components';
import { get, post, patch } from '../../../../utils/api';
import { Grid } from '@mui/material';
import moment from 'moment';

const EditPrepaid = (props) => {
  const {
    open,
    setOpen,
    dealId,
    setDealId,
    startDate,
    setStartDate,
    prepaidMonths,
    setPrepaidMonths,
    quantity,
    setQuantity,
    product,
    setProduct,
    edit,
    closeModal,
    setDealName,
    dealName,
    blockEdit,
    setBlockEdit,
    planId,
    reload,
    setReload,
    prepaidId,
    setPrepaidId,
    setSnackBar,
  } = props;
  const [beetrackId, setBeetrackId] = useState('');
  const [options, setOptions] = useState([]);

  const prepaidMonthsOptions = [
    { label: '6', value: 6 },
    { label: '12', value: 12 },
  ];

  const handleEditPrepaid = () => {
    setBlockEdit(true);
    if (edit) {
      var payload = {
        from: startDate,
        to: obtainToDate(startDate, prepaidMonths),
        qty: quantity,
      };
      patch(`/prepaids/${prepaidId}`, payload).then((response) => {
        if (response.status === 200) {
          setReload(reload + 1);
          setSnackBar({
            open: true,
            message: 'Prepago editado exitosamente',
            status: 'success',
          });
          closeModal();
        } else if (response.status === 400) {
          setSnackBar({
            open: true,
            message: 'Error validando prepago, intente nuevamente',
            status: 'error',
          });
          setBlockEdit(false);
        } else {
          setSnackBar({
            open: true,
            message: 'Error desde servidor, intente nuevamente',
            status: 'error',
          });
          setBlockEdit(false);
        }
      });
    } else {
      var payload = {
        hubspot_id: dealId,
        product: product,
        from: startDate,
        to: obtainToDate(startDate, prepaidMonths),
        qty: quantity,
      };
      post('/prepaids', payload).then((response) => {
        if (response.status === 201) {
          setReload(reload + 1);
          closeModal();
          setSnackBar({
            open: true,
            message: 'Prepago creado exitosamente',
            status: 'success',
          });
        } else if (response.status === 400) {
          setSnackBar({
            open: true,
            message: 'Error validando prepago, intente nuevamente',
            status: 'error',
          });
          setBlockEdit(false);
        } else {
          setSnackBar({
            open: true,
            message: 'Error desde servidor, intente nuevamente',
            status: 'error',
          });
          setBlockEdit(false);
        }
      });
    }
  };

  const searchDeal = () => {
    get(`/prepaids/${beetrackId}`).then((response) => {
      if (response.status === 200) {
        setBlockEdit(true);
        setDealName(response.data.dealname);
        setOptions(response.data.plans);
        setDealId(response.data.hubspot_id);
        setBlockEdit(false);
      } else if (response.status === 400) {
        setSnackBar({
          open: true,
          status: 'error',
          message: 'El beetrack ID solicitado no existe',
        });
      }
    });
  };

  const obtainToDate = (startDate, months) => {
    // Change format of start date so moment() can use it to add the prepaidMonths
    const [day, month, year] = startDate.split('/');
    const formatStartDate = [month, day, year].join('/');
    const to = moment(formatStartDate).add(months, 'M').calendar();
    // Now go back to format DD/MM/YYYY to return
    const [toMonth, toDay, toYear] = to.split('/');
    return [toDay, toMonth, toYear].join('/');
  };

  const manageStartDate = (startDate) => {
    // if startDate is after the 15th of the month, it will be fixed
    // as the 1st of the next month, else it will be fixed
    // as the 1st of the current month
    const [day, month, year] = startDate.split('/');
    const formatStartDate = [year, month, day].join('/');
    const startOfMonth = moment(formatStartDate).startOf('month');
    if (
      moment(formatStartDate).diff(startOfMonth.format('YYYY/MM/DD'), 'days') >=
      15
    ) {
      return obtainToDate(startOfMonth.format('DD/MM/YYYY'), 1);
    }
    return startOfMonth.format('DD/MM/YYYY');
  };

  return (
    <>
      <Modal
        key="edit-prepaid-modal"
        open={open}
        title={edit ? 'Editar prepago' : 'Nuevo prepago'}
        aside
        onClose={() => closeModal()}
        confirmButton={
          <Button
            type="primary"
            onClick={() => handleEditPrepaid()}
            disabled={blockEdit}
          >
            Confirmar y {edit ? 'editar' : 'crear'}
          </Button>
        }
      >
        <div className="modal__aside--form-container">
          <div className="modal__aside--form">
            {!edit && (
              <div className="modal__aside--form-row">
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <TextField
                      label="Beetrack ID"
                      fullWidth
                      value={beetrackId}
                      onChange={(e) => setBeetrackId(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Button onClick={() => searchDeal()}>Buscar</Button>
                  </Grid>
                </Grid>
              </div>
            )}
            <br />
            <div className="modal__aside--form-row">
              <TextField
                label="Nombre deal"
                fullWidth
                value={dealName}
                disabled
              />
            </div>
            <br />
            <div className="modal__aside--form-row">
              {!edit && (
                <Select
                  key="product-select"
                  value={product}
                  options={options}
                  label="Producto"
                  onChange={(option) => {
                    setProduct(option.value);
                  }}
                  disabled={blockEdit}
                />
              )}
              {edit && (
                <TextField
                  label="Producto"
                  fullWidth
                  value={product}
                  disabled
                />
              )}
            </div>
            <br />
            <div className="modal__aside--form-row">
              <TextField
                label="Cantidad prepagada"
                fullWidth
                type="number"
                value={quantity}
                disabled={blockEdit}
                onChange={(e) => setQuantity(e.target.value)}
              />
            </div>
            <br />
            <div className="modal__aside--form-row">
              <DatePicker
                label="Inicio de prepago"
                onChange={(value) => {
                  setStartDate(manageStartDate(value));
                }}
                format="DD/MM/YYYY"
                date={startDate}
                disabled={blockEdit}
              />
            </div>
            <br />
            <div className="modal__aside--form-row">
              <Select
                key="prepaid-months-select"
                label="Meses de prepago"
                options={prepaidMonthsOptions}
                value={prepaidMonths}
                onChange={(option) => {
                  setPrepaidMonths(option.value);
                }}
                disabled={blockEdit}
              />
              <p class="advice">
                <small>*El mes de prepago es siempre de 01 a 30/31.</small>
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditPrepaid;
