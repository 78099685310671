import React, { useEffect, useState } from 'react';
import { Chip, Form, FormRow, TextField } from '@beetrack/hp-components';
import {
  activePrepaid,
  expirationRangeActive,
} from '../../../../utils/commercialConditions/activePrepaid';
import {
  generateVariables,
  generateBase,
  generateMinimum,
  generateStep,
  GeneratePrepaids,
} from '../../../CommercialConditions/CommercialCondition/productForm/generators/index';

const ConditionShow = (props) => {
  const { plan, hubspotId } = props;

  return (
    <div>
      <Form
        title={
          <div>
            {plan.name}{' '}
            {activePrepaid(plan.prepaids) ? <Chip text="Prepago" /> : <></>}{' '}
            {expirationRangeActive(plan.prepaids) ? (
              <Chip text="Prepago por vencer" type="danger" />
            ) : (
              <></>
            )}
          </div>
        }
      >
        {generateMinimum(plan, null, null, null, null, true)}
        {generateStep(plan, null, null, true)}
        {generateBase(plan, null, null, true)}
        {generateVariables(plan, null, null, true)}
        <GeneratePrepaids
          plan={plan}
          hubspotId={hubspotId}
          renewal={expirationRangeActive(plan.prepaids)}
        />
      </Form>
    </div>
  );
};

export default ConditionShow;
