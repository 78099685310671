import React, { Component, useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  Radio,
  Select,
  TextField,
  Card,
  CardContent,
  Button,
  Snackbar,
  DatePicker,
  CardDivider,
  Loading,
  Page,
  Form,
  FormRow,
} from '@beetrack/hp-components';
import { get, post } from '../../../utils/api';
import moment from 'moment';
import { Grid } from '@material-ui/core';

const HRNewOnboarding = (props) => {
  const { history } = props;
  const id = props.match.params.id;
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [name, setName] = useState('');
  const [personalEmail, setPersonalEmail] = useState('');
  const [onboardingDate, setOnboardingDate] = useState(
    moment().format('DD-MM-YYYY'),
  );
  const [rut, setRut] = useState('');
  const [position, setPosition] = useState('');
  const [area, setArea] = useState();
  const [contractType, setContractType] = useState();
  const [country, setCountry] = useState('Chile');
  const [workEmail, setWorkEmail] = useState('correo@beetrack.com');
  const [radioButton, setRadioButton] = useState(true);
  const [disabledButton, setDisabledButton] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fixedMonth, setFixedMonth] = useState(1);
  const [offboardingDate1, setOffboardingDate1] = useState(
    moment().add(2, 'months').format('DD-MM-YYYY'),
  );
  const [offboardingDate2, setOffboardingDate2] = useState(
    moment().add(4, 'months').format('DD-MM-YYYY'),
  );

  const areaOptions = [
    { label: 'Customer Experience', value: 'Customer Experience' },
    { label: 'Finance', value: 'Finance' },
    { label: 'Marketing', value: 'Marketing' },
    { label: 'Producto', value: 'Producto' },
    { label: 'Sales', value: 'Sales' },
    { label: 'HR', value: 'HR' },
  ];
  const contractOptions = [
    { label: 'Contrato indefinido', value: 'indefinido' },
    { label: 'Plazo fijo', value: 'fijo' },
  ];
  const fixedMonthOptions = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
  ];
  const handleFormSend = () => {
    setDisabledButton(true);
    if (
      name === '' ||
      personalEmail === '' ||
      workEmail === 'correo@beetrack.com' ||
      position === '' ||
      workEmail === ''
    ) {
      setOpenSnack(true);
      setSnackMessage('Falta completar datos necesarios');
      setDisabledButton(false);
    } else {
      let payload = {
        name,
        personalEmail,
        workEmail,
        onboardingDate,
        rut,
        position,
        area,
        contractType,
        country,
        fixedMonth,
        offboardingDate1,
        offboardingDate2,
      };
      post(`/candidate/${id}`, payload).then((response) => {
        if (response.status === 200) {
          history.push(`/hr/candidate/${id}`);
        } else {
          setOpenSnack(true);
          setSnackMessage('Error ingresando los datos');
          setDisabledButton(false);
        }
      });
    }
  };

  useEffect(() => {
    get(`/candidate/${id.toString()}`).then((response) => {
      if (response.status === 200) {
        let candidate = response.data;
        setName(candidate.name);
        setWorkEmail(candidate.work_email);
        setPersonalEmail(candidate.personal_email);
        setWorkEmail(candidate.work_email);
        setOnboardingDate(
          moment(candidate.onboarding_date).format('DD-MM-YYYY'),
        );
        setPosition(candidate.position);
        setContractType(candidate.contract_type);
        setArea(candidate.area);
        setRut(candidate.rut);
        candidate.country === 'Chile'
          ? setRadioButton(true)
          : setRadioButton(false);
        setCountry(candidate.country);
        setLoading(false);
        if (
          candidate.contract_type == 'fijo' &&
          candidate.offboarding_date_1 &&
          !candidate.offboarding_date_2
        ) {
          setOffboardingDate1(
            moment(candidate.offboarding_date_1).format('DD-MM-YYYY'),
          );
          setFixedMonth(1);
        }
        if (
          candidate.contract_type == 'fijo' &&
          candidate.offboarding_date_1 &&
          candidate.offboarding_date_2
        ) {
          setOffboardingDate1(
            moment(candidate.offboarding_date_1).format('DD-MM-YYYY'),
          );
          setOffboardingDate2(
            moment(candidate.offboarding_date_2).format('DD-MM-YYYY'),
          );
          setFixedMonth(2);
        }
      }
    });
  }, []);
  return (
    <div>
      {loading && (
        <center>
          <Loading hasText />
        </center>
      )}
      {!loading && (
        <Form
          title="Editando Candidato"
          buttons={[
            <Button
              type="tertiary"
              disabled={disabledButton}
              onClick={() => history.push(`/hr/candidate/${id}`)}
            >
              Volver
            </Button>,
            <Button disabled={disabledButton} onClick={() => handleFormSend()}>
              Actualizar
            </Button>,
          ]}
        >
          <FormRow>
            <TextField
              key="name"
              label="Nombre Completo"
              value={name}
              fullWidth
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              key="personal_email"
              label="Correo personal"
              value={personalEmail}
              onChange={(e) => setPersonalEmail(e.target.value)}
              fullWidth
            />
          </FormRow>
          <FormRow>
            <TextField
              key="work_email"
              label="Correo Beetrack"
              value={workEmail}
              onChange={(e) => setWorkEmail(e.target.value)}
              fullWidth
            />
            <DatePicker
              label="Fecha de ingreso"
              date={onboardingDate}
              onChange={(value) => {
                setOnboardingDate(value);
              }}
            />
          </FormRow>
          <FormRow>
            <TextField
              key="position"
              label="Cargo"
              value={position}
              fullWidth
              onChange={(e) => setPosition(e.target.value)}
            />
          </FormRow>
          <FormRow>
            <Select
              key="area"
              options={areaOptions}
              label="Elegir area"
              value={area}
              onChange={(e) => setArea(e.value)}
            />
            <Select
              key="contract"
              options={contractOptions}
              label="Tipo de contrato"
              value={contractType}
              onChange={(e) => setContractType(e.value)}
            />
            {contractType === 'fijo' && (
              <div>
                <Select
                  key="number-of-months"
                  options={fixedMonthOptions}
                  label={'Cantidad de contratos fijos'}
                  value={fixedMonth}
                  onChange={(e) => setFixedMonth(e.value)}
                />
                <FormRow>
                  {fixedMonth >= 1 && (
                    <DatePicker
                      label="Fecha de termino 1"
                      date={offboardingDate1}
                      onChange={(value) => {
                        setOffboardingDate1(value);
                      }}
                    />
                  )}
                  {fixedMonth == 2 && (
                    <DatePicker
                      label="Fecha de termino 2"
                      date={offboardingDate2}
                      onChange={(value) => {
                        setOffboardingDate2(value);
                      }}
                    />
                  )}
                </FormRow>
              </div>
            )}
          </FormRow>
          <FormRow>
            <Radio
              label="Chile"
              name="radio"
              value="chile"
              checked={radioButton}
              onChange={(e) => {
                setRadioButton(true);
                setCountry('Chile');
              }}
            />
            <Radio
              label="Otro"
              name="radio"
              value="otro"
              checked={!radioButton}
              onChange={(e) => {
                setRadioButton(false);
                setCountry('');
              }}
            />
            <div style={{ paddingLeft: '50px', width: '100%' }}>
              {!radioButton && (
                <TextField
                  key="country"
                  label="Pais"
                  value={country}
                  fullWidth
                  onChange={(e) => {
                    setCountry(e.target.value);
                  }}
                />
              )}
              {radioButton && (
                <TextField
                  key="rut"
                  label="Rut"
                  value={rut}
                  fullWidth
                  onChange={(e) => setRut(e.target.value)}
                />
              )}
            </div>
          </FormRow>
        </Form>
      )}
      <Snackbar
        isOpen={openSnack}
        message={snackMessage}
        onDismiss={() => setOpenSnack(!openSnack)}
        status="error"
      />
    </div>
  );
};

export default HRNewOnboarding;
