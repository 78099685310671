import React from 'react';
import { PersonAdd, Link } from '@material-ui/icons';

export const styles = (theme) => ({
  statistics: {
    height: theme.spacing(40),
    marginBottom: theme.spacing(1.5),
    display: 'flex',
  },
  clientActions: {
    height: theme.spacing(10),
    marginBottom: theme.spacing(1.5),
    display: 'flex',
  },
  fab: {
    position: 'absolute',
    top: theme.spacing(2) + 64,
    right: theme.spacing(2),
  },
  graphic: {
    display: 'inline-block',
    width: '50%',
    height: '100%',
    padding: theme.spacing(1.5),
  },
  leftGraphic: {
    marginRight: '0.25%',
  },
  rightGraphic: {
    marginLeft: '0.25%',
  },
  speedDial: {
    position: 'absolute',
    top: theme.spacing(10),
    right: theme.spacing(3),
  },
  fullScreen: {
    width: '100%',
    height: '100vh',
    position: 'relative',
  },
  fullLoader: {
    position: 'relative',
    left: '50%',
    transform: 'translate(-50%, 0%)',
  },
  wrapper: {
    height: '100%',
    width: '100%',
  },
});

export const clientSpeedDial = [
  {
    identifier: 'client/associate',
    title: 'Asociar Cliente',
    icon: <Link />,
  },
  {
    identifier: 'client/create',
    title: 'Crear Cliente',
    icon: <PersonAdd />,
  },
];
