import React from 'react';
import { List, ListItem } from '@material-ui/core';
import { Modal } from '@beetrack/hp-components';

const PlanModal = (props) => {
  const plan = props.plan;
  const { open, setOpen } = props;

  const _base = () => {
    if (plan.condition.base === undefined) {
      return <></>;
    } else {
      if (plan.condition.base.qty === 0) {
        return (
          <ListItem>
            <b>Mínimo de facturación</b>:{' '}
            {plan.condition.base.price.toLocaleString()}
          </ListItem>
        );
      } else {
        return (
          <>
            <ListItem>
              <b>Cantidad base</b>: {plan.condition.base.qty.toLocaleString()}
            </ListItem>
            <ListItem>
              <b>Precio</b>: {plan.condition.base.price.toLocaleString()} (
              {plan.currency})
            </ListItem>
          </>
        );
      }
    }
  };

  const _variable = (variable = 'variable', varText = 'variable') => {
    if (plan.condition[variable] === undefined) {
      return <></>;
    } else {
      return (
        <>
          <ListItem key={`variable-${variable}-conditions`}>
            <b>Tramos de condición {varText}</b>:
          </ListItem>
          <List>
            {plan.condition[variable].map((vc) => (
              <ListItem key={`${plan.name}-${vc.from}-${vc.to}-${vc.price}`}>
                <b>
                  • {vc.from.toLocaleString()} a{' '}
                  {vc.to >= 1000000000 ? '∞' : vc.to.toLocaleString()}
                </b>
                : {vc.price.toLocaleString()} ({plan.currency})
              </ListItem>
            ))}
          </List>
        </>
      );
    }
  };

  const _step = () => {
    if (plan.condition.step === undefined) {
      return <></>;
    } else {
      return (
        <>
          <ListItem>
            <b>Cantidad incluida</b>:{' '}
            {plan.condition.step.free_amount.toLocaleString()}
          </ListItem>
          <ListItem>
            <b>Precio</b>: {plan.condition.step.price.toLocaleString()} (
            {plan.currency})
          </ListItem>
          <ListItem>
            <b>Tamaño de paquetes</b>:{' '}
            {plan.condition.step.step.toLocaleString()} unidades
          </ListItem>
        </>
      );
    }
  };

  const _generalMin = () => {
    return (
      <ListItem>
        <b>Mínimo de facturación</b>:{' '}
        {plan.condition.general_minimum.toLocaleString()} ({plan.currency})
      </ListItem>
    );
  };

  return (
    <>
      {plan === undefined || plan === null || Object.keys(plan).length === 0 ? (
        <></>
      ) : (
        <Modal
          open={open}
          title="Detalle de plan"
          scrollable
          onClose={() => props.setOpen(!open)}
        >
          {plan !== null ? (
            <>
              <h3>Producto: {plan.pretty_product_name}</h3>
              <List>
                <ListItem key={`name`}>
                  <b>Nombre</b>: {plan.name}
                </ListItem>
                <ListItem key={`currency`}>
                  <b>Moneda</b>: {plan.currency}
                </ListItem>
                {_generalMin()}
                {_base()}
                {_variable()}
                {_variable('variable_initial', 'variable inicial')}
                {_variable('variable_chat', 'variable chat')}
                {_step()}
                <hr className="dotted" />
                <ListItem key={`comments-title`}>
                  <b>Comentarios:</b>
                </ListItem>
                <ListItem key={`comments`}>{plan.description}</ListItem>
              </List>
            </>
          ) : (
            ''
          )}
        </Modal>
      )}
    </>
  );
};

export default PlanModal;
