import React, { useState, useEffect } from 'react';
import {
  Form,
  FormRow,
  TextField,
  Select,
  Button,
  Snackbar,
  Loading,
} from '@beetrack/hp-components';
import { get, put } from '../../../../utils/api';
import {
  QUICKBOOKS_REALM_ID_CL,
  QUICKBOOKS_REALM_ID_CO,
  QUICKBOOKS_REALM_ID_MX,
} from '../../../../utils/const';

const EditAssociated = (props) => {
  const { history } = props;
  const id = props.match.params.id;
  const [hubspotId, setHubspotId] = useState('');
  const [quickbooksId, setQuickbooksId] = useState('');
  const [parentId, setParentId] = useState('');
  const [realmId, setRealmId] = useState('');
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [snack, setSnack] = useState({
    open: false,
    message: '',
    status: 'error',
  });

  const options = [
    { label: 'Chile', value: QUICKBOOKS_REALM_ID_CL },
    { label: 'Mexico', value: QUICKBOOKS_REALM_ID_MX },
    { label: 'Colombia', value: QUICKBOOKS_REALM_ID_CO },
  ];

  const handleUpdate = () => {
    setDisabled(true);
    let payload = {
      hubspot_id: hubspotId,
      quickbooks_id: quickbooksId,
      quickbooks_realm_id: realmId,
      hubspot_quickbooks_relation_id: parentId,
    };
    put(`/associated_deals/${id}`, payload).then((response) => {
      if (response.status === 200) {
        history.push('/client/associated');
      } else {
        setSnack({
          message: 'Problemas actualizando asociacion',
          status: 'error',
          open: true,
        });
        setDisabled(false);
      }
    });
  };

  useEffect(() => {
    get(`/associated_deals/${id}`).then((response) => {
      if (response.status === 200) {
        setRealmId(response.data.realm_id);
        setQuickbooksId(response.data.quickbooks_id);
        setHubspotId(response.data.hubspot_id);
        setParentId(response.data.parent_id);
        setLoading(false);
      }
    });
  }, []);

  return (
    <div>
      <Snackbar
        key="snackbar"
        isOpen={snack.open}
        message={snack.message}
        onDismiss={() =>
          setSnack({ open: false, message: '', status: 'error' })
        }
        status={snack.status}
      />
      {!loading && (
        <Form
          title={`Editando asociacion ID: ${id}`}
          backTo="/client/associated"
          buttons={[
            <Button
              key="not-submit"
              type="tertiary"
              onClick={() => history.push('/client/associated')}
              disabled={disabled}
            >
              Cancelar
            </Button>,
            <Button
              key="submit"
              onClick={() => handleUpdate()}
              disabled={disabled}
            >
              Actualizar
            </Button>,
          ]}
        >
          <FormRow>
            <TextField
              key="hubspot-id"
              onChange={(e) => setHubspotId(e.target.value)}
              placeholder="HubSpot ID"
              label="Ingresa HubSpot ID"
              value={hubspotId}
            />
            <TextField
              key="quickbooks-id"
              onChange={(e) => setQuickbooksId(e.target.value)}
              placeholder="Quickbooks ID"
              label="Ingresa Quickbooks ID"
              value={quickbooksId}
            />
          </FormRow>
          <FormRow>
            <Select
              key="realm-id"
              options={options}
              label="País de Quickbooks"
              onChange={(e) => setRealmId(e.value)}
              value={realmId}
            />
            <TextField
              key="parent-id"
              onChange={(e) => setParentId(e.target.value)}
              placeholder="Parent ID"
              label="Ingresa Parent ID"
              value={parentId}
            />
          </FormRow>
        </Form>
      )}
      {loading && (
        <center>
          <Loading />
        </center>
      )}
    </div>
  );
};

export default EditAssociated;
