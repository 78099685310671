export const styles = theme => ({
  association: {
    padding: theme.spacing(2)
  },
  associationSection: {
    textAlign: 'center',
    padding: `${theme.spacing(2)}px 0`
  },
  hubspotTitle: {
    display: 'inline-block'
  },
  hubspotSelect: {
    display: 'inline-block',
    marginLeft: theme.spacing(2),
    width: theme.spacing(30),
    zIndex: 20
  },
  qbClient: {
    width: '40%',
    textAlign: 'left',
    margin: '0 auto',
    zIndex: 11
  },
  button: {
    margin: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  }
});
