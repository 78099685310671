import React from 'react';

import {
  FormGroup,
  TextField,
  FormSeparator,
  ActionsList,
} from '@beetrack/hp-components';

import TextElement from './TextElement.js';
import SimpleElement from './SimpleElement';
import DependantElement from './DependantElement';
import DateElement from './DateElement';
import FileElement from './FileElement';

import { sortKeys } from '../utils';

import Collapse from '@mui/material/Collapse';

import { Droppable, Draggable } from 'react-beautiful-dnd';

const Group = (props) => {
  const {
    draggableProvidedG,
    snapshotG,
    classes,
    components,
    group,
    indexGroup,
    newElement,
    collapsed,
    setCollapsed,
    textChangeHandler,
    switchHandler,
    deleteElement,
    simpleChangeHandler,
    addSimpleOption,
    addSpecialOption,
    deleteOption,
    handleClone,
    deleteGroup,
    groupTitleChanger,
    fileTypeChangeHandler,
  } = props;

  return (
    <div
      {...draggableProvidedG.draggableProps}
      ref={draggableProvidedG.innerRef}
      key={indexGroup}
      className={
        snapshotG.isDragging ? classes.draggingComponent : classes.component
      }
    >
      <div
        className={classes.groupIntro}
        isDragging={snapshotG.isDragging}
        {...draggableProvidedG.dragHandleProps}
      >
        <p className={classes.subTitle}>
          {components[group].title
            ? `${components[group].title}  (`
            : `Grupo: ${indexGroup + 1}  (`}
        </p>
        <p className={classes.subTitle}>
          {`${sortKeys(components[group]).length} elem.)`}
        </p>
        <ActionsList
          actions={[
            {
              title: 'Agregar elemento texto (corto)',
              onClick: () => newElement([group], 'text'),
            },
            {
              title: 'Agregar elemento texto (parrafo)',
              onClick: () => newElement([group], 'bigText'),
            },
            {
              title: 'Agregar elemento numerico',
              onClick: () => newElement([group], 'number'),
            },
            {
              title: 'Agregar elemento fecha',
              onClick: () => newElement([group], 'date'),
            },
            {
              title: 'Agregar elemento seleccion simple',
              onClick: () => newElement([group], 'simple'),
            },
            {
              title: 'Agregar elemento seleccion multiple',
              onClick: () => newElement([group], 'multiple'),
            },
            {
              title: 'Agregar elemento dependiente',
              onClick: () => newElement([group], 'dependant'),
            },
            {
              title: 'Agregar input archivo',
              onClick: () => newElement([group], 'file'),
            },
            {
              title: 'Eliminar Groupo',
              onClick: () => deleteGroup(group),
            },
          ]}
        />
        <p className={classes.collapsable}>
          <a
            onClick={() =>
              setCollapsed({
                ...collapsed,
                [group]: !collapsed[group],
              })
            }
          >
            {!collapsed[group] ? 'Ver más +' : 'Ver menos'}
          </a>
        </p>
      </div>
      <div>
        <FormGroup collapsible={false}>
          <Collapse in={collapsed[group]} timeout="auto" unmountOnExit>
            <Droppable key={group} type="ELEMENT" droppableId={group}>
              {(droppableProvidedE, snapshotE) => (
                <div
                  key={indexGroup}
                  {...droppableProvidedE.droppableProps}
                  isDraggingFrom={Boolean(snapshotE.draggingFromThisWith)}
                  className={
                    snapshotE.isDraggingOver
                      ? classes.groupContainerCreatorOver
                      : classes.groupContainerCreator
                  }
                >
                  <div className={classes.groupTitle}>
                    <TextField
                      label="Título del Grupo"
                      name={group}
                      key={`text-field-${group}-title`}
                      value={components[group].title}
                      onChange={groupTitleChanger}
                      helperText="Título que quieres que tenga el grupo de preguntas"
                    />
                  </div>
                  <div ref={droppableProvidedE.innerRef}>
                    {sortKeys(components[group]).map(
                      (element, indexElement) => {
                        return (
                          <Draggable
                            key={element}
                            draggableId={element}
                            index={indexElement}
                            type={'ELEMENT'}
                          >
                            {(draggableProvided, snapshot) => (
                              <div
                                isDragging={snapshot.isDragging}
                                {...draggableProvided.draggableProps}
                                ref={draggableProvided.innerRef}
                                key={indexElement}
                                className={
                                  snapshot.isDragging
                                    ? classes.draggingComponent
                                    : classes.component
                                }
                              >
                                {(components[group][element].type === 'text' ||
                                  components[group][element].type ===
                                    'number' ||
                                  components[group][element].type ===
                                    'bigText') && (
                                  <TextElement
                                    components={components}
                                    path={[group, element]}
                                    elementID={element}
                                    groupID={group}
                                    indexElement={indexElement + 1}
                                    type={components[group][element].type}
                                    textChangeHandler={textChangeHandler}
                                    switchHandler={switchHandler}
                                    deleteElement={deleteElement}
                                    collapsed={collapsed}
                                    setCollapsed={setCollapsed}
                                    draggableProvided={draggableProvided}
                                    handleClone={handleClone}
                                  />
                                )}
                                {(components[group][element].type ===
                                  'simple' ||
                                  components[group][element].type ===
                                    'multiple') && (
                                  <SimpleElement
                                    components={components}
                                    path={[group, element]}
                                    elementID={element}
                                    indexElement={indexElement + 1}
                                    type={components[group][element].type}
                                    textChangeHandler={textChangeHandler}
                                    switchHandler={switchHandler}
                                    simpleChangeHandler={simpleChangeHandler}
                                    addSimpleOption={addSimpleOption}
                                    deleteOption={deleteOption}
                                    deleteElement={deleteElement}
                                    collapsed={collapsed}
                                    setCollapsed={setCollapsed}
                                    draggableProvided={draggableProvided}
                                    handleClone={handleClone}
                                  />
                                )}
                                {components[group][element].type ===
                                  'dependant' && (
                                  <DependantElement
                                    components={components}
                                    path={[group, element]}
                                    elementID={element}
                                    indexElement={indexElement + 1}
                                    type={components[group][element].type}
                                    textChangeHandler={textChangeHandler}
                                    switchHandler={switchHandler}
                                    simpleChangeHandler={simpleChangeHandler}
                                    addSimpleOption={addSimpleOption}
                                    addSpecialOption={addSpecialOption}
                                    deleteOption={deleteOption}
                                    deleteElement={deleteElement}
                                    collapsed={collapsed}
                                    setCollapsed={setCollapsed}
                                    newElement={newElement}
                                    draggableProvided={draggableProvided}
                                    handleClone={handleClone}
                                  />
                                )}
                                {components[group][element].type === 'date' && (
                                  <DateElement
                                    components={components}
                                    path={[group, element]}
                                    elementID={element}
                                    indexElement={indexElement + 1}
                                    type={components[group][element].type}
                                    textChangeHandler={textChangeHandler}
                                    switchHandler={switchHandler}
                                    deleteElement={deleteElement}
                                    collapsed={collapsed}
                                    setCollapsed={setCollapsed}
                                    draggableProvided={draggableProvided}
                                    handleClone={handleClone}
                                  />
                                )}
                                {components[group][element].type === 'file' && (
                                  <FileElement
                                    components={components}
                                    path={[group, element]}
                                    elementID={element}
                                    indexElement={indexElement + 1}
                                    type={components[group][element].type}
                                    textChangeHandler={textChangeHandler}
                                    switchHandler={switchHandler}
                                    deleteElement={deleteElement}
                                    collapsed={collapsed}
                                    setCollapsed={setCollapsed}
                                    draggableProvided={draggableProvided}
                                    handleClone={handleClone}
                                    fileTypeChangeHandler={
                                      fileTypeChangeHandler
                                    }
                                  />
                                )}
                              </div>
                            )}
                          </Draggable>
                        );
                      },
                    )}
                    {droppableProvidedE.placeholder}
                  </div>
                </div>
              )}
            </Droppable>
          </Collapse>
        </FormGroup>
        <FormSeparator />
      </div>
    </div>
  );
};

export default Group;
