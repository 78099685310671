import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import { Truck, Route } from '@beetrack/hp-icons-react';
import { InfoBox } from '@beetrack/hp-components';

const ModalCollection = (props) => {
  const { drivers, routes, active } = props;

  return (
    <div style={{ height: '500px' }}>
      <Grid container spacing={3}>
        <Grid item xs>
          <Paper elevation={2}>
            <center>
              <Truck height="50" width="50" />
              <p className="hp-page__header-title">{drivers}</p>
              <p>Usuarios móviles (7 días)</p>
            </center>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper elevation={2}>
            <center>
              <Route height="50" width="50" />
              <p className="hp-page__header-title">{routes}</p>
              <p>Planificaciones (7 días)</p>
            </center>
          </Paper>
        </Grid>
      </Grid>
      <InfoBox status={active ? 'success' : 'error'}>
        {active
          ? 'Esta cuenta se encuentra activa'
          : 'Esta cuenta se encuentra desactivada'}
      </InfoBox>
    </div>
  );
};

export default ModalCollection;
