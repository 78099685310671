import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { get } from '../../utils/api';
import MaterialTable from 'material-table';
import {
  havePermissions,
  hubspotTimestampToDate,
  capitalize,
  lowerizeValues,
  userData,
} from '../../utils/utils';
import { defaultDealStages } from '../../utils/const';
import { SpeedDial, Button } from '@beetrack/hp-components';
import WarningMessage from '../../shared/WarningMessage';
import stringSimilarity from 'string-similarity';
import { InfoBox } from '@beetrack/hp-components';

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

function mapperSimilarity(stringToMatch, availableMatchersDict) {
  var matches = stringSimilarity.findBestMatch(
    stringToMatch,
    Object.values(availableMatchersDict),
  );
  var key = getKeyByValue(availableMatchersDict, matches.bestMatch.target);
  return key;
}

class Client extends Component {
  constructor(props) {
    super(props);
    const userInfo = userData();

    this.state = {
      clients: null,
      error: {},
      filter: [],
      owners: null,
      stages: defaultDealStages,
      columns: [
        {
          title: 'Name',
          field: 'name',
          render: (rowData) => (
            <a href={'client/' + rowData.deal_id}>{rowData.name}</a>
          ),
        },
        { title: 'Beetrack ID', field: 'beetrack__webapp_id' },
        { title: 'Lugar del stage pipeline', field: 'dealstage' },
        {
          title: 'Usuarios móviles (2 week stats)',
          field: 'users_lm_last_period',
          filtering: false,
        },
        {
          title: 'Usuarios potenciales',
          field: 'numero_conductores',
          filtering: false,
        },
        {
          title: 'Último Contacto',
          field: 'notes_last_contacted',
          filtering: false,
        },
        { title: 'Último Ticket', field: 'last_ticket_date', filtering: false },
        {
          title: 'Owner',
          field: 'hubspot_owner_id',
          // by default show all deals to account partners, else, show only (by default) the sales man deals
          defaultFilter: userInfo.scopes.includes('account_partner')
            ? ''
            : capitalize(userInfo.user.name),
        },
      ],
      routes: {},
      truck_drivers: {},
      speedDialOpen: false,
    };
  }

  showActions = () => {
    return (
      <div>
        {havePermissions(['client_admin']) && (
          <SpeedDial text="admin">
            <Button
              type="tertiary"
              onClick={() => this.props.history.push('/client/associated')}
            >
              Child Deals
            </Button>
            <Button
              type="tertiary"
              onClick={() => this.props.history.push('/client/relations')}
            >
              Relaciones HBQ
            </Button>
            <Button
              type="secondary"
              onClick={() => this.props.history.push('/client/associate')}
            >
              Asociar Cliente
            </Button>
            <Button onClick={() => this.props.history.push('/client/create')}>
              Crear Cliente
            </Button>
          </SpeedDial>
        )}
      </div>
    );
  };

  rowParser = (deal) => {
    return {
      deal_id: deal.id,
      name: deal.properties.dealname,
      beetrack__webapp_id:
        deal.properties.beetrack__webapp_id === null
          ? '-'
          : deal.properties.beetrack__webapp_id,
      dealstage: capitalize(this.state.stages[deal.properties.dealstage]),
      users_lm_last_period:
        deal.properties.users_lm_last_period === null
          ? '-'
          : deal.properties.users_lm_last_period,
      numero_conductores:
        deal.properties.numero_conductores === null
          ? '-'
          : deal.properties.numero_conductores,
      notes_last_contacted: hubspotTimestampToDate(
        deal.properties.notes_last_contacted,
      ),
      last_ticket_date: hubspotTimestampToDate(
        deal.properties.last_ticket_date,
      ),
      hubspot_owner_id:
        deal.properties.hubspot_owner_id === ''
          ? '-'
          : capitalize(this.state.owners[deal.properties.hubspot_owner_id]),
    };
  };
  processDealsRows = (deals) => {
    const dealRows = [];
    deals.forEach((deal) => {
      dealRows.push(this.rowParser(deal));
    });
    return dealRows;
  };
  processFilterToUrl = (query) => {
    var paired = query.filters.map(
      (filter) => `${filter.column.field}=${filter.value}`,
    );
    return paired.join('&');
  };

  processFilters = (query) => {
    const alteredQuery = JSON.parse(JSON.stringify(query));
    alteredQuery.filters.forEach((filter) => {
      if (filter.column.field === 'dealstage') {
        filter.value = mapperSimilarity(
          filter.value.toLowerCase(),
          this.state.stages,
        );
      }
      if (filter.column.field === 'hubspot_owner_id') {
        filter.value = mapperSimilarity(
          filter.value.toLowerCase(),
          this.state.owners,
        );
      }
    });
    return alteredQuery;
  };

  queryRemoteData = (query) => {
    return new Promise((resolve, reject) => {
      this.props.loadState(true);
      const additionalQueryParams = this.processFilterToUrl(
        this.processFilters(query),
      );
      var clientGetUrl = `/client/deals?page=${query.page}&per=${query.pageSize}`;
      clientGetUrl =
        additionalQueryParams.length === 0
          ? clientGetUrl
          : `${clientGetUrl}&${additionalQueryParams}`;
      get(clientGetUrl).then((response) => {
        resolve({
          data: this.processDealsRows(response.data.deals.results),
          page: query.page,
          totalCount: response.data.deals.total,
        });
        this.props.loadState(false);
      });
    });
  };

  componentWillMount() {
    get('/clients').then((response) => {
      if (response.status === 200) {
        // set the data of owners and stages which is fixed
        this.setState({
          owners: lowerizeValues(response.data.owners),
          stages: lowerizeValues(this.state.stages),
        });
      }
    });
  }

  render() {
    const { classes } = this.props;
    const { error } = this.state;

    return (
      <div className={classes.wrapper}>
        {error.hubspot === true ? (
          <WarningMessage
            message={
              'Para poder ingresar debes de vincular tu cuenta de Hubspot.'
            }
          />
        ) : (
          <div>
            <div>
              {this.state.owners !== null && (
                <MaterialTable
                  title="Clientes actuales"
                  columns={this.state.columns}
                  data={(query) => this.queryRemoteData(query)}
                  options={{
                    filtering: true,
                    search: false,
                    sorting: false,
                    paging: true,
                    pageSize: 15, // make initial page size
                    emptyRowsWhenPaging: true, //to make page size fix in case of less data rows
                    pageSizeOptions: [5, 15, 25], // rows selection options
                  }}
                />
              )}
            </div>
            {this.showActions()}
            <InfoBox>
              {
                'Por defecto solo se muestran deals de "Onboarding", "ELC" y "Activos"; para buscar "Risk" y "Perdidos" por favor buscar con el filtro de Pipeline.'
              }
            </InfoBox>
          </div>
        )}
      </div>
    );
  }
}
export default withStyles(styles)(Client);
