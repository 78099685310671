import React from 'react';
import { Table } from '@beetrack/hp-components';
import { View, Delete, Envelope } from '@beetrack/hp-icons-react';

const CandidatesTable = (props) => {
  const {
    candidates,
    history,
    setOpenModal,
    setModalDelete,
    loading,
    setSnack,
  } = props;

  const handleViewCandidate = (event) => {
    event.forEach((element) => {
      history.push(`/hr/candidate/${element.item.id.toString()}`);
    });
  };

  const handleDeleteCandidates = (event) => {
    setModalDelete({ open: true, deleteList: event });
  };

  const handleSendMail = (event) => {
    let open_modal = true;
    event.forEach((candidate) => {
      if (candidate.item.welcome_mail) {
        open_modal = false;
      }
    });
    if (open_modal) {
      setOpenModal({ open: true, candidates: event });
    } else {
      setSnack({
        open: true,
        message: 'Candidato(s) ya tiene su correo enviado',
        status: 'error',
      });
    }
  };

  const columns = [
    { name: 'name', title: 'Nombre', valueByAttribute: 'name' },
    {
      name: 'personal_email',
      title: 'Correo Personal',
      valueByAttribute: 'personal_email',
    },
    {
      name: 'supervisor_email',
      title: 'Correo del supervisor',
      valueByAttribute: 'supervisor_email',
    },
    {
      name: 'onboarding_date',
      title: 'Fecha Ingreso',
      valueByAttribute: 'onboarding_date',
    },
    { name: 'position', title: 'Cargo', valueByAttribute: 'position' },
  ];
  return (
    <Table
      columns={columns}
      data={candidates}
      actions={[
        {
          title: 'Ver Tracker',
          icon: <View />,
          onClick: handleViewCandidate.bind(this),
          isGlobal: false,
        },
        {
          title: 'Enviar Correo Bienvenida',
          onClick: handleSendMail.bind(this),
          icon: <Envelope />,
          isGlobal: true,
        },
        {
          title: 'Eliminar Tracker',
          onClick: handleDeleteCandidates.bind(this),
          icon: <Delete />,
          isGlobal: false,
        },
      ]}
      loading={loading}
      isItemLoaded={(index) => candidates[index]}
    />
  );
};

export default CandidatesTable;
