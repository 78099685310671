import React, { forwardRef, useEffect, useState } from 'react'
import { Paper } from '@material-ui/core'
import { Card, CardContent } from '@beetrack/hp-components'
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { List, ListItem, ListItemText, ListItemAvatar, Avatar } from '@material-ui/core';


const CommentsModal = forwardRef((props, ref) => {
  const {
    draftComment
  } = props

  const renderData = () => (
    <div>
      <Card fullWidth bordered>
        <CardContent>
          <p className='hp-typography__subtitle--bold' style={{ color: '#132045' }}> Comentario actual</p>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar>
                    <ContactMailIcon color='#546884'/>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={draftComment.creator} secondary={draftComment.comment} />
              </ListItem>
            </List>
        </CardContent>
      </Card>
    </div>
  )

  return(
    <Paper className={'preview'} ref={ref} tabIndex={-1}>
      { renderData() }
    </Paper>
  )
})

export default CommentsModal
