export const styles = theme => ({
  settingsPaper: {
    height: '100%',
    padding: theme.spacing(2)
  },
  titleSection: {
    textAlign: 'center',
    marginBottom: theme.spacing(3)
  },
  socialNetwork: {
    width: theme.spacing(45),
    margin: '16px auto'
  },
  socialName: {
    width: theme.spacing(20),
    display: 'inline-block',
    verticalAlign: 'middle'
  },
  socialText: {
    fontSize: '1.2rem'
  },
  socialButton: {
    width: theme.spacing(25)
  },
  showSocial: {
    minWidth: 0,
    width: theme.spacing(5)
  },
  newSocial: {
    width: theme.spacing(20)
  }
});
