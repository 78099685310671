import React, { useState, useEffect } from 'react';
import {
  Tabs,
  TabItem,
  DateRangePicker,
  DatePicker,
  Form,
  FormGroup,
  Button,
  Snackbar,
  Select,
  InfoBox,
} from '@beetrack/hp-components';
import { Grid } from '@material-ui/core';
import { get, post, get_blob } from '../../utils/api';
import BDRPointsTable from './BdrPointsTable';
import BDPointsTable from './BdPointsTable';
import TriggerBDCommission from './TriggerBDCommission';
import TriggerBDRPoints from './TriggerBDRPoints';
import moment from 'moment';
import { havePermissions } from '../../utils/utils';

const NewCommissions = (props) => {
  // user id for commission management
  const userId = props.userId === undefined ? '' : props.userId;
  // allowance for commission management
  const actionsAllowed =
    props.actionsAllowed === undefined ? false : props.actionsAllowed;

  // state to force reload on accept/reject entries
  const [reload, setReload] = useState(0);

  // selector for reuse component in commission management
  const userSelector = props.userSelector;
  const [prospectingPoints, setProspectingPoints] = useState([]);
  const [filteredProspectingPoints, setFilteredProspectingPoints] = useState(
    [],
  );
  const [paidLineRegistries, setPaidLineRegistries] = useState([]);
  const [filteredPaidLineRegistries, setFilteredPaidLineRegistries] = useState(
    [],
  );

  const [loading, setLoading] = useState(true);

  const [hubspotDeals, setHubspotDeals] = useState([]);
  const [currencyRates, setCurrencyRates] = useState({});
  const [date, setDate] = useState({
    from: moment().subtract(1, 'months').format('DD-MM-YYYY'),
    to: moment().format('DD-MM-YYYY'),
  });
  const [snackBar, setSnackBar] = useState({
    open: false,
    status: '',
    message: '',
  });

  const currencyOptions = [
    { label: 'CLP', value: 'CLP' },
    { label: 'USD', value: 'USD' },
    { label: 'MXN', value: 'MXN' },
    { label: 'COP', value: 'COP' },
  ];

  const [selectedCurrency, setSelectedCurrency] = useState('USD');

  const [currencyDate, setCurrencyDate] = useState(
    moment().format('DD-MM-YYYY'),
  );

  const fetchCurrencyRates = () => {
    get('/average_currency').then((response) => {
      if (response.status === 200) {
        setCurrencyRates(response.data);
        setLoading(false);
      }
    });
  };

  const parseAndFilter = (points, status) => {
    if (status === null) {
      return points;
    }
    return points.filter((point) => point.attributes.status === status);
  };

  const parseAndFilterBDR = (points, status) => {
    if (status === null) {
      return points.filter(
        (point) => moment().diff(point.attributes.closed_at, 'days') > 0,
      );
    } else if (status === 'approved' || status == 'rejected') {
      return points.filter(
        (point) =>
          point.attributes.status === status &&
          moment().diff(point.attributes.closed_at, 'days') <= 0,
      );
    } else if (status === 'pending') {
      return points.filter((point) => point.attributes.status === status);
    }
  };

  const filterByDateRange = (date, attr, entries, setter) => {
    if (entries === undefined) {
      return;
    }
    const from = moment(date.from, 'DD-MM-YYYY');
    const to = moment(date.to, 'DD-MM-YYYY');
    const data = entries.filter(
      (entry) =>
        from <= moment(entry.attributes[attr]) &&
        moment(entry.attributes[attr]) <= to,
    );
    setter(data);
  };

  const downloadCsv = (type, dateFromCSV) => {
    get_blob(
      `/download_${type}_csv?from=${dateFromCSV.from}&to=${dateFromCSV.to}`,
      'text/csv',
    ).then((response) => {
      let link = document.createElement('a');
      link.download = `commissions_${type}.csv`;
      let file = new Blob([response.data], { type: 'text/csv' });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        link.href = reader.result;
        link.click();
      };
    });
  };

  const downloadBdCsv = (date, currency, currencyDate) => {
    get_blob(
      `/download_bd_csv?from=${date.from}&to=${date.to}&curr=${currency}`,
      'text/csv',
    ).then((response) => {
      let link = document.createElement('a');
      link.download = `commissions_bd.csv`;
      let file = new Blob([response.data], { type: 'text/csv' });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        link.href = reader.result;
        link.click();
      };
    });
    setSnackBar({
      open: true,
      message: '¡Descarga en proceso! ... Puede tardar unos momentos',
      status: 'success',
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      let response = await get(`/sales/new_commissions/points/${userId}`);
      let hids = [];

      if (response.status === 200) {
        setProspectingPoints(response.data.data);
        // hids.push(
        //   ...response.data.data.map((data) => data.attributes.hubspot_id),
        // );
        filterByDateRange(
          date,
          'registered_at',
          response.data.data,
          setFilteredProspectingPoints,
        );
      }

      response = await get(`/sales/new_commissions/registries/${userId}`);
      if (response.status === 200 || response.status === 304) {
        setPaidLineRegistries(response.data.data);
        hids.push(
          ...response.data.data.map(
            (data) => data.attributes.hubspot_quickbooks_relation.hubspot_id,
          ),
        );
        filterByDateRange(
          date,
          'paid_at',
          response.data.data,
          setFilteredPaidLineRegistries,
        );
      }

      const uniqueHids = [...new Set(hids)];
      if (uniqueHids.size === 0) {
        return;
      }

      const payload = {
        filters: {
          hs_object_id: uniqueHids,
        },
        operators: { hs_object_id: 'IN' },
        properties: ['dealname', 'flujo_cliente', 'dealtype'],
      };
      response = await post('/hubspot_search/all', payload);
      if (response.status === 200) {
        setHubspotDeals(response.data.results);
      }
    };
    fetchData();
  }, [userId, reload]);

  useEffect(() => {
    fetchCurrencyRates();
  }, []);

  return (
    <>
      <Form
        requiredDisclaimer={false}
        title="Nuevas comisiones"
        helpContent="Para ver las comisiones de un cierto período de tiempo simplemente debes seleccionar el rango de tiempo en la casilla de abajo."
        onClick={(event) => event.preventDefault()}
      >
        <>{userSelector}</>
        <DateRangePicker
          label="Rango de registros a mostrar"
          onChange={(value) => {
            setDate(value);
            filterByDateRange(
              value,
              'registered_at',
              prospectingPoints,
              setFilteredProspectingPoints,
            );
            filterByDateRange(
              value,
              'paid_at',
              paidLineRegistries,
              setFilteredPaidLineRegistries,
            );
          }}
          {...date}
        />
        <FormGroup title="Comisiones BDR">
          {actionsAllowed ? (
            <TriggerBDRPoints
              reloader={{ reload: reload, setReload: setReload }}
            />
          ) : (
            <></>
          )}
          <Tabs>
            <TabItem label="Pendientes">
              <BDRPointsTable
                listActionsAllowed={['approve', 'reject']}
                actionsAllowed={actionsAllowed}
                data={parseAndFilterBDR(filteredProspectingPoints, 'pending')}
                hubspotData={hubspotDeals}
                loading={loading}
                reloader={{ reload: reload, setReload: setReload }}
              />
            </TabItem>
            <TabItem label="Aprobadas">
              <BDRPointsTable
                listActionsAllowed={['reject']}
                actionsAllowed={actionsAllowed}
                data={parseAndFilterBDR(filteredProspectingPoints, 'approved')}
                hubspotData={hubspotDeals}
                loading={loading}
                reloader={{ reload: reload, setReload: setReload }}
              />
            </TabItem>
            <TabItem label="Rechazadas">
              <BDRPointsTable
                listActionsAllowed={['approve']}
                actionsAllowed={actionsAllowed}
                data={parseAndFilterBDR(filteredProspectingPoints, 'rejected')}
                hubspotData={hubspotDeals}
                loading={loading}
                reloader={{ reload: reload, setReload: setReload }}
              />
            </TabItem>
            <TabItem label="Históricas">
              <BDRPointsTable
                data={parseAndFilterBDR(filteredProspectingPoints, null)}
                hubspotData={hubspotDeals}
                loading={loading}
              />
            </TabItem>
          </Tabs>
        </FormGroup>
        <FormGroup title="Comisiones BD">
          {actionsAllowed ? (
            <TriggerBDCommission
              reloader={{ reload: reload, setReload: setReload }}
            />
          ) : (
            <></>
          )}
          {!actionsAllowed ? (
            <div>
              <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                <Grid item xs={3}>
                  <Select
                    key="currency"
                    options={currencyOptions}
                    label="Divisa comisiones"
                    value={selectedCurrency}
                    onChange={(e) => setSelectedCurrency(e.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button
                    onClick={() =>
                      downloadBdCsv(date, selectedCurrency, currencyDate)
                    }
                    variant="contained"
                    color="primary"
                  >
                    Descargar comisiones en CSV
                  </Button>
                </Grid>
              </Grid>
              <InfoBox status="info">
                <p>
                  La tasa de cambio corresponde a un promedio del 01 del mes al
                  día de la consulta. La tasa definitiva para el cálculo se
                  genera el 20 de cada mes.
                </p>
              </InfoBox>
            </div>
          ) : (
            <></>
          )}
          <Tabs>
            <TabItem label="Pendientes">
              <BDPointsTable
                actionsAllowed={actionsAllowed}
                data={parseAndFilter(filteredPaidLineRegistries, 'pending')}
                hubspotData={hubspotDeals}
                loading={loading}
                currencyRates={currencyRates}
                reloader={{ reload: reload, setReload: setReload }}
              />
            </TabItem>
            <TabItem label="Aprobadas">
              <BDPointsTable
                data={parseAndFilter(filteredPaidLineRegistries, 'approved')}
                hubspotData={hubspotDeals}
                loading={loading}
                currencyRates={currencyRates}
              />
            </TabItem>
            <TabItem label="Rechazadas">
              <BDPointsTable
                data={parseAndFilter(filteredPaidLineRegistries, 'rejected')}
                hubspotData={hubspotDeals}
                loading={loading}
                currencyRates={currencyRates}
              />
            </TabItem>
            <TabItem label="Históricas">
              <BDPointsTable
                data={parseAndFilter(filteredPaidLineRegistries, null)}
                hubspotData={hubspotDeals}
                loading={loading}
                currencyRates={currencyRates}
              />
            </TabItem>
          </Tabs>
        </FormGroup>
        {havePermissions(['commissions_admin']) && actionsAllowed ? (
          <FormGroup title="Generar documentos comisiones">
            <Grid container>
              <Grid item xs={2}>
                <Button onClick={(e) => downloadCsv('registries', date)}>
                  Generar documento BD
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button onClick={(e) => downloadCsv('points', date)}>
                  Generar documento BDR
                </Button>
              </Grid>
            </Grid>
          </FormGroup>
        ) : (
          <></>
        )}
      </Form>
      <Snackbar
        key="snackbar-download-csv"
        isOpen={snackBar.open}
        message={snackBar.message}
        onDismiss={() =>
          setSnackBar({
            open: false,
            status: '',
            message: '',
          })
        }
        status={snackBar.status}
      />
      {/* empty space for the date time picker to be displayed correctly */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingTop: 200,
        }}
      ></div>
    </>
  );
};

export default NewCommissions;
