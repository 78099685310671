import React, { useEffect, useState } from 'react';
import { TopBarAccount, TopBar, TopBarHelps } from '@beetrack/hp-components';
import { makeStyles } from '@material-ui/core/styles';
import { logout } from '../../../utils/api';
import decode from 'jwt-decode';
import { topbarItems } from '../../../utils/const';
import { BrowserRouter } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  topBar: {
    top: 50,
    transform: 'translateY(0%) !important',
  },
  colors: {
    backgroundColor: 'red',
  },
  imgTopBar: {
    width: '14%',
    [theme.breakpoints.down('sm')]: {
      width: '26%',
    },
  },
}));

const TopBarHive = (props) => {
  const classes = useStyles();
  const [manageUsers, setManageUsers] = useState(false);
  const [name, setName] = useState('');
  const [items, setItems] = useState([]);
  const helpList = [
    {
      id: 0,
      onClick: () => props.history.push('/user-management'),
      content: 'User management',
    },
  ];

  useEffect(() => {
    const token = decode(localStorage.getItem('jwt_token'));
    let userScope = false;
    token.scopes.forEach(function (value) {
      if (value === 'user') {
        userScope = true;
      }
    });
    let items = [];
    topbarItems.forEach(function (item) {
      if (item.identifier !== 'users') {
        items.push(item);
      } else if (userScope) {
        items.push(item);
      }
    });

    setName(token.user.name);
    setManageUsers(userScope);
    setItems(items);
  }, []);

  const logOut = async () => {
    let jwt = localStorage.getItem('jwt_token');
    localStorage.removeItem('jwt_token');
    let result = await logout(jwt);
    props.history.push('/?logout');
  };

  const CustomLogo = ({ additionalClassName }) => {
    return (
      <img
        src="https://www.beetrack.com/hubfs/Assets/HIVE/Hive.png"
        className={classes.imgTopBar}
      />
    );
  };

  return (
    <BrowserRouter>
      <div>
        <TopBar
          {...props}
          product="coca-cola"
          className={classes.colors}
          productLogo={CustomLogo}
          customerLogo="https://beetrack-hive.s3-us-west-2.amazonaws.com/hive/hive_logo_small.png"
          loading={false}
        >
          {manageUsers ? <TopBarHelps helps={helpList} /> : <></>}
          <TopBarAccount
            username={name}
            logOut={logOut}
            configAccount="/settings"
            avatar="https://p7.hiclipart.com/preview/556/742/534/avatar-youtube-8-ball-pool-user-avatar.jpg"
          />
        </TopBar>
      </div>
    </BrowserRouter>
  );
};

export default TopBarHive;
