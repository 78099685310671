import React, { useState, useEffect } from 'react';
import {
  Page,
  Form,
  FormRow,
  TextField,
  Button,
  Snackbar,
  Select,
  Modal,
} from '@beetrack/hp-components';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { get, post } from '../../utils/api';
import Checkbox from '@mui/material/Checkbox';
import { Search, Envelope, View } from '@beetrack/hp-icons-react';
import ConfirmationModal from '../../shared/ConfirmationModal';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const CollectionV2 = (props) => {
  const [reload, setReload] = useState(0);
  const [data, setData] = useState([]);
  const [shownData, setShownData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [allSelected, setAllSelected] = useState(false);
  const [rowSelected, setRowSelected] = useState({});

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    status: 'success',
  });
  const [modalEmail, setModalEmail] = useState({
    open: false,
    text: '',
  });

  const [modalInfo, setModalInfo] = useState({
    open: false,
    data: { due: [], overdue: [] },
  });

  const columns = [
    {
      name: 'name',
      title: 'Nombre cliente',
      valueByAttribute: 'name',
    },
    {
      name: 'balance',
      title: 'Balance',
      valueByAttribute: 'balance',
    },
    {
      name: 'country',
      title: 'País',
      valueByAttribute: 'country',
    },
    {
      name: 'due_invoices',
      title: 'Facturas Pendientes',
      valueByAttribute: 'due_invoices',
    },
    {
      name: 'overdue_invoices',
      title: 'Facturas Vencidas',
      valueByAttribute: 'overdue_invoices',
    },
  ];
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event, row) => {
    setRowSelected(row);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event, row) => {
    if (event.target.checked) {
      setSelectedRows(Array.from(new Set([...selectedRows, row.code])));
    } else {
      setSelectedRows(
        selectedRows.filter((selectedRow) => selectedRow !== row.code),
      );
    }
  };

  const handleSearch = (search) => {
    setSearchValue(search.toLowerCase());
    setShownData(
      Array.from(
        new Set(
          data
            .filter((item) => {
              const name = item.name.toLowerCase();
              const country = item.country.toLowerCase();
              if (
                !country.includes(search.toLowerCase()) &&
                !name.includes(search.toLowerCase())
              ) {
                return false;
              }
              return true;
            })
            .map((item) => {
              return item.code;
            }),
        ),
      ),
    );
  };

  const selectAll = () => {
    if (!allSelected) {
      let shownIds = [];
      shownData.map((item) => {
        shownIds = [...shownIds, item];
      });
      setSelectedRows(Array.from(new Set([...selectedRows, ...shownData])));
    } else {
      setSelectedRows([]);
    }
    setAllSelected(!allSelected);
  };

  const handleSendEmail = (code, value) => {
    if (code === 'ALL') {
      setModalEmail({
        ...modalEmail,
        open: true,
        text: value,
        list: [...selectedRows],
      });
    } else {
      setModalEmail({ ...modalEmail, open: true, text: value, list: [code] });
    }
  };

  const sendMail = () => {
    let sorted = {};
    modalEmail.list.forEach((element) => {
      console.log(element);
      if (sorted[element.split('-')[1]]) {
        sorted[element.split('-')[1]].push(element.split('-')[0]);
      } else {
        sorted[element.split('-')[1]] = [element.split('-')[0]];
      }
    });
    const payload = {
      type: modalEmail.text,
      clients: sorted,
    };
    post('/collectionsv2', payload).then((response) => {
      if (response.status === 200) {
        setSnackbar({
          open: true,
          message: 'Correo envíado con éxito',
          status: 'success',
        });
      }
    });
    setModalEmail({ ...modalEmail, list: [] });
  };

  const seeDetail = (row) => {
    const data = row.code.split('-');
    setModalInfo({ ...modalInfo, open: true });
    handleClose();
    console.log(data);
    get(`/collections/details/${data[0]}/${data[1]}`).then((response) => {
      console.log(response);
      if (response.status === 200) {
        setModalInfo({
          ...modalInfo,
          open: true,
          data: {
            due: response.data.due_invoices,
            overdue: response.data.overdue_invoices,
          },
        });
      } else {
        setModalInfo({
          ...modalInfo,
          open: true,
          data: { due: [], overdue: [] },
        });
      }
    });
  };

  useEffect(() => {
    get(`/collections/`).then((response) => {
      if (response.status === 200) {
        setLoading(false);
        setData(response.data.data);
        setShownData(
          response.data.data.map((content) => {
            return content.code;
          }),
        );
      }
    });
  }, [reload]);

  return (
    <Page title="Cobranza v2">
      <p>
        Aquí encontrarás información de <strong> clientes </strong> deudores
      </p>
      <div>
        <TextField
          label="Buscador"
          placeholder="Supermercados Optimus Prime"
          onChange={(e) => handleSearch(e.target.value)}
          value={searchValue}
          trailingIcon={<Search />}
        />
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="caption table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox checked={allSelected} onChange={() => selectAll()} />
              </TableCell>
              <TableCell>Nombre Cliente</TableCell>
              <TableCell>Balance</TableCell>
              <TableCell>Pais</TableCell>
              <TableCell># Facturas impagas</TableCell>
              <TableCell># Facturas vencidas</TableCell>
              <TableCell>
                {selectedRows.length !== 0 && (
                  <div>
                    <IconButton onClick={handleClick}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={() => handleSendEmail('ALL', 'impagas')}
                      >
                        <Envelope /> Facturas Impagas
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleSendEmail('ALL', 'vencidas')}
                      >
                        <Envelope /> Facturas Vencidas
                      </MenuItem>
                    </Menu>
                  </div>
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(
              (row) =>
                shownData.includes(row.code) && (
                  <TableRow
                    key={row.code}
                    selected={selectedRows.includes(row.code)}
                  >
                    <TableCell>
                      <Checkbox
                        checked={!!selectedRows.includes(row.code)}
                        onChange={(event) => handleChange(event, row)}
                      />
                    </TableCell>
                    <TableCell>
                      {row.name}-{row.code}
                    </TableCell>
                    <TableCell>{row.balance}</TableCell>
                    <TableCell>{row.country}</TableCell>
                    <TableCell>{row.due_invoices}</TableCell>
                    <TableCell>{row.overdue_invoices}</TableCell>
                    <TableCell>
                      <div>
                        <IconButton onClick={(e) => handleClick(e, row)}>
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                        >
                          <MenuItem
                            onClick={() =>
                              handleSendEmail(rowSelected.code, 'impagas')
                            }
                          >
                            <Envelope /> Aviso Pendientes
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              handleSendEmail(rowSelected.code, 'vencidas')
                            }
                          >
                            <Envelope /> Aviso de Corte
                          </MenuItem>
                          <MenuItem onClick={() => seeDetail(rowSelected)}>
                            <View /> Detalle
                          </MenuItem>
                        </Menu>
                      </div>
                    </TableCell>
                  </TableRow>
                ),
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <ConfirmationModal
        text="¿Enviar Correo Cobranza?"
        secondaryText={`Se le notificara al cliente sobre sus facturas ${modalEmail.text}`}
        open={modalEmail.open}
        close={() => setModalEmail({ open: false, list: [] })}
        onConfirm={() => sendMail(modalEmail.list)}
      />
      <Modal
        open={modalInfo.open}
        onClose={() =>
          setModalInfo({ open: false, data: { due: [], overdue: [] } })
        }
        preventOnCloseOutside
      >
        <div>
          <div>
            <div>ID</div>
            <div>Balance</div>
          </div>
          <div>Pendientes</div>
          <div>
            {modalInfo.data.due.map((invoice) => {
              return (
                <div>
                  <div>{invoice.table.id}</div>
                  <div>{invoice.table.balance}</div>
                </div>
              );
            })}
          </div>
          <div>Vencidas</div>
          <div>
            {modalInfo.data.overdue.map((invoice) => {
              return (
                <div>
                  <div>{invoice.table.id}</div>
                  <div>{invoice.table.balance}</div>
                </div>
              );
            })}
          </div>
        </div>
      </Modal>
      <Snackbar
        key="snackbar"
        isOpen={snackbar.open}
        message={snackbar.message}
        onDismiss={() =>
          setSnackbar({ open: false, message: '', status: snackbar.status })
        }
        status={snackbar.status}
      />
    </Page>
  );
};

export default CollectionV2;
