import React from 'react';
import { Person, Settings } from '@material-ui/icons';
export const resetPasswordUrl = '/password/recover';
export const loginUrl = '/login';
export const topbarItems = [
  {
    identifier: 'settings',
    name: 'Configuración',
    icon: <Settings />,
    style: false,
  },
  {
    identifier: 'users',
    name: 'Administración de Usuarios',
    icon: <Person />,
    style: false,
  },
  {
    identifier: 'logout',
    name: 'Cerrar Sesión',
    icon: null,
    style: true,
  },
];

export const dealStage = {
  presentationscheduled: 'Piloto',
  closedwon: 'Onboarding',
  'b81f0a96-4124-494b-9d02-103a436372c5': 'Engagement',
  'd9a97d63-cbee-4725-9f26-498d39211709': 'Risk',
  closedlost: 'Perdido',
};

export const pilotTypes = ['Piloto pagado', 'Piloto gratis', 'No es piloto'];

export const defaultDealStages = {
  'dc15e06f-1e42-4631-bdd0-6e6a90953207': 'Deal x Validar',
  appointmentscheduled: 'Deal Validado',
  qualifiedtobuy: 'Coordinacion Inicial',
  decisionmakerboughtin: 'Negociacion Final',
  contractsent: 'Pre Frozen',
  frozen: 'Frozen',
  3284227: 'Audit',
  closedwon: 'Onboarding',
  3284228: 'ELC',
  'b81f0a96-4124-494b-9d02-103a436372c5': 'Activas',
  'd9a97d63-cbee-4725-9f26-498d39211709': 'Risk',
  closedlost: 'Perdido',
};

export const loginMessages = {
  expired: 'Your session has expired.',
  invalid: 'Email or password are wrong.',
  logout: 'You have logged out succesfully.',
  new_password: 'Your password has been changed.',
};

export const clientModel = {
  razon: '',
  giro: '',
  taxIdentifier: '',
  country: {
    label: '',
    value: '',
  },
  city: '',
  comune: '',
  postalCode: '',
  address: '',
  office: '',
  contacts: [],
  paymentMethod: '',
  paymentTerm: '',
  hubspot: {
    id: '',
    name: '',
  },
  dateStartService: null,
};

export const baseConditions = {
  mobile: { comments: '', fixCost: '', unitsIncluded: '' },
  sms: { comments: '', fixCost: '', unitsIncluded: '' },
  email: { comments: '', fixCost: '', unitsIncluded: '' },
  widget: { comments: '', fixCost: '', unitsIncluded: '' },
  planner: { comments: '', fixCost: '', unitsIncluded: '' },
};

export const variableConditions = {
  mobile: [],
  sms: [],
  email: [],
  widget: [],
  planner: [],
};

export const MAX_TAX_IDENTIFIER_LENGTHS = {
  CL: 11,
  MX: 14,
};

export const countryToCurrency = (country) => {
  switch (country) {
    case 'Chile':
      return 'UF';
    case 'Mexico':
      return 'MXN';
    case 'Colombia':
      return 'COP';
    default:
      return 'USD';
  }
};

export const commercialConditionsCurrencies = [
  { label: 'UF', value: 'UF' },
  { label: 'USD', value: 'USD' },
  { label: 'MXN', value: 'MXN' },
  { label: 'COP', value: 'COP' },
];

export const commercialConditionsProducts = [
  { value: 'lastmile', label: 'LastMile' },
  { value: 'plannerpro', label: 'PlannerPro' },
  { value: 'ondemand', label: 'OnDemand' },
  { value: 'lastmile_optimization', label: 'Optimización LastMile' },
  { value: 'lastmile_per_delivery', label: 'LastMile (por delivery)' },
  { value: 'lastmile_collect', label: 'LastMile Collect' },
  { value: 'google_maps', label: 'Google Maps' },
  { value: 'sms', label: 'SMS' },
  // { value: 'email', label: 'Email' },
  // { value: 'whatsapp', label: 'whatsapp' },
  { value: 'other', label: 'Otros' },
];

export const MAX_VARIABLE_NUMBER = 1000000000;

export const QUICKBOOKS_REALM_ID_CL = process.env.QB_REALM_CL;

export const QUICKBOOKS_REALM_ID_MX = process.env.QB_REALM_MX;

export const QUICKBOOKS_REALM_ID_CO = process.env.QB_REALM_CO;
