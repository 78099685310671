import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import {
  IconButton,
  Button,
  Snackbar,
  ActionsList,
  ConfirmationModal,
  TextField,
} from '@beetrack/hp-components';
import {
  ChevronRight,
  Add,
  Delete,
  Edit,
  FileCopy,
  Search,
  Download,
} from '@beetrack/hp-icons-react';
import { useStyles } from './Styles/styles';
import { post, get, delForm } from '../../utils/api';
import LoadingCircle from '../../shared/LoadingCircle/LoadingCircle';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const Forms = () => {
  const [forms, setForms] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [filteredForms, setFilteredForms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [selectedForm, setSelectedForm] = useState(false);

  const [owners, setOwners] = useState([]);

  const [errorMessage, setErrorMessage] = useState('');
  const [status, setStatus] = useState('');
  const [openAlert, setOpenAlert] = useState(false);

  const classes = useStyles();

  const duplicateForm = async (fid) => {
    const response = await post(`/forms/duplicate/${fid}`);
    if (response.status === 201) {
      setErrorMessage('Formulario clonado correctamente');
      setStatus('success');
      setOpenAlert(true);
      window.location.reload();
    } else {
      setErrorMessage('Ha ocurrido un error');
      setStatus('error');
      setOpenAlert(true);
    }
  };

  const goToForm = (fid) => {
    window.location = `/forms/${fid}`;
  };

  const createForm = () => {
    window.location = '/create-form/';
  };

  const editForm = (fid) => {
    window.location = `/edit-form/${fid}`;
  };

  const deleteFormHandler = (fid) => {
    setOpenModal(!openModal);
    setSelectedForm(fid);
  };

  const deleteForm = async () => {
    const response = await delForm(`/forms/delete/${selectedForm}`);
    if (response.status === 200) {
      setErrorMessage('Eliminado Correctamente');
      setStatus('success');
      setAlertOpen(true);
    } else {
      setErrorMessage('Oops, ocurrio un error');
      setStatus('warning');
      setAlertOpen(true);
    }
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    const filteredList = forms.filter((item) => {
      const title = item.title.toLowerCase();
      const name = item.name.toLowerCase();
      const description = item.description.toLowerCase();
      const searchQuery = value.toLowerCase();
      if (
        !title.includes(searchQuery) &&
        !name.includes(searchQuery) &&
        !description.includes(searchQuery)
      ) {
        return false;
      }
      return true;
    });
    setFilteredForms(filteredList);
  };

  const download = async () => {
    let myData = [];

    forms.map((form) => {
      const data = form['content'];
      for (const [groupKey, groupValue] of Object.entries(data)) {
        for (const [elementKey, elementValue] of Object.entries(groupValue)) {
          let rowData = {
            Form: form.id,
            Name: form['name'],
            Group: groupKey,
            Element: elementKey,
            Type: elementValue.type,
            Title: elementValue.title,
            ImgUrl: elementValue.imgUrl,
            Question: elementValue.options ? elementValue.question : '',
            HelperText: elementValue.helperText,
            PropertyName: elementValue.propertyName,
            Label: '',
            Value: '',
          };

          if (elementValue.options) {
            for (const [optionKey, optionValue] of Object.entries(
              elementValue.options,
            )) {
              let rowData = {
                Form: form.id,
                Name: form['name'],
                Group: groupKey,
                Element: elementKey,
                Type: elementValue.type,
                Title: elementValue.title,
                ImgUrl: elementValue.imgUrl,
                Question: elementValue.options ? elementValue.question : '',
                HelperText: elementValue.helperText,
                PropertyName: elementValue.propertyName,
                Label: '',
                Value: '',
              };
              rowData.Label = optionValue.label;
              rowData.Value = optionValue.value;
              myData.push(rowData);
            }
          } else {
            myData.push(rowData);
          }
        }
      }
    });
    exportToExcel(myData);
  };

  const exportToExcel = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    const fileData = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    FileSaver.saveAs(fileData, 'data.xlsx');
  };

  const loadForms = async () => {
    const response = await get('/forms');
    if (response.status === 200) {
      setForms(response.data.forms);
      setFilteredForms(response.data.forms);
      setOwners(response.data.names);
    } else {
      setErrorMessage('Ha ocurrido un error');
      setStatus('error');
      setOpenAlert(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadForms();
  }, []);

  return (
    <div>
      {!loading ? (
        <div className={classes.formsTable}>
          <div className={classes.horizontalCenter}>
            <h1 className={classes.mainTitle}> Formularios Hive</h1>
            <div className={classes.topButton}>
              <Button icon={<Add />} type="secondary" onClick={createForm}>
                Crear Form
              </Button>
            </div>
            <div className={classes.formsTable}>
              {forms.length > 0 && (
                <Button icon={<Download />} type="tertiary" onClick={download}>
                  Descargar Formularios
                </Button>
              )}
            </div>
          </div>
          <TextField
            label="Buscador"
            placeholder="Formulario LM"
            onChange={(e) => handleSearch(e.target.value)}
            value={searchValue}
            trailingIcon={<Search />}
          />
          <div className={classes.informationCardsContainer}>
            {filteredForms.length > 0 ? (
              filteredForms.map((form, index) => {
                return (
                  <Grid
                    key={index}
                    className={classes.informationCard}
                    item
                    xs={12}
                    md={6}
                  >
                    <div className={classes.formInformation}>
                      <div>
                        <span className={classes.line} />
                        <p className={classes.content}>ID: {form.id}</p>
                        <p className={classes.content}>
                          Nombre Formulario: {form.name}
                        </p>
                        <p className={classes.content}>
                          Dueño: {owners[index]}
                        </p>
                      </div>
                      <div className={classes.buttonContainer}>
                        <ActionsList
                          actions={[
                            {
                              title: 'Clonar Formulario',
                              onClick: () => duplicateForm(form.id),
                              icon: <FileCopy />,
                            },
                            {
                              title: 'Editar Formulario',
                              onClick: () => editForm(form.id),
                              icon: <Edit />,
                            },
                            {
                              title: 'Eliminar Formulario',
                              onClick: () => deleteFormHandler(form.id),
                              icon: <Delete />,
                            },
                          ]}
                        />
                        <IconButton
                          onClick={() => goToForm(form.id)}
                          icon={<ChevronRight />}
                          type="tertiary"
                          size="large"
                        />
                      </div>
                    </div>
                  </Grid>
                );
              })
            ) : (
              <div>
                <div className={classes.notFoundMessage}>
                  No se encontraron formularios :c
                </div>
                <Snackbar
                  isOpen={openAlert}
                  message={errorMessage}
                  onDismiss={() => setOpenAlert(false)}
                  status={status}
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className={classes.loading}>
          <LoadingCircle size="big" />
        </div>
      )}
      <ConfirmationModal
        key="simple-modal"
        open={openModal}
        title="Eliminar Formulario"
        onClose={() => setOpenModal(!openModal)}
        confirmButton={<Button onClick={() => deleteForm()}>Eliminar</Button>}
      />
    </div>
  );
};

export default Forms;
