import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { get } from '../../../utils/api';
import { Select } from '@beetrack/hp-components';
import Graph from './Graph';
import { generateRGB } from '../../../utils/utils';

const useStyles = makeStyles({
  container: {
    paddingBottom: '100px',
  },
  title: {
    color: '#546884',
    fontSize: '28px',
    fontStyle: 'normal',
    fontFamily: '"Raleway", sans-serif',
    fontWeight: '800',
    lineHeight: '28px',
    paddingRight: '10px',
  },
  titleContainer: {
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  filters: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'row',
  },
});

const HelpCenter = () => {
  const classes = useStyles();

  const [collectionsData, setCollectionsData] = useState({});
  const [sections, setSections] = useState({});
  const [sectionsData, setSectionsData] = useState({});
  const [articles, setArticles] = useState({});
  const [articlesData, setArticlesData] = useState({});

  const [selectedCollection, setSelectedCollection] = useState(false);
  const [selectedSection, setSelectedSection] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(false);

  const [reactions, setReactions] = useState(false);

  const [sectionOptions, setSectionOptions] = useState([
    { label: 'None', value: false },
  ]);
  const [articleOptions, setArticleOptions] = useState([
    { label: 'None', value: false },
  ]);
  const [data, setData] = useState(false);
  const [reactionsData, setReactionsData] = useState({});

  const [labels, setLabels] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await get('/helpcenter/data');

      if (response.status === 200) {
        setCollectionsData(response.data.collections_data);

        setSections(response.data.sections);
        setSectionsData(response.data.sections_data);

        setArticles(response.data.articles);
        setArticlesData(response.data.articles_data);
      }
    };

    const populateLabels = () => {
      const startDate = new Date(2022, 0, 1); // Set start date to Jan 1, 2022
      const endDate = new Date(); // Use current date as end date

      // Calculate the number of months between start date and end date
      const numMonths =
        (endDate.getFullYear() - startDate.getFullYear()) * 12 +
        (endDate.getMonth() - startDate.getMonth()) +
        1;

      // Create an array of month-year strings for each month between start date and end date
      const monthYearList = Array.from({ length: numMonths }, (_, i) => {
        const date = new Date(startDate);
        date.setMonth(startDate.getMonth() + i);
        return date
          .toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
          })
          .replace(/ /g, '-');
      });
      setLabels(monthYearList);
    };

    fetchData();
    populateLabels();
  }, []);

  const configureData = () => {
    if (selectedCollection) {
      if (selectedSection) {
        if (selectedArticle) {
          // ARTICLE DATA
          const datasets = [selectedArticle].map((articleId) => {
            const color = generateRGB();
            const data = articleViews(articleId, labels);

            return {
              label: articlesData[articleId][0],
              data: data,
              borderColor: color,
              backgroundColor: color,
            };
          });

          setData({
            labels: labels,
            datasets: datasets,
          });
        } else {
          // ARTICLES IN SECTION
          const datasets = articles[selectedSection].map((articleId) => {
            const color = generateRGB();
            const data = articleViews(articleId, labels);

            return {
              label: articlesData[articleId][0],
              data: data,
              borderColor: color,
              backgroundColor: color,
            };
          });

          setData({
            labels: labels,
            datasets: datasets,
          });
        }
      } else {
        // SECTIONS IN COLLECTION
        const datasets = sections[selectedCollection].map((sectionId) => {
          const color = generateRGB();
          const data = addArticles(sectionId, labels);
          // const helper = sections[collection].map((sectionid)=> {
          //   return (
          //     {sectionid: addArticles(sectionid, labels)}
          //   )
          // })
          return {
            label: sectionsData[sectionId],
            data: data,
            borderColor: color,
            backgroundColor: color,
            // tooltipLabels: labels.map((label) => {
            //   let text = `Total: ${data[labels.indexOf(label)]}\n`
            //   const specificData = helper.map(obj => `${sectionsData[Object.keys(obj)[0]]}: ${Object.values(obj)[0]}\n`).join(',')
            //   console.log(specificData)
            //   return (
            //     text += specificData
            //    `Addition: ${addedData[labels.indexOf(label)]}\n` +
            //    `${sections_data[section]}: ${data1[labels.indexOf(label)]}\n` +
            //    `Data 2: ${data2[labels.indexOf(label)]}`
            //   );
            // }),
          };
        });

        setData({
          labels: labels,
          datasets: datasets,
        });
      }
    } else {
      // COLLECTIONS
      addSections(Object.keys(collectionsData)[0], labels);

      const datasets = Object.keys(collectionsData).map((collection) => {
        const color = generateRGB();
        const data = addSections(collection, labels);
        const helper = sections[collection].map((sectionid) => {
          return { sectionid: addArticles(sectionid, labels) };
        });

        return {
          label: collectionsData[collection],
          data: data,
          borderColor: color,
          backgroundColor: color,
          tooltipLabels: labels.map((label) => {
            let text = `Total: ${data[labels.indexOf(label)]}\n`;

            const specificData = helper
              .map(
                (obj) =>
                  `${sectionsData[Object.keys(obj)[0]]}: ${
                    Object.values(obj)[0]
                  }\n`,
              )
              .join(',');
            return (text += specificData);
            // `Addition: ${addedData[labels.indexOf(label)]}\n` +
            // `${sections_data[section]}: ${data1[labels.indexOf(label)]}\n` +
            // `Data 2: ${data2[labels.indexOf(label)]}`
          }),
        };
      });

      setData({
        labels: labels,
        datasets: datasets,
      });
    }
  };

  const articleViews = (articleId, labels) => {
    return labels.map((key) => {
      if (articlesData[articleId][1]?.views?.[key]) {
        return articlesData[articleId][1].views[key];
      } else {
        return 0;
      }
    });
  };

  const addArticles = (sectionId, labels) => {
    const articleList = articles[sectionId];
    return labels.map((key) => {
      const newList = articleList.map((articleId) => {
        if (articlesData[articleId][1]?.views?.[key]) {
          return articlesData[articleId][1].views[key];
        } else {
          return 0;
        }
      });
      const numbers = newList.reduce((total, current) => total + current, 0);

      return numbers;
    });
  };

  const addSections = (collectionId, labels) => {
    if (Object.keys(sections).length > 0) {
      const sectionList = sections[collectionId];
      return labels.map((key) => {
        const newSectionList = sectionList.map((sectionId) => {
          const articleList = articles[sectionId];
          const newList = articleList.map((articleId) => {
            if (articlesData[articleId][1]?.views?.[key]) {
              return articlesData[articleId][1].views[key];
            } else {
              return 0;
            }
          });
          const numbers = newList.reduce(
            (total, current) => total + current,
            0,
          );
          return numbers;
        });
        const numbers2 = newSectionList.reduce(
          (total, current) => total + current,
          0,
        );
        return numbers2;
      });
    } else {
      return labels.map((key) => {
        return 0;
      });
    }
  };

  useEffect(() => {
    configureData();
  }, [selectedCollection, selectedSection, selectedArticle, labels]);

  const stringListToOptions = (
    list,
    section,
    initialOptions = [{ value: false, label: 'None' }],
  ) => {
    return [
      ...initialOptions,
      ...list.map((str) => ({
        label: section ? sectionsData[str] : articlesData[str][0],
        value: str,
      })),
    ];
  };

  useEffect(() => {
    if (selectedArticle && selectedSection && selectedCollection) {
      setReactions(true);
      setReactions(true);
      setReactionsData({
        labels: labels,
        datasets: [
          {
            label: 'Likes',
            data: labels.map((key) => {
              if (articlesData[selectedArticle][1]?.reactions?.like?.[key]) {
                return articlesData[selectedArticle][1].reactions.like[key];
              } else {
                return 0;
              }
            }),
            borderColor: 'rgb(95, 166, 68)',
            backgroundColor: 'rgba(95, 166, 68, 0.5)',
          },
          {
            label: 'Neutral',
            data: labels.map((key) => {
              if (articlesData[selectedArticle][1]?.reactions?.neutral?.[key]) {
                return articlesData[selectedArticle][1].reactions.neutral[key];
              } else {
                return 0;
              }
            }),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
          {
            label: 'Dislikes',
            data: labels.map((key) => {
              if (articlesData[selectedArticle][1]?.reactions?.dislike?.[key]) {
                return articlesData[selectedArticle][1].reactions.dislike[key];
              } else {
                return 0;
              }
            }),
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          },
        ],
      });
    } else {
      setReactions(false);
      setReactionsData({});
    }
  }, [selectedCollection, selectedArticle, selectedSection]);

  useEffect(() => {
    if (selectedSection) {
      const articleList = articles[selectedSection];
      const articleOptionsValues = stringListToOptions(articleList, false);
      setArticleOptions(articleOptionsValues);
      setSelectedArticle(false);
    } else {
      setSelectedArticle(false);
      setArticleOptions([{ value: false, label: 'None' }]);
    }
  }, [selectedSection]);

  useEffect(() => {
    if (selectedCollection) {
      const sectionList = sections[selectedCollection];
      const sectionOptionsValues = stringListToOptions(sectionList, true);
      setSectionOptions(sectionOptionsValues);
      setSelectedSection(false);
    } else {
      setSelectedSection(false);
      setSelectedArticle(false);
      setSectionOptions([{ value: false, label: 'None' }]);
      setArticleOptions([{ value: false, label: 'None' }]);
    }
  }, [selectedCollection]);

  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <h1 className={classes.title}>Dashboard Help Center</h1>
      </div>
      <div className={classes.filters}>
        <Select
          key="simple-select-1"
          value={selectedCollection}
          options={[
            { value: false, label: 'None' },
            ...Object.keys(collectionsData).map((key) => ({
              label: collectionsData[key],
              value: key,
            })),
          ]}
          label="Select Collection"
          onChange={(e) => {
            setSelectedCollection(e.value);
          }}
        />
        <Select
          key="simple-select-2"
          value={selectedSection}
          options={sectionOptions}
          label="Select Section"
          onChange={(e) => {
            setSelectedSection(e.value);
          }}
        />
        <Select
          key="simple-select-3"
          value={selectedArticle}
          options={articleOptions}
          label="Select Article"
          onChange={(e) => {
            setSelectedArticle(e.value);
          }}
        />
      </div>
      <div>
        <Graph
          data={data}
          reactions={reactions}
          reactionsData={reactionsData}
        />
      </div>
    </div>
  );
};

export default HelpCenter;
