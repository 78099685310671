import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  mainTitle: {
    fontSize: '28px',
    lineHeight: '28px',
    fontWeight: '800',
    fontFamily: '"Raleway", sans-serif',
    fontStyle: 'normal',
    color: '#546884',
    paddingRight: '10px',
  },
  topcontainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '20px',
    alignItems: 'center',
  },
  subTitle: {
    fontSize: '20px',
    fontWeight: '400',
    fontFamily: '"Raleway", sans-serif',
    fontStyle: 'normal',
    color: '#546884',
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '50px',
  },
  beetrack: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif !important',
    color: '#000000E5',
    '& h1': {
      background:
        'linear-gradient(0deg, #F27B42 0%, #F27B42 33%, #FFFFFF 33%, #FFFFFF 100%)',
      paddingLeft: '15px',
      paddingRight: '15px',
    },
  },
  description: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif !important',
    color: '#000000B2',
    textAlign: 'center',
  },
  spacing: {
    paddingBottom: '20px',
  },
  horizontal: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '15px',
  },
  horizontalCenter: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '15px',
    alignItems: 'center',
  },
  formTitle: {
    paddingBottom: '15px',
  },
  introElement: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '15px',
    '&:hover': {
      backgroundColor: 'lightblue',
    },
  },
  groupIntro: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    '&:hover': {
      backgroundColor: 'lightblue',
    },
  },
  optionTitle: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'lightblue',
    },
  },
  optionContainer: {
    padding: '5px',
  },
  line: {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    width: 20,
    bottom: 0,
    top: 0,
    left: 0,
    position: 'absolute',
    background: 'linear-gradient(180deg,#ffd200,#f4aa1f)',
  },
  content: {
    fontSize: '20px',
    fontWeight: '400',
    fontFamily: '"Raleway", sans-serif !important',
    fontStyle: 'normal',
  },
  formInformation: {
    boxShadow: '0px 8px 15px rgba(45, 41, 28, 0.15)',
    borderRadius: 4,
    position: 'relative',
    overflow: 'hidden',
    paddingLeft: '40px',
    marginBottom: '20px',
    marginRight: '40px',
    display: 'flex',
    alignItems: 'center',
    paddingRight: '40px',
    justifyContent: 'space-between',
    backgroundColor: 'white',
  },
  informationCardsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  question: {
    fontSize: '18px',
    lineHeight: '20px',
    fontWeight: '500',
    fontFamily: '"Raleway", sans-serif !important',
    fontStyle: 'normal',
    color: '#546884',
    paddingBottom: '10px',
    paddingTop: '8px',
  },
  groupContainer: {
    display: 'flex',
    paddingTop: '15px',
    paddingBottom: '15px',
    justifyContent: 'space-evenly',
  },
  bannerContainer: {
    paddingTop: '20px',
  },
  loading: {
    paddingTop: '70%',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  notFoundMessage: {},
  imageContainer: {},
  infoBox: {
    padding: '15px',
  },
  switchContainer: {
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  switchText: {
    fontFamily: '"Raleway", sans-serif',
    color: '#546884',
  },
  collapsable: {
    color: '#0792E0',
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  vertical: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  requiredSwitch: {
    paddingBottom: '20px',
    color: '#546884',
  },
  groupContainerCreator: {
    display: 'block',
    padding: '25px',
    marginBottom: '15px',
  },
  container: {
    paddingLeft: '10px',
  },
  elementContainer: {
    border: 'solid 2px',
    borderColor: '#546884',
  },
  component: {
    marginBottom: '15px',
  },
  draggingComponent: {
    marginBottom: '15px',
    backgroundColor: 'lightblue',
    boxShadow: '0 0 25px;',
  },
  red: {
    color: 'red',
    fontSize: '28px !important',
  },
  groupContainerCreatorOver: {
    display: 'block',
    border: 'solid 2px',
    borderColor: '#546884',
    padding: '25px',
    marginBottom: '15px',
    backgroundColor: '#ffd200',
  },
  groupsContainer: {
    padding: '10px',
  },
  topButton: {
    padding: '5px',
  },
  fillerEmail: {
    paddingBottom: '15px',
  },
}));
