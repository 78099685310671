import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import { useHistory } from 'react-router-dom';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { DialogActions, Grid } from '@material-ui/core';

const SIDEBARCOLORSELECTED = '#2b456a';

const useStyles = makeStyles((theme) => ({
  lineBox: {
    display: 'flex',
    justifyContent: 'left',
    paddingLeft: '9px',
    minWidth: '200px',
  },
  color: {
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover': {
      color: 'rgb(244, 180, 35)',
    },
  },
  lineBoxSmall: {
    paddingLeft: '15px',
    minWidth: '200px',
  },
  lineItems: {
    fontFamily: 'Raleway,sans-serif',
    fontWeight: '600',
    alignItems: 'center',
    display: 'flex',
    padding: '3px 0px',
  },
  icon: {
    paddingTop: '4px',
  },
  contentBig: {
    paddingLeft: '15.7px',
    fontWeight: 'lighter',
    fontSize: '0.87rem',
  },
  contentSmall: {
    paddingLeft: '52px',
    padding: '5px 0px',
    fontWeight: 'lighter',
    fontSize: '0.87rem',
  },
  borderBox: {
    color: 'rgba(255, 255, 255, 0.7)',
    marginTop: '11px',
    cursor: 'pointer',
    '&:hover': {
      color: 'rgb(255, 255, 255)',
      cursor: 'pointer',
      backgroundColor: SIDEBARCOLORSELECTED,
    },
  },
  borderBoxSelected: {
    borderLeft: '5px solid #f5b63e',
  },
  borderBoxNotSelected: {
    borderLeft: `5px solid ${SIDEBARCOLORSELECTED}`,
  },
  subSelected: {
    color: 'rgb(244, 180, 35)',
  },
  notSelected: {
    color: 'rgba(255, 255, 255, 0.7)',
  },
  // bigIconSelected: {
  //   color: 'rgb(255, 255, 255)'
  // },
  textDeco: {
    textDecoration: 'none',
  },
  iconMargin: {
    margin: '8px 0px 0px -12px',
  },
  rotate: {
    transform: 'rotate(180deg)',
  },
}));

const SideBarItem = (props) => {
  const {
    content,
    icon,
    subRoutes,
    smallselected,
    setsmallselected,
    bigselected,
    setbigselected,
    mainRoute,
    over,
    to,
    id,
  } = props;
  const classes = useStyles();
  const [expanded, setExpanded] = useState(mainRoute === bigselected);
  const history = useHistory();
  const canExpand = () => {
    // if (mainRoute === bigSelected) return
    setExpanded(!expanded);
  };
  const handleClickBig = () => {
    setbigselected(mainRoute);
    history.push(to);
  };
  const handleClickSmall = (item) => {
    setbigselected(mainRoute);
    setsmallselected(item.name);
    history.push(item.to);
  };
  return (
    <a className={classes.textDeco}>
      {subRoutes ? (
        <div className={classes.borderBox}>
          {/* <a href={subRoutes[0].to} className={classes.textDeco}> */}
          <div
            onClick={() => canExpand()}
            className={`${classes.lineBox} ${
              mainRoute === bigselected
                ? classes.borderBoxSelected
                : classes.borderBoxNotSelected
            }`}
          >
            <Grid container>
              <Grid item xs={11}>
                <div className={classes.lineItems}>
                  <div
                    className={`${classes.icon} ${
                      mainRoute === bigselected ? classes.bigIconSelected : ''
                    }`}
                  >
                    {icon}
                  </div>
                  <div
                    className={`${classes.contentBig} ${
                      mainRoute === bigselected ? classes.bigIconSelected : ''
                    }`}
                  >
                    {content}
                  </div>
                </div>
              </Grid>
              <Grid item xs={1}>
                <KeyboardArrowDownIcon
                  className={`${classes.iconMargin} ${
                    expanded && over && classes.rotate
                  }`}
                />
              </Grid>
            </Grid>
          </div>
          <Collapse in={expanded && over}>
            {subRoutes.map((item, index) => {
              return (
                <a
                  key={index}
                  onClick={() => handleClickSmall(item)}
                  className={classes.textDeco}
                >
                  <div className={`${classes.lineBox} ${classes.color}`}>
                    <div className={classes.lineItems}>
                      <div
                        className={`${classes.contentSmall} ${
                          item.name === smallselected ? classes.subSelected : ''
                        }`}
                      >
                        {item.content}
                      </div>
                    </div>
                  </div>
                </a>
              );
            })}
          </Collapse>
        </div>
      ) : (
        <div className={classes.borderBox}>
          <a
            key={id}
            onClick={() => handleClickBig({ to, mainRoute })}
            className={classes.textDeco}
          >
            <div
              className={`${classes.lineBox} ${
                mainRoute === bigselected
                  ? classes.borderBoxSelected
                  : classes.borderBoxNotSelected
              }`}
            >
              <Grid container>
                <Grid item xs={11}>
                  <div className={classes.lineItems}>
                    <div
                      className={`${classes.icon} ${
                        mainRoute === bigselected ? classes.bigIconSelected : ''
                      }`}
                    >
                      {icon}
                    </div>
                    <div
                      className={`${classes.contentBig} ${
                        mainRoute === bigselected ? classes.bigIconSelected : ''
                      }`}
                    >
                      {content}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </a>
        </div>
      )}
    </a>
  );
};

export default SideBarItem;
