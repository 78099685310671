import React from 'react';
import {
  Home,
  AttachMoney,
  Settings,
  SupervisorAccount,
  RequestQuote,
  ManageAccounts,
  Store,
  NaturePeople,
  PointOfSale,
  Star,
  Receipt,
  DataObject,
  DataUsage,
  AssignmentInd,
} from '@mui/icons-material';

import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

export const mainRoutes = [
  {
    id: 1,
    content: 'Inicio',
    to: '/',
    icon: <Home />,
    mainRoute: 'home',
    permission: '',
  },
  {
    id: 2,
    content: 'Mis comisiones',
    to: '/user/commissions',
    icon: <AttachMoney />,
    mainRoute: 'commissions',
    permission: 'sales_man',
  },
  {
    id: 11,
    content: 'Mis nuevas comisiones',
    to: '/user/new_commissions',
    icon: <Receipt />,
    mainRoute: 'new_commissions',
    permission: 'sales_man',
  },
  {
    id: 3,
    content: 'Soporte',
    to: '/',
    icon: <Settings />,
    mainRoute: 'support',
    subRoutes: [
      {
        id: 1,
        content: 'Crear cuenta',
        to: '/beetrack/accounts/create',
        name: 'create-account',
      },
      {
        id: 2,
        content: 'Desactivar cuenta',
        to: '/beetrack/accounts/toggle',
        name: 'toggle-account',
      },
    ],
    permission: ['support_agent', 'support_supervisor', 'support_admin'],
  },
  {
    id: 4,
    content: 'Account partner',
    to: '/accountpartner',
    icon: <SupervisorAccount />,
    mainRoute: 'accountpartner',
    permission: 'account_partner',
    subRoutes: [
      {
        id: 1,
        content: 'Borradores de factura',
        to: '/accountpartner/drafts',
        name: 'ap-drafts',
        permission: 'account_partner',
      },
    ],
  },
  {
    id: 5,
    content: 'Contabilidad',
    to: '',
    icon: <RequestQuote />,
    mainRoute: 'accounting',
    permission: 'accounting',
    subRoutes: [
      {
        id: 1,
        content: 'Cobranza v2',
        to: '/collection',
        name: 'accounting-collection',
        permission: 'accounting',
      },
      {
        id: 2,
        content: 'Facturas',
        to: '/invoices',
        name: 'invoicing',
        permission: 'accounting',
      },
      {
        id: 2,
        content: 'Solicitudes',
        to: '/drafts',
        name: 'invoice-drafts',
        permission: 'accounting',
      },
      {
        id: 3,
        content: 'Conciliaciones',
        to: '/conciliations',
        name: 'conciliations',
        permission: 'accounting',
      },
      // {
      //   id: 4,
      //   content: 'Comisiones',
      //   to: '/commission-management',
      //   name: 'commissions-management',
      //   permission: 'accounting',
      // },
      {
        id: 5,
        content: 'Nuevas Comisiones',
        to: '/new-commission-management',
        name: 'new-commissions-management',
        permission: 'accounting',
      },
      {
        id: 6,
        content: 'Proveedores',
        to: '/suppliers?accounting',
        name: 'accounting-suppliers',
        permission: 'accounting',
      },
      {
        id: 7,
        content: 'Prepagos',
        to: '/accounting/prepaids',
        name: 'accounting-prepaids',
        permission: 'accounting',
      },
    ],
  },
  {
    id: 6,
    content: 'Management',
    to: '',
    icon: <ManageAccounts />,
    mainRoute: 'management',
    permission: 'approver',
    subRoutes: [
      {
        id: 1,
        content: 'Comentarios borradores',
        to: '/management/drafts',
        name: 'management-drafts',
        permission: 'approver',
      },
      {
        id: 2,
        content: 'Aprobación propuestas comerciales',
        to: '/sales/commercial-conditions-approval',
        name: 'cc-approval',
        permission: 'admin_sales_man',
      },
    ],
  },
  {
    id: 7,
    content: 'Clientes',
    to: '/client',
    icon: <Store />,
    mainRoute: 'clients',
    permission: 'client',
  },
  {
    id: 8,
    content: 'HR',
    to: '/hr',
    icon: <NaturePeople />,
    mainRoute: 'hr',
    permission: 'hr',
    subRoutes: [
      {
        id: 1,
        content: 'Ingresos',
        to: '/candidates',
        name: 'hr-candidates',
        permission: 'hr',
      },
      {
        id: 2,
        content: 'Accesos & Inventarios',
        to: '/hr/resources/all',
        name: 'hr-accesses',
        permission: 'hr',
      },
    ],
  },
  {
    id: 9,
    content: 'Ventas',
    to: '/salesman',
    icon: <PointOfSale />,
    mainRoute: 'sales',
    permission: 'sales_man',
    subRoutes: [
      {
        id: 1,
        content: 'Cobranzas',
        to: '/sales/collection',
        name: 'sales-collection',
        permission: 'sales_man',
      },
      {
        id: 2,
        content: 'Mis borradores',
        to: '/sales/drafts',
        name: 'sales-drafts',
        permission: 'sales_man',
      },
      {
        id: 3,
        content: 'Prospectadores',
        to: '/sales/prospectors',
        name: 'prospectors',
        permission: 'prospector',
      },
      {
        id: 4,
        content: 'Condiciones comerciales',
        to: '/sales/commercial-conditions',
        name: 'sales-cc',
        permission: 'sales_man',
      },
      {
        id: 5,
        content: 'Newbees checklist',
        to: '/sales/checklist',
        name: 'sales-checklist',
        permission: 'sales_man',
      },
    ],
  },
  {
    id: 10,
    content: 'Team leader',
    to: '',
    icon: <Star />,
    mainRoute: 'teamleader',
    permission: 'team_leader',
    subRoutes: [
      {
        id: 1,
        content: 'Proveedores',
        to: '/suppliers?team_leader',
        name: 'tl-suppliers',
        permission: 'team_leader',
      },
    ],
  },
  {
    id: 11,
    content: 'Datawarehouse',
    to: '/datawarehouse',
    icon: <DataObject />,
    mainRoute: 'datawarehouse',
    permission: 'data_admin',
  },
  {
    id: 12,
    content: 'Flujos',
    to: '/forms',
    icon: <FormatListBulletedIcon />,
    mainRoute: 'form',
    permission: 'form_admin',
    subRoutes: [
      {
        id: 1,
        content: 'Formularios',
        to: '/forms',
        name: 'forms',
        permission: 'form_admin',
      },
    ],
  },
  {
    id: 13,
    content: 'Dashboards',
    to: '/dashboards',
    icon: <DataUsage />,
    mainRoute: 'dashboards',
    permission: 'dashboard',
    subRoutes: [
      {
        id: 1,
        content: 'HelpCenter',
        to: '/dashboards/helpcenter',
        name: 'helpcenter',
        permission: 'dashboard',
      },
    ],
  },
  {
    id: 14,
    content: 'Customer',
    to: '/customer-auth',
    icon: <AssignmentInd />,
    mainRoute: 'customer-auth',
    permission: 'super',
  },
];
