import React, { useEffect, useState } from 'react';
import {
  Page,
  Table,
  SearchBar,
  Button,
  Snackbar,
} from '@beetrack/hp-components';
import { Add, Edit, Delete } from '@beetrack/hp-icons-react';
import { del, get } from '../../../utils/api';
import ConfirmationModal from '../../../shared/ConfirmationModal';

const AssociatedDeals = (props) => {
  const { history } = props;
  const [relations, setRelations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [reload, setReload] = useState(0);
  const [snack, setSnack] = useState({
    open: false,
    message: '',
    status: 'success',
  });
  const [modalAccept, setModalAccept] = useState({
    open: false,
    hubspot_id: '',
    quickbooks_id: '',
    country: '',
    id: '',
  });

  const handleDestroy = (item) => {
    let relation = item[0].item;
    setModalAccept({
      open: true,
      hubspot_id: relation.hubspot_id,
      quickbooks_id: relation.quickbooks_id,
      country: relation.realm_country,
      id: relation.relation_id,
    });
  };

  const destroyRelation = (id) => {
    del(`/associated_deals/${id}`).then((response) => {
      if (response.status === 200) {
        setSnack({
          open: true,
          message: 'Relación eliminada lógicamente',
          status: 'success',
        });
        setReload(reload + 1);
      } else {
        setSnack({
          open: true,
          message: 'Error eliminando relación',
          status: 'error',
        });
      }
    });
  };
  const handleEdit = (item) => {
    history.push(`/client/associated/edit/${item[0].item.relation_id}`);
  };

  const columns = [
    {
      name: 'relation_id',
      title: 'Relation ID',
      valueByAttribute: 'relation_id',
    },
    {
      name: 'parent_id',
      title: 'ID relación padre',
      valueByAttribute: 'parent_id',
    },
    { name: 'hubspot_id', title: 'Hubspot ID', valueByAttribute: 'hubspot_id' },
    {
      name: 'quickbooks_id',
      title: 'Quickbooks ID',
      valueByAttribute: 'quickbooks_id',
    },
    {
      name: 'realm_country',
      title: 'País Quickbooks',
      valueByAttribute: 'realm_country',
    },
  ];

  const actions = [
    {
      title: 'Editar relación',
      icon: <Edit />,
      onClick: handleEdit.bind(this),
      isGlobal: false,
      key: 'edit',
    },
    {
      title: 'Borrado lógico',
      icon: <Delete />,
      onClick: handleDestroy.bind(this),
      isGlobal: false,
      key: 'delete',
    },
  ];

  const handleSearch = (value) => {
    setSearch(value);
    var new_data = relations;
    if (value.hubspot_id) {
      new_data = new_data.filter(
        (element) => element.hubspot_id == value.hubspot_id,
      );
    } else if (value.quickbooks_id) {
      new_data = new_data.filter(
        (element) => element.quickbooks_id == value.quickbooks_id,
      );
    } else if (value.realm_country) {
      new_data = new_data.filter(
        (element) => element.realm_country == value.realm_country,
      );
    } else if (value.relation_id) {
      new_data = new_data.filter(
        (element) => element.relation_id == value.relation_id,
      );
    } else if (value.parent_id) {
      new_data = new_data.filter(
        (element) => element.parent_id == value.parent_id,
      );
    }

    setFilteredData(new_data);
  };

  useEffect(() => {
    get('/associated_deals').then((response) => {
      if (response.status === 200) {
        setRelations(response.data.relations);
        setFilteredData(response.data.relations);
        setLoading(false);
      }
    });
  }, [reload]);

  return (
    <Page
      title="Associated Deals"
      onGoBack={'/client'}
      buttons={[
        <Button
          icon={<Add />}
          onClick={() => history.push('/client/associated/new')}
        >
          {' '}
          Nueva Relacion{' '}
        </Button>,
      ]}
    >
      <Snackbar
        key="snackbar"
        isOpen={snack.open}
        message={snack.message}
        onDismiss={() =>
          setSnack({ open: false, message: '', status: 'error' })
        }
        status={snack.status}
      />
      <ConfirmationModal
        text="¿Eliminar asociación?"
        secondaryText={`Se eliminará lógicamente el deal asociado con Hubspot ID: ${modalAccept.hubspot_id}, Quickbooks ID: ${modalAccept.quickbooks_id} y realm ID ${modalAccept.country}`}
        open={modalAccept.open}
        close={() =>
          setModalAccept({
            open: false,
            hubspot_id: '',
            quickbooks_id: '',
            country: '',
            id: '',
          })
        }
        onConfirm={() => destroyRelation(modalAccept.id)}
      />
      <div style={{ paddingBottom: '24px' }}>
        <SearchBar
          callback={(value) => handleSearch(value)}
          emptyCallback={() => {
            setSearch({});
            setFilteredData(relations);
          }}
          value={search}
          options={[
            { label: 'Relation ID', name: 'relation_id' },
            { label: 'Hubspot ID', name: 'hubspot_id' },
            { label: 'Quickbooks ID', name: 'quickbooks_id' },
            { label: 'País', name: 'realm_country' },
            { label: 'Relación padre ID', name: 'parent_id' },
          ]}
        />
      </div>
      <Table
        columns={columns}
        data={filteredData}
        loading={loading}
        actions={actions}
      />
    </Page>
  );
};

export default AssociatedDeals;
