import React from 'react';
import { Select } from '@beetrack/hp-components';
import { Grid } from '@mui/material';

import { getNestedValue } from '../utils';

const MultipleElementTranslator = (props) => {
  const { classes, form, formValues, setFormValues, path, dependant } = props;

  const element = getNestedValue(form.content, path);

  return (
    <Grid item sm={12} md={dependant ? 12 : 10} className={classes.element}>
      <div className={classes.question}>{element.question}</div>
      {element.imgUrl && (
        <div className={classes.imageContainer}>
          <img
            style={{ width: '100%', height: '100%' }}
            src={element.imgUrl}
            alt="new"
          />
        </div>
      )}
      <div className={classes.selectField}>
        <Select
          isMulti
          helperText={element.helperText}
          value={formValues[`${element.propertyName}`]}
          label={element.title}
          required={element.required}
          options={Object.values(element.options)}
          onChange={(values) => {
            setFormValues({
              ...formValues,
              [`${element.propertyName}`]: (values || []).map((x) => x.value),
            });
          }}
        />
      </div>
    </Grid>
  );
};

export default MultipleElementTranslator;
