import React, { useState, useEffect } from 'react';
import { Loading } from '@beetrack/hp-components';
import { hr_new_get } from '../../../utils/api';
import FormChile from './FormChile';
import FormOther from './FormOther';

const PublicForm = (props) => {
  const uuid = props.match.params.uuid;
  const [name, setName] = useState('');
  const [rut, setRut] = useState('');
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState('');
  const [loading, setLoading] = useState(true);
  const [completed, setCompleted] = useState(false);
  const [contractType, setContractType] = useState('');

  useEffect(() => {
    hr_new_get('/onboarding-form/' + uuid).then((res) => {
      if (res.status === 200) {
        let candidate = res.data;
        setName(candidate.name);
        setRut(candidate.rut);
        setCountry(candidate.country);
        setEmail(candidate.personal_email);
        setCompleted(candidate.form_completed);
        setContractType(candidate.contract_type);
        setLoading(false);
      }
    });
  }, []);

  return (
    <div>
      {loading && (
        <center>
          {' '}
          <Loading />{' '}
        </center>
      )}
      {!loading && !completed && (
        <div>
          {country === 'Chile' && (
            <FormChile
              contractType={contractType}
              uuid={uuid}
              rut={rut}
              country={country}
              name={name}
              email={email}
            />
          )}
          {country !== 'Chile' && (
            <FormOther
              contractType={contractType}
              uuid={uuid}
              country={country}
              name={name}
              email={email}
            />
          )}
        </div>
      )}
      {!loading && completed && (
        <center>
          <h4>
            {' '}
            Estimad@ {name}, ya has completado este formulario. Si no fue así
            contacta a Recursos Humanos{' '}
          </h4>
        </center>
      )}
    </div>
  );
};

export default PublicForm;
