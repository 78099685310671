import React, { useState, useEffect } from 'react';
import {
  Form,
  TextField,
  Button,
  Snackbar,
  Modal,
  InfoBox,
  Switch,
  ConfirmationModal,
  Select,
} from '@beetrack/hp-components';
import { View, Add } from '@beetrack/hp-icons-react';
import { useStyles } from './Styles/styles';

import { post, get, putForm } from '../../utils/api';
import FormTranslator from './FormTranslator';
import {
  getMaxKey,
  updateNestedObject,
  getNestedValue,
  deleteNestedProperty,
  addNewKey,
  newTextElement,
  newBigTextElement,
  newNumberElement,
  newDateElement,
  newSimpleElement,
  newMultipleElement,
  newDependantElement,
  sortKeys,
  reOrder,
  renameElement,
  checkPropertyNames,
  newFileElement,
} from './utils';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Group from './FormComponents/Group';

const CreateForm = (props) => {
  const [components, setComponents] = useState({});
  const [collapsed, setCollapsed] = useState({});

  const [creating, setCreating] = useState(true);
  const [fid, setFid] = useState(undefined);

  const [formName, setFormName] = useState('');
  const [formTitle, setFormTitle] = useState('');
  const [formDescription, setFormDescription] = useState('');
  const [formMultipleResponses, setFormMultipleResponses] = useState(false);

  const [email, setEmail] = useState('');
  const [needsEmail, setNeedsEmail] = useState(false);

  const [groupID, setGroupID] = useState(1);
  const [elementID, setElementID] = useState(1);
  const [optionID, setOptionID] = useState(1);

  const [errorMessage, setErrorMessage] = useState('');
  const [status, setStatus] = useState('alert');
  const [openAlert, setOpenAlert] = useState(false);

  const [openPreviewModal, setOpenPreviewModal] = useState(false);

  const [previewFormValues, setPreviewFormValues] = useState({});

  const [openCloneModal, setOpenCloneModal] = useState(false);

  const [selectedElement, setSelectedElement] = useState(false);
  const [selectedFormId, setSelectedFormId] = useState(false);
  const [selectedForm, setSelectedForm] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(false);

  const [formOptions, setFormOptions] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);

  const classes = useStyles();

  const handleClone = async (path) => {
    setSelectedElement(getNestedValue(components, path));
    setOpenCloneModal(!openCloneModal);
  };

  const handleCloneClose = () => {
    setSelectedElement(false);
    setSelectedGroup(false);
    setSelectedFormId(false);
    setSelectedForm(false);
    setGroupOptions([]);
    setOpenCloneModal(!openCloneModal);
  };

  const loadForms = async () => {
    const response = await get('/forms');
    if (response.status === 200) {
      setFormOptions([
        { value: -1, label: 'Este formulario' },
        ...response.data.forms
          .filter((item) => item.id !== fid)
          .map((item) => ({
            value: item,
            label: item.id + ' - ' + item.name,
          })),
      ]);
    } else if (response.status === 500) {
      setErrorMessage('Hubo un error, recarga la pagina');
      setStatus('error');
      setOpenAlert(true);
    } else {
      setErrorMessage('Ha ocurrido un error inesperado');
      setStatus('error');
      setOpenAlert(true);
    }
  };

  const clone = async () => {
    if (!checkPropertyNames(selectedElement.propertyName)) {
      setErrorMessage(
        'Se encontraron elementos con valor interno de property vacias',
      );
      setStatus('alert');
      setOpenAlert(true);
    } else if (selectedFormId === -1) {
      const maxE = getMaxKey(components, 'E');
      const new_element = renameElement(
        selectedElement,
        maxE + 2,
        getMaxKey(selectedForm, 'O') + 1,
      );
      setComponents(
        addNewKey(
          components,
          [`${selectedGroup}`],
          `E-${maxE + 1}`,
          new_element,
        ),
      );
      setErrorMessage('Pregunta clonada exitosamente');
      setStatus('success');
      setOpenAlert(true);
    } else {
      const maxE = getMaxKey(selectedForm.content, 'E');
      const new_element = renameElement(
        selectedElement,
        maxE + 2,
        getMaxKey(selectedForm.content, 'O') + 1,
      );
      const new_content = addNewKey(
        selectedForm.content,
        [`${selectedGroup}`],
        `E-${maxE + 1}`,
        new_element,
      );
      const payload = {
        fid: selectedFormId,
        new_content: new_content,
      };

      const response = await post('/forms/clone', payload);
      if (response.status === 200) {
        setErrorMessage('Elemento clonado exitosamente');
        setStatus('success');
        setOpenAlert(true);
      } else {
        setErrorMessage('Ocurrio un error al intentar clonar el elemento');
        setStatus('error');
        setOpenAlert(true);
      }
    }
    setSelectedElement(false);
    setSelectedGroup(false);
    setSelectedFormId(false);
    setSelectedForm(false);
    setGroupOptions([]);
    setOpenCloneModal(!openCloneModal);
    loadForms();
  };

  const formChangeHandler = (e) => {
    setSelectedGroup(false);
    if (e.value === -1) {
      setGroupOptions(
        sortKeys(components).map((element) => ({
          value: element,
          label: components[element].title
            ? components[element].title
            : element,
        })),
      );
      setSelectedFormId(-1);
      setSelectedForm(components);
    } else {
      setSelectedForm(e.value);
      setSelectedFormId(e.value.id);
      setGroupOptions(
        sortKeys(e.value.content).map((item) => ({
          value: item,
          label: e.value.content[item].title
            ? e.value.content[item].title
            : item,
        })),
      );
    }
  };

  const groupChangeHandler = (e) => {
    setSelectedGroup(e.value);
  };

  const previewHandleForm = () => {
    setOpenPreviewModal(!openPreviewModal);
  };

  const formNameChanger = (e) => {
    setFormName(e.target.value);
  };

  const formTitleChanger = (e) => {
    setFormTitle(e.target.value);
  };

  const formDescriptionChanger = (e) => {
    setFormDescription(e.target.value);
  };

  const emailChanger = (e) => {
    setEmail(e.target.value);
  };

  const fileTypeChangeHandler = (e, path) => {
    const newObj = updateNestedObject(
      components,
      path,
      (e || []).map((x) => x.value),
    );
    setComponents(newObj);
  };

  const newGroup = () => {
    const key = `G-${groupID}`;
    setComponents({ ...components, [key]: { title: '' } });
    setCollapsed({ ...collapsed, [key]: true });
    setGroupID(groupID + 1);
  };

  const deleteGroup = (group) => {
    let aux = { ...components };
    delete aux[group];
    setComponents(aux);
  };

  const groupTitleChanger = (e) => {
    const aux = e.target.name;
    setComponents({
      ...components,
      [aux]: { ...components[aux], title: e.target.value },
    });
  };

  const newElement = (path, elementType) => {
    const key = `E-${elementID}`;
    setCollapsed({ ...collapsed, [key]: true });
    if (elementType === 'text') {
      const newObj = addNewKey(components, path, key, newTextElement);
      setComponents(newObj);
    } else if (elementType === 'bigText') {
      const newObj = addNewKey(components, path, key, newBigTextElement);
      setComponents(newObj);
    } else if (elementType === 'number') {
      const newObj = addNewKey(components, path, key, newNumberElement);
      setComponents(newObj);
    } else if (elementType === 'date') {
      const newObj = addNewKey(components, path, key, newDateElement);
      setComponents(newObj);
    } else if (elementType === 'simple') {
      const newObj = addNewKey(
        components,
        path,
        key,
        newSimpleElement(optionID),
      );
      setOptionID(optionID + 1);
      setComponents(newObj);
    } else if (elementType === 'multiple') {
      const newObj = addNewKey(
        components,
        path,
        key,
        newMultipleElement(optionID),
      );
      setOptionID(optionID + 1);
      setComponents(newObj);
    } else if (elementType === 'dependant') {
      const newObj = addNewKey(
        components,
        path,
        key,
        newDependantElement(optionID),
      );
      setOptionID(optionID + 1);
      setComponents(newObj);
    } else if (elementType === 'file') {
      const newObj = addNewKey(components, path, key, newFileElement);
      setComponents(newObj);
    }
    setElementID(elementID + 1);
  };

  const deleteElement = (path) => {
    const aux = { ...components };
    const newComponent = deleteNestedProperty(aux, path);
    setComponents(newComponent);
  };

  const textChangeHandler = (e, path_to) => {
    const path = [...path_to, e.target.name];
    const newObj = updateNestedObject(components, path, e.target.value);
    setComponents(newObj);
  };

  const switchHandler = (e, path) => {
    const newObj = updateNestedObject(components, path, e.target.checked);
    setComponents(newObj);
  };

  const addSimpleOption = (path) => {
    const key = `O-${optionID}`;
    const newObj = addNewKey(components, path, key, { label: '', value: '' });
    setComponents(newObj);
    setOptionID(optionID + 1);
  };

  const addSpecialOption = (path) => {
    const key = `O-${optionID}`;
    const newObj = addNewKey(components, path, key, {
      label: '',
      value: '',
      set: {},
    });
    setComponents(newObj);
    setOptionID(optionID + 1);
  };

  const deleteOption = (path, key) => {
    if (Object.keys(getNestedValue(components, path)).length < 2) {
      setErrorMessage('Se requiere almenos una opción');
      setStatus('error');
      setOpenAlert(true);
    } else {
      const newObj = deleteNestedProperty(components, [...path, key]);
      setComponents(newObj);
    }
  };

  const simpleChangeHandler = (e, path_to) => {
    const path = [...path_to, e.target.name];
    const newObj = updateNestedObject(components, path, e.target.value);
    setComponents(newObj);
  };

  const handleForm = async (event) => {
    event.preventDefault();

    const data = {
      name: formName,
      title: formTitle,
      description: formDescription,
      payload: {
        ...components,
        email: { required: needsEmail, propertyName: email },
      },
      multiple_responses: formMultipleResponses,
    };
    if (creating) {
      const formPostResponse = await post('/forms/create_form', data);
      if (formPostResponse.status === 201) {
        setErrorMessage('Datos Ingresados Correctamente');
        setStatus('success');
        setOpenAlert(true);
        window.location = `/forms/${formPostResponse.data.fid}`;
      } else if (Object.keys(formPostResponse.data.errors)) {
        setErrorMessage(
          `${Object.keys(formPostResponse.data.errors)[0]}: ${
            Object.values(formPostResponse.data.errors)[0][0]
          }`,
        );
        setStatus('error');
        setOpenAlert(true);
      } else {
        setErrorMessage('Debes crear almenos un componente');
        setStatus('error');
        setOpenAlert(true);
      }
    } else {
      const formPutResponse = await putForm(`/forms/edit_form/${fid}`, data);
      if (formPutResponse.status === 201) {
        setErrorMessage('Datos Ingresados Correctamente');
        setStatus('success');
        setOpenAlert(true);
        window.location = `/forms/${fid}`;
      } else if (Object.keys(formPutResponse.data.errors)) {
        setErrorMessage(
          `${Object.keys(formPutResponse.data.errors)[0]}: ${
            Object.values(formPutResponse.data.errors)[0][0]
          }`,
        );
        setStatus('error');
        setOpenAlert(true);
      } else {
        setErrorMessage('Debes crear almenos un componente');
        setStatus('error');
        setOpenAlert(true);
      }
    }
  };

  const previewForm = () => {
    setOpenPreviewModal(true);
  };

  const fetchForm = async (fid) => {
    const response = await get(`/forms/fetch_form/${fid}`);
    if (response.status === 200) {
      setComponents(response.data.data.content);
      setFormTitle(response.data.data.title);
      setFormName(response.data.data.name);
      setFormDescription(response.data.data.description);
      setFormMultipleResponses(response.data.data.multiple_responses);
      setEmail(response.data.data.content.email?.propertyName ?? false);
      setNeedsEmail(response.data.data.content.email?.needsEmail ?? false);
      const data = response.data.data.content;
      setGroupID(getMaxKey(data, 'G') + 1);
      setElementID(getMaxKey(data, 'E') + 1);
      setOptionID(getMaxKey(data, 'O') + 1);
    } else {
      setErrorMessage('Ha ocurrido un error, recarga la pagina');
      setStatus('error');
      setOpenAlert(true);
    }
  };

  useEffect(() => {
    const fid_value = props?.match?.params?.fid;
    if (fid_value) {
      setFid(fid_value);
      setCreating(false);
      fetchForm(fid_value);
    }
    loadForms();
  }, []);

  return (
    <div>
      <div className={classes.topcontainer}>
        <h1 className={classes.mainTitle}>
          {creating ? 'Creando Form' : 'Editando Form'}
        </h1>
        <Button icon={<View />} type="tertiary" onClick={previewForm}>
          Vista previa
        </Button>
      </div>
      <Form
        title="Creador de Formularios"
        buttons={[
          <Button
            key="submit"
            form="my-form"
            disabled={
              formName === '' ||
              formTitle === '' ||
              formDescription === '' ||
              (needsEmail === true && email === '')
            }
          >
            {creating ? 'Crear Formulario' : 'Guardar Cambios'}
          </Button>,
        ]}
        id="my-form"
        onSubmit={handleForm}
      >
        <div className={classes.formName}>
          <TextField
            label="Nombre del Form"
            name={`name`}
            key={`form-name`}
            value={formName}
            onChange={formNameChanger}
            helperText="Nombre del formulario para uso interno"
            required
          />
        </div>
        <div className={classes.formTitle}>
          <TextField
            label="Título del Form"
            name={`title`}
            key={`form-title`}
            value={formTitle}
            onChange={formTitleChanger}
            helperText="Título que quieres que tenga el formulario"
            required
          />
        </div>
        <div className={classes.formDescription}>
          <TextField
            label="Descripción del Form"
            name={`description`}
            key={`form-description`}
            value={formDescription}
            onChange={formDescriptionChanger}
            helperText="Descripción que quieres que tenga el formulario"
            required
          />
        </div>
        <div className={classes.switchContainer}>
          <div className={classes.switchText}>
            ¿Deseas que el formulario permita múltiples respuestas de un mismo
            'deal'?
          </div>
          <Switch
            rightLabel="Sí"
            leftLabel="No"
            onChange={(e) => setFormMultipleResponses(e.target.checked)}
            checked={formMultipleResponses}
          />
        </div>

        <div className={classes.switchContainer}>
          <div className={classes.switchText}>
            Quien rellenará el formulario, debe ingresar su correo para enviar
            una copia de las respuestas?
          </div>
          <Switch
            rightLabel="Sí"
            leftLabel="No"
            onChange={(e) => setNeedsEmail(e.target.checked)}
            checked={needsEmail}
          />
        </div>
        {needsEmail && (
          <div>
            <TextField
              label="Nombre interno property"
              name={`email`}
              key={`form-email`}
              value={email}
              onChange={emailChanger}
              helperText="Nombre interno de la propery que contendra el correo"
              required
            />
          </div>
        )}
        <div className={classes.groupsContainer}>
          <DragDropContext
            onDragEnd={(result) => reOrder(result, components, setComponents)}
          >
            <Droppable
              key={'groups'}
              type="GROUP"
              droppableId={'board'}
              isCombineEnabled={false}
              ignoreContainerClipping={true}
            >
              {(droppableProvidedG, snapshotG) => (
                <div
                  key={'group'}
                  {...droppableProvidedG.droppableProps}
                  ref={droppableProvidedG.innerRef}
                  className={
                    snapshotG.isDraggingOver
                      ? classes.groupContainerCreatorOver
                      : classes.groupContainerCreator
                  }
                >
                  {sortKeys(components).map((group, indexGroup) => {
                    return (
                      <Draggable
                        key={group}
                        draggableId={group}
                        index={indexGroup}
                        type={'GROUP'}
                      >
                        {(draggableProvidedG, snapshotG) => (
                          <Group
                            draggableProvidedG={draggableProvidedG}
                            snapshotG={snapshotG}
                            classes={classes}
                            components={components}
                            group={group}
                            indexGroup={indexGroup}
                            newElement={newElement}
                            collapsed={collapsed}
                            setCollapsed={setCollapsed}
                            textChangeHandler={textChangeHandler}
                            switchHandler={switchHandler}
                            deleteElement={deleteElement}
                            simpleChangeHandler={simpleChangeHandler}
                            addSimpleOption={addSimpleOption}
                            addSpecialOption={addSpecialOption}
                            deleteOption={deleteOption}
                            handleClone={handleClone}
                            deleteGroup={deleteGroup}
                            groupTitleChanger={groupTitleChanger}
                            fileTypeChangeHandler={fileTypeChangeHandler}
                          />
                        )}
                      </Draggable>
                    );
                  })}
                  {droppableProvidedG.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <Button icon={<Add />} type="tertiary" onClick={newGroup}>
          Añadir Grupo
        </Button>
        <div className={classes.infoBox}>
          <InfoBox status="info">
            <p>
              Los grupos corresponden a un "set" de preguntas, tu formulario
              puede tener uno como varios grupos con el fin de estar más
              ordenado
            </p>
          </InfoBox>
        </div>
      </Form>
      <Snackbar
        isOpen={openAlert}
        message={errorMessage}
        onDismiss={() => setOpenAlert(false)}
        status={status}
      />

      <Modal
        key="aside-modal"
        open={openPreviewModal}
        title="Vista Previa"
        aside
        onClose={() => setOpenPreviewModal(!openPreviewModal)}
        renderContentIfOpen
      >
        <div>
          <FormTranslator
            disableSubmit={true}
            form={{
              name: formName,
              title: formTitle,
              description: formDescription,
              content: components,
            }}
            formValues={previewFormValues}
            setFormValues={setPreviewFormValues}
            classes={classes}
            handleForm={previewHandleForm}
          />
        </div>
      </Modal>

      <ConfirmationModal
        key="conf-modal"
        open={openCloneModal}
        title="Clonar Pregunta"
        aside
        onClose={() => handleCloneClose()}
        confirmButton={
          <Button
            disabled={!selectedFormId || !selectedGroup || !selectedElement}
            onClick={() => clone()}
          >
            Clonar
          </Button>
        }
      >
        <Form>
          <Select
            helperText={'Seleccione el Formulario'}
            value={
              selectedFormId
                ? selectedFormId !== -1
                  ? selectedForm.name
                  : 'Este Form'
                : ''
            }
            label={'Formulario 1'}
            options={formOptions}
            required
            onChange={(e) => formChangeHandler(e)}
          />
          <Select
            helperText={'Seleccione el Grupo'}
            value={
              selectedFormId
                ? selectedFormId !== -1
                  ? selectedForm?.content[selectedGroup]?.title
                  : components[selectedGroup]?.title
                : ''
            }
            label={'Grupo 1'}
            options={groupOptions}
            required
            onChange={(e) => groupChangeHandler(e)}
          />
        </Form>
      </ConfirmationModal>
    </div>
  );
};

export default CreateForm;
