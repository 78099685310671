export const styles = (theme) => ({
  wrapper: {
    width: '100%',
    height: '100%',
  },
  paperSelector: {
    height: '35%',
    marginBottom: theme.spacing(2),
    padding: `${theme.spacing(2)}px 0`,
  },
  paperList: {
    height: '65%',
    overflowY: 'auto',
  },
  removeIconMargin: {
    marginRight: 0,
  },
  warningIcon: {
    marginRight: theme.spacing(1),
    verticalAlign: 'sub',
  },
  clientName: {
    padding: 0,
  },
  clientInformation: {
    display: 'grid',
    height: `calc(100% - ${theme.spacing(4.5)}px)`,
    marginTop: theme.spacing(2),
    gridTemplateColumns: '1fr 1fr',
  },
  tableInvoices: {
    overflowY: 'auto',
  },
  stretchRow: {
    height: theme.spacing(3.5),
  },
  checkColumn: {
    width: theme.spacing(6),
  },
  title: {
    flex: '0 0 auto',
  },
  toolbarColor: {
    backgroundColor: theme.palette.primary.main,
  },
  spacer: {
    flex: '1 1 100%',
  },
  toolbarText: {
    color: 'white',
  },
  toolbarActions: {
    position: 'relative',
  },
  toggleButton: {
    width: '220px',
    textAlign: 'right',
    paddingRight: '2px',
  },
  actionButton: {
    width: '170px',
    backgroundColor: theme.palette.primary.dark,
    color: 'white',
    fontWeight: 'bold',
    borderRadius: '4px 0px 0px 4px',
  },
  optionButton: {
    minWidth: '32px',
    borderRadius: '0px 4px 4px 0px',
    height: '36px',
  },
  listTitle: {
    fontWeight: 'bold',
  },
  beetrackData: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  selectContainer: {
    position: 'relative',
    zIndex: 11
  },
  stickyToolbar: {
    position: 'sticky',
    top: '0',
  } /*  tbHead: {
    borderStyle: 'dotted',
    borderColor: 'red',
    position: 'fixed',
    top: '65px'
  } */,
});
