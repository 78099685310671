import React, { useState, useEffect } from 'react';
import { TextField, FormRow, FormGroup } from '@beetrack/hp-components';

const BankData = (props) => {
  const { setBankData, country, paymentMethod } = props;
  const [bankName, setBankName] = useState('');
  const [bankRepresentant, setBankRepresentant] = useState('');
  const [bankIdentifier, setBankIdentifier] = useState('');
  const [bankAccount, setBankAccount] = useState('');
  const [bankEmail, setBankEmail] = useState('');
  const [bankClabe, setBankClabe] = useState('');
  const [bankRepresentantCountry, setBankRepresentantCountry] = useState('');
  const [bankRepresentantCity, setBankRepresentantCity] = useState('');
  const [bankRepresentantPhone, setBankRepresentantPhone] = useState('');
  const [bankRepresentantEmail, setBankRepresentantEmail] = useState('');
  const [bankRepresentantContact, setBankRepresentantContact] = useState('');
  const [bankRepresentantAddress, setBankRepresentantAddress] = useState('');
  const [bankLocal, setBankLocal] = useState('');
  const [bankSwift, setBankSwift] = useState('');
  const [bankCountry, setBankCountry] = useState('');
  const [bankCode, setBankCode] = useState('');

  useEffect(() => {
    setBankData({
      bankName,
      bankRepresentant,
      bankIdentifier,
      bankAccount,
      bankEmail,
      bankClabe,
      bankRepresentantCountry,
      bankRepresentantCity,
      bankRepresentantPhone,
      bankRepresentantEmail,
      bankRepresentantContact,
      bankRepresentantAddress,
      bankLocal,
      bankSwift,
      bankCountry,
      bankCode,
    });
  }, [
    bankName,
    bankRepresentant,
    bankIdentifier,
    bankAccount,
    bankEmail,
    bankClabe,
    bankRepresentantCountry,
    bankRepresentantCity,
    bankRepresentantPhone,
    bankRepresentantEmail,
    bankRepresentantContact,
    bankRepresentantAddress,
    bankLocal,
    bankSwift,
    bankCountry,
    bankCode,
  ]);
  const ChileBank = () => {
    return (
      <FormGroup title="Datos Bancarios" collapsible={false}>
        <FormRow>
          <TextField
            key="bankName"
            label="Banco"
            value={bankName}
            fullWidth
            onChange={(e) => setBankName(e.target.value)}
          />
          <TextField
            key="bankRepresentant"
            label="Titular"
            value={bankRepresentant}
            onChange={(e) => setBankRepresentant(e.target.value)}
            fullWidth
          />
        </FormRow>
        <FormRow>
          <TextField
            key="bankIdentifier"
            label="RUT"
            value={bankIdentifier}
            onChange={(e) => setBankIdentifier(e.target.value)}
            fullWidth
          />
          <TextField
            key="bankAccount"
            label="Numero cuenta corriente"
            value={bankAccount}
            fullWidth
            onChange={(e) => setBankAccount(e.target.value)}
          />
        </FormRow>
        <FormRow>
          <TextField
            key="bankEmail"
            label="Correo Electrónico"
            value={bankEmail}
            onChange={(e) => setBankEmail(e.target.value)}
            fullWidth
          />
        </FormRow>
      </FormGroup>
    );
  };
  const MexicoBank = () => {
    return (
      <FormGroup title="Datos Bancarios" collapsible={false}>
        <FormRow>
          <TextField
            key="bankName"
            label="Banco"
            value={bankName}
            fullWidth
            onChange={(e) => setBankName(e.target.value)}
          />
          <TextField
            key="bankRepresentant"
            label="Titular"
            value={bankRepresentant}
            onChange={(e) => setBankRepresentant(e.target.value)}
            fullWidth
          />
        </FormRow>
        <FormRow>
          <TextField
            key="bankIdentifier"
            label="RFC"
            value={bankIdentifier}
            onChange={(e) => setBankIdentifier(e.target.value)}
            fullWidth
          />
          <TextField
            key="bankAccount"
            label="Numero cuenta corriente"
            value={bankAccount}
            fullWidth
            onChange={(e) => setBankAccount(e.target.value)}
          />
        </FormRow>
        <FormRow>
          <TextField
            key="bankEmail"
            label="Correo Electrónico"
            value={bankEmail}
            onChange={(e) => setBankEmail(e.target.value)}
            fullWidth
          />
          <TextField
            key="bankClabe"
            label="Clabe interbancaria"
            value={bankClabe}
            onChange={(e) => setBankClabe(e.target.value)}
            fullWidth
          />
        </FormRow>
      </FormGroup>
    );
  };
  const ItlBank = () => {
    return (
      <div>
        <FormGroup title="Datos del beneficiario" collapsible={false}>
          <FormRow>
            <TextField
              key="bankRepresentant"
              label="Nombre beneficiario"
              value={bankRepresentant}
              onChange={(e) => setBankRepresentant(e.target.value)}
              fullWidth
            />
            <TextField
              key="bankRepresentantAddress"
              label="Dirección"
              value={bankRepresentantAddress}
              fullWidth
              onChange={(e) => setBankRepresentantAddress(e.target.value)}
            />
          </FormRow>
          <FormRow>
            <TextField
              key="bankRepresentantCountry"
              label="País"
              value={bankRepresentantCountry}
              onChange={(e) => setBankRepresentantCountry(e.target.value)}
              fullWidth
            />
            <TextField
              key="bankRepresentantCity"
              label="Ciudad"
              value={bankRepresentantCity}
              fullWidth
              onChange={(e) => setBankRepresentantCity(e.target.value)}
            />
          </FormRow>
          <FormRow>
            <TextField
              key="bankRepresentantPhone"
              label="Teléfono"
              value={bankRepresentantPhone}
              onChange={(e) => setBankRepresentantPhone(e.target.value)}
              fullWidth
            />
            <TextField
              key="bankRepresentantEmail"
              label="Correo electrónico"
              value={bankRepresentantEmail}
              onChange={(e) => setBankRepresentantEmail(e.target.value)}
              fullWidth
            />
          </FormRow>
          <FormRow>
            <TextField
              key="bankRepresentantContact"
              label="Contacto"
              value={bankRepresentantContact}
              onChange={(e) => setBankRepresentantContact(e.target.value)}
              fullWidth
            />
            <TextField
              key="bankSwift"
              label="Código swift del banco"
              value={bankSwift}
              onChange={(e) => setBankSwift(e.target.value)}
              fullWidth
            />
          </FormRow>
        </FormGroup>
        <br />
        <FormGroup title="Datos Bancarios" collapsible={false}>
          <FormRow>
            <TextField
              key="bankName"
              label="Banco"
              value={bankName}
              onChange={(e) => setBankName(e.target.value)}
              fullWidth
            />
            <TextField
              key="bankLocal"
              label="Sucursal"
              value={bankLocal}
              fullWidth
              onChange={(e) => setBankLocal(e.target.value)}
            />
          </FormRow>
          <FormRow>
            <TextField
              key="bankCountry"
              label="País"
              value={bankCountry}
              onChange={(e) => setBankCountry(e.target.value)}
              fullWidth
            />
            <TextField
              key="bankCode"
              label="Código"
              value={bankCode}
              fullWidth
              onChange={(e) => setBankCode(e.target.value)}
            />
          </FormRow>
          <FormRow>
            <TextField
              key="bankAccount"
              label="Cuenta corriente"
              value={bankAccount}
              onChange={(e) => setbankAccount(e.target.value)}
              fullWidth
            />
          </FormRow>
        </FormGroup>
      </div>
    );
  };

  return (
    <div>
      {paymentMethod === 'Transfer itl' && ItlBank()}
      {paymentMethod === 'Transfer' && (
        <>
          {country
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase() === 'chile' && ChileBank()}
          {country
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase() === 'mexico' && MexicoBank()}
        </>
      )}
      <br />
    </div>
  );
};

export default BankData;
