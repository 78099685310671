import React, { forwardRef, useEffect, useState } from 'react';
import { Paper, Grid } from '@material-ui/core';
import { get, post } from '../../../../utils/api';
import { Button, Card, CardContent, CardDivider } from '@beetrack/hp-components';
import { makeStyles } from '@material-ui/core/styles';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import DraftTable from './Components/Table';
import DraftCollapsable from './Components/Collapsable';
import CommentsModal from '../../../Drafts/Components/CommentsModal';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { Loading } from '@beetrack/hp-components';
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles({
  root: {
    paddingBottom: '3%',
  },
  modal: {
    paddingLeft: '3%',
    paddingRight: '3%',
    paddingBottom: '3%',
    paddingTop:'3%',
    margin: '0 auto',
    position: 'relative',
    backgroundColor: '#ededed',
    height: '650px',
    overflow: 'auto',
    borderRadius: '15px',
  },
  closeIcon: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    cursor: 'pointer',
  },
  buttons: {
    paddingTop: '5%',
    paddingLeft: '10%',
  },
  rightBorder: {
    borderRight: '1px solid black',
  },
  table: {
    minWidth: 650,
    border: '2px solid black',
    backgroundColor: '#ebb734',
  },
  loading: {
    width: '100vw',
    textAlign: 'center',
    display: 'block',
    paddingTop: '10%',
  },
  line: {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    width: 10,
    bottom: 0,
    top: 0,
    left: 0,
    position: 'absolute',
    background: 'linear-gradient(180deg,#ffd200,#f4aa1f)',
  },
});

const DraftModal = forwardRef((props, ref) => {
  const id = props.id;
  const amount = props.amount;
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [accountPartner, setAccountPartner] = useState('');
  const [salesMan, setSalesMan] = useState('');
  const [companyType, setCompanyType] = useState('');
  const [dealName, setDealName] = useState('');
  const [dealSource, setDealSource] = useState('');
  const [beetrackId, setBeetrackId] = useState('');
  const [products, setProducts] = useState([]);
  const [comments, setComments] = useState([]);
  const [lastInvoice, setLastInvoice] = useState({ amount: 1532.34, id: 5 });
  const [companyName, setCompanyName] = useState(props.client);
  const [invoiceDraft, setInvoiceDraft] = useState([]);
  const [currencyRates, setCurrencyRates] = useState({});
  const [draftComment, setDraftComment] = useState({});
  const [serviceDate, setServiceDate] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState(1);
  const [documentType, setDocumentType] = useState('');

  const clickPdf = () => {
    props.setSnackBar({
      open: true,
      status: 'alert',
      message: 'Espera mientras se carga el pdf...',
    });
    const payload = {
      realm_id: lastInvoice.realm_id,
    };
    post(
      '/draft_comments/last_invoice_pdf/' + lastInvoice.id.toString(),
      payload,
    ).then((response) => {
      if (response.status === 200) {
        window.open(response.data.url, '_blank');
      }
    });
  };

  const renderData = () => (
    <div className={classes.modal}>
      <CloseIcon className={classes.closeIcon} onClick={() => props.close()}/>
      {!loading && (
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Card fullWidth bordered>
              <CardContent>
              <h2 style={{ color: '#546884' }}>{companyName}</h2>
                <CardDivider />
                <p><strong>Nombre Deal</strong> {dealName}</p> 
                <p><strong>Beetrack ID</strong> {beetrackId}</p> 
                <p><strong>Rubro</strong> {companyType} <strong>| Origen Deal</strong> {dealSource}</p> 
                <p><strong>Account Partner - </strong> {accountPartner}</p> 
                <p><strong>Vendedor - </strong> {salesMan}</p>
                <p><strong>Service Date - </strong> {serviceDate ? serviceDate : "Revisar en glosas"} </p>
                {(documentType === 'invoice' &&
                  <p><strong>Número de factura - </strong> {invoiceNumber} </p>
                  )
                }
                <CardDivider />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={8}>
            <DraftTable rows={rows} amount={amount} invoiceDraft={invoiceDraft} currencyRates={currencyRates} />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'right',
                  justifyContent: 'right',
                  flexWrap: 'wrap',
                  marginTop: '1%',
                }}
              >
                <Button
                  icon={<ReceiptIcon font="small" />}
                  onClick={() => clickPdf()}
                >
                  Última Factura Emitida ${lastInvoice.amount.toLocaleString()}
                </Button>
              </div>
          </Grid>
          <Grid item xs={4}>
            <DraftCollapsable
              invoiceId={id}
              products={products}
              comments={comments}
            />
          </Grid>
          <Grid item xs={8}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              {draftComment ? (
                <CommentsModal draftComment={draftComment} />
              ) : (
                <></>
              )}
            </div>
          </Grid>
        </Grid>
      )}
      {loading && (
        <div className={classes.loading}>
          <Loading hasText />
        </div>
      )}
    </div>
  );

  useEffect(() => {
    getDraftInfo();
    fetchCurrencyRates();
  }, []);

  const fetchCurrencyRates = () => {
    get('/currency_rates').then((response) => {
      if (response.status === 200) {
        setCurrencyRates(response.data);
      }
    });
  };

  const getDraftInfo = () => {
    get('/draft_comments/' + id).then((response) => {
      if (response.status === 200) {
        setAccountPartner(response.data.account_partner);
        setDealName(response.data.deal_name);
        setDealSource(response.data.deal_source);
        setBeetrackId(response.data.beetrack_id);
        setCompanyType(response.data.company_type);
        setSalesMan(response.data.salesman_mail);
        setLastInvoice(response.data.last_invoice);
        setComments(response.data.last_comments);
        setProducts(response.data.commercial_conditions);
        setRows(response.data.lines);
        setInvoiceDraft(response.data.invoice_draft);
        setDraftComment(response.data.draft_comment);
        setInvoiceNumber(response.data.invoice_number);
        setDocumentType(response.data.document_type);
        setServiceDate(response.data.lines.length > 0 && 
          response.data.lines.every(line => line.service_date === response.data.lines[0].service_date) 
          ? response.data.lines[0].service_date
          : '');
        setLoading(false);
        get(
          `/commercial_conditions/plan_relations?hubspot_id=${response.data.hubspot_id}&approved=true`,
        ).then((response) => {
          if (response.status === 200) {
            setProducts(response.data.data.plan_relations.nodes);
          }
        });
      }
    });
  };

  return (
    <Paper className={classes.modal} ref={ref} tabIndex={-1}>
      {renderData()}
    </Paper>
  );
});

export default DraftModal;
