import React, { Component } from 'react';
import { style } from './style';
import { withStyles } from '@material-ui/core';
import decode from 'jwt-decode';

class Menu extends Component {
  constructor(props) {
    const token = decode(localStorage.getItem('jwt_token'));
    super(props);
    this.state = {
      name: token.user.name,
    };
  }

  render() {
    let { classes } = this.props;
    return (
      <div style={{ height: '100vh' }}>
        <div className={classes.box}>
          <h2
            style={{
              fontFamily: 'Raleway, sans-serif',
              color: '#222E3F',
              fontSize: '25px',
            }}
          >
            ¡Bienvenid@ a Hive {this.state.name}!
          </h2>
          <div className={classes.outerCard}>
            <span className={classes.line} />
            <div>
              <p className="hp-typography__subtitle--bold">¿Qué es Hive?</p>
              <p className="hp-typography__body--regular">
                Es nuestro software ERP de automatización de procesos internos
                de la empresa, el cual funciona como intermediario entre Hubspot
                y Quickbooks.
              </p>
              <p className="hp-typography__body--regular">
                Hecho por Trackers para Trackers
              </p>
            </div>
          </div>
          <div className={classes.image}>
            <img
              src={
                'https://beetrack-hive.s3-us-west-2.amazonaws.com/hive/hive_logo_big.png'
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(style)(Menu);
