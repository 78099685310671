import React, { useState } from 'react';
import { List, ListItem, ListSubheader } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { havePermissions } from '../../../../../../utils/utils';
import { get, destroy } from '../../../../../../utils/api';

const useStyles = makeStyles({
  root: {
    margin: '2%',
    width: '100%',
  },
  table: {
    border: '2px solid black',
    backgroundColor: '#b4d7ed',
  },
});

const ALLOWED_KEYS = ['base', 'step', 'variable'];

const ConditionsTable = (props) => {
  const classes = useStyles();
  const { hubspotId } = props;
  const { id, product, condition, currency, pretty_product_name } = props.plan;
  const [deleted, setDeleted] = useState(false);

  const generateName = (condition, product) => {
    let productConditions = Object.keys(condition).filter((cond) =>
      ALLOWED_KEYS.includes(cond),
    );
    return `${product} (${productConditions.join(' & ')})`;
  };

  const zip = (a, b) => a.map((k, i) => [k, b[i]]);

  const _conditionParser = (product, condition) => {
    const detail = [];
    const price = [];

    if (condition.base) {
      if (product === 'other') {
        detail.push(`Fijo`);
        price.push(condition.base.price);
      } else {
        detail.push(`Base: ${condition.base.qty}`);
        price.push(condition.base.price);
      }
    }
    if (condition.step) {
      detail.push(`0 a ${condition.step.free_amount}`);
      detail.push(`${condition.step.step} ↻`);
      price.push(0);
      price.push(condition.step.price);
    }
    if (condition.variable) {
      condition.variable.forEach((variable) => {
        detail.push(
          `${variable.from} a ${variable.to >= 1000000000 ? '∞' : variable.to}`,
        );
        price.push(variable.price);
      });
    }
    if (condition.variable_initial) {
      condition.variable_initial.forEach((variable_initial) => {
        detail.push(
          `VI: ${variable_initial.from} a ${
            variable_initial.to >= 1000000000 ? '∞' : variable_initial.to
          }`,
        );
        price.push(variable_initial.price);
      });
    }
    if (condition.variable_chat) {
      condition.variable_chat.forEach((variable_chat) => {
        detail.push(
          `VC: ${variable_chat.from} a ${
            variable_chat.to >= 1000000000 ? '∞' : variable_chat.to
          }`,
        );
        price.push(variable_chat.price);
      });
    }
    return zip(detail, price);
  };

  const deleteCondition = () => {
    get(
      `/commercial_conditions/plan_relations?hubspot_id=${hubspotId}&plan_id=${id}`,
    ).then((response) => {
      if (response.status === 200) {
        const relationId = response.data.data.plan_relations.nodes[0].id;
        destroy(`/commercial_conditions/plan_relations/${relationId}`).then(
          (response) => {
            if (response.status === 200) {
              setDeleted(true);
            }
          },
        );
      }
    });
  };

  const editParser = (product, id) => {
    if (
      hubspotId &&
      product !== 'email' &&
      product !== 'whatsapp' &&
      havePermissions(['admin_sales_man', 'approver'])
    ) {
      return (
        <>
          <ListItem key={`${product}-edit`}>
            <a href={`edit-condition/${hubspotId}/${id}`}>Editar condición</a>
          </ListItem>
          <ListItem key={`${product}-delete`}>
            <a href={'#'} onClick={() => deleteCondition()}>
              Eliminar condición
            </a>
          </ListItem>
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <div className={classes.root}>
      {deleted ? (
        <></>
      ) : (
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              {generateName(condition, pretty_product_name)}
            </ListSubheader>
          }
        >
          {_conditionParser(product, condition).map((pair, idx) => (
            <ListItem key={`${product}-${idx}`}>
              {' '}
              {pair[0]} : {currency} ${pair[1]}
            </ListItem>
          ))}
          {editParser(product, id)}
        </List>
      )}
    </div>
  );
};

export default ConditionsTable;
