import React, { Component } from 'react';
import decode from 'jwt-decode';
import { get } from '../../utils/api';
import { serializerUnpacker } from '../../utils/utils';
import { CardContent, DatePicker, Table } from '@beetrack/hp-components';
import { useStyles } from './styles.js';
import { withStyles } from '@material-ui/core/styles';

class Commissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: this.formatDate(new Date()),
      columns: [
        { name: 'product', title: 'Producto', valueByAttribute: 'product' },
        { name: 'client', title: 'Cliente', valueByAttribute: 'client' },
        { name: 'invoice', title: 'Folio', valueByAttribute: 'invoice_id' },
        { name: 'comment', title: 'Comentario', valueByAttribute: 'comment' },
        {
          name: 'invoiceAmount',
          title: 'Monto Factura',
          valueByAttribute: 'original_amount',
        },
        {
          name: 'commission',
          title: 'Comisión',
          valueByAttribute: 'amount_paid',
        },
        {
          name: 'paidDate',
          title: 'Fecha de pago',
          valueByAttribute: 'paid_on',
        },
      ],
      approvedResume: [],
      rejectedResume: [],
      notConsideredResume: [],
      reversedResume: [],
      loading: false,
      currentUserId: '',
    };
  }

  formatDate = (date) => {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
      month = `0${month}`;
    }
    return `${day}-${month}-${year}`;
  };

  componentDidMount() {
    let token = decode(localStorage.getItem('jwt_token'));
    let email = token.user.email;
    get(`/users`).then((response) => {
      if (response.status === 200) {
        let users = serializerUnpacker(response.data.users.data);
        users.map((user) => {
          if (user.email === email) {
            this.setState({ currentUserId: user.id }, () =>
              this.fetchCommissions(),
            );
          }
        });
      }
    });
  }

  fetchCommissions = () => {
    get(
      `/commissions?sales_man_id=${this.state.currentUserId}&date=${this.state.date}`,
    ).then((response) => {
      if (response.status === 200) {
        var data = [];
        let approvedData = [];
        let rejectedData = [];
        let notConsideredData = [];
        let reversedData = [];
        response.data.map((commission) => {
          switch (commission.status) {
            case 'approved':
              approvedData.push(commission);
              break;
            case 'rejected':
              rejectedData.push(commission);
              break;
            case 'not_considered':
              notConsideredData.push(commission);
              break;
            case 'reversed':
              reversedData.push(commission);
              break;
          }
        });
        this.setState({
          approvedResume: approvedData,
          rejectedResume: rejectedData,
          notConsideredResume: notConsideredData,
          reversedResume: reversedData,
        });
      } else {
        return "Couldn't get a response";
      }
    });
  };

  onDateChange = (date) => {
    this.setState({ date: date }, () => this.fetchCommissions());
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.mainCard}>
        <div className={classes.honeypotCardFullWidth}>
          <CardContent>
            <div className={classes.datepicker}>
              <DatePicker
                inputLabel="Fecha"
                date={this.state.date}
                onChange={(value) => {
                  this.onDateChange(value);
                }}
                locale="es"
              />
            </div>
            <div>
              <br />
            </div>
            <div>
              <hr />
            </div>
            <div>
              <h3>Aprobadas</h3>
            </div>
            <Table
              columns={this.state.columns}
              data={this.state.approvedResume}
              loading={this.state.loading}
            />
            <div>
              <hr />
            </div>
            <div>
              <h3>Rechazadas</h3>
            </div>
            <Table
              columns={this.state.columns}
              data={this.state.rejectedResume}
              loading={this.state.loading}
            />
            <div>
              <hr />
            </div>
            <div>
              <h3>No Consideradas</h3>
            </div>
            <Table
              columns={this.state.columns}
              data={this.state.notConsideredResume}
              loading={this.state.loading}
            />
            <div>
              <hr />
            </div>
            <div>
              <h3>Reversadas</h3>
            </div>
            <Table
              columns={this.state.columns}
              data={this.state.reversedResume}
              loading={this.state.loading}
            />
          </CardContent>
        </div>
      </div>
    );
  }
}

export default withStyles(useStyles)(Commissions);
