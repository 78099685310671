import React from 'react';
import { Paper, Grid } from '@material-ui/core';
import { Button } from '@beetrack/hp-components';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade } from '@material-ui/core';

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
  },
  innerModal: {
    width: 'auto',
    margin: 'auto',
    maxHeight: '70%',
    overflow: 'auto',
    paddingBottom: '30px',
    paddingRight: '30px',
  },
  buttons: {
    paddingTop: '5%',
    paddingLeft: '15%',
  },
  text: {
    paddingLeft: '7%',
  },
});

const ConfirmationModal = (props) => {
  const classes = useStyles();
  const text = props.text
    ? props.text
    : '¿Estás seguro de realizar esta acción?';
  const secondaryText = props.secondaryText;
  const closeButton = props.closeButton ? props.closeButton : 'Cerrar';
  const confirmButton = props.confirmButton ? props.confirmButton : 'Confirmar';
  const secondButton = props.secondButton ? props.secondButton : '';
  const open = props.open;
  const close = props.close;
  const confirmAction = props.onConfirm;
  const secondConfirmAction = props.onSecondConfirm;

  const confirm = () => {
    confirmAction();
    close();
  };

  const secondConfirm = () => {
    secondConfirmAction();
    close();
  };

  return (
    <Modal
      open={open}
      onClose={() => close()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
      className={classes.modal}
    >
      <Fade in={open}>
        <Paper className={classes.innerModal}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={classes.text}>
                <h4> {text} </h4>
                <p> {secondaryText} </p>
              </div>
            </Grid>
            <Grid container justify="flex-end">
              <Button type="close" onClick={() => close()}>
                {closeButton}
              </Button>
              <Button onClick={confirm}>{confirmButton}</Button>
              {secondButton !== '' && (
                <Button onClick={secondConfirm}>{secondButton}</Button>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default ConfirmationModal;
