import React, { useState, useEffect } from 'react';
import { Tabs, TabItem, LoadingPage } from '@beetrack/hp-components';
import { get, get_blob } from '../../utils/api';
import DraftsTable from './Components/DraftsTable';
import { havePermissions } from '../../utils/utils';
import { Button } from '@beetrack/hp-components';
import { Snackbar, Select } from '@beetrack/hp-components';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Modal } from '@beetrack/hp-components';

const Drafts = (props) => {
  const [dataTablePending, setDataTablePending] = useState([]);
  const [dataTablePreApproved, setDataTablePreApproved] = useState([]);
  const [dataTableApproved, setDataTableApproved] = useState([]);
  const [dataTableCommented, setDataTableCommented] = useState([]);
  const [dataTableRejected, setDataTableRejected] = useState([]);
  const [draftsCount, setDraftsCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(0);
  const [snackBar, setSnackBar] = useState({
    open: false,
    status: '',
    message: '',
  });
  const [openModal, setOpenModal] = useState(false);
  const [numberOfMonths, setNumberOfMonths] = useState(3);

  const getDrafts = () => {
    get('/drafts').then((response) => {
      if (response.status === 200) {
        setDataTableApproved(response.data['approved']);
        setDataTablePreApproved(response.data['pre_aproved']);
        setDataTableCommented(response.data['commented']);
        setDataTablePending(response.data['pending']);
        setDataTableRejected(response.data['rejected']);
        setDraftsCount(
          response.data['approved'].length +
            response.data['pre_aproved'].length +
            response.data['commented'].length +
            response.data['pending'].length +
            response.data['rejected'].length,
        );
        setLoading(false);
      }
    });
  };

  const exportToExcel = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    const fileData = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    FileSaver.saveAs(fileData, 'invoices_draft.xlsx');
  };

  const download_drafts = () => {
    get(`/download_drafts?last_x_months=${numberOfMonths}`).then((response) => {
      exportToExcel(response.data.data);
    });
    setOpenModal(false);
  };

  useEffect(() => {
    getDrafts();
  }, [reload]);
  return (
    <div>
      {!loading && (
        <div>
          <h2> Solicitud de Borradores </h2>
          <p>
            {' '}
            Aquí encontrarás todos los{' '}
            <strong> borradores de este mes ,</strong> ordenados por su estado.
          </p>
          {havePermissions(['super']) && (
            <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
              <Button
                primary
                onClick={() => {
                  get('/run_draft_simulation').then((response) => {
                    setSnackBar({
                      message: response.data.message,
                      status: response.status === 200 ? 'success' : 'error',
                      open: true,
                    });
                  });
                }}
              >
                Correr Simulacion
              </Button>
              <Button
                secondary
                onClick={() => {
                  get('/delete_draft_simulation').then((response) => {
                    setSnackBar({
                      message: response.data.message,
                      status: response.status === 200 ? 'success' : 'error',
                      open: true,
                    });
                  });
                }}
              >
                Eliminar Simulacion
              </Button>
            </div>
          )}

          <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
            <Button primary onClick={() => setOpenModal(true)}>
              Descargar borradores
            </Button>
          </div>
          <Tabs>
            <TabItem
              label={
                'Borradores Pendientes ' +
                dataTablePending.length +
                '/' +
                draftsCount.toString()
              }
            >
              <DraftsTable
                tableType="pending"
                reloadAction={() => setReload(reload + 1)}
                dataTable={dataTablePending}
              />
            </TabItem>
            <TabItem
              label={
                'Borradores Comentados ' +
                dataTableCommented.length +
                '/' +
                draftsCount.toString()
              }
            >
              <DraftsTable
                tableType="commented"
                reloadAction={() => setReload(reload + 1)}
                dataTable={dataTableCommented}
              />
            </TabItem>
            <TabItem
              label={
                'Borradores Pre-Aprobados ' +
                dataTablePreApproved.length +
                '/' +
                draftsCount.toString()
              }
            >
              <DraftsTable
                tableType="pre_aproved"
                reloadAction={() => setReload(reload + 1)}
                dataTable={dataTablePreApproved}
              />
            </TabItem>
            <TabItem
              label={
                'Facturas Emitidas ' +
                dataTableApproved.length +
                '/' +
                draftsCount.toString()
              }
            >
              <DraftsTable
                tableType="approved"
                reloadAction={() => setReload(reload + 1)}
                dataTable={dataTableApproved}
              />
            </TabItem>
            <TabItem
              label={
                'Facturas Rechazadas ' +
                dataTableRejected.length +
                '/' +
                draftsCount.toString()
              }
            >
              <DraftsTable
                tableType="rejected"
                reloadAction={() => setReload(reload + 1)}
                dataTable={dataTableRejected}
              />
            </TabItem>
          </Tabs>
        </div>
      )}
      {snackBar.open && (
        <Snackbar
          key="snackbar"
          isOpen={snackBar.open}
          message={snackBar.message}
          onDismiss={() =>
            setSnackBar({
              open: !snackBar.open,
              status: '',
              message: '',
            })
          }
          status={snackBar.status}
        />
      )}
      {loading && <LoadingPage text="Cargando..." />}
      <Modal
        title="Descarga de borradores"
        open={openModal}
        close={() => setOpenModal(false)}
        confirmButton={
          <Button type="primary" onClick={() => download_drafts()}>
            Confirmar
          </Button>
        }
      >
        Seleccione la cantidad de meses hacia atras que quiere Descargar
        <Select
          name="language"
          value={numberOfMonths}
          options={[
            { label: 1, value: 1 },
            { label: 2, value: 2 },
            { label: 3, value: 3 },
            { label: 4, value: 4 },
            { label: 5, value: 5 },
            { label: 6, value: 6 },
            { label: 7, value: 7 },
            { label: 8, value: 8 },
            { label: 9, value: 9 },
            { label: 10, value: 10 },
            { label: 11, value: 11 },
            { label: 12, value: 12 },
          ]}
          label="Numero de meses"
          onChange={(e) => setNumberOfMonths(e.value)}
        />
      </Modal>
    </div>
  );
};

export default Drafts;
