import React, { useState, useEffect } from 'react';
import { Page, Button } from '@beetrack/hp-components';
import ResourcesTable from './Components/ResourcesTable';
import InventoryTable from './Components/InventoryTable';

const Resources = (props) => {
  const [inventoryView, setInventoryView] = useState(false);
  const typeResource = props.match.params.type;

  useEffect(() => {
    if (typeResource === 'inventory') {
      setInventoryView(true);
    }
  }, [typeResource]);

  return (
    <Page
      title="Inventarios y accesos"
      buttons={[
        <Button
          key="inventory-button"
          type="secondary"
          onClick={() => setInventoryView(!inventoryView)}
        >
          {inventoryView ? 'Mostrar Beetrackers' : 'Mostrar Inventario'}
        </Button>,
        ,
        <>
          {' '}
          {inventoryView && (
            <Button
              key="inventory-button"
              type="primary"
              onClick={() => props.history.push('/hr/resources/add/inventory')}
            >
              Crear inventario
            </Button>
          )}
        </>,
      ]}
    >
      <p>
        {' '}
        Aquí podrás gestionar los <strong>accesos</strong> e{' '}
        <strong> inventarios </strong> de los beetrackers
      </p>
      {inventoryView && <InventoryTable history={props.history} />}
      {!inventoryView && <ResourcesTable history={props.history} />}
    </Page>
  );
};

export default Resources;
