import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';

const Line = (props) => {
  const renderLine = () => {
    let { line } = props;
    return (
      <TableRow>
        <TableCell>{line.SalesItemLineDetail.ItemRef.name}</TableCell>
        <TableCell>{line.Description}</TableCell>
        <TableCell>{line.SalesItemLineDetail.ServiceDate}</TableCell>
        <TableCell align="right">{line.SalesItemLineDetail.Qty}</TableCell>
        <TableCell align="right">
          {line.SalesItemLineDetail.UnitPrice}
        </TableCell>
        <TableCell align="right">{line.Amount}</TableCell>
      </TableRow>
    );
  };

  return renderLine();
};

export default Line;
