import React, { Component } from 'react';
import {Form, FormRow, TextField, Button, Snackbar} from '@beetrack/hp-components'
import { Link } from 'react-router-dom';
import { loginUrl } from '../../utils/const';
import { reset_password } from '../../utils/api.js';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      confirmPassword: '',
      token: '',
      snackbarState: false,
      status: 'error'
    }
  }

  componentDidMount() {
    this.setState({ token: this.props.match.params.token })
  }

  setValue = (e, key) => {
    this.setState({[key]: e.target.value})
  }

  handleResetPassword = async () => {
    if (this.validatePassword(this.state.password, this.state.confirmPassword)){
      let result = await reset_password(this.state.token, this.state.password);
      if (result.status === 200) {
        this.props.history.push({pathname:'/login', state: result.status });
      } else {
        this.setState({
          message: result.data.errors,
          snackbarState: true
        });
      }
    }
  }

  validatePassword = (password, confirmPassword) => {
    if( password !== confirmPassword){
      this.setState({
        message: 'Passwords does not match',
        snackbarState: true
      });
      return false
    }
    else {
      if (password.length === 0){
        this.setState({
          message: 'Passwords must not be empty',
          snackbarState: true
        });
        return false
      }
    }
    return true
  }

  setOpen = (open) =>{
    this.setState({snackbarState: open})
  }

  render () {
    let { classes } = this.props;
    return (
      <div className={'form-container'}>
        <Snackbar
          key='snackbar'
          isOpen={this.state.snackbarState}
          message={this.state.message}
          onDismiss={() => this.setOpen(false)}
          status={this.state.status}
        />
        <div className={'row'}>
          <div className={'column'}>
            <div className={'logo-position'}>
              <img src={'https://beetrack-hive.s3-us-west-2.amazonaws.com/hive/hive_logo_big.png'} width={'390px'} />
            </div>
          </div>
          <div className={'column'}>
            <div className={'form-position'}>
              <Form
                title='Beetrack Hive - Nueva contraseña'
              >
                <FormRow>
                  <TextField
                    type={'password'}
                    value={this.state.password}
                    onChange={(e) => this.setValue(e, 'password')}
                    label='Contraseña'
                    onKeyPress={ event => {
                      if (event.key === 'Enter') {
                        this.handleResetPassword();
                      }
                    }}
                  />
                </FormRow>
                <FormRow>
                  <TextField
                    type={'password'}
                    value={this.state.confirmPassword}
                    onChange={(e) => this.setValue(e, 'confirmPassword')}
                    label='Confirmar contraseña'
                    onKeyPress={ event => {
                      if (event.key === 'Enter') {
                        this.handleResetPassword();
                      }
                    }}
                  />
                </FormRow>
                <FormRow>
                  <Button
                    type='primary'
                    className={'centered'}
                    onClick={this.handleResetPassword}>
                    Resetear contraseña
                  </Button>
                </FormRow>
                <div className={'forgot-password'}>
                  <Link to={ loginUrl }>
                    <span>Iniciar sesión</span>
                  </Link>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ResetPassword;
