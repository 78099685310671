import React, { Component, useState } from 'react'
import { Paper } from '@material-ui/core'
import '../DraftLines/style.scss'
import { get, put } from '../../../../utils/api'
import { Radio, Button, FormRow, Form } from '@beetrack/hp-components'

class VoidReasonModal extends Component {

  constructor(props) {
    super(props)
    this.state = {
      radios: {
        salesman_modified: true,
        automatic_invoicing_failed: false,
        other: false
      },
      void_reason: 'salesman_modified'
    }
  }

  handleRadioChange = (event) => {
    let radios = this.state.radios
    Object.keys(radios).map((radio) => radios[radio] = false)
    radios[event] = true
    this.setState({ radios: radios, void_reason: event})
  }

  handleButton = () => {
    put('/drafts/' + this.props.data.id, { 'resolution': 'rejected', 'void_reason': this.state.void_reason }).then((response) => {
      this.props.removeItem()
      this.props.closeModal()
    })
  }

  render() {
    return(
      <Form
        title='Razón de Rechazo'>
        <FormRow>
          <Radio
            label='Modificación Vendedor'
            name='salesman_modified'
            value='salesman_modified'
            checked={ this.state.radios.salesman_modified }
            onChange={ (e) => this.handleRadioChange(e.target.value) }
          />
          <Radio
            label='Error Facturación Automática'
            name='automatic_invoicing_failed'
            value='automatic_invoicing_failed'
            checked={ this.state.radios.automatic_invoicing_failed}
            onChange={ (e) => this.handleRadioChange(e.target.value) }
          />
          <Radio
            label='Otros'
            name='other'
            value='other'
            checked={ this.state.radios.other}
            onChange={ (e) => this.handleRadioChange(e.target.value) }
          />
        </FormRow>
        <FormRow>
          <Button onClick={ () => this.handleButton() }>Rechazar</Button>
        </FormRow>
      </Form>
    )
  }
}

export default VoidReasonModal
