export const style = (theme) => ({
  box: {
    paddingBottom: '25px',
    width: '80%',
    [theme.breakpoints.only('xs')]: {
      marginLeft: '20%',
    },
    [theme.breakpoints.only('sm')]: {
      marginLeft: '10%',
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '5%',
    },
  },
  outerCard: {
    display: 'flex',
    flexDirection: 'column',
    background: '#ffffff',
    borderRadius: '4px',
    position: 'relative',
    overflow: 'hidden',
    marginLeft: 5,
    padding: '28px',
    boxShadow: '0px 8px 15px rgba(45, 41, 28, 0.15)',
  },
  line: {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    width: 10,
    bottom: 0,
    top: 0,
    left: 0,
    position: 'absolute',
    background: 'linear-gradient(180deg,#ffd200,#f4aa1f)',
  },
  image: {
    display: 'flex',
    justifyContent: 'center',
  },
});
