import React, { useState, useEffect } from 'react';
import {
  Page,
  Card,
  CardContent,
  Button,
  Loading,
  DatePicker,
  Chip,
  Select,
} from '@beetrack/hp-components';
import {
  Grid,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from '@material-ui/core';
import { Receipt } from '@beetrack/hp-icons-react';
import { get, post } from '../../../utils/api';
import Line from './Line';
import moment from 'moment';
import Prepay from './Prepay';
import '../styles';

const QBCL = process.env.QB_REALM_CL;
const QBMX = process.env.QB_REALM_MX;
const QBCO = process.env.QB_REALM_CO;
const TAX = JSON.parse(process.env.QB_TAX);
const InvoiceDetails = (props) => {
  const { history } = props;
  const clientId = props.match.params.id;
  const context = props.match.params.type;
  const [lines, setLines] =
    context === 'prepaid'
      ? useState([])
      : useState([
          {
            service: null,
            description: '',
            quantity: 0,
            price: 0,
            total: 0,
          },
        ]);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [clientDetails, setClientDetails] = useState(null);
  const [serviceDate, setServiceDate] = useState(moment().format('MM/YYYY'));
  const [invoiceDate, setInvoiceDate] = useState(moment().format('DD/MM/YYYY'));
  const [currencies, setCurrencies] = useState({ uf: 0, usd: 0 });
  const [firstInvoice, setFirstInvoice] = useState(null);

  const addLine = () => {
    let newLines = [...lines];
    newLines.push({
      service: null,
      description: '',
      quantity: 0,
      price: 0,
      total: 0,
    });
    setLines(newLines);
  };

  const deleteRow = (index) => {
    let newLines = [...lines];
    newLines.splice(index, 1);
    setLines(newLines);
  };

  const updateLine = (index, param, value) => {
    let numbers = ['quantity', 'price'];
    let newLines = [...lines];
    let line = newLines[index];
    line[param] = value;
    if (numbers.includes(param)) {
      let quantity = line.quantity === '' ? 0 : line.quantity;
      let price = line.price === '' ? 0 : line.price;
      line['total'] = parseFloat(price) * parseFloat(quantity);
    }
    newLines[index] = line;
    setLines(newLines);
  };

  const errorInParams = () => {
    if (firstInvoice === null) return 'Campo "Primera factura" sin responder';
    if (serviceDate === null) return 'Campo "Fecha de servicio" vacío';
    if (invoiceDate === null) return 'Campo "Fecha de defactura" vacía';
    for (const index in lines) {
      if (lines[index].price === 0)
        return `La línea ${index + 1} tiene el campo "Precio" inválido`;
      if (lines[index].service === 0)
        return `La línea ${index + 1} tiene el campo "Servicio" inválido`;
      if (lines[index].quantity === 0)
        return `Lalínea ${index + 1} tiene el campo "Cantidad" inválido`;
      if (lines[index].description.length === 0)
        return `Lalínea ${index + 1} tiene el campo "Descripción" inválido`;
    }
    return false;
  };

  const createInvoice = () => {
    const message = errorInParams();
    if (message) return alert(`Tienes el siguiente error:\n  ${message}`);
    let invoice = buildData('invoice');
    post('/drafts', invoice).then((result) => {
      if (result.status === 201) {
        history.push('/drafts');
      } else {
        alert(result.message);
      }
    });
  };

  const buildData = () => {
    let realm_id = clientId.split('-')[0];
    let currencyRef = clientDetails['CurrencyRef']['value'];
    let txnDate = moment(invoiceDate, 'DD/MM/YYYY').format('YYYY/MM/DD');
    let descriptionDate = moment(serviceDate, 'MM/YYYY').format('MM-YYYY');
    const in_advanced = clientDetails['in_advanced'];
    let qbServiceDate = moment(serviceDate, 'MM/YYYY');
    if (in_advanced) {
      qbServiceDate = qbServiceDate.subtract(1, 'months');
    }
    let invoice = {
      TxnDate: txnDate,
      Line: [],
      CustomerRef: {
        value: clientDetails['Id'],
        name: clientDetails['DisplayName'],
      },
      CurrencyRef: {
        value: clientDetails['CurrencyRef']['value'],
        name: clientDetails['CurrencyRef']['name'],
      },
      TxnTaxDetail: taxCode(realm_id, currencyRef)['rate'],
      GlobalTaxCalculation: isTaxFree(currencyRef),
    };
    lines.forEach((line) => {
      let lineObj = {
        DetailType: 'SalesItemLineDetail',
        Description:
          context !== 'prepaid'
            ? `${line.description} (${descriptionDate})`
            : line.description,
        Amount: line.total,
        SalesItemLineDetail: {
          ItemRef: {
            name: line.service.label,
            value: line.service.value,
          },
          Qty: line.quantity,
          UnitPrice: line.price,
          ServiceDate:
            context === 'prepaid'
              ? line.serviceDate
              : qbServiceDate.format('YYYY/MM/DD'),
        },
      };
      if (!isTaxFree(currencyRef)) {
        lineObj['SalesItemLineDetail']['TaxCodeRef'] = taxCode(
          realm_id,
          currencyRef,
        )['code'];
      }
      invoice.Line.push(lineObj);
    });
    const Data = {
      document_type: context === 'estimate' ? 'estimate' : 'invoice',
      client_id: clientDetails.Id,
      first_invoice: firstInvoice,
      realm_id: realm_id,
      invoice: invoice,
    };
    return Data;
  };

  const taxCode = (realm_id, currency) => {
    if (realm_id == QBCL) {
      if (currency == 'CLP') {
        return TAX['CL'];
      } else {
        return {
          rate: {
            TotalTax: 0,
          },
        };
      }
    } else if (realm_id == QBMX) {
      return TAX['MX'];
    } else if (realm_id === QBCO) {
      return TAX['CO'];
    } else {
      alert('REALM_ID no existente');
    }
  };

  const isTaxFree = (currency) => {
    return !['MXN', 'CLP', 'COP'].includes(currency);
  };

  useEffect(() => {
    get('/invoices/services/' + clientId).then((response) => {
      if (response.status == 200) {
        let items = [];
        response.data.services.forEach((item) => {
          items.push({
            value: item.Id,
            label: item.Name,
          });
        });
        setServices(items);
        get('/client/details/' + clientId).then((response) => {
          if (response.status == 200) {
            setClientDetails(response.data.client_details[0]);
            get('/currency_rates').then((response) => {
              if (response.status === 200) {
                setCurrencies({
                  uf: response['data']['uf'],
                  usd: response['data']['usd'],
                });
              }
            });
            setLoading(false);
          }
        });
      }
    });
  }, []);

  return (
    <Page
      style={{ padding: '0px' }}
      onGoBack={() => history.push('/invoices')}
      title={`Nueva ${
        context === 'invoice' || context === 'prepaid'
          ? 'factura'
          : 'estimación'
      } ${context === 'prepaid' ? 'de prepago' : ''}`}
      buttons={[
        <Button icon={<Receipt />} onClick={() => createInvoice()}>
          {' '}
          Crear {context === 'estimate' ? 'Estimación' : 'Factura'}{' '}
        </Button>,
      ]}
    >
      {loading && (
        <center>
          {' '}
          <Loading />{' '}
        </center>
      )}
      {!loading && (
        <div>
          <Card fullWidth bordered={false}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={7}>
                  <div>
                    <h1 className="hp-typography__title--small">
                      <b>{clientDetails['CompanyName']}</b>
                    </h1>
                  </div>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <p className="hp-typography__body--regular">
                        País: {clientDetails['BillAddr']['Country']}
                      </p>
                    </Grid>
                    <Grid item xs={12}>
                      <p className="hp-typography__body--regular">
                        {' '}
                        Moneda: {clientDetails['CurrencyRef']['value']}{' '}
                      </p>
                    </Grid>
                    <Grid item xs={12}>
                      <p className="hp-typography__body--regular">
                        {' '}
                        Es adelantado:{' '}
                        {clientDetails['in_advanced'] ? 'Sí' : 'No'}{' '}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={5}>
                  <Grid container spacing={0}>
                    <Grid item xs={6}>
                      <Chip
                        secondaryContent={
                          <div style={{ width: '140px' }}>${currencies.uf}</div>
                        }
                        text="UF  &nbsp;"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Chip
                        secondaryContent={
                          <div style={{ width: '140px' }}>
                            ${currencies.usd}
                          </div>
                        }
                        text="USD"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <div style={{ marginTop: '23px' }}>
            <Card fullWidth bordered={false}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <p className="hp-typography__body--regular">
                      ¿Es Primera Factura?
                    </p>
                    <Select
                      label="Primera factura"
                      options={[
                        { label: 'Sí', value: 'true' },
                        { label: 'No', value: 'false' },
                      ]}
                      value={firstInvoice}
                      onChange={(option) => {
                        setFirstInvoice(option.value);
                      }}
                    />
                    <p class="adivce">*Obligatorio</p>
                  </Grid>
                  <Grid item xs={4}>
                    {context !== 'prepaid' && (
                      <>
                        <p className="hp-typography__body--regular">
                          Fecha de Servicio:{' '}
                        </p>
                        <DatePicker
                          date={serviceDate}
                          onChange={(value) => {
                            setServiceDate(value);
                          }}
                          format="MM/YYYY"
                        />
                        <p class="adivce">*Obligatorio</p>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <>
                      <p className="hp-typography__body--regular">
                        Fecha de Factura:{' '}
                      </p>
                      <DatePicker
                        date={invoiceDate}
                        onChange={(value) => {
                          setInvoiceDate(value);
                        }}
                        format="DD/MM/YYYY"
                      />
                      <p class="adivce">*Obligatorio</p>
                    </>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </div>
          {context === 'prepaid' && (
            <Prepay
              lines={lines}
              setLines={setLines}
              currency={clientDetails['CurrencyRef']['value']}
              advance={clientDetails['in_advanced']}
            />
          )}
          <div style={{ marginTop: '23px' }}>
            <Card fullWidth bordered={false}>
              <CardContent>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell style={{ width: '20%' }}>Servicio</TableCell>
                      <TableCell style={{ width: '40%' }}>
                        Descripción
                      </TableCell>
                      <TableCell>Cantidad</TableCell>
                      <TableCell>Precio</TableCell>
                      <TableCell>Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {lines.map((line, index) => {
                      return (
                        <Line
                          key={`line-${index}`}
                          index={index}
                          items={services}
                          line={line}
                          deleteRow={() => deleteRow(index)}
                          updateLine={updateLine}
                          currency={clientDetails['CurrencyRef']['value']}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
                <Button type="tertiary" onClick={() => addLine()}>
                  Agregar Línea
                </Button>
              </CardContent>
            </Card>
          </div>
        </div>
      )}
    </Page>
  );
};

export default InvoiceDetails;
