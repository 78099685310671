import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import { get, post, del } from '../../../utils/api';
import { arraysToHash } from '../../../utils/utils'
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import MaterialTable from 'material-table'
import {
  Button,
  Paper,
  Typography
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';



class AssociateClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hubspotClients: [],
      quickbooksClients: [],
      hubspotSelect: null,
      qbSelected: null,
      selectIsLoading: false,
      hbClients: null,
      qbAccounts: null,
      qbClients: null,
      tableData: null
    };
    this.loadData = this.loadData.bind()
    this.arrayToObject = this.arrayToObject.bind()
    this.arrayToObjectQb = this.arrayToObjectQb.bind()
  }

  componentDidMount() {
    this.props.loadState(true);
    this.loadData()
  }

  loadData = () => {
    this.props.loadState(true);
    this.setState({ selectIsLoading: true })
    get('/client_actions/associate').then(response => {
      if(response['data'] !== undefined && response['data'].status != 'error') {
        let hubspotClients = response['data']['message']['hubspot_deals']
        let quickbooksClients = response['data']['message']['quickbooks_clients']
        let tableData = response['data']['message']['table_data']

        this.setState({
          hubspotClients, quickbooksClients,
          tableData, selectIsLoading: false
        });
        this.props.loadState(false);
      }
    });
  }

  arrayToObject = (array, keyField, valuefield) =>
    array.reduce((obj, item) => {
      obj[item[keyField]] = item[valuefield]
      return obj
    }, {})

  arrayToObjectQb = (array, keyField, valuefield) =>
    array.reduce((obj, item) => {
      obj[item[keyField].split('-')[1]] = item[valuefield]
      return obj
    }, {})

  handleChange = name => value => {
    this.setState({ [name]: value });
  }

  createAssociation = () => {
    let { hubspotSelect, qbSelected } = this.state
    let data = { hubspot_deal: hubspotSelect, quickbooks_clients: qbSelected}
    post('/client_actions/associate', data).then(response => {
      this.setState({
        hubspotSelect: null,
        qbSelected: null
      })
      this.loadData()
    });
  }

  delete = (relation_id) => {
    if (confirm("Si eliminas esta relación, se eliminarán todas aquellas relaciones asociadas a este cliente de Hubspot")) {
      del('/client_actions/associate/' + relation_id).then(() => {
        this.props.loadState(true);
        this.loadData()
      })
    }
  }

  render() {
    const { classes } = this.props;
    const { hubspotClients, hubspotSelect, qbSelected, selectIsLoading, hbClients, qbAccounts, qbClients, tableData } = this.state;

    const data = tableData ?
                  (<MaterialTable
                    title="Associations"
                    columns={[
                      {
                        title: 'Relation ID',
                        field: 'relation_id',
                        hidden: true
                      },
                      {
                        title: 'Hubspot Negocio',
                        field: 'deal_name'
                      },
                      {
                        title: 'Cuenta Quickbooks',
                        field: 'quickbooks_account'
                      },
                      {
                        title: 'Quickbooks Cliente',
                        field: 'quickbooks_name',
                        grouping: false
                      },
                    ]}
                    data={tableData}        
                    options={{
                      grouping: true
                    }}
                    actions={[
                      {
                        icon: 'delete',
                        tooltip: 'Delete Association',
                        onClick: (_, rowData) => {
                          this.delete(rowData.relation_id)
                        }
                      }
                    ]}
                  />) :
                  null

    return(
      <div>
        <Paper className={classes.association}>
          <section className={classes.associationSection}>
            <Typography display='inline' variant='h5'>Hubspot Clients</Typography>
            <Select
              components={makeAnimated()}
              value={hubspotSelect}
              options={hubspotClients}
              onChange={this.handleChange('hubspotSelect')}
              className={classes.hubspotSelect}
              isLoading={selectIsLoading}
              isDisabled={selectIsLoading}
              name="hbClients"
            />
            
          </section>
          <section className={classes.associationSection}>
            <Typography display='inline' variant='h5'>Quickbooks Clients</Typography>
            <Select
              closeMenuOnSelect={false}
              components={makeAnimated()}
              isMulti
              value={qbSelected}
              options={this.state.quickbooksClients}
              onChange={this.handleChange('qbSelected')}
              className={classes.qbClient}
              isLoading={selectIsLoading}
              isDisabled={selectIsLoading}
              name="qbClients"
            />
          </section>
          <section className={classes.associationSection} >
            <Button
              variant="contained"
              className={classes.button}
              disabled={!(hubspotSelect && qbSelected)}
              onClick={this.createAssociation}
            >
              Associate
              <SaveIcon className={classes.rightIcon} />
            </Button>
          </section>
        </Paper>
        <Paper>
          {data}
        </Paper>
      </div>
    );
  }

}

export default withStyles(styles)(AssociateClient);
