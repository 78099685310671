import React, { useState, useEffect } from 'react';
import { Page, Button, Loading, ProgressBar } from '@beetrack/hp-components';
import Section from './Components/Section';
import { get } from '../../utils/api';
import ConfettiGenerator from 'confetti-js';
import './styles.scss';

const CheckList = (props) => {
  const [loading, setLoading] = useState(true);
  const [sections, setSections] = useState([]);
  const [totalTasks, setTotalTasks] = useState(0);
  const [completedTasks, setCompletedTasks] = useState(0);

  useEffect(() => {
    get('/check_list?team=sales').then((response) => {
      if (response.status === 200) {
        setSections(response.data.sections);
        setCompletedTasks(response.data.completed_tasks);
        setTotalTasks(response.data.total_tasks);
        setLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    var confettiSettings = { target: 'my-canvas' };
    var confetti = new ConfettiGenerator(confettiSettings);
    if (completedTasks === totalTasks && completedTasks !== 0) {
      confetti.render();
    }
  }, [completedTasks]);

  return (
    <Page>
      <center>
        {' '}
        <h1 className="hp-typography__hero--medium">
          {' '}
          Newbees on Sales 🐝{' '}
        </h1>{' '}
      </center>
      <canvas
        id="my-canvas"
        width="100%"
        height="100%"
        style={{ position: 'fixed', top: 0, left: 0 }}
      />
      {loading && (
        <center>
          {' '}
          <Loading />{' '}
        </center>
      )}
      {!loading && (
        <div>
          <ProgressBar
            progress={(completedTasks / totalTasks) * 100}
            showProgress={false}
            additionalClassName="progress-bar"
          />
          <h4 className="hp-page__header-title"> ¡Comencemos!💃🏻🕺🏻 </h4>
          <p className="hp-typography__subtitle--medium">
            Aquí podrás encontrar la lista de las tareas relacionadas a cada uno
            de los días de tu Sales onboarding.{' '}
          </p>

          <p className="hp-typography__subtitle--medium">
            {' '}
            Marca las tareas que ya tienes realizadas para que puedas ir
            revisando tu avance.{' '}
          </p>

          {sections.map((element) => {
            return (
              <Section
                title={element.title}
                tasks={element.tasks}
                setCompletedTasks={setCompletedTasks}
                completedTasks={completedTasks}
              />
            );
          })}
        </div>
      )}
    </Page>
  );
};

export default CheckList;
