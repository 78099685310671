import React, { useState } from 'react';

import {
  Form,
  FormRow,
  Button,
  Select,
  TextField,
  Checkbox,
} from '@beetrack/hp-components';
import { Delete } from '@material-ui/icons';
import PlanModal from '../../ApprovalTable/planModal';
import {
  generateVariables,
  generateBase,
  generateMinimum,
  generatePrepaid,
  generateDiscount,
} from './generators/index';
import {
  newDateFromDelta,
  calculateDiscountFromDeltaMonths,
} from '../../Calculator/';

// this constant defines the maximum amount of times a general minimum value can be
const EDITABLE_PRODUCTS = ['lastmile', 'plannerpro', 'ondemand'];

const ProductForm = (props) => {
  const {
    idx,
    plans,
    plan,
    setModified,
    productsToCreate,
    setProductsToCreate,
  } = props;

  const handleSelectChange = (newValue, index) => {
    const newData = [...productsToCreate];
    newData[index] = newValue;
    setProductsToCreate(newData);
  };

  const [planModal, setPlanModal] = useState({});
  const [open, setOpen] = useState(false);

  const setMinimum = (newValue, index, plan) => {
    const newData = [...productsToCreate];
    const parsedValue = newValue === '' ? 0 : parseFloat(newValue);
    newData[index].condition.general_minimum = parsedValue;
    setProductsToCreate(newData);
  };

  const setVariableConditionState = (newValue, index, variableIndex) => {
    setModified(true);
    const newData = [...productsToCreate];
    newData[index].condition.variable[variableIndex].price =
      newValue === '' ? 0 : parseFloat(newValue);
    setProductsToCreate(newData);
  };

  const setBaseConditionState = (newValue, index) => {
    setModified(true);
    const newData = [...productsToCreate];
    newData[index].condition.base.price =
      newValue === '' ? 0 : parseFloat(newValue);
    setProductsToCreate(newData);
  };

  const setPrepaid = (newValue, index) => {
    const newData = [...productsToCreate];
    // we want to make sure that the discount is only applied
    // to prepaids as to ensure non prepaids do not have discounts
    if (!newValue) {
      newData[index].discount = false;
    }
    newData[index].prepaid = newValue;
    setProductsToCreate(newData);
  };

  const setPrepaids = (newValue, index, param) => {
    const newData = [...productsToCreate];

    if (param === 'from') {
      newData[index].prepaids[0]['from'] = newValue;
      newData[index].prepaids[0]['to'] = newDateFromDelta(
        newValue,
        newData[index].prepaids[0]['delta'],
      );
    } else if (param === 'delta') {
      newData[index].prepaids[0]['delta'] = newValue;
      newData[index].prepaids[0]['to'] = newDateFromDelta(
        newData[index].prepaids[0]['from'],
        newValue,
      );
    } else if (param === 'qty') {
      newData[index].prepaids[0]['qty'] = Math.max(parseInt(newValue), 1);
    }

    newData[index].discounts[0].percentage = calculateDiscountFromDeltaMonths(
      newData[index].prepaids[0]['delta'],
    );
    setProductsToCreate(newData);
  };

  const setDiscount = (newValue, index) => {
    const newData = [...productsToCreate];
    newData[index].discount = newValue;
    setProductsToCreate(newData);
  };

  const setDiscounts = (newValue, index, param) => {
    const newData = [...productsToCreate];
    newData[index].discounts[0][param] = newValue;
    setProductsToCreate(newData);
  };

  const _setPlansOnModal = () => {
    setOpen(true);
    setPlanModal(plan);
  };

  const _removeProduct = (idx) => {
    productsToCreate.splice(idx, 1);
    const newData = [...productsToCreate];
    newData.length === 0 ? setModified(false) : null;
    setProductsToCreate(newData);
  };

  const _mapToSelect = () => {
    return plans.map((plan) => {
      return {
        label: plan.name,
        value: plan,
      };
    });
  };

  return (
    <Form
      buttons={[
        <Button
          type="tertiary"
          onClick={(e) => _removeProduct(idx)}
          icon={<Delete />}
        >
          Eliminar plan/servicio
        </Button>,
      ]}
    >
      <FormRow additionalClassName="grid">
        <Select
          additionalClassName="grid__item-6"
          options={_mapToSelect()}
          value={plan.name}
          onChange={(e) => handleSelectChange(e.value, idx)}
        />
        {plan.name === undefined ? (
          <></>
        ) : EDITABLE_PRODUCTS.includes(plan.product) ? (
          <>
            <TextField
              additionalClassName="grid__item-4"
              placeholder="Moneda"
              label="Moneda"
              value={plan.currency}
              disabled
            />
          </>
        ) : (
          <Button
            additionalClassName="grid__item-6"
            type="text"
            onClick={(e) => {
              _setPlansOnModal(plan);
            }}
            style={{ cursor: 'pointer', color: '#0792E0' }}
          >
            REVISAR TARIFAS
          </Button>
        )}
      </FormRow>

      {plan.name !== undefined && EDITABLE_PRODUCTS.includes(plan.product) ? (
        <FormRow additionalClassName="grid">
          <Checkbox
            label="¿Prepago?"
            checked={plan.prepaid}
            onChange={(e) => setPrepaid(e.target.checked, idx)}
            name="prepaid"
          />
        </FormRow>
      ) : (
        <></>
      )}

      {plan.name !== undefined && EDITABLE_PRODUCTS.includes(plan.product) ? (
        <>
          {generatePrepaid(plan, idx, setPrepaids, setDiscount)}
          {plan.prepaid ? (
            <FormRow>
              <TextField floating disabled additionalClassName="grid__item-3" />
              <Checkbox
                additionalClassName="grid__item-1"
                label="¿Descuento?"
                checked={plan.discount}
                onChange={(e) => setDiscount(e.target.checked, idx)}
                name="discount"
              />
            </FormRow>
          ) : (
            <></>
          )}
          {generateDiscount(plan, idx, setDiscounts)}
          {generateMinimum(plan, idx, setMinimum)}
          {generateBase(plan, idx, setBaseConditionState)}
          {generateVariables(plan, idx, setVariableConditionState)}
        </>
      ) : (
        <></>
      )}
      <PlanModal plan={planModal} open={open} setOpen={setOpen} />
    </Form>
  );
};

export default ProductForm;
