import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from '../../styles';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Delete } from '@material-ui/icons';
import { IconButton, TableCell, TableRow, TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';

class Line extends Component {
  constructor(props) {
    super(props);
    this.state = { errorMessage: false };
  }

  handleChange = (name) => (event) => {
    let index = this.props.index;
    let value =
      name === 'service'
        ? event
        : name == 'price'
        ? event.floatValue
        : event.target.value;
    this.props.updateLine(index, name, value);
  };

  handleDelete = () => {
    let index = this.props.index;
    this.props.deleteRow(index);
  };

  verifyNumber = (name) => (event) => {
    if (event.target.value === '') {
      let index = this.props.index;
      this.props.updateLine(index, name, 0);
    }
  };

  verifyDescription = (event) => {
    const rgx = /Servicio.+\(cantidad: (?<qty>\d+)\)/;
    const result = event.target.value.match(rgx);
    if (result) {
      if (Number.isInteger(parseInt(result.groups.qty))) {
        this.setState({ errorMessage: false });
      } else {
        this.setState({ errorMessage: true });
      }
    } else {
      this.setState({ errorMessage: true });
    }
  };

  render() {
    let { classes, items, line, currency } = this.props;
    return (
      <TableRow>
        <TableCell className={classes.iconColumn}>
          <IconButton
            aria-label="Delete"
            onClick={() => this.props.deleteRow()}
          >
            <Delete />
          </IconButton>
        </TableCell>
        <TableCell className={classes.widthColumns}>
          <Select
            components={makeAnimated()}
            value={line.service}
            options={items}
            name="service"
            onChange={this.handleChange('service')}
          />
        </TableCell>
        <TableCell className={classes.widthColumns}>
          <TextField
            error={this.state.errorMessage}
            id="description"
            margin="normal"
            value={line.description}
            onChange={this.handleChange('description')}
            onBlur={this.verifyDescription}
            type="text"
            className={classes.allWidth}
          />
        </TableCell>
        <TableCell>
          <TextField
            id="quantity"
            margin="normal"
            value={line.quantity}
            type="number"
            className={classes.allWidth}
            onChange={this.handleChange('quantity')}
            onBlur={this.verifyNumber('quantity')}
            inputProps={{ 'aria-label': 'quantity', min: '0' }}
          />
        </TableCell>
        <TableCell>
          <NumberFormat
            value={line.price}
            customInput={TextField}
            prefix={currency + '$ '}
            type="text"
            thousandSeparator={true}
            onValueChange={this.handleChange('price')}
          />
        </TableCell>
        <TableCell>
          <NumberFormat
            id="total"
            margin="normal"
            value={line.total}
            customInput={TextField}
            prefix={currency + '$ '}
            type="text"
            disabled
            thousandSeparator={true}
            onChange={this.handleChange('total')}
            inputProps={{ 'aria-label': 'total' }}
          />
        </TableCell>
      </TableRow>
    );
  }
}

export default withStyles(styles)(Line);
