import React from 'react';
import Task from './Task';

const Section = (props) => {
  const { title, tasks, setCompletedTasks, completedTasks } = props;
  return (
    <div style={{ paddingTop: '2%' }}>
      <h3 className="hp-page__header-title">{title}</h3>
      <br />
      {tasks.map((task) => {
        return (
          <Task
            id={task.id}
            body={task.text}
            completed={task.completed}
            setCompletedTasks={setCompletedTasks}
            completedTasks={completedTasks}
          />
        );
      })}
    </div>
  );
};

export default Section;
