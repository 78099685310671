import React, { Component, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { get, put } from '../../utils/api'
import ConciliationsList from './conciliations_list.js'
import Button from '@beetrack/hp-button-react'

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center'
  }
}

class Conciliations extends Component {

  constructor(props) {
    super(props)
    this.state = {
      conciliations: [],
      clients: []
    }
  }

  async componentDidMount() {
    this.getPendingConciliations()
  }

  getPendingConciliations = async () => {
    let response = await get('/conciliations?status=pending')
    let data = response.status === 200 ? response.data : []
    this.setState({
      conciliations: data.message,
      clients: data.qb_clients
    })
  }

  render() {
    return(
      <div>
      <ConciliationsList
        conciliations={ this.state.conciliations }
        clients={this.state.clients}
        />
      </div>
    )
  }
}

export default withStyles(styles)(Conciliations)
