import React from 'react';
import {
  FormRow,
  FormGroup,
  TextField,
  FormInfoField,
} from '@beetrack/hp-components';
import { calculateUserEquivalent } from '../../../Calculator';
import '../../../styles.scss';

export function generateMinimum(
  plan,
  idx,
  setter,
  setterError,
  setterHelper,
  readOnly = false,
) {
  return (
    <FormGroup title="Mínimo general a facturar" collapsible={false}>
      <div>
        <FormRow additionalClassName="grid">
          <TextField
            additionalClassName="grid__item-6"
            placeholder={`Monto mínimo a facturar en "${plan.currency}"`}
            label={`Monto mínimo a facturar en "${plan.currency}"`}
            type="number"
            value={plan.condition.general_minimum}
            onChange={(e) =>
              setter(Math.max(0, parseFloat(e.target.value)), idx, plan)
            }
            readOnly={readOnly}
            fullWidth
          />
          <TextField
            additionalClassName="grid__item-5 custom-disabled"
            placeholder="Cantidad equivalente"
            label="Cantidad equivalente"
            type="number"
            value={calculateUserEquivalent(
              plan.condition,
              plan.condition.general_minimum,
            ).units.toFixed(2)}
            disabled
            fullWidth
          />
          <FormInfoField
            title="Sobre la cantidad equivalente"
            content="La cantidad equivalente en términos de usuarios se calcula a partir de los cotos unitarios de los tramos y del monto mínimo a facturar"
            additionalClassName="grid__item-1"
            position="bottom"
            fullWidth={false}
          />
        </FormRow>
      </div>
    </FormGroup>
  );
}
