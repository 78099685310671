import React, { Component } from 'react';
import {
  Card,
  CardContent,
  Table,
  Select,
  Button,
} from '@beetrack/hp-components';
import './styles.scss';
import DetailCommission from './DetailCommission';
import ManualCommission from './ManualCommission';
import { get } from '../../utils/api';

class CommissionManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { name: 'salesman', title: 'Vendedor', valueByAttribute: 'name' },
        { name: 'mail', title: 'Correo', valueByAttribute: 'email' },
      ],
      salesmanOptions: [],
      salesmanListData: [],
      salesmanSelected: false,
      manualSelected: false,
      loading: true,
      selectedSalesmanObject: '',
    };
  }

  populateSalesmanSelect() {
    get(`/salesman`).then((response) => {
      if (response.status === 200) {
        if (response.data != null) {
          var salesmanOptions = [];
          var salesmanList = [];
          response.data.map((item, i) => {
            salesmanOptions.push({
              value: item.name,
              label: item.name,
              id: item.id,
            });
            salesmanList.push({
              id: item.id,
              name: item.name,
              email: item.email,
            });
          });
          this.setState({
            salesmanListData: salesmanList,
            loading: false,
            salesmanOptions: salesmanOptions,
          });
        }
      }
    });
  }

  componentDidMount() {
    this.populateSalesmanSelect();
  }

  handleSelectChange = (e, key) => {
    this.setState({
      salesmanSelected: true,
      selectedSalesmanObject: { name: e.value, id: e.id },
      loading: true,
    });
  };

  goBackButton = () => {
    this.setState({
      salesmanSelected: false,
      manualSelected: false,
      loading: false,
    });
  };

  onSelectedCalculate = () => {
    this.setState({ salesmanSelected: true, manualSelected: false });
  };

  onSelectedManual = () => {
    this.setState({ manualSelected: true, salesmanSelected: false });
  };

  render() {
    let { classes } = this.props;
    return (
      <div className={'main-card'}>
        <Card fullWidth>
          <CardContent>
            <div className={'selector-container row'}>
              <div className={'salesman-selector column'}>
                <Select
                  key="simple-select"
                  options={this.state.salesmanOptions}
                  label="Vendedor"
                  noOptionsMessage="No hay opciones"
                  onChange={(e) => this.handleSelectChange(e, 'salesman')}
                />
              </div>
              {this.state.salesmanSelected || this.state.manualSelected ? (
                <div className={'button-menu'}>
                  <div className={'column button-item'}>
                    <Button type="primary" onClick={this.onSelectedCalculate}>
                      Comisión automática
                    </Button>
                  </div>
                  <div className={'column'}>
                    <Button type="primary" onClick={this.onSelectedManual}>
                      Comisión manual
                    </Button>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
            {this.state.salesmanSelected ? (
              <div>
                <div>
                  <DetailCommission
                    selectedSalesmanObject={this.state.selectedSalesmanObject}
                  />
                </div>
                <div className={'button-back button-container row'}>
                  <Button type="primary" onClick={this.goBackButton}>
                    Volver
                  </Button>
                </div>
              </div>
            ) : this.state.manualSelected ? (
              <div>
                <div>
                  <ManualCommission
                    selectedSalesmanObject={this.state.selectedSalesmanObject}
                  />
                </div>
                <div className={'button-back button-container row'}>
                  <Button type="primary" onClick={this.goBackButton}>
                    Volver
                  </Button>
                </div>
              </div>
            ) : (
              <Table
                columns={this.state.columns}
                data={this.state.salesmanListData}
                loading={this.state.loading}
              />
            )}
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default CommissionManagement;
