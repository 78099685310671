import React, { Component } from 'react';
import {
  Table,
  CardContent,
  Card,
  Button,
  Modal,
} from '@beetrack/hp-components';
import { ArrowLeft } from '@beetrack/hp-icons-react';
import { get, put } from '../../../../utils/api';

class PaidManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { name: 'product', title: 'Producto', valueByAttribute: 'product' },
        { name: 'client', title: 'Cliente', valueByAttribute: 'client' },
        { name: 'invoice', title: 'Folio', valueByAttribute: 'invoice' },
        { name: 'comment', title: 'Comentario', valueByAttribute: 'comment' },
        {
          name: 'invoiceAmount',
          title: 'Monto Factura',
          valueByAttribute: 'invoiceAmount',
        },
        {
          name: 'commission',
          title: 'Comisión',
          valueByAttribute: 'commission',
        },
        {
          name: 'paidDate',
          title: 'Fecha de pago',
          valueByAttribute: 'paidDate',
        },
      ],

      resumeColumns: [
        { name: 'product', title: 'Producto', valueByAttribute: 'product' },
        { name: 'client', title: 'Cliente', valueByAttribute: 'client' },
        { name: 'invoice', title: 'Folio', valueByAttribute: 'invoice_id' },
        { name: 'comment', title: 'Comentario', valueByAttribute: 'comment' },
        {
          name: 'invoiceAmount',
          title: 'Monto Factura',
          valueByAttribute: 'original_amount',
        },
        {
          name: 'commission',
          title: 'Comisión',
          valueByAttribute: 'amount_paid',
        },
        {
          name: 'paidDate',
          title: 'Fecha de pago',
          valueByAttribute: 'paid_on',
        },
      ],
      approvedResume: [],
      rejectedResume: [],
      notConsideredResume: [],
      reversedResume: [],
      totalPay: '',
      data: [],
      loading: false,
      products: { LastMile: 0, PlannerPro: 1 },
      updatedData: false,
      modalOpen: false,
      modalData: [],
    };
  }

  formatDate = (date) => {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
      month = `0${month}`;
    }
    return `${year}-${month}-${day}`;
  };

  componentDidMount() {
    this.fetchInvoicesData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props !== prevProps) {
      this.fetchInvoicesData();
    }
  }

  reverseAction = (event) => {
    event.map((commission) => {
      put(`/commissions/${commission.item.id}`, { status: 'reversed' }).then(
        (response) => {
          if (response.status === 200) {
            this.fetchInvoicesData(this.props.selectedSalesmanObject);
          }
        },
      );
    });
  };

  fetchInvoicesData = () => {
    get(
      `/commissions?sales_man_id=${this.props.selectedSalesmanObject.id}&date=${this.props.date}&status=0`,
    ).then((response) => {
      if (response.status === 200) {
        var data = [];
        response.data.map((commission) => {
          data.push({
            id: commission.id,
            product: commission.product,
            percentage: 'default',
            client: commission.client,
            invoice: commission.invoice_id,
            comment: commission.comment,
            invoiceAmount: commission.original_amount,
            commission: commission.amount_paid,
            paidDate: commission.paid_on,
          });
        });
        this.setState({ loading: false, data: data });
      } else {
        return "Couldn't get a response";
      }
    });
  };

  populateModalData = () => {
    get(
      `/commissions?sales_man_id=${this.props.selectedSalesmanObject.id}&date=${this.props.date}`,
    ).then((response) => {
      if (response.status === 200) {
        let approvedData = [];
        let rejectedData = [];
        let notConsideredData = [];
        let reversedData = [];
        let totalData = [];
        let total = 0;
        response.data.map((commission) => {
          switch (commission.status) {
            case 'approved':
              approvedData.push(commission);
              total += parseInt(commission.amount_paid);
              break;
            case 'rejected':
              rejectedData.push(commission);
              break;
            case 'not_considered':
              notConsideredData.push(commission);
              break;
            case 'reversed':
              reversedData.push(commission);
              break;
          }
        });
        this.setState({
          totalPay: total,
          approvedResume: approvedData,
          rejectedResume: rejectedData,
          notConsideredResume: notConsideredData,
          reversedResume: reversedData,
        });
      }
    });
  };

  setOpen = (modalOpen) => {
    this.populateModalData();
    this.setState({ modalOpen: modalOpen });
  };

  render() {
    return (
      <div>
        <Card fullWidth>
          <CardContent>
            <div>
              <Table
                columns={this.state.columns}
                data={this.state.data}
                loading={this.state.loading}
                actions={[
                  {
                    title: 'Reversar',
                    onClick: this.reverseAction.bind(this),
                    icon: <ArrowLeft />,
                    isGlobal: true,
                  },
                ]}
              />
            </div>
            <div className={'row'}>
              <div className={'glossary-container column'}>
                <div className={'summary-glossary-item'}>
                  <div className={'summary-glossary-icon'}>
                    <ArrowLeft />
                  </div>
                  <div className={'sumary-glossary-text'}>
                    <p>= Reversar</p>
                  </div>
                </div>
              </div>
              <div className={'modal-style column'}>
                <Button
                  key="button-simple-modal"
                  onClick={() => this.setOpen(!this.state.modalOpen)}
                  className={'button-modal-style'}
                >
                  Resumen
                </Button>
                <Modal
                  key="simple-modal"
                  open={this.state.modalOpen}
                  onClose={() => this.setOpen(!this.state.modalOpen)}
                  title="Resumen comisiones"
                >
                  <div>
                    <h3>Pagadas</h3>
                  </div>
                  <Table
                    columns={this.state.resumeColumns}
                    data={this.state.approvedResume}
                    loading={this.state.loading}
                  />
                  <div>Total: {this.state.totalPay}</div>
                  <div>
                    <h3>Rechazadas</h3>
                  </div>
                  <Table
                    columns={this.state.resumeColumns}
                    data={this.state.rejectedResume}
                    loading={this.state.loading}
                  />
                  <div>
                    <h3>No Consideradas</h3>
                  </div>
                  <Table
                    columns={this.state.resumeColumns}
                    data={this.state.notConsideredResume}
                    loading={this.state.loading}
                  />
                  <div>
                    <h3>Reversadas</h3>
                  </div>
                  <Table
                    columns={this.state.resumeColumns}
                    data={this.state.reversedResume}
                    loading={this.state.loading}
                  />
                </Modal>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default PaidManager;
