import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Button,
  DatePicker,
  TextField,
  Select,
} from '@beetrack/hp-components';
import { ArrowRight } from '@beetrack/hp-icons-react';
import { Grid } from '@material-ui/core';
import moment from 'moment';

const QB_INFO = JSON.parse(process.env.QB_INFO);

const Prepay = (props) => {
  const { setLines, lines, currency, advance } = props;
  const [startMonth, setStartMonth] = useState(moment().format('MM/YYYY'));
  const [users, setUsers] = useState(0);
  const [prepaidMonths, setPrepaidMonths] = useState(0);
  const [amount, setAmount] = useState(0);
  const [ufAmount, setUfAmount] = useState(0);
  const [product, setProduct] = useState('');

  const prepaidMonthsOptions = [
    { label: '6', value: 6 },
    { label: '12', value: 12 },
  ];
  const productOptions = [
    { label: 'LastMile', value: 'last_mile_id' },
    { label: 'PlannerPro', value: 'planner_pro_id' },
    { label: 'Email', value: 'email_id' },
  ];

  const generateServiceId = (product) => {
    if (currency === 'MXN') {
      return QB_INFO[product.value]['mx'];
    } else if (currency === 'COP') {
      return QB_INFO[product.value]['co'];
    } else {
      return QB_INFO[product.value]['cl'];
    }
  };
  const generateLines = () => {
    let prepaidLines = [...lines];
    const serviceId = generateServiceId(product);
    for (let i = 0; i < prepaidMonths; i++) {
      let serviceDate = advance
        ? moment(startMonth, 'MM/YYYY')
            .add(i, 'months')
            .subtract(1, 'months')
            .format('YYYY/MM/DD')
        : moment(startMonth, 'MM/YYYY').add(i, 'months').format('YYYY/MM/DD');
      let newLine = {
        service: { value: serviceId.toString(), label: product.label },
        description: `Servicio ${
          product.label
        } (prepago) - (cantidad: ${users}) (${moment(startMonth, 'MM/YYYY')
          .add(i, 'months')
          .format('MM-YYYY')})`,
        quantity: currency === 'CLP' ? ufAmount : users,
        price: parseFloat(amount),
        total:
          currency === 'CLP'
            ? parseFloat(ufAmount) * parseFloat(amount)
            : parseFloat(users) * parseFloat(amount),
        serviceDate: serviceDate,
      };
      prepaidLines.push(newLine);
    }
    prepaidLines.sort(
      (a, b) => new Date(a.serviceDate) - new Date(b.serviceDate),
    );
    setLines(prepaidLines);
    setStartMonth(moment().format('MM/YYYY'));
    setUsers(0);
    setPrepaidMonths(0);
    setAmount(0);
    setProduct('');
  };

  return (
    <div style={{ paddingTop: '23px' }}>
      <Card fullWidth bordered={false}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <DatePicker
                date={startMonth}
                onChange={(value) => {
                  setStartMonth(value);
                }}
                format="MM/YYYY"
                helperText="Primer mes de prepago"
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="Cantidad Usuarios"
                onChange={(e) => setUsers(e.target.value)}
                type="number"
                value={users}
              />
            </Grid>
            {currency === 'CLP' && (
              <Grid item xs={2}>
                <TextField
                  label="Cantidad UF"
                  onChange={(e) => setUfAmount(e.target.value)}
                  value={ufAmount}
                />
              </Grid>
            )}
            <Grid item xs={2}>
              <TextField
                label={`Precio ${currency === 'CLP' ? 'UF' : 'usuario'}`}
                onChange={(e) => setAmount(e.target.value)}
                value={amount}
              />
            </Grid>
            <Grid item xs={2}>
              <Select
                label="Meses de prepago"
                options={prepaidMonthsOptions}
                value={prepaidMonths}
                onChange={(e) => setPrepaidMonths(e.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <Select
                label="Seleccionar producto"
                options={productOptions}
                value={product}
                onChange={(e) => setProduct(e)}
              />
            </Grid>
          </Grid>
          <Grid container justify="flex-end">
            <Button
              type="secondary"
              icon={<ArrowRight />}
              onClick={() => generateLines()}
            >
              Generar Lineas
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default Prepay;
