import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { hpLangs } from '@beetrack/hp-components'

const langResources = {
  es: {honeypot: hpLangs.es}
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: true,
    lng: 'es',
    fallbackLng: 'en',
    defaultNS: 'general',
    resources: langResources,
    partialBundledLanguages: true
  })
export default i18n
