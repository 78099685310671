import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { styles } from './styles';
import { get, put } from '../../utils/api';
import { Error } from '@material-ui/icons';
import { Loading } from '@beetrack/hp-components';
import {
  Button,
  IconButton,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';

class ConfirmationUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmPassword: '',
      hash: '',
      loading: true,
      message: '',
      password: '',
      snackOpen: false,
    };
  }

  componentDidMount() {
    let message = this.props.location.search.substr(1);
    if (message === '') {
      this.props.history.push('/login');
    } else {
      this.setState({ hash: message });
      get(`/users/confirm?id=${message}`).then((response) => {
        if (response.status !== 200) {
          this.props.history.push('/login');
        }
        this.setState({ loading: false });
      });
    }
  }

  handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ snackOpen: false });
  };

  generatePassword = () => {
    let { confirmPassword, hash, password } = this.state;
    let data = {
      password,
      confirmPassword,
      hash,
    };
    put('/users', data).then((response) => {
      if (response.status === 200) {
        this.props.history.push('/login?new_password');
      } else if (response.status === 409) {
        this.setState({ message: 'Ambas contraseñas deben coincidir.' });
      } else {
        this.setState({
          message: 'Ha ocurrido un error, por favor vuelva a intentar.',
        });
      }
      this.setState({ snackOpen: true });
    });
  };

  render() {
    let { confirmPassword, loading, message, password, snackOpen } = this.state;
    let { classes } = this.props;

    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={snackOpen}
          autoHideDuration={2000}
          variant="error"
          onClose={this.handleCloseSnack}
          ContentProps={{
            'aria-describedby': 'snack-message',
          }}
          message={<span id="snack-message">{message}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleCloseSnack}
            >
              <Error />
            </IconButton>,
          ]}
        />
        {loading && (
          <center>
            <Loading />
          </center>
        )}
        {!loading && (
          <Paper className={classes.form}>
            <div className={classes.title}>
              <Typography variant="h2">Bienvenido</Typography>
            </div>
            <div className={classes.fieldsForm}>
              <Typography>
                Por favor ingresa la que será la contraseña de tu cuenta
              </Typography>
              <div className={classes.fields}>
                <TextField
                  margin="dense"
                  id="password"
                  label="Contraseña"
                  type="password"
                  value={password}
                  className={classes.field}
                  onChange={(event) =>
                    this.setState({ password: event.target.value })
                  }
                />
                <br />
                <TextField
                  margin="dense"
                  id="confirmPassword"
                  label="Confirmar Contraseña"
                  type="password"
                  value={confirmPassword}
                  className={classes.field}
                  onChange={(event) =>
                    this.setState({ confirmPassword: event.target.value })
                  }
                />
              </div>
              <Button
                variant="contained"
                color="primary"
                onClick={this.generatePassword}
              >
                Guardar
              </Button>
            </div>
          </Paper>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(ConfirmationUser);
