import React, { useState, useEffect } from 'react';
import {
  Snackbar,
  Tabs,
  TabItem,
  Page,
  Button,
  SearchBar,
} from '@beetrack/hp-components';
import { del, get, post } from '../../../utils/api';
import moment from 'moment';
import ConfirmationModal from '../../../shared/ConfirmationModal';
import CandidatesTable from './Components/CandidatesTable';
import CandidateMail from './Components/CandidateMail';
import { Add } from '@beetrack/hp-icons-react';

const Candidates = (props) => {
  const { history } = props;
  const [newCandidates, setNewCandidates] = useState([]);
  const [pastCandidates, setPastCandidates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sendMail, setSendMail] = useState({ open: false, candidates: [] });
  const [showingDataNew, setShowingDataNew] = useState([]);
  const [showingDataPast, setShowingDataPast] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [search, setSearch] = useState('');
  const [snack, setSnack] = useState({
    open: false,
    message: '',
    status: 'success',
  });
  const [modalDelete, setModalDelete] = useState({
    open: false,
    deleteList: null,
  });

  const handleSearchNew = (value) => {
    setSearch(value);
    var filteredData = newCandidates;
    if (value.name) {
      filteredData = filteredData.filter((element) =>
        element.name.toLowerCase().includes(value.name.toLowerCase()),
      );
    }
    setShowingDataNew(filteredData);
  };

  const handleSearchPast = (value) => {
    setSearch(value);
    var filteredData = pastCandidates;
    if (value.name) {
      filteredData = filteredData.filter((element) =>
        element.name.toLowerCase().includes(value.name.toLowerCase()),
      );
    }
    setShowingDataPast(filteredData);
  };

  const handleDeleteCandidate = (deleteList) => {
    deleteList.forEach((element) => {
      del(`/candidate/${element.item.id}`).then((res) => {
        if (res.status === 200) {
          setSnack({
            open: true,
            message: 'Candidato eliminado exitosamente',
            status: 'success',
          });
          setRefresh(refresh + 1);
        }
      });
    });
  };

  const handleSendMail = (
    candidates,
    time,
    fixture,
    meeting,
    delayed,
    delayedOnboarding,
  ) => {
    if (delayed) {
      var payload = {
        delayed: delayed,
        delayed_onboarding: delayedOnboarding,
        entry_time: time,
        schedule: fixture,
        candidates: candidates,
        meeting: meeting,
      };
    } else {
      var payload = {
        entry_time: time,
        schedule: fixture,
        candidates: candidates,
        meeting: meeting,
      };
    }
    post('/candidates/send_email', payload).then((response) => {
      if (response.status === 200) {
        setSendMail({ candidates: [], open: false });
        setRefresh(refresh + 1);
        setSnack({
          open: true,
          message: 'Correo(s) enviado(s) correctamente',
          status: 'success',
        });
      }
    });
  };

  useEffect(() => {
    get('/candidates').then((response) => {
      if (response.status === 200) {
        let response_new = response.data.new_candidates.map((element) => {
          element.onboarding_date = moment
            .utc(element.onboarding_date)
            .format('DD/MM/YYYY');
          return element;
        });
        let response_past = response.data.past_candidates.map((element) => {
          element.onboarding_date = moment
            .utc(element.onboarding_date)
            .format('DD/MM/YYYY');
          return element;
        });
        setNewCandidates(response_new);
        setPastCandidates(response_past);
        setShowingDataNew(response_new);
        setShowingDataPast(response_past);
        setSendMail({ open: false, candidates: [] });
        setLoading(false);
      }
    });
  }, [refresh]);

  return (
    <div>
      {!sendMail.open && (
        <Page
          title={<h3 className="hp-page__header-title"> Ingresos </h3>}
          buttons={[
            <Button
              key="action-2"
              icon={<Add />}
              onClick={() => history.push('/new-onboarding')}
            >
              {' '}
              Nuevo ingreso{' '}
            </Button>,
          ]}
        >
          <div>
            <p>
              {' '}
              Acá encontrarás información de los distintos{' '}
              <strong> candidatos </strong> que entran a Beetrack.
            </p>
            <Tabs defaultActiveTab={0}>
              <TabItem label="Próximos Ingresos">
                <div>
                  <div style={{ padding: '13px' }}>
                    <SearchBar
                      callback={(value) => handleSearchNew(value)}
                      emptyCallback={() => {
                        setSearch({});
                        setShowingDataNew(newCandidates);
                      }}
                      value={search}
                      options={[{ label: 'Nombre', name: 'name' }]}
                    />
                  </div>
                  <CandidatesTable
                    history={history}
                    candidates={showingDataNew}
                    setModalDelete={setModalDelete}
                    setOpenModal={setSendMail}
                    setSnack={setSnack}
                    loading={loading}
                  />
                </div>
              </TabItem>
              <TabItem label="Ingresos Pasados">
                <div style={{ padding: '13px' }}>
                  <SearchBar
                    callback={(value) => handleSearchPast(value)}
                    emptyCallback={() => {
                      setSearch({});
                      setShowingDataPast(pastCandidates);
                    }}
                    value={search}
                    options={[{ label: 'Nombre', name: 'name' }]}
                  />
                </div>
                <CandidatesTable
                  history={history}
                  candidates={showingDataPast}
                  setModalDelete={setModalDelete}
                  setOpenModal={setSendMail}
                  setSnack={setSnack}
                  loading={loading}
                />
              </TabItem>
            </Tabs>
          </div>
        </Page>
      )}
      {sendMail.open && (
        <CandidateMail
          refresh={refresh}
          setRefresh={setRefresh}
          candidates={sendMail.candidates}
          handleSendMail={handleSendMail}
          entryDate={sendMail.candidates[0].item.onboarding_date}
        />
      )}
      <Snackbar
        key="snackbar"
        isOpen={snack.open}
        message={snack.message}
        onDismiss={() =>
          setSnack({ open: false, message: '', status: snack.status })
        }
        status={snack.status}
      />
      <ConfirmationModal
        text="¿Estás seguro de eliminar al candidato?"
        secondaryText="Esta acción es irreversible."
        open={modalDelete.open}
        close={() => setModalDelete({ open: false, id: null })}
        onConfirm={() => handleDeleteCandidate(modalDelete.deleteList)}
      />
    </div>
  );
};

export default Candidates;
