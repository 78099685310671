import React, { useEffect, useState } from 'react';
import { TextField, Button, Select, Snackbar } from '@beetrack/hp-components';
import { Grid } from '@material-ui/core';
import { get, post } from '../../utils/api';

const TriggerBDCommission = (props) => {
  const { reload, setReload } = props.reloader;
  const [paymentId, setPaymentId] = useState('');
  const [invoiceId, setInvoiceId] = useState('');
  const [selectedData, setSelectedData] = useState({});

  const [quickbooksData, setQuickbooksData] = useState([]);
  const [blockButton, setBlockButton] = useState(false);
  const [snackState, setSnackState] = useState({
    message: '',
    status: 'success',
    open: false,
  });

  const getQuickbooksData = () => {
    setBlockButton(true);
    get('/collections').then((response) => {
      if (response.status === 200) {
        const payload = response.data.data.map((entry) => {
          return {
            value: entry.id,
            label: entry.name,
            realmId: entry.realm_id,
          };
        });
        setQuickbooksData(payload);
      } else {
        setSnackState({
          message: `No se pudo gatillar o crear comisiones. ${JSON.stringify(
            response.data.errors,
          )}`,
          status: 'error',
          open: true,
        });
      }
      setBlockButton(false);
    });
  };

  const triggerCommission = () => {
    post('/sales/new_commissions/trigger/bd/', {
      invoices_ids: [invoiceId],
      payment_id: paymentId,
      accounting_id: selectedData.value,
      accounting_realm_id: selectedData.realmId,
    }).then((response) => {
      if (response.status === 200) {
        setSnackState({
          message: `Comisión regatillada para ${selectedData.label}`,
          status: 'success',
          open: true,
        });
        setPaymentId('');
        setInvoiceId('');
        setSelectedData({});
        setReload(reload + 1);
      } else {
        setSnackState({
          message: `No se pudo gatillar la comisión para ${selectedData.label}`,
          status: 'error',
          open: true,
        });
      }
    });
  };

  useEffect(() => {
    getQuickbooksData();
  }, []);

  // { invoices_ids: [], payment_id: <int>, accounting_id: <int>, accounting_realm_id: <int> }
  return (
    <Grid container>
      <Grid item xs={3}>
        <Select
          key="client-select"
          value={selectedData.value}
          options={quickbooksData}
          label="Cliente"
          onChange={(entry) => {
            setSelectedData({
              value: entry.value,
              label: entry.label,
              realmId: entry.realmId,
            });
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          label="ID de pago"
          onChange={(e) => setPaymentId(e.target.value)}
          type="number"
          value={paymentId}
          placeholder="ID del pago en Quickbooks"
          helperText="Este ID es el número que esta en la URL. Por ejemplo, si tuvieramos https://app.qbo.intuit.com/app/recvpayment?txnId=2212, el ID sería 2212."
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          label="ID de factura"
          onChange={(e) => setInvoiceId(e.target.value)}
          type="number"
          value={invoiceId}
          placeholder="ID de la factura en Quickbooks"
          helperText="Este ID es el número que esta en la URL y NO el que aparece en la vista de la tabla. Por ejemplo, si tuvieramos https://app.qbo.intuit.com/app/invoice?txnId=1613, el ID sería 1613."
        />
      </Grid>
      <Grid item xs={3}>
        <Button onClick={(e) => triggerCommission()}>
          Regatillar comisión
        </Button>
      </Grid>

      <Snackbar
        key="test-snackbar"
        isOpen={snackState.open}
        message={snackState.message}
        onDismiss={() => setSnackState({ ...snackState, open: false })}
        status={snackState.status}
      />
    </Grid>
  );
};

export default TriggerBDCommission;
