import React, { useState, useEffect } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
})

const DraftTable = (props) => {
  const classes = useStyles()
  const rows = props.rows
  const amount = props.amount
  const draftCurrency = props.invoiceDraft.table.CurrencyRef.value
  const currencyRates = props.currencyRates

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell><strong>Descripción</strong></TableCell>
            <TableCell align="right"><strong>Cantidad</strong></TableCell>
            <TableCell align="right"><strong>Precio</strong></TableCell>
            <TableCell align="right"><strong>Total</strong></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.description}>
              <TableCell>{row.description}</TableCell>
              <TableCell align="right">{row.quantity}</TableCell>
              <TableCell align="right">{row.price.toLocaleString()}</TableCell>
              <TableCell align="right">{row.amount.toLocaleString()}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={1}></TableCell>
            <TableCell align="right" colSpan={2}><strong>Total a facturar {draftCurrency}</strong></TableCell>
            <TableCell align="right"> ${amount ? amount.toLocaleString() : ''} </TableCell>
          </TableRow>
          {(draftCurrency !== 'USD') ? (
            <TableRow>
              <TableCell colSpan={1}></TableCell>
              <TableCell align="right" colSpan={2}><strong>Total a facturar USD</strong></TableCell>
              <TableCell align="right"> ${amount && currencyRates ? ((amount * currencyRates[draftCurrency.toLowerCase()] / currencyRates['usd']).toFixed(2)).toLocaleString() : ''} </TableCell>
            </TableRow>
          ) : (<></>) 
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default DraftTable