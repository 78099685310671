import React, { useState, useEffect } from 'react';
// import CommentsTable from './Components/Table';
import {
  Form,
  FormRow,
  FormGroup,
  Snackbar,
  Button,
  TextField,
  InfoBox,
  Select,
} from '@beetrack/hp-components';
import { get, post } from '../../../utils/api';
import { sleep } from '../../../utils/utils';
import ProductForm from './productForm/index';
import OtherProductForm from './othersForm';
import { useHistory } from 'react-router-dom';
import {
  commercialConditionsCurrencies,
  countryToCurrency,
} from '../../../utils/const';
import moment from 'moment';

const SalesManForm = () => {
  const history = useHistory();

  const [snackState, setSnackState] = useState({
    open: false,
    text: '',
    type: 'error',
  });

  const [currency, setCurrency] = useState('UF');

  const [modified, setModified] = useState(false);
  const [products, setProducts] = useState([]);
  const [productsToCreate, setProductsToCreate] = useState([]);
  const [others, setOthers] = useState([]);

  const [dealName, setDealName] = useState('');
  const [hubspotId, setHubspotId] = useState('');
  const [validHubspotData, setValidHubspotData] = useState(false);

  const [comment, setComment] = useState('');

  const _getProducts = (curr) => {
    get(`/commercial_conditions/plans?visible=true&currency=${curr}`).then(
      (response) => {
        if (response.status === 200) {
          var parsePlans = response.data.data.plans.nodes.sort(
            (a, b) => (a.name > b.name && 1) || -1,
          );
          // add default prepaid and discount
          parsePlans = parsePlans.map((obj) => ({
            ...obj,
            prepaid: false,
            discount: false,
          }));
          // add default values for prepaid
          // const today = new Date(new Date().setHours(0, 0, 0, 0));
          const today = moment().format('DD-MM-YYYY');
          const future = moment().add(6, 'months').format('DD-MM-YYYY');
          parsePlans = parsePlans.map((obj) => ({
            ...obj,
            prepaids: [
              {
                from: today,
                delta: 6,
                to: future,
                qty: 1,
              },
            ],
            discounts: [
              {
                percentage: 0,
                used_at: null,
              },
            ],
          }));
          setProducts(parsePlans);
        } else {
          setSnackState({
            open: true,
            text: response.data.errors[0].message,
            type: 'error',
          });
        }
      },
    );
  };

  const setSelectedCurrency = (curr) => {
    setCurrency(curr);
    _getProducts(curr);
    setProductsToCreate([]);
  };

  const _addProduct = () => {
    setProductsToCreate((state) => [...state, {}]);
  };

  const _addOther = () => {
    const defaultOther = {
      name: '',
      condition: {
        base: {
          price: '',
          qty: 0,
        },
      },
      currency: currency,
      product: 'other',
    };
    setOthers((state) => [...state, defaultOther]);
  };

  const validateHubspotData = (hs_object_id, dealname) => {
    post('/commercial_conditions/plans/validate_hubspot', {
      hs_object_id: hs_object_id,
      dealname: dealname,
    }).then((response) => {
      setValidHubspotData(response.data.exists);
      const curr = countryToCurrency(response.data.country);
      setCurrency(curr);
      _getProducts(curr);
    });
  };

  const postPlanRelations = () => {
    const payload = {
      plans: [...productsToCreate, ...others],
      modified: modified,
      comment: comment,
      hubspot_id: hubspotId,
      dealname: dealName,
    };
    post('/commercial_conditions/plans/create_plan_relations', payload).then(
      (response) => {
        if (response.status === 200) {
          var names = payload.plans.map((cond) => `"${cond.name}"`).join(', ');
          setSnackState({
            text: `Relación(es) de plan con deal creadas: ${names}`,
            open: true,
            type: 'success',
          });
          previousPage(5);
        } else {
          setSnackState({
            text: response.data.errors[0].message,
            open: true,
            type: 'error',
          });
        }
      },
    );
  };

  const previousPage = (delay = 0) => {
    sleep(delay * 1000).then(() => {
      history.push('/sales/commercial-conditions');
    });
  };

  const hubspotValid = (name, hid, valid) => {
    return (
      // test if there is a plan name
      name !== '' &&
      // test if there is a valid number
      hid !== '' &&
      valid
    );
  };

  useEffect(() => {}, []);

  return (
    <Form
      title="Nueva Asignación Comercial"
      buttons={[
        <Button
          key={`button-previous-page`}
          onClick={previousPage}
          type="tertiary"
        >
          Cancelar
        </Button>,
        <Button
          key={`button-condition-generation`}
          onClick={postPlanRelations}
          disabled={
            !(
              // test if there is any plan
              (
                !([...productsToCreate, ...others].length === 0) &&
                hubspotValid(dealName, dealName, validHubspotData)
              )
            )
          }
        >
          Guardar
        </Button>,
      ]}
      backTo="/sales/commercial-conditions"
    >
      <div style={{ paddingBottom: '15px' }}>
        <i>
          Aquí podrás modificar el pricing de una condición comercial ya
          existente y asociarla a un deal de Hubspot.
        </i>
      </div>
      {/* deal data */}
      <FormGroup title="Datos del deal" collapsible={false}>
        <div style={{ paddingBottom: '5px' }}>Ingresa datos del cliente</div>
        <FormRow>
          <TextField
            onChange={(e) => {
              setDealName(e.target.value);
              validateHubspotData(hubspotId, e.target.value);
            }}
            placeholder="Transportes Abejita"
            label="Nombre del deal"
            value={dealName}
            helperText={
              hubspotId === '' || dealName === ''
                ? 'Ingresa el nombre de la misma forma que está ingresado en Husbpot'
                : validHubspotData
                ? 'Ingresa el nombre de la misma forma que está ingresado en Husbpot'
                : 'El nombre del deal no calza con el ID de hubspot'
            }
            error={
              hubspotId === '' || dealName === '' ? false : !validHubspotData
            }
            fullWidth
          />
          <TextField
            onChange={(e) => {
              setHubspotId(e.target.value);
              validateHubspotData(e.target.value, dealName);
            }}
            placeholder="ID Deal en Hubspot"
            label="ID Deal en Hubspot"
            type="number"
            value={hubspotId}
            helperText={
              hubspotId === '' || dealName === ''
                ? 'Solo se permiten números '
                : validHubspotData
                ? 'Solo se permiten números '
                : 'El ID de hubspot no existe o no calza con el nombre del deal'
            }
            error={
              hubspotId === '' || dealName === '' ? false : !validHubspotData
            }
            fullWidth
          />
        </FormRow>
        {hubspotValid(dealName, dealName, validHubspotData) ? (
          <>
            <FormRow>
              <Select
                additionalClassName="grid__item-6"
                options={commercialConditionsCurrencies}
                value={currency}
                onChange={(e) => setSelectedCurrency(e.value)}
              />
            </FormRow>
          </>
        ) : (
          <></>
        )}
        <></>
      </FormGroup>
      {hubspotValid(dealName, dealName, validHubspotData) ? (
        <div>
          {/* plans and products that are non others */}
          <FormGroup
            title="Planes/servicios a contratar por el cliente"
            collapsible={true}
          >
            {productsToCreate.map((product, idx) => {
              return (
                <div key={`div-fr-product-${idx}`}>
                  <ProductForm
                    modified={modified}
                    setModified={setModified}
                    productsToCreate={productsToCreate}
                    setProductsToCreate={setProductsToCreate}
                    plan={product}
                    plans={products}
                    idx={idx}
                  />
                  <br />
                </div>
              );
            })}
            <Button type="tertiary" onClick={_addProduct}>
              + AÑADIR PRODUCTO / SERVICIO
            </Button>
          </FormGroup>
          {/* other plans */}
          <FormGroup title="Otros cobros" collapsible={true}>
            {others.map((other, idx) => {
              return (
                <div key={`div-fr-other-${idx}`}>
                  <OtherProductForm
                    setOthers={setOthers}
                    other={other}
                    others={others}
                    idx={idx}
                  />
                  <br />
                </div>
              );
            })}
            <Button type="tertiary" onClick={_addOther}>
              + AÑADIR COBRO
            </Button>
          </FormGroup>
          {/* comments */}
          <div style={{ paddingTop: '10px' }}>
            <FormGroup title="Comentarios adicionales" collapsible={false}>
              <div style={{ paddingTop: '15px', paddingBottom: '15px' }}>
                Serán ingresados en la notificación al Manager
              </div>
              <FormRow>
                <TextField
                  label="Comentarios"
                  onChange={(e) => setComment(e.target.value)}
                  value={comment}
                  helperText="Detalla, en caso de ser necesario, el motivo por el cual este plan es diferente, qué se negoció, etc."
                />
              </FormRow>
              <FormRow>
                <InfoBox status="info">
                  <p>
                    {' '}
                    Recuerda que serás notificado vía correo en caso de ser
                    aprobada o rechazada la condición comercial en caso de no
                    tener los precios por defecto.
                  </p>
                </InfoBox>
              </FormRow>
            </FormGroup>
          </div>
          <Snackbar
            isOpen={snackState.open}
            message={snackState.text}
            onDismiss={() => setSnackState({ ...!open })}
            status={snackState.type}
          />
        </div>
      ) : (
        <></>
      )}
    </Form>
  );
};

export default SalesManForm;
