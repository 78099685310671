import React, { useState, useEffect } from 'react';
import {
  Table,
  Modal,
  Loading,
  Snackbar,
  SearchBar,
} from '@beetrack/hp-components';
import { View, Envelope } from '@beetrack/hp-icons-react';
import { get, post } from '../../../../utils/api';
import ConfirmationModal from '../../../../shared/ConfirmationModal';
import ModalCollection from './ModalCollection';

const DebtorsTable = (props) => {
  const { data, loading, accountTypes } = props;
  const [modalInfo, setModalInfo] = useState({
    open: false,
    data: { additional_data: {} },
  });
  const [modalDiscard, setModalDiscard] = useState({ open: false, list: [] });
  const [usage, setUsage] = useState({});
  const [loadingModal, setLoadingModal] = useState(true);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    status: 'success',
  });
  const [search, setSearch] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [fixedData, setFixedData] = useState([]);

  const columns = [
    {
      name: 'name',
      title: 'Nombre cliente',
      valueByAttribute: 'name',
    },
    {
      name: 'balance',
      title: 'Balance',
      valueByAttribute: 'balance',
    },
    {
      name: 'country',
      title: 'País',
      valueByAttribute: 'country',
    },
    {
      name: 'overdue_invoices',
      title: 'Facturas vencidas',
      valueByAttribute: 'overdue_invoices',
    },
  ];

  const handleSendMail = (client) => {
    setModalDiscard({ open: true, list: client });
  };

  const handleSearch = (value) => {
    setSearch(value);
    var new_data = filteredData;
    if (value.name) {
      new_data = new_data.filter((element) =>
        element.name.toLowerCase().includes(value.name.toLowerCase()),
      );
    } else if (value.country) {
      new_data = new_data.filter((element) =>
        element.country.toLowerCase().includes(value.country.toLowerCase()),
      );
    }
    setFilteredData(new_data);
  };

  const sendMail = (list) => {
    let sorted = {};
    list.forEach((element) => {
      if (sorted[element.item.realm_id]) {
        sorted[element.item.realm_id].push(element.item.id);
      } else {
        sorted[element.item.realm_id] = [element.item.id];
      }
    });
    const payload = {
      type: 'email',
      clients: sorted,
    };
    post('/collections', payload).then((response) => {
      if (response.status === 200) {
        setSnackbar({
          open: true,
          message: 'Correo envíado con éxito',
          status: 'success',
        });
      }
    });
  };

  const handleViewResource = (client) => {
    setLoadingModal(true);
    setModalInfo({ open: true, data: client[0].item, usage: {} });
    get(
      `/collection/usage/${client[0].item.id}/${client[0].item.realm_id}`,
    ).then((response) => {
      if (response.status === 200) {
        setUsage(response.data);
        setLoadingModal(false);
      }
    });
  };

  const actions = [
    {
      title: 'Más información',
      icon: <View />,
      onClick: handleViewResource.bind(this),
      isGlobal: false,
      key: 'info',
    },
    {
      title: 'Enviar correo cobranza',
      icon: <Envelope />,
      onClick: handleSendMail.bind(this),
      isGlobal: true,
      key: 'mail',
    },
  ];

  useEffect(() => {
    var new_data = data;
    if (accountTypes === 'debtors') {
      new_data = new_data.filter((element) => element.hidden_balance > 0);
    }
    setFixedData(new_data);
    setFilteredData(new_data);
  }, [data, accountTypes]);

  return (
    <>
      <SearchBar
        callback={(value) => handleSearch(value)}
        emptyCallback={() => {
          setSearch({});
          setFilteredData(fixedData);
        }}
        value={search}
        options={[
          { label: 'Nombre', name: 'name' },
          { label: 'País', name: 'country' },
        ]}
      />
      <br />
      <Table
        columns={columns}
        data={filteredData}
        fetchData={() => {}}
        actions={actions}
        loading={loading}
        isItemLoaded={() => {}}
      />
      <Modal
        key="info-modal"
        open={modalInfo.open}
        title={`${modalInfo.data.name} (ID: ${
          usage.beetrack_id === undefined ? '-' : usage.beetrack_id
        })`}
        onClose={() => setModalInfo({ open: false, data: {} })}
      >
        {loadingModal && <Loading />}
        {!loadingModal && (
          <ModalCollection
            drivers={usage.drivers}
            routes={usage.routes}
            active={usage.active}
          />
        )}
      </Modal>
      <ConfirmationModal
        text="¿Enviar Correo Cobranza?"
        secondaryText="Se notificará al cliente de sus facturas impagas y si en 5 días no resuelve sus facturas se cortará la cuenta."
        open={modalDiscard.open}
        close={() => setModalDiscard({ open: false, list: [] })}
        onConfirm={() => sendMail(modalDiscard.list)}
      />
      <Snackbar
        key="snackbar"
        isOpen={snackbar.open}
        message={snackbar.message}
        onDismiss={() =>
          setSnackbar({ open: false, message: '', status: snackbar.status })
        }
        status={snackbar.status}
      />
    </>
  );
};

export default DebtorsTable;
