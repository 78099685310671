import React from 'react';
import { TextField } from '@beetrack/hp-components';
import { Grid } from '@mui/material';

import { getNestedValue } from '../utils';

const NumberElementTranslator = (props) => {
  const { classes, form, formValues, path, numberChangeHandler, dependant } =
    props;

  const element = getNestedValue(form.content, path);

  return (
    <Grid item sm={12} md={dependant ? 12 : 10} className={classes.element}>
      <div className={classes.question}>{element.question}</div>
      {element.imgUrl && (
        <div className={classes.imageContainer}>
          <img
            style={{ width: '100%', height: '100%' }}
            src={element.imgUrl}
            alt="new"
          />
        </div>
      )}
      <div className={classes.textField}>
        <TextField
          label={element.title}
          name={element.propertyName}
          helperText={element.helperText}
          placeholder={element.placeHolder}
          required={element.required}
          value={formValues[`${element.propertyName}`]}
          onChange={numberChangeHandler}
        />
      </div>
    </Grid>
  );
};

export default NumberElementTranslator;
