import React, { useEffect, useState } from 'react';
import { Table, Modal, Button } from '@beetrack/hp-components';
import { Delete, View } from '@beetrack/hp-icons-react';
import { get, post } from '../../../../../utils/api';

const InvoiceTableSupplier = (props) => {
  const { bills } = props;

  const columns = [
    {
      name: 'dueDate',
      title: <strong> Fecha de vencimiento </strong>,
      valueByAttribute: 'dueDate',
    },
    {
      name: 'amount',
      title: <strong> Monto </strong>,
      valueByAttribute: 'amount',
    },
    {
      name: 'balance',
      title: <strong> Balance </strong>,
      valueByAttribute: 'balance',
    },
  ];

  const actions = [];
  return (
    <Table
      columns={columns}
      data={bills}
      fetchData={() => {}}
      actions={actions}
    />
  );
};

export default InvoiceTableSupplier;
