import moment from 'moment';

export function activePrepaid(prepaids) {
  if (prepaids.length === 0) {
    return false;
  } else {
    const lastPrepaid = prepaids[prepaids.length - 1];
    return Date.now() < Date.parse(lastPrepaid.to);
  }
}

export function expirationRangeActive(prepaids) {
  if (prepaids.length === 0) {
    return false;
  } else {
    // last expiration date (to)
    var lastPrepaid = prepaids[prepaids.length - 1].to;
    var startRange = new Date(Date.parse(lastPrepaid));
    var endrange = new Date(Date.parse(lastPrepaid));
    var today = new Date();

    // now we have to modify the date for the respective range
    startRange.setDate(startRange.getDay() - 15);
    endrange.setMonth(endrange.getMonth() + 1);

    return startRange <= today && today <= endrange;
  }
}

export function deltaMonths(dateStart, dateEnd) {
  // 14-02-2022
  // calculate difference in reverse order to obtain diff as positive int
  return moment(dateEnd, 'YYYY-MM-DD').diff(
    moment(dateStart, 'YYYY-MM-DD'),
    'months',
  );
}
