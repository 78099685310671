import React from 'react';
import {
  Card,
  CardContent,
  CardDivider,
  Chip,
  Tooltip,
} from '@beetrack/hp-components';
import { Grid } from '@material-ui/core';
import StorefrontIcon from '@material-ui/icons/Storefront';

const SupplierCard = (props) => {
  const { supplier } = props;

  return (
    <Card bordered={false} fullWidth>
      <CardContent>
        <Grid container justify="flex-start">
          <Chip text={supplier.data.area} closeButton={false} />
        </Grid>
        <center>
          <StorefrontIcon fontSize="large" />
          <h3 className="hp-page__header-title">{supplier.data.name}</h3>
          <p>
            {' '}
            {supplier.data.legalName} - {supplier.data.taxIdentifier}
          </p>
        </center>
        <CardDivider />
        <p>
          {' '}
          <strong> Dirección comercial: </strong> {supplier.data.address},{' '}
          {supplier.data.city}, {supplier.data.country}
        </p>
        <p>
          {' '}
          <strong> Giro comercial: </strong> {supplier.data.activity}{' '}
        </p>
        <p>
          {' '}
          <strong> Representante: </strong> {supplier.data.representantName},{' '}
          {supplier.data.representantPhone}, {supplier.data.representantEmail}
        </p>
        <p>
          {' '}
          <strong> Representante legal: </strong>
          {supplier.data.legalRepresentant}, {supplier.data.legalRepresentantId}
        </p>
        <p>
          {' '}
          <strong> Plazo de pago: </strong> {supplier.data.due}{' '}
        </p>
        <p>
          {' '}
          <strong> Moneda: </strong> {supplier.data.currency}{' '}
        </p>
        <p>
          {' '}
          <strong> Método de pago: </strong> {supplier.data.paymentMethod}{' '}
        </p>
        <p>
          {' '}
          <strong> Servicio contratado: </strong> {supplier.data.service}{' '}
        </p>
        <p>
          {' '}
          <strong> Numero de licencias: </strong> {supplier.data.quantity}{' '}
        </p>
        <p>
          {' '}
          <strong> Tarifa: </strong> {supplier.data.price}{' '}
        </p>
        <p>
          {' '}
          <strong> Fecha inicio: </strong> {supplier.data.serviceStart}{' '}
        </p>
        <p>
          {' '}
          <strong> Fecha término: </strong> {supplier.data.serviceEnd}{' '}
        </p>
        <p>
          {' '}
          <strong> Frecuencia de pago: </strong> {supplier.data.paymentType}{' '}
        </p>
        <p>
          {' '}
          <strong> Tracker encargado: </strong> {supplier.data.owner}{' '}
        </p>
        <p>
          {' '}
          <a href={supplier.data.contract_url} target="__blank">
            {' '}
            Ver contrato{' '}
          </a>
        </p>
      </CardContent>
    </Card>
  );
};

export default SupplierCard;
