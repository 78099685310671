import React, { useState, useEffect } from 'react';
import { Button, Page, Snackbar } from '@beetrack/hp-components';
import { get } from '../../../utils/api';
import { MAX_VARIABLE_NUMBER } from '../../../utils/const';
import { havePermissions } from '../../../utils/utils';
import MaterialTable from 'material-table';
import './styles.scss';
// left out commented for when HP supports multi line rows
import {
  List,
  ListItem,
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const CommercialConditionsTable = (props) => {
  const history = useHistory();

  const [snackState, setSnackState] = useState({
    open: false,
    text: '',
    type: 'error',
  });

  const columns = [
    { field: 'name', title: 'Nombre CC', valueByAttribute: 'name' },
    {
      field: 'pretty_product_name',
      title: 'Producto',
    },
    {
      field: 'detail',
      title: 'Detalle de rangos',
      filtering: false,
      // valueByAttribute: 'detail',
      render: (object) => (
        <List>
          {object.detail.split(',\n').map((element) => (
            <ListItem key={element}>{element}</ListItem>
          ))}
        </List>
      ),
    },
    {
      field: 'price',
      title: 'Precios por rango',
      filtering: false,
      // valueByAttribute: 'price',
      render: (object) => (
        <List>
          {object.price.split(',\n').map((element) => (
            <ListItem key={element}>
              {element === '0' || element === 0 ? 'Gratis' : element}
            </ListItem>
          ))}
        </List>
      ),
    },
    { field: 'currency', title: 'Moneda', valueByAttribute: 'currency' },
    {
      field: 'description',
      title: 'Comentarios',
      // valueByAttribute: 'description',
      filtering: false,
    },
  ];

  const [plans, setPlans] = useState([]);

  const _conditionParser = (condition) => {
    const detail = [];
    const price = [];

    if (condition.base) {
      detail.push(`Base: ${condition.base.qty.toLocaleString()}`);
      price.push(condition.base.price);
    }
    if (condition.step) {
      detail.push(
        `Eslabón: 0 a ${condition.step.free_amount.toLocaleString()}`,
      );
      detail.push(`${condition.step.step.toLocaleString()} ↻`);
      price.push(0);
      price.push(condition.step.price.toLocaleString());
    }
    if (condition.variable) {
      condition.variable.forEach((variable) => {
        detail.push(
          `${variable.from.toLocaleString()} a ${
            variable.to >= MAX_VARIABLE_NUMBER
              ? '∞'
              : variable.to.toLocaleString()
          }`,
        );
        price.push(variable.price.toLocaleString());
      });
    }
    if (condition.variable_initial) {
      condition.variable_initial.forEach((variable_initial) => {
        detail.push(
          `VI: ${variable_initial.from.toLocaleString()} a ${
            variable_initial.to >= MAX_VARIABLE_NUMBER
              ? '∞'
              : variable_initial.to.toLocaleString()
          }`,
        );
        price.push(variable_initial.price.toLocaleString());
      });
    }
    if (condition.variable_chat) {
      condition.variable_chat.forEach((variable_chat) => {
        detail.push(
          `VC: ${variable_chat.from.toLocaleString()} a ${
            variable_chat.to >= MAX_VARIABLE_NUMBER
              ? '∞'
              : variable_chat.to.toLocaleString()
          }`,
        );
        price.push(variable_chat.price.toLocaleString());
      });
    }
    return [detail.join(',\n'), price.join(',\n')];
  };

  const pasePlansIntoTableData = (plans) => {
    var data = [];
    plans.forEach((plan) => {
      const [detail, price] = _conditionParser(plan.condition, plan.currency);
      data.push({
        id: plan.id,
        name: plan.name,
        pretty_product_name: plan.pretty_product_name,
        detail: detail,
        price: price,
        currency: plan.currency,
        description: plan.description,
      });
    });
    return data;
  };

  const getConditionData = () => {
    get(`/commercial_conditions/plans?visible=true`).then((response) => {
      if (response.status === 200) {
        setPlans(response.data.data.plans.nodes);
      }
    });
  };

  const handleOnClickNewCondition = () => {
    history.push(`/sales/new-commercial-condition`);
  };

  const handleOnClickNewCustomCondition = () => {
    history.push(`/sales/new-custom-commercial-condition`);
  };

  useEffect(() => {
    getConditionData();
    if (props.location.state && props.location.state.payload) {
      setSnackState(props.location.state.payload);
    }
  }, []);

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#f5b73c',
      },
      secondary: {
        main: '#f5b73c',
      },
    },
  });

  return (
    <Page
      title={<>Condiciones comerciales</>}
      buttons={[
        havePermissions(['admin_sales_man']) && (
          <Button
            key="redirector"
            onClick={(e) => handleOnClickNewCustomCondition()}
            type="secondary"
          >
            + Nueva condición personalizada
          </Button>
        ),
        havePermissions(['sales_man']) && (
          <Button
            // type="text"
            key="redirector"
            onClick={(e) => handleOnClickNewCondition()}
          >
            + Crear Asignación Comercial
          </Button>
        ),
      ]}
    >
      {/* <p>
        Aquí podrás revisar todas las condiciones comerciales que existen y
        seleccionar una de ellas. Para crear puedes usar las ya existentes o
        crear una desde cero.{' '}
      </p> */}
      <MuiThemeProvider theme={theme}>
        <MaterialTable
          title="Aquí podrás revisar todas las condiciones comerciales que existen."
          columns={columns}
          data={pasePlansIntoTableData(plans)}
          options={{
            search: false,
            filtering: true,
          }}
        />
      </MuiThemeProvider>
      {/* <Table
        actions={actions}
        columns={columns}
        data={pasePlansIntoTableData(plans)}
        loading={false}
        isItemLoaded={() => {}}
        fetchData={() => {}}
      /> */}
      <Snackbar
        key="snackbar"
        isOpen={snackState.open}
        message={snackState.text}
        onDismiss={() => setSnackState({ ...!open })}
        status={snackState.type}
      />
    </Page>
  );
};

export default CommercialConditionsTable;
