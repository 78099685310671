import React, { Component } from 'react';
import './styles.scss'
import { get, post } from '../../../utils/api.js'
import {
  Form,
  Select,
  Button,
  Snackbar,
  Loading
} from "@beetrack/hp-components";

class ToggleAccount extends Component {

  constructor(props) {
    super(props);
    this.state = {
      accounts: [],
      full_accounts: [],
      selected_deal: null,
      beetrack_id: '-',
      beetrack_name: '-',
      activate: false,
      snackbarState: false,
      snackbar_message:'',
      snackbar_status:'success',
      select_loading: true,
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData (){
    this.setState({select_loading: true});
    get('/beetrack/accounts/fetch_active').then((response) => {
      let accounts = [];
      let full_accounts = [];
      response.data.message.map((client) => {
        accounts.push({label: client.hubspot_name, value: client.deal_id});
        full_accounts.push(client)
      });
      this.setState({ accounts: accounts, full_accounts: full_accounts, select_loading: false })
    })
  }

  handleSelectChange = (event) => {
    this.setState({ selected_deal: event.value });
    this.fetchBeetrackAccountInfo(event.value)
  };

  fetchBeetrackAccountInfo = (hubspot_id) => {
    this.state.full_accounts.map((account) => {
      if(account.deal_id === hubspot_id){
        this.setState({
          beetrack_id: account.beetrack_account.id,
          beetrack_name: account.beetrack_account.name
        })
      }
    })
  };

  setOpen = (open) =>{
    this.setState({snackbar_state: open})
  };

  handleSubmit = () => {
    this.setState({ button_disabled: true});
    const data = {
      beetrack_id: this.state.beetrack_id,
      hubspot_deal: this.state.selected_deal,
      activate: this.state.activate
    };
    post(`/beetrack/accounts/toggle`, data).then((response) => {
      if(response.status === 200){
        this.setState({ button_disabled: false, snackbar_message: response.data.message, snackbar_status:'success'});
        this.setOpen(true);
        this.fetchData()
      }else{
        this.setState({ button_disabled: false, snackbar_message: response.data.message, snackbar_status:'error' });
        this.setOpen(true)
      }
    })
  };
  
  render() {
    return(
      <Form
        className='hp-form account-form'
        title='Desactivar Cuenta'>
          <div className='select-group'>
            {this.state.select_loading ? <Loading hasText />:
              <Select
              name='Beetrack Account'
              options={ this.state.accounts }
              label='Cuenta'
              onChange={ (e) => this.handleSelectChange(e) }
            />}
          </div>
        <h2 className='h2'>Información en Beetrack</h2>
        <p>ID de Cuenta: { this.state.beetrack_id }</p>
        <p>Nombre de Cuenta: { this.state.beetrack_name }</p>
        <div className='submit-button'>
          <Button disabled= {this.state.button_disabled} onClick={() => this.handleSubmit()} primary>{ this.state.activate? 'Activar' : 'Desactivar'} Cuenta</Button>
        </div>
        <Snackbar
          key='snackbar'
          isOpen={this.state.snackbar_state}
          message={this.state.snackbar_message}
          onDismiss={() => this.setOpen(false)}
          status={this.state.snackbar_status}
        />
      </Form>
    )
  }
}

export default ToggleAccount
