import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { havePermissions } from '../../../../utils/utils';
import {
  CheckCircle,
  Visibility,
  Cancel,
  Edit,
  TrendingUpRounded,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import DraftModal from '../DraftModal';
import {
  NoDataState,
  SuccessState,
  Snackbar,
  Select,
  ConfirmationModal,
} from '@beetrack/hp-components';
import { post, put } from '../../../../utils/api';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import GradeIcon from '@mui/icons-material/Grade';
import Tooltip from '@material-ui/core/Tooltip';
import VoidReasonModal from '../VoidReasonModal';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

const QBMX = process.env.QB_REALM_MX;

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
  },
  appBar: {
    position: 'relative',
    backgroundColor: '#132045',
  },
  title: {
    marginLeft: '2%',
    paddingTop: '1%',
  },
  selectContainer: {
    paddingTop: '20px',
    paddingBottom: '75px',
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DraftsTable = (props) => {
  const classes = useStyles();
  const dataTable = props.dataTable;
  const [modalDraft, setModalDraft] = useState({ open: false, data: {} });
  const [modalAccept, setModalAccept] = useState({
    open: false,
    id: null,
    country: '',
  });
  const [modalReject, setModalReject] = useState({ open: false, id: null });
  const [editModalDraft, setEditModalDraft] = useState({
    open: false,
    id: null,
  });
  const [draftType, setDraftType] = useState(null);
  const [selectedRow, setSelectedRow] = useState(0);
  const [snackBar, setSnackBar] = useState({
    open: false,
    status: 'close',
    message: '',
  });

  const tableType = props.tableType;

  const [openSnack, setOpenSnack] = useState({
    open: false,
    message: '',
    status: 'success',
  });

  const setColumns = () => {
    return [
      { title: 'ID', field: 'id', searchable: false, hidden: true },
      {
        title: 'Nombre',
        field: 'name',
        field: 'company_name',
        filtering: true,
      },
      {
        title: 'Período Facturación',
        field: 'facturation_date',
        filtering: true,
      },
      {
        title: 'Encargado',
        field: 'salesman',
        filtering: true,
        customFilterAndSearch: (term, rowData) => {
          if (
            rowData.account_partner !== 'Sin Account Partner' &&
            rowData.account_partner !== null
          ) {
            return rowData.account_partner.split('@')[0].startsWith(term);
          } else if (rowData.salesman !== null) {
            return rowData.salesman.split('@')[0].startsWith(term);
          } else {
            return ' '.startsWith(term);
          }
        },
        render: (rowData) =>
          rowData.account_partner !== 'Sin Account Partner' &&
          rowData.account_partner !== null
            ? rowData.account_partner.split('@')[0]
            : rowData.salesman
            ? rowData.salesman.split('@')[0]
            : ' ',
      },
      //{ title: 'Account Partner', field: 'account_partner', filtering: false },
      {
        title: 'Usuarios LM',
        field: 'last_mile',
        filtering: false,
        searchable: false,
      },
      {
        title: 'Usuarios PP',
        field: 'planner_pro',
        filtering: false,
        searchable: false,
      },
      {
        title: 'Monto Total',
        field: 'total_amount',
        filtering: false,
        searchable: false,
        render: (rowData) => '$ ' + rowData.total_amount.toLocaleString(),
      },
      {
        title: 'Tipo Documento',
        field: 'type',
        filtering: true,
        lookup: { estimate: 'Estimación', invoice: 'Factura' },
      },
      {
        title: 'Estado',
        field: 'comment_status',
        filtering: false,
        searchable: false,
        render: (rowData) =>
          rowData.comment_status === 'commented' ? (
            rowData.comment_approval_status === 'approved' ? (
              <Tooltip title="Comentario aprobado por management">
                <IconButton aria-label="info">
                  <InfoIcon style={{ color: '#3377FF' }} />
                </IconButton>
              </Tooltip>
            ) : rowData.comment_approval_status === 'rejected' ? (
              <Tooltip title="Comentario NO aprobado por management">
                <IconButton aria-label="info">
                  <InfoIcon style={{ color: 'red' }} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Comentario pendiente de aprobación">
                <IconButton aria-label="info">
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            )
          ) : rowData.comment_status === 'pending' ? (
            rowData.draft_status === 'pre_aproved' ? (
              <Tooltip title="Borrador Pre-Aprobado">
                <IconButton aria-label="grade">
                  <GradeIcon style={{ color: 'gold' }} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Borrador pendiente de revisión">
                <IconButton aria-label="info">
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            )
          ) : rowData.comment_status === 'approved' ? (
            <Tooltip title="Borrador aprobado">
              <IconButton aria-label="info">
                <InfoIcon style={{ color: 'green' }} />
              </IconButton>
            </Tooltip>
          ) : null,
      },
    ];
  };

  const approveDraft = (draftId, cfdi) => {
    const multiple = Array.isArray(draftId);

    if (multiple) {
      post('/drafts/approve_all_pre_approved', {
        ids: draftId,
      }).then((response) => {
        if (response.status === 200) {
          setSnackBar({
            open: true,
            status: 'success',
            message: 'Borradores aprobados',
          });
        } else {
          setSnackBar({
            open: true,
            status: 'error',
            message: 'Ocurrió un error',
          });
        }
        props.reloadAction();
      });
    } else {
      put('/drafts/' + draftId, { resolution: 'approved', cfdi: cfdi }).then(
        (response) => {
          if (response.status === 204) {
            setSnackBar({
              open: true,
              status: 'success',
              message: 'Borrador Aprobado',
            });
          } else {
            setSnackBar({
              open: true,
              status: 'error',
              message: 'Ocurrió un error',
            });
          }
          props.reloadAction();
        },
      );
    }
  };

  const rejectDraft = (draftId, comment) => {
    put('/drafts/' + draftId, { resolution: 'approved' }).then((response) => {
      if (response.status === 204) {
        setSnackBar({
          open: true,
          status: 'success',
          message: 'Borrador Rechazado',
        });
      } else {
        setSnackBar({
          open: true,
          status: 'error',
          message: 'Ocurrió un error',
        });
      }
      props.reloadAction();
    });
  };

  const editDraftTypeConfirmationClick = (draftId) => {
    put('/draft/type/' + draftId, { newType: draftType }).then((response) => {
      if (response.status === 200) {
        window.location.reload();
        setEditModalDraft({ open: false, data: {} });
        setOpenSnack({
          status: 'success',
          open: true,
          message: 'Tipo de borrador editado correctamente',
        });
      } else {
        setEditModalDraft({ open: false, data: {} });
        setOpenSnack({
          status: 'error',
          open: true,
          message: 'Oops, ocurrio un error',
        });
      }
    });
  };

  const closeModals = () => {
    setModalDraft({ open: false, data: {} });
    props.reloadAction();
  };

  const setActions = () => {
    if (
      havePermissions(['admin_accounting']) &&
      (tableType === 'pending' || tableType === 'commented')
    ) {
      return [
        {
          icon: () => <CheckCircle style={{ color: 'green' }} />,
          tooltip: 'Aprobar Borrador',
          onClick: (_, rowData) => {
            setModalAccept({
              open: true,
              id: rowData.id,
              realmId: rowData.realm_id,
            });
          },
        },
        {
          icon: () => <Cancel style={{ color: 'red' }} />,
          tooltip: 'Rechazar Borrador',
          onClick: (_, rowData) =>
            setModalReject({ open: true, data: rowData }),
        },
        {
          icon: () => <Visibility />,
          tooltip: 'Visualizar Borrador',
          onClick: (_, rowData) => setModalDraft({ open: true, data: rowData }),
        },
        {
          icon: () => <Edit />,
          tooltip: 'Editar Tipo',
          onClick: (_, rowData) => {
            setDraftType(rowData.type);
            setEditModalDraft({ open: true, data: rowData });
          },
        },
      ];
    } else if (
      havePermissions(['admin_accounting']) &&
      tableType === 'pre_aproved'
    ) {
      return [
        {
          icon: () => <CheckCircle style={{ color: 'green' }} />,
          tooltip: 'Aprobar Borradores',
          isFreeAction: true,
          onClick: () => setModalAccept({ open: true, id: _invoicesIds() }),
        },
        {
          icon: () => <Cancel style={{ color: 'red' }} />,
          tooltip: 'Rechazar Borrador',
          onClick: (_, rowData) =>
            setModalReject({ open: true, data: rowData }),
        },
        {
          icon: () => <Visibility />,
          tooltip: 'Visualizar Borrador',
          onClick: (_, rowData) => setModalDraft({ open: true, data: rowData }),
        },
        {
          icon: () => <Edit />,
          tooltip: 'Editar Tipo',
          onClick: (_, rowData) => {
            setDraftType(rowData.type);
            setEditModalDraft({ open: true, data: rowData });
          },
        },
      ];
    } else if (
      havePermissions(['admin_accounting']) &&
      (tableType === 'approved' || tableType === 'rejected')
    ) {
      return [
        {
          icon: () => <Visibility />,
          tooltip: 'Visualizar Borrador',
          onClick: (_, rowData) => setModalDraft({ open: true, data: rowData }),
        },
      ];
    } else {
      return [];
    }
  };

  const _invoicesIds = () => {
    return dataTable.map((invoice) => invoice.id);
  };

  return (
    <div>
      <ConfirmationModal
        key="simple-modal"
        open={modalAccept.open}
        title="Confirmation Modal"
        onClose={() => setModalAccept({ open: false, id: null })}
        confirmButton={
          <Button onClick={() => approveDraft(modalAccept.id, 4)}>
            Aprobar
          </Button>
        }
      >
        <div>
          {tableType === 'pending' || tableType === 'commented'
            ? '¿Aceptar borrador?'
            : '¿Aceptar todos los borradores?'}
        </div>
        <div>
          {tableType === 'pending' || tableType === 'commented'
            ? 'Se aprobará el borrador de factura y se enviará al cliente'
            : 'Se aprobarán TODOS los borradores pre-aprobados'}
        </div>
      </ConfirmationModal>
      <Modal
        open={modalReject.open}
        onClose={() => setModalReject({ open: false, data: {} })}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
        className={classes.modal}
      >
        <Fade in={modalReject.open}>
          <VoidReasonModal
            data={modalReject.data}
            removeItem={() => {
              setSnackBar({
                open: true,
                status: 'success',
                message: 'Borrador Rechazado',
              });
              props.reloadAction();
            }}
            closeModal={() => setModalReject({ open: false, data: {} })}
          />
        </Fade>
      </Modal>
      <Dialog
        fullScreen
        open={modalDraft.open}
        onClose={() => setModalDraft({ open: false, data: {} })}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setModalDraft({ open: false, data: {} })}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {`Borrador de factura ${modalDraft.data.company_name} - ${modalDraft.data.facturation_date}`}
            </Typography>
          </Toolbar>
        </AppBar>
        <DraftModal
          setSnackBar={setSnackBar}
          amount={modalDraft.data.total_amount}
          close={closeModals}
          id={modalDraft.data.id}
          client={modalDraft.data.company_name}
          data={modalDraft.data}
          tableType={tableType}
        />
      </Dialog>

      {dataTable.length !== 0 && (
        <MaterialTable
          title={'Borradores'}
          pageSize={20}
          pageSizeOptions={[20, 30, 50]}
          columns={setColumns()}
          onRowClick={(evt, selectedRow) =>
            setSelectedRow(selectedRow.tableData.id)
          }
          data={dataTable}
          actions={setActions()}
          options={{
            search: false,
            actionsColumnIndex: -1,
            filtering: true,
            rowStyle: (rowData) => ({
              backgroundColor: selectedRow === rowData.id ? '#EEE' : '#FFF',
            }),
            pageSize: 20,
            pageSizeOptions: [20, 30, 50],
          }}
          localization={{
            body: {
              emptyDataSourceMessage: 'No hay documentos por resolver',
            },
          }}
        />
      )}
      {dataTable.length === 0 &&
        (tableType === 'pending' || tableType === 'commented') && (
          <SuccessState text="No hay borradores para mostrar" />
        )}
      {dataTable.length === 0 &&
        (tableType === 'pre_aproved' ||
          tableType === 'approved' ||
          tableType === 'rejected') && (
          <NoDataState text="No hay borradores para mostrar" />
        )}
      {snackBar.open && (
        <Snackbar
          key="snackbar-table"
          isOpen={snackBar.open}
          message={snackBar.message}
          onDismiss={() =>
            setSnackBar({
              open: !snackBar.status,
              status: 'close',
              message: '',
            })
          }
          status={snackBar.status}
        />
      )}
      {editModalDraft.open && (
        <div>
          <ConfirmationModal
            open={editModalDraft.open}
            onClose={() => setEditModalDraft({ open: false, data: {} })}
            confirmButton={
              <Button
                onClick={() =>
                  editDraftTypeConfirmationClick(editModalDraft.data.id)
                }
              >
                Confirmar y cambiar
              </Button>
            }
          >
            <div className={classes.selectContainer}>
              <Select
                name="document_type"
                value={draftType}
                options={[
                  { value: 'estimate', label: 'Estimación' },
                  { value: 'invoice', label: 'Factura' },
                ]}
                label="Tipo de Borrador"
                onChange={(e) => setDraftType(e.value)}
              />
            </div>
          </ConfirmationModal>
        </div>
      )}
      <Snackbar
        isOpen={openSnack.open}
        message={openSnack.message}
        onDismiss={() =>
          setOpenSnack({ open: false, status: 'success', message: '' })
        }
        status={openSnack.status}
      />
    </div>
  );
};

export default DraftsTable;
