import React, { useState } from 'react';
import { dateSort } from '../../../utils/utils';
import MaterialTable from 'material-table';
import { post, get_blob } from '../../../utils/api';
import './styles.scss';
import { Button, Modal } from '@beetrack/hp-components';
import {
  Paper,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  attachmentDiv: {
    color: 'blue',
    textDecoration: 'underline',
    '&hover': {
      cursor: 'pointer',
    },
  },
});

export default (props) => {
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [extraEmails, setExtraEmails] = useState('');
  const [shownAttachments, setShownAttachments] = useState([]);
  const [attachmentOpenModal, setAttachmentOpenModal] = useState(false);

  const classes = useStyles();

  const columns = [
    {
      title: 'Documento',
      field: 'DocType',
      filtering: false,
    },
    {
      field: 'Id',
      hidden: true,
    },
    {
      title: 'Folio',
      field: 'DocNumber',
    },
    {
      title: 'Fecha Emisión',
      field: 'TxnDate',
    },
    {
      title: 'Desglose',
      field: 'CostSep',
    },
    {
      title: 'Monto Total $',
      field: 'TotalAmt',
      filtering: false,
    },
    {
      title: 'Balance $',
      field: 'Balance',
      filtering: false,
    },
    {
      title: 'Fecha de pago',
      field: 'PaymentDate',
      filtering: false,
    },
  ];

  const actions = [
    {
      icon: 'email',
      tooltip: 'Enviar Correo',
      onClick: (_, rowData) => {
        handleClickOpen(rowData);
      },
    },
    {
      icon: 'cloud_download',
      tooltip: 'Descargar Factura',
      onClick: (_, rowData) => {
        downloadDocument(rowData);
      },
    },
    {
      icon: 'more_vert',
      tooltip: 'Ver Archivos',
      onClick: (_, rowData) => {
        showAttachments(rowData);
      },
    },
  ];

  const showAttachments = (rowData) => {
    let matchedAttachments = [];

    props?.attachments?.forEach((attachment, index) => {
      if (
        attachment?.table?.attachable_ref[0]?.table.entity_ref?.table?.value ==
        rowData.Id
      ) {
        matchedAttachments.push({
          name: `Attachment ${index}`,
          url: attachment?.table?.temp_download_uri,
        });
      }
    });

    setShownAttachments(matchedAttachments);
    setAttachmentOpenModal(true);
  };

  const handleClickOpen = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
    setExtraEmails('');
  };

  const handleWriteEmail = (event) => {
    setExtraEmails(event.target.value);
  };

  const handleSendMail = () => {
    sendEmailData(selectedRow);
    handleClose();
  };

  const downloadDocument = (row) => {
    const docType = row.DocType === 'Factura' ? 'invoice' : 'estimate';
    get_blob(
      `/quickbooks/download_${docType}?${docType}_id=${row.Id}&deal_id=${props.dealId}`,
      'application/pdf',
    ).then((response) => {
      let file = new Blob([response.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    });
  };

  const sendEmailData = (row) => {
    let data = {
      deal_id: props.dealId,
      document_number: row.DocNumber,
      extra_emails: extraEmails,
    };
    post('/quickbooks/client/send_mail', data).then((response) => {
      if (response.status === 200) {
        props.message('Correo enviado Exitosamente');
      } else {
        props.message(response.data.message);
      }
    });
  };

  const downloadHistoricalDataCsv = () => {
    get_blob(
      `/quickbooks/download_historical_csv?deal_id=${props.dealId}`,
      'text/csv',
    ).then((response) => {
      let file = new Blob([response.data], { type: 'text/csv' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    });
  };

  return (
    <div>
      <Button
        onClick={() => downloadHistoricalDataCsv()}
        variant="contained"
        color="primary"
        startIcon={<CloudDownload />}
        style={{ margin: '0 auto', display: 'flex' }}
      >
        Descargar datos históricos en CSV
      </Button>
      <div style={{ paddingBottom: '20px' }} />
      <MaterialTable
        columns={columns}
        data={props.invoices.reverse()}
        actions={actions}
        title="Facturas"
        options={{
          filtering: true,
          paging: true,
          sorting: true,
          actionsColumnIndex: -1,
          maxBodyHeight: 800,
          pageSize: 15,
          pageSizeOptions: [5, 15, 25],
        }}
        components={{
          Container: (props) => (
            <Paper {...props} className="container__table" />
          ),
        }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Enviar factura</DialogTitle>
        <DialogContent>
          <DialogContentText>
            En caso de querer, puedes enviar la factura a un correo adicional,
            por lo que al hacer click en enviar, la factura se enviará a los
            correos predeterminados, sumado al que se escribe abajo.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Correo electrónico"
            type="email"
            fullWidth
            value={extraEmails}
            onChange={(e) => handleWriteEmail(e)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleSendMail}>Enviar</Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={attachmentOpenModal}
        onClose={() => {
          setShownAttachments([]);
          setAttachmentOpenModal(false);
        }}
      >
        {shownAttachments.map((attachment) => {
          return (
            <div
              onClick={() => window.open(attachment.url, '_blank')}
              className={classes.attachmentDiv}
            >
              {attachment.name}
            </div>
          );
        })}
      </Modal>
    </div>
  );
};
