import React, { useState, useEffect } from 'react';
// import CommentsTable from './Components/Table';
import {
  Form,
  FormRow,
  FormGroup,
  Button,
  Snackbar,
  Select,
  TextField,
} from '@beetrack/hp-components';
import { get, post } from '../../../utils/api';
import { countryToCurrency } from '../../../utils/utils';
import { useParams } from 'react-router-dom';
import { commercialConditionsCurrencies } from '../../../utils/const';
import { Redirect } from 'react-router-dom';

const defaultOtherCondition = [
  { name: '', price: '', currency: { label: 'UF', value: 'UF' } },
];

const PlanRelation = () => {
  // params from uri
  const params = useParams();

  // snackbar
  const [snackState, setSnackState] = useState({
    open: false,
    text: '',
    type: 'error',
  });

  const [conditions, setConditions] = useState([]);
  const [dealName, setDealName] = useState('');
  const [hubspotId, setHubspotId] = useState('');
  const [selectedPlans, setSelectedPlans] = useState([]);

  // base condition that is considered as "other" product
  const [otherConditions, setOtherConditions] = useState(defaultOtherCondition);

  // comments
  const [commentText, setCommentText] = useState('');

  const [validHubspotData, setValidHubspotData] = useState(false);
  const [currency, setCurrency] = useState('USD');

  const setObjState = (setter, key, value) => {
    setter((state) => ({ ...state, [key]: value }));
  };

  const onChangePlanHandler = (event) => {
    setSelectedPlans((event || []).map((x) => x.value));
  };

  const validateHubspotData = (hs_object_id, dealname) => {
    post('/commercial_conditions/validate_hubspot', {
      hs_object_id: hs_object_id,
      dealname: dealname,
    }).then((response) => {
      setValidHubspotData(response.data.exists);
      setCurrency(countryToCurrency(response.data.country));
    });
  };

  const getPlanData = (planIds) => {
    get(`/commercial_conditions?default=true&visible=true`).then((response) => {
      if (response.status === 200) {
        // we know by getting the plan by id we only have 1 plan in the response payload
        const data = response.data.data.plans.nodes;
        setConditions(data);
        setSelectedPlans(planIds);
      }
    });
  };

  const mapConditionsSelect = (conditions) => {
    return conditions.map((condition) => ({
      label: condition.name,
      value: condition.id,
    }));
  };

  const handleChangeOtherCondition = (value, index, key) => {
    const newData = [...otherConditions];
    newData[index][key] = value;
    setOtherConditions(newData);
  };

  const handleAddNewOther = (event) => {
    setOtherConditions((state) => [
      ...state,
      { name: '', price: '', currency: { label: 'UF', value: 'UF' } },
    ]);
  };

  const _baseOtherConditionMapper = (condition, product = 'other') => {
    return {
      name: condition.name,
      product: product,
      currency: condition.currency.value,
      condition: {
        base: {
          price: condition.price,
          qty: 1_000_000_000,
        },
      },
    };
  };

  const _otherCondGenerator = () => {
    return otherConditions
      .map((cond) => _baseOtherConditionMapper(cond))
      .filter(
        (cond) =>
          cond.name !== '' &&
          cond.condition.base.price !== '' &&
          cond.condition.base.price !== 0 &&
          cond.condition.base.currency !== '',
      );
  };

  const handleSavePlanRelation = () => {
    const payload = {
      other_plans: _otherCondGenerator(),
      plans: conditions.filter((cond) => selectedPlans.includes(cond.id)),
      hubspot_id: hubspotId,
      currency: currency,
      comment: commentText,
    };
    post('/commercial_conditions/create_plan_relations', payload).then(
      (response) => {
        if (response.status === 200) {
          var names = conditions
            .filter((cond) => selectedPlans.includes(cond.id))
            .map((cond) => `"${cond.name}"`);
          names.push(_otherCondGenerator().map((cond) => `"${cond.name}"`));
          names = names.join(', ');
          setSnackState({
            text: `Relación(es) de plan con deal creadas: ${names}`,
            open: true,
            type: 'success',
          });
        } else {
          setSnackState({
            text: response.data.errors[0].message,
            open: true,
            type: 'error',
          });
        }
      },
    );
  };

  useEffect(() => {
    getPlanData(params.ids.split(','));
  }, []);

  return (
    <Form title="Asociación Condición comercial">
      <p>
        Aquí podrás asociar una condición comercial, ya existente, a un deal de
        Hubspot.
      </p>
      {/* plan selection */}
      <FormRow>
        <FormGroup
          title="Planes/servicios a contratar por el cliente"
          collapsible={false}
        >
          <p>Revisa que lo seleccionado esté correcto:</p>
          <Select
            isMulti
            key="cc-plan"
            value={selectedPlans}
            options={mapConditionsSelect(conditions)}
            label="Plan(es) a utilizar"
            onChange={(e) => onChangePlanHandler(e)}
          />
        </FormGroup>
      </FormRow>
      {/* base condition */}
      <FormRow>
        <FormGroup
          title="Otros cobros (integración u otro)"
          collapsible={false}
        >
          <div key={'form-others'}>
            {otherConditions.map((cond, index) => {
              return (
                <div key={`form-row-${index}`}>
                  <FormRow key={`form-row-1-other-${index}`}>
                    <TextField
                      key={`other-name-${index}`}
                      placeholder="Mantención integración ABC"
                      label="Nombre"
                      helperText={'Ej: Mantención integración ABC'}
                      value={cond.name}
                      onChange={(e) =>
                        handleChangeOtherCondition(
                          e.target.value,
                          index,
                          'name',
                        )
                      }
                    />
                    <Select
                      key={`other-currency-${index}`}
                      options={commercialConditionsCurrencies}
                      label="Moneda"
                      value={cond.currency.value}
                      onChange={(e) =>
                        handleChangeOtherCondition(e, index, 'currency')
                      }
                    />
                  </FormRow>
                  <FormRow key={`form-row-2-other-${index}`}>
                    <TextField
                      key={`other-price-${index}`}
                      placeholder="Precio"
                      label="Precio"
                      type="number"
                      helperText={`Cobro mensual fijo en ${cond.currency.value}`}
                      value={cond.price}
                      onChange={(e) =>
                        handleChangeOtherCondition(
                          e.target.value === ''
                            ? 0
                            : parseFloat(e.target.value),
                          index,
                          'price',
                        )
                      }
                    />
                  </FormRow>
                </div>
              );
            })}
          </div>
          <FormRow>
            <Button type="text" onClick={(e) => handleAddNewOther(e)}>
              + Añadir costo adicional
            </Button>
          </FormRow>
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup
          title="Comentarios adicionales (serán ingresados en la notificación al Manager)."
          collapsible={true}
        >
          <FormRow>
            <TextField
              key="comments"
              textArea
              type="text"
              label={'Comentarios'}
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
            />
          </FormRow>
        </FormGroup>
      </FormRow>
      {/* hubspot data */}
      <FormRow>
        <FormGroup title="Ingresa datos del cliente" collapsible={false}>
          <FormRow>
            <TextField
              key="dealname"
              onChange={(e) => {
                setDealName(e.target.value);
                validateHubspotData(hubspotId, e.target.value);
              }}
              placeholder="Transportes Abejita"
              label="Nombre del deal"
              value={dealName}
              helperText={
                hubspotId === '' || dealName === ''
                  ? 'Ingresa el nombre de la misma forma que está ingresado en Husbpot'
                  : validHubspotData
                  ? 'Ingresa el nombre de la misma forma que está ingresado en Husbpot'
                  : 'El nombre del deal no calza con el ID de hubspot'
              }
              error={
                hubspotId === '' || dealName === '' ? false : !validHubspotData
              }
            />
            <TextField
              key="hs_object_id"
              onChange={(e) => {
                setHubspotId(e.target.value);
                validateHubspotData(e.target.value, dealName);
              }}
              placeholder="ID Deal en Hubspot"
              label="ID Deal en Hubspot"
              type="number"
              value={hubspotId}
              helperText={
                hubspotId === '' || dealName === ''
                  ? 'Solo se permiten números '
                  : validHubspotData
                  ? 'Solo se permiten números '
                  : 'El ID de hubspot no existe o no calza con el nombre del deal'
              }
              error={
                hubspotId === '' || dealName === '' ? false : !validHubspotData
              }
            />
          </FormRow>
        </FormGroup>
      </FormRow>
      {/* save button */}
      <FormRow>
        <Button
          disabled={
            !(
              !(selectedPlans.length === 0) &&
              // test if there is a plan name
              dealName !== '' &&
              // test f there is a valid number
              hubspotId !== '' &&
              validHubspotData
            )
          }
          onClick={(e) => handleSavePlanRelation()}
        >
          Guardar
        </Button>
        <Snackbar
          key="snackbar"
          isOpen={snackState.open}
          message={snackState.text}
          onDismiss={() => setSnackState({ ...!open })}
          status={snackState.type}
        />
      </FormRow>
      {snackState.type === 'success' && (
        <Redirect
          to={{
            pathname: '/sales/commercial-conditions',
            state: { payload: { ...snackState } },
          }}
        />
      )}
    </Form>
  );
};

export default PlanRelation;
