import React, { useState } from 'react';
import {
  FormRow,
  FormGroup,
  TextField,
  FormInfoField,
  Checkbox,
  InfoBox,
} from '@beetrack/hp-components';
import '../../../styles.scss';
import NumberFormat from 'react-number-format';
import { calculateMoneyEquivalent } from '../../../Calculator';

const metaForm = (plan, idx, setter, prepaidIndex = 0, discountIdx = 0) => {
  const priceRange = calculateMoneyEquivalent(
    plan.condition,
    plan.prepaids[prepaidIndex].qty,
  );

  const qty = plan.prepaids[prepaidIndex].qty;
  const price = priceRange.price;
  const discount = plan.discounts[discountIdx].percentage;
  const months = plan.prepaids[prepaidIndex].delta;

  const totalAmount = ((price * qty * months * (100 - discount)) / 100).toFixed(
    2,
  );

  return (
    <div>
      <FormRow additionalClassName="grid">
        <InfoBox status="info" additionalClassName={'grid__item-4'}>
          <p>
            ¡Importante! Una vez que hagas click en "Renovar prepago", se
            gatillará la factura al cliente por el nuevo periodo. Una vez que
            esta factura esté pagada, la renovación estará realizada. Si el
            cliente no paga dicha factura (prepago), se emitirá una factura
            (post-pago) por el mes siguiente. Si el cliente no paga dicha
            factura, entrará al flujo de desactivación automática de la cuenta.
          </p>
        </InfoBox>
        {/* empty spacing is now allowed so we use a blank text */}
        <p style={{ color: 'white' }}>123</p>
        <NumberFormat
          customInput={TextField}
          prefix={`${plan.currency} `}
          additionalClassName="custom-disabled grid__item-6"
          placeholder="Cantidad equivalente en facturación con descuento"
          label={`Monto total a pagar (${qty} un. x ${months} meses x ${price} ${plan.currency} / un.) - ${discount} % dscto.`}
          value={totalAmount}
          type="text"
          allowNegative={false}
          isNumericString={true}
          decimalSeparator={','}
          thousandSeparator={'.'}
          readOnly={true}
          helperText={`Monto total con descuento de ${discount} %`}
        />
        <FormInfoField
          title="Sobre el descuento"
          content="El descuento se aplicará solamente en la primera factura que se emita para este prepago, luego de esto se tendrán que crear descuentos nuevos"
          additionalClassName="grid__item-1"
          position="bottom"
          fullWidth={false}
        />
      </FormRow>
    </div>
  );
};

export function generateDiscount(plan, idx, setter) {
  if (!plan.discount || !plan.prepaid) {
    return <></>;
  }

  return (
    <FormGroup title="Descuento" collapsible={false}>
      {metaForm(plan, idx, setter, 0, 0)}
    </FormGroup>
  );
}

export const GenerateDiscount = (props) => {
  const { plan, setPlan, prepaidIndex, discountIdx } = props;
  const [useDiscount, setUseDiscount] = useState(false);

  const _setPlanPrepaidDiscountNew = (buttonState) => {
    const newPlan = { ...plan };
    newPlan.discount = buttonState;
    setUseDiscount(buttonState);
    setPlan(newPlan);
  };

  return (
    <>
      <FormRow additionalClassName="grid">
        <TextField floating disabled additionalClassName="grid__item-3" />
        <Checkbox
          additionalClassName="grid__item-6"
          label="¿Descuento?"
          checked={useDiscount}
          onChange={(e) => _setPlanPrepaidDiscountNew(!useDiscount)}
          name="checkbox"
        />
      </FormRow>
      {useDiscount ? (
        <FormGroup title={<div>Descuento </div>} collapsible={false}>
          {metaForm(plan, 0, null, prepaidIndex, discountIdx)}
        </FormGroup>
      ) : (
        <></>
      )}
    </>
  );
};
