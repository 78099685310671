import React, { useState, useEffect } from 'react';
import {
  Page,
  Button,
  Loading,
  FormRow,
  Form,
  TextField,
  Select,
  Snackbar,
} from '@beetrack/hp-components';
import { get, post } from '../../../utils/api';

const AddResources = (props) => {
  const { history } = props;
  const id = props.match.params.id;
  const [user, setUser] = useState({});
  const [resourceType, setResourceType] = useState('');
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [brand, setBrand] = useState('');
  const [model, setModel] = useState('');
  const [identifier, setIdentifier] = useState('');
  const [objectType, setObjectType] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    status: 'success',
  });
  const [sendingForm, setSendingForm] = useState(false);

  const options = [
    { label: 'Acceso', value: 'access' },
    { label: 'Inventario', value: 'item' },
  ];

  const itemOptions = [
    { label: 'Laptop', value: 'Laptop' },
    { label: 'Cargador', value: 'Cargador' },
    { label: 'Monitor', value: 'Monitor' },
    { label: 'Celular', value: 'Celular' },
    { label: 'Tarjeta de acceso', value: 'Tarjeta de acceso' },
    { label: 'Huella', value: 'Huella' },
    { label: 'Mouse', value: 'Mouse' },
    { label: 'Teclado', value: 'Teclado' },
    { label: 'Adaptador computadora', value: 'Adaptador computadora' },
    { label: 'Llaves', value: 'Llaves' },
  ];

  const formSubmit = () => {
    setSendingForm(true);
    if (name === '') {
      setSnackbar({
        open: true,
        message: 'Faltan campos por completar',
        status: 'error',
      });
      setSendingForm(false);
    } else {
      if (resourceType === 'access') {
        var payload = {
          userId: id,
          name,
          resourceType,
          description,
        };
      } else {
        var payload = {
          userId: id,
          name,
          resourceType,
          description,
          brand,
          model,
          identifier,
          objectType,
        };
      }
      post('/resources', payload).then((response) => {
        if (response.status === 201) {
          setSnackbar({
            open: true,
            message: 'Recurso creado',
            status: 'success',
          });
          history.push(`/hr/resources/${id}`);
        } else {
          setSnackbar({
            open: true,
            message: 'Recurso no creado, revisa los campos',
            status: 'error',
          });
        }
      });
      setSendingForm(false);
    }
  };
  useEffect(() => {
    get(`/resources/${id}`).then((response) => {
      if (response.status == 200) {
        setUser(response.data.user.data.attributes);
        setLoading(false);
      }
    });
  }, []);
  return (
    <div>
      <Snackbar
        key="snackbar"
        isOpen={snackbar.open}
        message={snackbar.message}
        status={snackbar.status}
        onDismiss={() =>
          setSnackbar({
            open: false,
            status: snackbar.status,
            message: snackbar.message,
          })
        }
      />
      {!loading && (
        <Page
          onGoBack={() => history.push(`/hr/resources/${id}`)}
          title={'Volver'}
        >
          <br />
          <Form
            title={`Nuevo recurso para ${user.name}`}
            id="form"
            buttons={
              <Button disabled={sendingForm} onClick={() => formSubmit()}>
                Crear
              </Button>
            }
          >
            <FormRow>
              <Select
                key="item-select"
                value={name}
                options={itemOptions}
                label="Nombre"
                onChange={(option) => {
                  setName(option.value);
                }}
              />
              <Select
                label="Tipo de recurso"
                options={options}
                value={resourceType}
                onChange={(option) => setResourceType(option.value)}
              />
            </FormRow>
            <FormRow>
              <TextField
                value={description}
                label="Información adicional"
                name="description"
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormRow>
            {resourceType === 'item' && (
              <>
                <FormRow>
                  <TextField
                    value={brand}
                    onChange={(e) => setBrand(e.target.value)}
                    label="Marca"
                    name="brand"
                  />
                  <TextField
                    value={model}
                    onChange={(e) => setModel(e.target.value)}
                    label="Modelo"
                    name="model"
                  />
                </FormRow>
                <FormRow>
                  <TextField
                    value={identifier}
                    onChange={(e) => setIdentifier(e.target.value)}
                    label="Identificador"
                    name="identifier"
                  />
                </FormRow>
              </>
            )}
          </Form>
        </Page>
      )}
      {loading && (
        <center>
          <Loading hasText />
        </center>
      )}
    </div>
  );
};

export default AddResources;
