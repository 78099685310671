// finds path of a key inside a nested object
// data = {
//   a: 1,
//   b: {
//     c: 2,
//     d: {
//       e: 3
//     }
//   },
// };
// key = e
// answer: ["b", "d", "e"]

export function findKeyPath(data, key) {
  let result = [];
  for (let i in data) {
    if (i === key) {
      result.push(i);
      return result;
    }
    if (typeof data[i] === 'object') {
      let path = findKeyPath(data[i], key);
      if (path.length) {
        result = result.concat(i, path);
        return result;
      }
    }
  }
  return result;
}

// changes value of a property inside an object
// object = {
//   a: 1,
//   b: {
//     c: 2,
//     d: {
//       e: 3
//     }
//   },
// };
// properties = ["b", "d", "e"]
// newValue = 100

// object = {
//   a: 1,
//   b: {
//     c: 2,
//     d: {
//       e: 100
//     }
//   },
// };
export function updateNestedObject(obj, path, value) {
  if (path.length === 0) {
    return value;
  }
  let key = path[0];
  let newPath = path.slice(1);
  return {
    ...obj,
    [key]: updateNestedObject(obj[key], newPath, value),
  };
}

export function sortKeys(obj) {
  const keys = Object.keys(obj);
  const sortedKeys = []
    .concat(...keys)
    .filter((key) => key !== 'title')
    .filter((key) => key !== 'email')
    .sort((a, b) => {
      const aNum = a.match(/\d+/)[0];
      const bNum = b.match(/\d+/)[0];
      return aNum - bNum;
    });
  return sortedKeys;
}

export function addNewKey(obj, keys, newKey, newValue) {
  if (keys.length === 0) {
    obj[newKey] = newValue;
    return obj;
  }
  const currentKey = keys.shift();
  obj[currentKey] = addNewKey(obj[currentKey], keys, newKey, newValue);
  return obj;
}

export function getNestedValue(obj, keys) {
  let current = obj;
  for (let i = 0; i < keys.length; i++) {
    current = current[keys[i]];
    if (current === undefined) {
      return undefined;
    }
  }
  return current;
}

export function deleteNestedProperty(obj, keys) {
  if (keys.length === 0) {
    return obj;
  }

  let key = keys[0];
  let newKeys = keys.slice(1);
  if (!obj[key]) {
    return obj;
  }
  if (newKeys.length === 0) {
    let newObj = { ...obj };
    delete newObj[key];
    return newObj;
  }
  return {
    ...obj,
    [key]: deleteNestedProperty(obj[key], newKeys),
  };
}

export function getMaxKey(obj, letter = 'G') {
  let largestNumber = 0;
  for (let key in obj) {
    if (key.match(new RegExp(`^${letter}-\\d+$`))) {
      let currentNumber = parseInt(key.split('-')[1]);
      if (currentNumber > largestNumber) {
        largestNumber = currentNumber;
      }
    } else if (typeof obj[key] === 'object') {
      let nestedLargestNumber = getMaxKey(obj[key], letter);
      if (nestedLargestNumber > largestNumber) {
        largestNumber = nestedLargestNumber;
      }
    }
  }
  return largestNumber;
}

export const newTextElement = {
  type: 'text',
  question: '',
  title: '',
  propertyName: '',
  placeHolder: '',
  helperText: '',
  imgUrl: '',
  required: false,
};

export const newBigTextElement = {
  type: 'bigText',
  question: '',
  title: '',
  propertyName: '',
  placeHolder: '',
  helperText: '',
  imgUrl: '',
  required: false,
};

export const newNumberElement = {
  type: 'number',
  question: '',
  title: '',
  propertyName: '',
  placeHolder: '',
  helperText: '',
  imgUrl: '',
  required: false,
};

export const newDateElement = {
  type: 'date',
  question: '',
  title: '',
  propertyName: '',
  placeHolder: '',
  helperText: '',
  imgUrl: '',
  required: false,
};

export const newSimpleElement = (optionID) => {
  return {
    type: 'simple',
    question: '',
    title: '',
    propertyName: '',
    helperText: '',
    imgUrl: '',
    required: false,
    emptyOption: false,
    options: { [`O-${optionID}`]: { label: '', value: '' } },
  };
};

export const newMultipleElement = (optionID) => {
  return {
    type: 'multiple',
    question: '',
    title: '',
    propertyName: '',
    helperText: '',
    imgUrl: '',
    required: false,
    options: { [`O-${optionID}`]: { label: '', value: '' } },
  };
};

export const newDependantElement = (optionID) => {
  return {
    type: 'dependant',
    question: '',
    title: '',
    propertyName: '',
    helperText: '',
    imgUrl: '',
    required: false,
    options: { [`O-${optionID}`]: { label: '', value: '', set: {} } },
  };
};

export const newFileElement = {
  type: 'file',
  question: '',
  fileName: '',
  helperText: '',
  extentions: [],
  required: false,
};
export const checkFields = (components, filled) => {};

export const reOrder = (result, components, setComponents) => {
  const { source, destination, type } = result;
  let obj = components;

  // Base condition
  if (!destination) {
    return;
  } else if (
    source.index === destination.index &&
    source.droppableId === destination.droppableId
  ) {
    return;
  }

  // change position if type is ELEMENT
  if (type === 'ELEMENT') {
    const endList = sortKeys(obj[destination.droppableId]);
    const startList = sortKeys(obj[source.droppableId]);
    const endKey = endList[destination.index];

    // Change position on same Group
    if (source.droppableId === destination.droppableId) {
      const startKey = endList[source.index];
      const auxValue = obj[source.droppableId][startKey];
      if (source.index > destination.index) {
        for (let i = source.index; i > destination.index; i--) {
          const currentKey = endList[i];
          const prevKey = endList[i - 1];
          obj[source.droppableId][currentKey] =
            obj[source.droppableId][prevKey];
        }
      } else if (source.index < destination.index) {
        for (let i = source.index; i < destination.index; i++) {
          const currentKey = endList[i];
          const nextKey = endList[i + 1];
          obj[source.droppableId][currentKey] =
            obj[source.droppableId][nextKey];
        }
      }
      obj[source.droppableId][endKey] = auxValue;

      // Change position of elements in different groups
    } else {
      const startKey = startList[source.index];
      const endKey = endList[destination.index];
      const auxValue = obj[source.droppableId][startKey];
      if (auxValue && endKey === undefined) {
        obj[destination.droppableId][startKey] = auxValue;
        delete obj[source.droppableId][startKey];
      } else {
        const Newkey = `E-${getMaxKey(components, 'E') + 1}`;
        obj[destination.droppableId][Newkey] =
          obj[source.droppableId][startKey];
        delete obj[source.droppableId][startKey];
        const list = sortKeys(obj[destination.droppableId]);
        for (let i = list.length - 1; i > destination.index; i--) {
          const currentKey = list[i];
          const prevKey = list[i - 1];
          obj[destination.droppableId][currentKey] =
            obj[destination.droppableId][prevKey];
        }
        obj[destination.droppableId][endKey] = auxValue;
      }
    }
    setComponents(obj);
    return;
    // change position if type is GROUP
  } else if (type === 'GROUP') {
    const startKey = sortKeys(obj)[source.index];
    const endKey = sortKeys(obj)[destination.index];
    const auxStart = obj[startKey];
    const auxEnd = obj[endKey];
    obj[startKey] = auxEnd;
    obj[endKey] = auxStart;
    setComponents(obj);

    // change position if type is OPTION
  } else if (type === 'OPTION') {
    if (source.droppableId !== destination.droppableId) {
      alert('No se pueden cambiar opciones entre elementos :c');
      return;
    } else {
      const path = findKeyPath(components, source.droppableId);
      const startKey = sortKeys(
        getNestedValue(components, [...path, 'options']),
      )[source.index];
      const endKey = sortKeys(getNestedValue(components, [...path, 'options']))[
        destination.index
      ];
      const auxStart = getNestedValue(components, [
        ...path,
        'options',
        startKey,
      ]);
      const auxEnd = getNestedValue(components, [...path, 'options', endKey]);
      obj = updateNestedObject(obj, [...path, 'options', startKey], auxEnd);
      obj = updateNestedObject(obj, [...path, 'options', endKey], auxStart);
      setComponents(obj);
    }
  }
  return;
};

export function renameElement(obj, eStart, oStart) {
  let newObj = {};

  for (let key in obj) {
    let newKey = key;

    if (key.startsWith('E-')) {
      let num = parseInt(key.split('-')[1], 10);
      newKey = 'E-' + (eStart + num - 1);
    } else if (key.startsWith('O-')) {
      let num = parseInt(key.split('-')[1], 10);
      newKey = 'O-' + (oStart + num - 1);
    }

    if (typeof obj[key] === 'object' && obj[key] !== null) {
      newObj[newKey] = renameElement(obj[key], eStart, oStart);
    } else {
      newObj[newKey] = obj[key];
    }
  }

  return newObj;
}

export function checkPropertyNames(obj) {
  // Iterate over the keys in the object
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const val = obj[key];

      // Check if the current object has a "propertyName" key with an empty value
      if (
        typeof val === 'object' &&
        val !== null &&
        val.hasOwnProperty('propertyName') &&
        val.propertyName === ''
      ) {
        return false;
      }

      // Recursively check any nested objects
      if (typeof val === 'object' && val !== null) {
        if (!checkPropertyNames(val)) {
          return false;
        }
      }
    }
  }

  // All "propertyName" keys have a value, return true
  return true;
}
