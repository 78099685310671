import React, { useState, useEffect } from 'react';
import {
  Snackbar,
  LoadingPage,
  Page,
  Button,
  Modal,
  InfoBox,
  DatePicker,
  TextField,
  Tabs,
  TabItem,
  Tooltip,
} from '@beetrack/hp-components';
import { Document, Chat } from '@beetrack/hp-icons-react';
import { get, post } from '../../../utils/api';
import Grid from '@material-ui/core/Grid';
import SupplierCard from './Components/Card';
import InvoiceTableSupplier from './Components/Table';
import { Receipt } from '@material-ui/icons';
import moment from 'moment';
import FilePicker, { useFilePicker } from '@beetrack/hp-file-picker-react';
import BillsTable from './Components/BillsTable';

const Supplier = (props) => {
  const { history } = props;
  const permissionType = props.location.search;
  const id = props.match.params.id;
  const [supplier, setSupplier] = useState({ data: { name: '' } });
  const [quickbooksBills, setQuickbooksBills] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(0);
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [dueDate, setDueDate] = useState(moment().format('DD-MM-YYYY'));
  const [amount, setAmount] = useState('');
  const [bills, setBills] = useState([]);
  const [disabledButton, setDisabledButton] = useState(false);
  const { handleChangeFile, filePickerProps } = useFilePicker({
    allowedExtensions: ['.pdf'],
  });
  const [snack, setSnack] = useState({
    open: false,
    message: '',
    status: 'success',
  });

  const onGoBack = () => {
    history.push(`/suppliers${permissionType}`);
  };

  const uploadBill = () => {
    setDisabledButton(true);
    const payload = {
      supplier_id: id,
      dueDate,
      billFile: filePickerProps.file[0],
      permission: permissionType.replace('?', ''),
      amount,
    };
    var formData = new FormData();
    for (const [key, value] of Object.entries(payload)) {
      formData.append(key, value);
    }
    post('/bills', formData).then((response) => {
      if (response.status === 200) {
        setRefresh(refresh + 1);
        setInvoiceModal(false);
        setDisabledButton(false);
        setAmount('');
        setDueDate(useState(moment().format('DD-MM-YYYY')));
        setSnack({
          open: true,
          status: 'success',
          message: 'Factura de proveedor enviada correctamente',
        });
        setRefresh(refresh + 1);
      } else {
        setInvoiceModal(false);
        setDisabledButton(false);
        setSnack({
          open: true,
          status: 'error',
          message: 'Problema creando factura, intente nuevamente',
        });
      }
    });
  };
  useEffect(() => {
    get('/suppliers/' + id).then((response) => {
      if (response.status === 200) {
        setSupplier(response.data.supplier);
        setQuickbooksBills(response.data.quickbooks_bills);
        setBills(response.data.bills);
        setLoading(false);
      }
    });
  }, [refresh]);
  return (
    <div>
      <Snackbar
        key="snackbar"
        isOpen={snack.open}
        message={snack.message}
        onDismiss={() =>
          setSnack({ open: false, message: '', status: snack.status })
        }
        status={snack.status}
      />
      <Page
        onGoBack={onGoBack}
        title="volver"
        buttons={[
          <Button icon={<Receipt />} onClick={() => setInvoiceModal(true)}>
            {permissionType === 'accounting'
              ? 'Validar factura'
              : 'subir factura'}
          </Button>,
        ]}
      >
        <Modal
          key="invoice-modal"
          open={invoiceModal}
          title={`Agregar factura ${supplier.data.name}`}
          onClose={() => setInvoiceModal(false)}
          confirmButton={
            <Button disabled={disabledButton} onClick={() => uploadBill()}>
              {' '}
              Subir{' '}
            </Button>
          }
        >
          <InfoBox status="info">
            {permissionType === '?accounting' &&
              'Se subirá la factura a Hive y se enviará una solicitud de confirmación al owner del proveedor.'}
            {permissionType === '?team_leader' &&
              'Se subirá la factura a Hive y se enviará al equipo de payments para su pago.'}
          </InfoBox>
          <p> Fecha de vencimiento </p>
          <DatePicker
            date={dueDate}
            onChange={(value) => {
              setDueDate(value);
            }}
          />
          <p> Monto </p>
          <TextField
            label="Ingrese monto de factura"
            onChange={(e) => setAmount(e.target.value)}
            value={amount}
          />
          <p> Factura </p>
          <FilePicker
            onChange={handleChangeFile}
            value={filePickerProps.file}
            {...filePickerProps}
          />
        </Modal>
        {!loading && (
          <Grid container spacing={1}>
            <Grid item xs>
              <SupplierCard supplier={supplier} />
            </Grid>
            <Grid item xs>
              <Tabs>
                <TabItem
                  label="Facturas Quickbooks"
                  key={0}
                  icon={
                    <Tooltip
                      position="bottom"
                      title="Facturas Quickbooks"
                      content="Esta tabla muestra las facturas ingresadas en Quickbooks."
                    >
                      <Document />
                    </Tooltip>
                  }
                >
                  <InvoiceTableSupplier bills={quickbooksBills} />
                </TabItem>
                <TabItem
                  label="Facturas Ingresadas"
                  key={1}
                  icon={
                    <Tooltip
                      position="bottom"
                      title="Facturas Ingresadas"
                      content="Esta tabla muestra las facturas ingresadas en Hive."
                    >
                      <Chat />
                    </Tooltip>
                  }
                >
                  <BillsTable
                    bills={bills}
                    permissionType={permissionType}
                    setSnack={setSnack}
                    reload={refresh}
                    setReload={setRefresh}
                  />
                </TabItem>
              </Tabs>
            </Grid>
          </Grid>
        )}
      </Page>
      {loading && <LoadingPage text="Cargando..." />}
    </div>
  );
};

export default Supplier;
