const axios = require('axios');
const api_version = '/api/v1';
const api_external_version = '/api/external/v1';
const base_hive_url = process.env.BASE_HIVE_URL;
const sign_in = '/login';
const sign_out = '/logout';
const password_forgot = '/password/forgot';
const password_reset = '/password/reset';
const ping = '/ping';
const auth = '/auth/';
const oauth = '/login';
const countriesApi = 'https://restcountries.eu/rest/v2/all';
const citiesApi = 'https://clientformcities.s3.us-west-2.amazonaws.com/';

export function login(email, password) {
  return axios
    .post(api_version + sign_in, {
      user: {
        email: email,
        password: password,
      },
    })
    .then(function (response) {
      saveAuthorization(response);
      return response;
    })
    .catch(function (error) {
      let response = {
        status: error.response.status,
        data: error.response.data,
      };
      return response;
    });
}

export function forgot_password(email) {
  return axios
    .post(api_version + password_forgot, {
      email: email,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      let response = {
        status: error.response.status,
        data: error.response.data,
      };
      return response;
    });
}

export function reset_password(token, password) {
  return axios
    .post(api_version + password_reset, {
      token: token,
      password: password,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      let response = {
        status: error.response.status,
        data: error.response.data,
      };
      return response;
    });
}

export function logout(jwt) {
  return axios
    .delete(api_version + sign_out, {
      headers: {
        Authorization: jwt,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export function test(jwt) {
  return axios
    .get(api_version + ping, {
      headers: {
        Authorization: jwt,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      let response = {
        status: error.response.status,
        data: error.response.data,
      };
      return response;
    });
}

export function register(provider, jwt) {
  return axios
    .get(api_version + auth + provider + oauth, {
      headers: {
        Authorization: jwt,
      },
    })
    .then(function (response) {
      if (response.status === 200) {
        window.location.replace(response.data['data']);
        return response;
      } else {
        return response;
      }
    })
    .catch(function (error) {
      let response = {
        status: error.response.status,
        data: error.response.data,
      };
      return response;
    });
}

export function get(path) {
  let jwt = localStorage.getItem('jwt_token');
  return axios
    .get(api_version + path, {
      headers: {
        Authorization: jwt,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      let response = {
        status: error.response.status,
        data: error.response.data,
      };
      return response;
    });
}

export function get_blob(path, accept_type = 'application/json') {
  let jwt = localStorage.getItem('jwt_token');
  return axios
    .get(api_version + path, {
      headers: {
        Authorization: jwt,
        Accept: accept_type,
      },
      responseType: 'blob',
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      let response = {
        status: error.response.status,
        data: error.response.data,
      };
      return response;
    });
}

export function payments_get(path) {
  return axios
    .get(base_hive_url + api_version + path, {
      headers: {
        Accept: 'application/json',
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      let response = {
        status: error.response.status,
        data: error.response.data,
      };
      return response;
    });
}

export function hr_get(path) {
  return axios
    .get(base_hive_url + api_version + path, {
      headers: {
        Accept: 'application/json',
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      let response = {
        status: error.response.status,
        data: error.response.data,
      };
      return response;
    });
}

export function hr_new_get(path) {
  return axios
    .get(api_version + path, {
      headers: {
        Accept: 'application/json',
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      let response = {
        status: error.response.status,
        data: error.response.data,
      };
      return response;
    });
}

export function del(path) {
  let jwt = localStorage.getItem('jwt_token');
  return axios
    .delete(api_version + path, {
      headers: {
        Authorization: jwt,
      },
    })
    .then(function (response) {
      if (response.status === 200) {
        return response;
      } else {
        return 'Error';
      }
    })
    .catch(function (error) {
      let response = {
        status: error.response.status,
        data: error.response.data,
      };
      return response;
    });
}

export function post(path, data, external = false) {
  let jwt = localStorage.getItem('jwt_token');
  let csrf = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content');

  let api_url = api_version;
  if (external) {
    api_url = api_external_version;
  }

  return axios
    .post(api_url + path, data, {
      headers: {
        Authorization: jwt,
        'X-CSRF-Token': csrf,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      let response = {
        status: error.response.status,
        data: error.response.data,
      };
      return response;
    });
}

export function patch(path, data, external = false) {
  let jwt = localStorage.getItem('jwt_token');
  let csrf = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content');

  let api_url = api_version;
  if (external) {
    api_url = api_external_version;
  }

  return axios
    .patch(api_url + path, data, {
      headers: {
        Authorization: jwt,
        'X-CSRF-Token': csrf,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      let response = {
        status: error.response.status,
        data: error.response.data,
      };
      return response;
    });
}

export function payments_post(path, data) {
  return axios
    .post(base_hive_url + api_version + path, data, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    .then(function (response) {
      if (response.status === 200) {
        return response;
      } else {
        return 'Error';
      }
    })
    .catch(function (error) {
      let response = {
        status: error.response.status,
        data: error.response.data,
      };
      return response;
    });
}

export function hr_new_post(path, data) {
  return axios
    .post(api_version + path, data, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    .then(function (response) {
      if (response.status === 200) {
        return response;
      } else {
        return 'Error';
      }
    })
    .catch(function (error) {
      let response = {
        status: error.response.status,
        data: error.response.data,
      };
      return response;
    });
}

export function hr_post(path, data) {
  return axios
    .post(base_hive_url + api_external_version + path, data, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    .then(function (response) {
      if (response.status === 200) {
        return response;
      } else {
        return 'Error';
      }
    })
    .catch(function (error) {
      let response = {
        status: error.response.status,
        data: error.response.data,
      };
      return response;
    });
}

export function hr_put(path, data) {
  return axios
    .put(base_hive_url + api_version + path, data, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    .then(function (response) {
      if (response.status === 200) {
        return response;
      } else {
        return 'Error';
      }
    })
    .catch(function (error) {
      let response = {
        status: error.response.status,
        data: error.response.data,
      };
      return response;
    });
}

export function put(path, data) {
  return axios
    .put(api_version + path, data, headers())
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      let response = {
        status: error.response.status,
        data: error.response.data,
      };
      return response;
    });
}

export function external_api_post(path, data) {
  return axios
    .post(`${api_external_version}/${path}`, data, {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      let response = {
        status: error.response.status,
        data: error.response.data,
      };
      return response;
    });
}

export function destroy(path) {
  return axios
    .delete(api_version + path, headers())
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      let response = {
        status: error.response.status,
        data: error.response.data,
      };
      return response;
    });
}

export function countries(params = [], names = []) {
  let fields =
    params.length > 0 ? '?fields=' + params.toString().replace(',', ';') : '';
  return axios.get(countriesApi + fields).then((response) => {
    let data = response.data;
    return bindingData(params, names, data);
  });
}

function saveAuthorization(response) {
  localStorage.setItem('jwt_token', response.headers.authorization);
}

function headers() {
  let jwt = localStorage.getItem('jwt_token');
  return {
    headers: {
      Authorization: jwt,
    },
  };
}

function bindingData(dataFields, returnFields, data) {
  return data.map((country) => {
    let countryData = {};
    if (dataFields.length > 0) {
      dataFields.forEach((field, index) => {
        countryData[returnFields[index]] = country[field];
      });
    } else {
      countryData = country;
    }
    return countryData;
  });
}

export function clientFormGet(path) {
  return axios
    .get(base_hive_url + api_version + path, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      let response = {
        status: error.response.status,
        data: error.response.data,
      };
      return response;
    });
}

export function clientFormPost(path, data, external = false) {
  return axios
    .post(base_hive_url + api_version + path, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      let response = {
        status: error.response.status,
        data: error.response.data,
      };
      return response;
    });
}

const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export function getCities(code) {
  return axios
    .get(citiesApi + code + '.json', {
      headers: {
        Accept: '*/*',
        'Access-Control-Allow-Origin': '*',
      },
    })
    .then(function (response) {
      const data = Object.keys(response.data).map((key) => {
        return {
          ['value']: capitalizeFirstLetter(key),
          ['label']: capitalizeFirstLetter(key),
        };
      });
      return data;
    });
}

export function delForm(path) {
  let jwt = localStorage.getItem('jwt_token');
  let csrf = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content');

  return axios
    .delete(api_version + path, {
      headers: {
        Authorization: jwt,
        'X-CSRF-Token': csrf,
      },
    })
    .then(function (response) {
      if (response.status === 200) {
        return response;
      } else {
        return 'Error';
      }
    })
    .catch(function (error) {
      let response = {
        status: error.response.status,
        data: error.response.data,
      };
      return response;
    });
}

export function putForm(path, data) {
  let jwt = localStorage.getItem('jwt_token');
  let csrf = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content');

  return axios
    .put(api_version + path, data, {
      headers: {
        Authorization: jwt,
        'X-CSRF-Token': csrf,
      },
    })
    .then(function (response) {
      if (response.status === 201) {
        return response;
      } else {
        return { Error: [response.status] };
      }
    })
    .catch(function (error) {
      let response = {
        status: error.response.status,
        data: error.response.data,
      };
      return response;
    });
}
