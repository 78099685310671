import React from 'react';

import { Grid } from '@material-ui/core';
import {
  TextField,
  ActionsList,
  Switch,
  Select,
} from '@beetrack/hp-components';
import { Delete, FileCopy } from '@beetrack/hp-icons-react';
import Collapse from '@mui/material/Collapse';
import { getNestedValue } from '../utils';

import { useStyles } from '../Styles/styles';

const FileElement = (props) => {
  const {
    components,
    path,
    elementID,
    indexElement,
    type,
    textChangeHandler,
    switchHandler,
    deleteElement,
    collapsed,
    setCollapsed,
    draggableProvided,
    handleClone,
    fileTypeChangeHandler,
  } = props;

  const classes = useStyles();

  const fileOptions = [
    { value: '.pdf', label: '.pdf' },
    { value: '.xls', label: '.xls' },
    { value: '.xlsx', label: '.xlsx' },
    { value: '.xlsm', label: '.xlsm' },
  ];

  return (
    <div className={classes.elementContainer}>
      <div
        className={classes.introElement}
        {...(draggableProvided && draggableProvided.dragHandleProps)}
      >
        <p className={classes.subTitle}>
          {getNestedValue(components, [...path, 'question'])
            ? getNestedValue(components, [...path, 'question'])
            : `Elemento: ${indexElement}`}
          {` (${type})`}
          <a className={classes.red}>
            {getNestedValue(components, [...path, 'required']) ? '*' : ''}
          </a>
        </p>
        <ActionsList
          actions={[
            {
              title: 'Eliminar Elemento',
              onClick: () => deleteElement(path),
              icon: <Delete />,
            },
            {
              title: 'Clonar pregunta',
              onClick: () => handleClone(path),
              icon: <FileCopy />,
            },
          ]}
          launcherType="tertiary"
        />
        <p className={classes.collapsable}>
          <a
            onClick={() =>
              setCollapsed({
                ...collapsed,
                [elementID]: !collapsed[elementID],
              })
            }
          >
            {!collapsed[elementID] ? 'Ver más +' : 'Ver menos'}
          </a>
        </p>
      </div>
      <div className={classes.container}>
        <Collapse in={collapsed[elementID]} timeout="auto" unmountOnExit>
          <div className={classes.horizontal}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Pregunta"
                name={`question`}
                key={`text-field-${elementID}-1`}
                placeholder="¿Te gustan nuestros productos?"
                value={getNestedValue(components, [...path, 'question'])}
                onChange={(e) => textChangeHandler(e, path)}
                helperText="Título o pregunta que quieres que aparezca SOBRE la casilla"
                required
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.horizontal}>
              <TextField
                label="Nombre con el que se guardara el archivo"
                name={`fileName`}
                key={`text-field-${elementID}-3`}
                placeholder="Documento-CSF"
                value={getNestedValue(components, [...path, 'fileName'])}
                onChange={(e) => textChangeHandler(e, path)}
                helperText="El archivo se guardara como: nombreIngresado-HID segun el cliente que rellene el formulario"
                required
              />
            </Grid>
          </div>
          <div className={classes.horizontal}>
            <Grid item xs={12} md={4}>
              <TextField
                label="Texto que quieres que quede de ayuda"
                name={`helperText`}
                key={`text-field-${elementID}-4`}
                value={getNestedValue(components, [...path, 'helperText'])}
                onChange={(e) => textChangeHandler(e, path)}
                helperText="Texto que iría acá"
              />
            </Grid>
            <Grid className={classes.vertical} item xs={12} md={2}>
              <a className={classes.requiredSwitch}>
                {' '}
                ¿Es un campo obligatorio?
              </a>
              <Switch
                rightLabel="Sí"
                leftLabel="No"
                onChange={(e) => switchHandler(e, [...path, 'required'])}
                checked={getNestedValue(components, [...path, 'required'])}
              />
            </Grid>
          </div>
          <div className={classes.horizontal}>
            <Grid className={classes.vertical} item xs={12} md={6}>
              <Select
                isMulti
                options={fileOptions}
                key={`select-field-${elementID}-4`}
                label="Tipo de archivo"
                value={getNestedValue(components, [...path, 'extentions'])}
                onChange={(e) =>
                  fileTypeChangeHandler(e, [...path, 'extentions'])
                }
                helperText="Selecciona los tipos de archivos que deberia aceptar"
                placeholder=".pdf"
              />
            </Grid>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default FileElement;
