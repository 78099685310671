import React, { useEffect, useState } from 'react';
import {
  Page,
  Button,
  Loading,
  Accordion,
  AccordionItem,
  Snackbar,
} from '@beetrack/hp-components';
import { get, post } from '../../utils/api';

const Datawarehouse = (props) => {
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState('0');
  const [processing, setProcessing] = useState(false);
  const [snack, setSnackbar] = useState({
    message: '',
    status: 'success',
    open: false,
  });

  useEffect(() => {
    get('/datawarehouse/processes').then((response) => {
      if (response.status === 200) {
        setProcessing(response.data.current_process);
        setLoading(false);
      }
    });
  }, []);

  const startProcess = (type) => {
    post('/datawarehouse/process', { process_type: type }).then((response) => {
      if (response.status === 200) {
        setSnackbar({
          message: 'Proceso inicado correctamente',
          status: 'success',
          open: true,
        });
      } else {
        setSnackbar({
          message: 'Error iniciando proceso',
          status: 'error',
          open: true,
        });
      }
    });
  };

  return (
    <Page title={'Procesos DWH'}>
      {loading && (
        <center>
          <Loading />
        </center>
      )}
      {!loading && (
        <Accordion>
          <AccordionItem
            launcher={
              <p className="hp-typography__hero">Hubspot Stats Upload</p>
            }
            selected={selected === '1'}
            id="1"
            setSelected={setSelected}
            buttons={[
              <Button
                type="secondary"
                flat
                onClick={() => startProcess('stats')}
              >
                Procesar
              </Button>,
              <Button flat onClick={() => setSelected(null)}>
                Cerrar
              </Button>,
            ]}
            footer={
              processing ? (
                <p className="hp-typography__body--bold">
                  {' '}
                  ⚠️ Actualmente hay un proceso de carga en curso.{' '}
                </p>
              ) : (
                ''
              )
            }
          >
            <p className="hp-typography__hero"> Hubspot Stats Upload </p>
            <p className="hp-typography__body--regular">
              {' '}
              Este proceso carga a Hubspot propiedades acerca el uso de los
              productos de los clientes.
            </p>
          </AccordionItem>
          <AccordionItem
            launcher={
              <p className="hp-typography__hero">Hubspot Global Stats Upload</p>
            }
            selected={selected === '2'}
            id="2"
            setSelected={setSelected}
            buttons={[
              <Button
                type="secondary"
                flat
                onClick={() => startProcess('global_stats')}
              >
                Procesar
              </Button>,
              <Button flat onClick={() => setSelected(null)}>
                Cerrar
              </Button>,
            ]}
            footer={
              processing ? (
                <p className="hp-typography__body--bold">
                  {' '}
                  ⚠️ Actualmente hay un proceso de carga en curso.{' '}
                </p>
              ) : (
                ''
              )
            }
          >
            <p className="hp-typography__hero"> Hubspot Global Stats Upload </p>
            <p className="hp-typography__body--regular">
              {' '}
              Este proceso carga a Hubspot propiedades globales acerca del
              cliente como por ejemplo el número de facturas no pagadas,
              productos contratados, fecha de primera ruta y otros.
            </p>
          </AccordionItem>
          <AccordionItem
            launcher={
              <p className="hp-typography__hero">Intercom Properties Loader</p>
            }
            selected={selected === '3'}
            id="3"
            setSelected={setSelected}
            buttons={[
              <Button
                type="secondary"
                flat
                onClick={() => startProcess('intercom_loader')}
              >
                Procesar
              </Button>,
              <Button flat onClick={() => setSelected(null)}>
                Cerrar
              </Button>,
            ]}
            footer={
              processing ? (
                <p className="hp-typography__body--bold">
                  {' '}
                  ⚠️ Actualmente hay un proceso de carga en curso.{' '}
                </p>
              ) : (
                ''
              )
            }
          >
            <p className="hp-typography__hero"> Intercom Properties Loader </p>
            <p className="hp-typography__body--regular">
              {' '}
              Este proceso carga a Intercom propiedades de hubspot acerca del
              cliente.
            </p>
          </AccordionItem>
          <AccordionItem
            launcher={
              <p className="hp-typography__hero">HelpCenter Articles Loader</p>
            }
            selected={selected === '4'}
            id="4"
            setSelected={setSelected}
            buttons={[
              <Button
                type="secondary"
                flat
                onClick={() => startProcess('helpcenter_articles_loader')}
              >
                Procesar
              </Button>,
              <Button flat onClick={() => setSelected(null)}>
                Cerrar
              </Button>,
            ]}
            footer={
              processing ? (
                <p className="hp-typography__body--bold">
                  {' '}
                  ⚠️ Actualmente hay un proceso de carga en curso.{' '}
                </p>
              ) : (
                ''
              )
            }
          >
            <p className="hp-typography__hero"> HelpCenter Articles Loader </p>
            <p className="hp-typography__body--regular">
              {' '}
              Este proceso carga a articulos al HelpCenter desde intercom.
            </p>
          </AccordionItem>
        </Accordion>
      )}
      <Snackbar
        key="snackbar-status"
        isOpen={snack.open}
        message={snack.message}
        onDismiss={() =>
          setSnackbar({
            open: !snack.open,
            status: snack.status,
            message: '',
          })
        }
        status={snack.status}
      />
    </Page>
  );
};

export default Datawarehouse;
